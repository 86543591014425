import React, { useEffect, useState } from 'react';
import {
  Navbar,
  NavbarBrand,
  NavLink,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText, Nav,
  NavbarToggler, Collapse

} from 'reactstrap';
import './header.css';
// import LogoImg from '../assets/showcrafLogo.png';
import LogoImg from '../assets/showcraftLogo.png';
//import LandingPage from '../common/SideBar/SideBar';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { GoDashboard } from "react-icons/go";
import { AiFillProject } from "react-icons/ai";
import { SiGoogletagmanager } from "react-icons/si";
import { BsCalendarEventFill } from "react-icons/bs";
import Removecookie from '../modules/login/Removecookie';
import Getcookie from '../modules/login/Getcookie';
import miniUserIcon from '../assets/miniUserIcon.png';
import { ENCRYPTION_KEY, GET_EMP_DATAILS_BY_MCODE } from '../utiles/AllApis';
import { Get, Post } from '../utiles/APIService';
import axios from 'axios';
var CryptoJS = require("crypto-js");
const Header = (props) => {

  const [showlogindata, setShoeLoginData] = useState();
  const [redirectLink, setRedirectLink] = useState("/");
  const [handledrop, sethandledrop] = useState(true);
  const [timer, setTimer] = useState("");
  const [loginType, setLoginType] = useState("")
  const [userFname, setUserFname] = useState("");
  const [userLname, setUserLname] = useState("")

  const mainMenu = [
    {
      "name": "Dashboard",
      "link": "/Dashboard",
      //  "icon":<GoDashboard/>
    },
    {
      "name": "Manage Master",
      "link": "/manageMaster/employee_master",
      "icon": <SiGoogletagmanager />
    },
    {
      "name": "Projects",
      "link": "/projectList",
      "icon": <AiFillProject />
    },
    {
      "name": "Running Project",
      "link": "/ItemMaster",
      "icon": <BsCalendarEventFill />
    },
    {
      "name": "Daily Update",
      "link": "/dailyUpdate",
      "icon": <BsCalendarEventFill />
    }, {
      "name": "Reports",
      "link": "/reports",
      "icon": <BsCalendarEventFill />
    }
  ]

  const handledropdown = (e) => {
    // alert("fsdf")
    sethandledrop(false)
  }
  const handleup = () => {
    sethandledrop(true);
  }
  useEffect(() => {
    if (props.isAuthenticated) {
      const decrypted = CryptoJS.AES.decrypt(Getcookie("ERPUser"), ENCRYPTION_KEY);
      const tokectDetails = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      console.log(tokectDetails.mcode)
      setLoginType(tokectDetails?.logintype);
      setShoeLoginData(true);
      axios.post(GET_EMP_DATAILS_BY_MCODE,
        {
          "EmployeeID": tokectDetails?.mcode
        }
      ).then((response) => {
        if (response.data.isSuccess === true) {
          setUserFname(response?.data?.data[0]?.firstName);
          setUserLname(response?.data?.data[0]?.lastName);
        }

      });
    }
  }, []);

  //  useEffect(()=>
  // {
  // if(props.isAuthenticated)
  // {
  // setTimer(setTimeout(() => {
  //   if((Getcookie("ERPUser")===null || Getcookie("ERPUser")==="" && Getcookie("ERPUser")!==undefined))
  //   {
  //      window.location.href="/";
  //   }
  //if((Getcookie("AspxAutoDetectCookieSupport")===null || Getcookie("AspxAutoDetectCookieSupport")==="" && Getcookie("AspxAutoDetectCookieSupport")!==undefined))
  // if((Getcookie(".AspNetCore.Cookies")===null || Getcookie(".AspNetCore.Cookies")==="" && Getcookie(".AspNetCore.Cookies")!==undefined))
  // {
  //   Removecookie("ERPUser");
  //   window.location.href="/";
  // }
  //  },1000));
  // }
  //},[timer])
  const logout = () => {
    window.location.href = "/";
    Removecookie('ERPUser');
    Removecookie('.AspNetCore.Cookies');
  }

  return (
    <div>
      <Navbar
        expand="md"
        light
        className='headerBgColor px-3'
      >
        <NavbarBrand href={redirectLink} className='text-white py-0'>
          <img src={LogoImg} alt="logoImg" height={45}></img>
        </NavbarBrand>{showlogindata ? <span className='mt-3'><span className='text-white h6 '>Welcome {userFname} {userLname} </span></span> : null}
        {/* <div className='topgun'>TOP GUN</div> */}
        <NavbarToggler onClick={handledropdown} />
        {showlogindata ? <Collapse navbar>
          <Nav
            className="me-auto header"
            navbar
          >
          </Nav>

          <img src={miniUserIcon} alt="user" style={{ width: "4%", zIndex: "999" }} />
          <NavbarText style={{ backgroundColor: "#ed3137", width: "12%", boxShadow: "0 0 50px 0 rgb(191 31 31)", marginLeft: "-17px", fontSize: "12px", textAlign: "center" }} className='px-2 py-0 rounded-pill'>

            {loginType}

          </NavbarText>
          <Nav>
            <UncontrolledDropdown

              nav
            >
              <DropdownToggle
                caret
                nav
                className='emptyDropDown'
                style={{ marginLeft: "-22px" }}
              >
              </DropdownToggle >
              <DropdownMenu >
                <DropdownItem onClick={logout}>
                  Logout
                </DropdownItem>

              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse> : null}


      </Navbar>
      {
        handledrop ?
          "" :

          <ul className='sideBarnav  text-center ' >
            <p style={{ position: "absolute", right: "20px" }} onClick={handleup}>❌</p>
            {
              mainMenu?.map((data, i) => {
                return (<li key={i} className="py-2 menuListingItems" ><a href={`${data.link}`} className="listMenu" ><span className='px-2'  >{data.icon}</span>{data.name}</a></li>)
              })
            }

          </ul>


      }

    </div>
  )
}
export default Header;