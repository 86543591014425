import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import EmpSearch from '../../../../common/EmpSearch/EmpSearch';
import axios from 'axios';
import { VscError } from 'react-icons/vsc';
import './salaryStructure.css';
import {
  ALLOWED_USERTYPE, GET_SALARY_STRUCTURE, CREATE_SLY_STRUCTURE, UPDATE_SLY_STRUCTURE,
  GET_EMP_CHANGES_BY_MCODE, DELETE_SLY_STRUCTURE, GET_ALL_EMP_ACC, GET_ALL_COL_NAME
} from '../../../../utiles/AllApis';
import ErrorAlertBox from '../../../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../../../common/AlertBox/SuccessAlertBox';
import { Modal, ModalBody } from 'reactstrap';
import { onlynumerical, checkSpecialChar } from "../../../../GenericFunction/GenericFunction";
function SlryStructure(props) {
  const [isLoading, setLoading] = useState(false);
  const [structureData, setStructureData] = useState([]);
  const [allAccList, setAllAccList] = useState([]);
  const [uType, setUType] = useState(props.tokectDetails.logintype);
  const [userID, setUserID] = useState(props.tokectDetails.mcode);
  const [selecedUserID, setSelectedUserID] = useState(uType === ALLOWED_USERTYPE.ADMIN ? "" : props.tokectDetails.mcode);
  const [seletedeData, setseletedeData] = useState({});
  const [AllColumns, setAllColumns] = useState([]);
  const [isadd, setIsadd] = useState(false);
  const [forFixVal, setForFixVal] = useState("fix");
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpencomfirm, setIsOpencomfirm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [rowpos, setRowpos] = useState("");
  const [heading, setHeading] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const handalOpenErr = () => { setIsOpen(true); }
  const handalOpen1 = () => { setIsOpen1(true); }
  const handalCloseconfim = () => { setIsOpencomfirm(false); }

  const [slryStrct, setSlryStrct] = useState(
    {
      "EmployeeID": selecedUserID,
      "wef": "",
      "wet": "",
      "type": "",
      "nature": "",
      "seq": 0,
      "salaryHead": "",
      "printName": "",
      "formulaBased": false,
      "fix": true,
      "none": false,
      //"pcnt": 0,
      "lop": false,
      "wod": false,
      "maxvalue": 0,
      "arrear_payble": false,
      "salary_ac": "",
      "contra_ac": "",
      //"query":"",
      //"calcmethod":"",
      "calcval": ""
    });
  useEffect(() => {
    setSlryStrct({ ...slryStrct, "EmployeeID": selecedUserID })
    getDetails();
    getAllAccList();
    GetAllColName();
  }, [selecedUserID])
  const addDetails = () => {
    setIsadd(true);
    setSlryStrct({});
    setIsEdit(false);
  }
  //------------get pay salary structure---------
  function getAllAccList() {
    setLoading(true);
    axios.get(GET_ALL_EMP_ACC).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setAllAccList(response.data.data);

      }
      else {
        setAllAccList([]);
      }
    })
  }
  //------------get all relative details--------
  function getDetails() {
    if (uType === ALLOWED_USERTYPE.ADMIN) {
      getAllEmgncyDtls(selecedUserID);
    }
    else {
      getAllEmgncyDtls(userID);
    }
  }
  //---------------get all Relative/emergency details--------------
  const getAllEmgncyDtls = (mcode) => {

    //-----------gey all details------------
    if (mcode == "") {
      setLoading(true);
      axios.get(GET_SALARY_STRUCTURE).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setStructureData(response.data.data);
        }
        else {
          setStructureData([]);
        }
      })
    }
    //-----------gey details by userMCode------------
    else {
      setLoading(true);
      axios.post(GET_EMP_CHANGES_BY_MCODE, {
        "EmployeeID": mcode
      }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setStructureData(response.data.data);
        }
        else {
          setStructureData([]);
        }
      })
    }
  }
  //---------------get relatives details--------------
  const onHandleChange = (e) => {

    if (e.target.name === "lop" || e.target.name === "wod" || e.target.name === "arrear_payble") {
      e.target.value = e.target.checked;
    }
    if (e.target.name === "ForFixVal" || e.target.name === "fix" || e.target.name === "formulaBased" || e.target.name === "none") {
      e.target.name = e.target.id;
      e.target.value = e.target.checked;
      setForFixVal(e.target.id);
    }
    setSlryStrct({ ...slryStrct, [e.target.name]: e.target.value });
  }
  //--------------get all columns -----------
  const GetAllColName = () => {
    setLoading(true);
    axios.get(GET_ALL_COL_NAME).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setAllColumns(response.data.data);
      }
      else {
        setAllColumns([]);
      }

    })
  }
  //---------add Emergency relative details---------------
  const handlesubmit = () => {
    if (slryStrct?.wet == "" || slryStrct?.wef == "" || slryStrct?.type == "" || slryStrct?.nature == "") {
      setHeading("Failed");
      setErrMsg("Mandatory fields cannot empty");
      handalOpenErr();
    }
    else {
      if (new Date(slryStrct.wef) > new Date(slryStrct.wet)) {
        setHeading("Failed");
        setErrMsg("Effective date cannot greater then upto valid date.");
        handalOpenErr();
        return;
      }
      var str1 = slryStrct?.calcval?.replace(/[&\/\\#,+()$~%.'":*?<>{}0-9]/g, " ");
      if (str1 !== undefined) {
        str1 = str1?.split(" ");
        str1 = str1?.filter((i) => (i !== ""));
        if (!str1?.some(item => AllColumns?.map(data => data?.printName?.includes(item)))) {
          setHeading("Calculated formula is not valid.");
          setErrMsg("Formula must be based on salary head only.");
          handalOpenErr();
          return;
        }
      }
      setLoading(true);
      axios.post(CREATE_SLY_STRUCTURE, {
        "EmployeeID": selecedUserID,
        "wef": slryStrct.wef,
        "wet": slryStrct.wet,
        "type": slryStrct.type,
        "nature": slryStrct.nature,
        "seq": slryStrct.seq,
        "salaryHead": slryStrct.salaryHead,
        "printName": slryStrct.printName,
        "formulaBased": slryStrct.formulaBased,
        "fix": slryStrct.fix,
        "none": slryStrct.none,
        // "pcnt": slryStrct.pcnt,
        "lop": slryStrct.lop,
        "wod": slryStrct.wod,
        "maxvalue": slryStrct.maxvalue,
        "arrear_payble": slryStrct.arrear_payble,
        "salary_ac": slryStrct.salary_ac,
        "contra_ac": slryStrct.contra_ac,
        //"query":slryStrct.query,
        //"calcmethod":slryStrct.calcmethod,
        "calcval": slryStrct.calcval
      }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setSlryStrct({});
          setHeading1("Success");
          setsuccessMsg("added successfully");
          handalOpen1();
          setIsadd(false);
          getDetails();
        }
        else {
          setHeading("Failed");
          setErrMsg(response.data.message);
          handalOpenErr();
        }
      })
    }
  }

  //---------update Emergency relative details---------------
  const handleUpdate = () => {
    setSlryStrct(
      {
        "EmployeeID": selecedUserID,
        "wef": "",
        "wet": "",
        "type": "",
        "nature": "",
        "seq": 0,
        "salaryHead": "",
        "printName": "",
        "formulaBased": false,
        "fix": false,
        "none": false,
        // "pcnt": 0,
        "lop": false,
        "wod": false,
        "maxvalue": 0,
        "arrear_payble": false,
        "salary_ac": "",
        "contra_ac": "",
        //"query":"",
        //"calcmethod":"",
        "calcval": ""
      }
    )
    setIsEdit(false);
    if (slryStrct?.wet == "" || slryStrct?.wef == "" || slryStrct?.type == "" || slryStrct?.nature == "") {
      setHeading("Failed");
      setErrMsg("Mandatory fields cannot empty");
      handalOpenErr();
    }
    else {
      if (new Date(slryStrct.wef) > new Date(slryStrct.wet)) {
        setHeading("Failed");
        setErrMsg("Effective date cannot greater then upto valid date.");
        handalOpenErr();
        return;
      }
      setLoading(true);
      axios.post(UPDATE_SLY_STRUCTURE, {
        "EmployeeID": selecedUserID,
        "wef": slryStrct.wef,
        "wet": slryStrct.wet,
        "type": slryStrct.type,
        "nature": slryStrct.nature,
        "seq": slryStrct.seq,
        "salaryHead": slryStrct.salaryHead,
        "printName": slryStrct.printName,
        "formulaBased": slryStrct.formulaBased,
        "fix": slryStrct.fix,
        "none": slryStrct.none,
        //"pcnt": slryStrct.pcnt,
        "lop": slryStrct.lop,
        "wod": slryStrct.wod,
        "maxvalue": slryStrct.maxvalue,
        "arrear_payble": slryStrct.arrear_payble,
        "salary_ac": slryStrct.salary_ac,
        "contra_ac": slryStrct.contra_ac,
        "ROWPOS": rowpos,
        //"query":slryStrct.query,
        //"calcmethod":slryStrct.calcmethod,
        "calcval": slryStrct.calcval
      }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setHeading1("Success");
          setsuccessMsg("Record update successfully..");
          handalOpen1();
          setIsadd(false);
          getDetails();
          setSlryStrct({});
        }
        else {
          setHeading("Failed");
          setErrMsg(response.data.message);
          handalOpenErr();
        }
      })
    }
  }

  //-------------Edit emergency details------------------
  const handelEdit = (id, wef, wet, type, nature, seq, salaryHead, printName, FrmBsed, fix, lop, wod, maxvalue, arrear_payble, salary_ac, contra_ac, calcval) => {
    // setLoading(true);
    setRowpos(id);
    setIsEdit(true);
    setIsadd(true);
    setSlryStrct({
      "EmployeeID": selecedUserID,
      "wef": wef,
      "wet": wet,
      "type": type,
      "nature": nature,
      "seq": seq,
      "salaryHead": salaryHead,
      "printName": printName,
      "": FrmBsed,
      "fix": fix,
      //"pcnt": pcnt,
      "lop": lop,
      "wod": wod,
      "maxvalue": maxvalue,
      "arrear_payble": arrear_payble,
      "salary_ac": salary_ac,
      "contra_ac": contra_ac,
      //"query":query,
      //"calcmethod":calcmethod,
      "calcval": calcval
    });
  }
  //-------------Delete emergency details------------------
  const handelDelete = (id) => {
    handalCloseconfim();
    setRowpos(id);
    setLoading(true);
    axios.post(DELETE_SLY_STRUCTURE, { "ROWPOS": id }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {

        getDetails();
      }
      else {
        setHeading("Failed");
        setErrMsg(response.data.message);
        handalOpenErr();
      }
    })
  }
  return (
    <>
      {
        isLoading ? <div className='loaderDiv'>
        </div> : null
      }
      <div className='emp-aboutdetailmaincontent'>
        <div className={uType === ALLOWED_USERTYPE.ADMIN ? 'mnusTopMrgn' : ''}>
          <h5 className='text-white fw-bold mt-2 text-center'>Salary Structure</h5>
          <hr className='myhr text-white' />
        </div>
        <div className='row'>
          <div className='col-md-2 col-lg-2 col-sm-2 col-2 mt-1'>
            {
              isadd ? <button className='btn btnSuccess' onClick={e => { setIsadd(false); }}>View List</button>
                :
                <button className='btn btnSuccess' onClick={e => { addDetails(); }}>Add Details</button>
            }
          </div>
        </div>
        {
          isadd ?
            <div className={uType === ALLOWED_USERTYPE.ADMIN ? '' : 'mnusTopMrgn'}>
              <h5 className='SecoHead text-center'>Defined Salary Structure</h5>
              <hr className='myhr text-white' />
              <div className='emp_profile_form'>
                <div className='row'>
                  <div className='col-md-8 col-sm-8 col-8'>
                    <div className='row'>
                      <div className='col-md-3 col-sm-4 col-6 mt-3'>
                        <label
                          htmlFor="wef"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          With Effective Form<span className='required'>*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control w-100 mt-2"
                          value={slryStrct.wef?.split("T")[0]}
                          id="wef"
                          name="wef"
                          max="9999-12-31"
                          onChange={(e) => onHandleChange(e)}
                        />
                      </div>
                      <div className='col-md-3 col-sm-4 col-6 mt-3'>,
                        <label
                          htmlFor="wet"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          Valid Upto<span className='required'>*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control w-100 mt-2"
                          value={slryStrct?.wet?.split("T")[0]}
                          id="wet"
                          name="wet"
                          max="9999-12-31"
                          onChange={(e) => onHandleChange(e)}
                        />
                      </div>
                      <div className='col-md-3 col-sm-4 col-6 mt-3'>
                        <label
                          htmlFor="type"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          Type<span className='required'>*</span>
                        </label>
                        <select class="form-select mt-2 w-100" value={slryStrct?.type}
                          aria-label="Default select example" name="type" id="type" onChange={e => { onHandleChange(e) }}>
                          <option value="">--Select Type--</option>
                          <option value="A">Basic Salary</option>
                          <option value="B">Allowance</option>
                          <option value="R">Reimbursument</option>
                          <option value="P">perks</option>
                          <option value="D">Deduction</option>
                          <option value="X">Employer Contribution</option>
                        </select>
                      </div>
                      <div className='col-md-3 col-sm-4 col-6 mt-3'>
                        <label
                          htmlFor="nature"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          Nature<span className='required'>*</span>
                        </label>
                        <select class="form-select mt-2 w-100" value={slryStrct.nature}
                          aria-label="Default select example" name="nature" id="nature" onChange={e => { onHandleChange(e) }}>
                          <option value="">--Select Nature--</option>
                          <option value="SALARY">Salary</option>
                          <option value="LOAN">Loan</option>
                          <option value="OVERTIME">Over Time</option>
                          <option value="DOUBLEOT">Double OT</option>
                          <option value="EMPLOYER">employer</option>
                        </select>
                      </div>
                      <div className='col-md-3 col-sm-4 col-6 mt-3'>
                        <label
                          htmlFor="seq"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          Seq
                        </label>
                        <input
                          type="text"
                          className="form-control w-100 mt-2"
                          id="seq"
                          name="seq"
                          value={slryStrct?.seq}
                          placeholder="Enter Basic pay of employee Here..."
                          onChange={(e) => onHandleChange(e)}
                          onKeyPress={onlynumerical}
                        />
                      </div>
                      <div className='col-md-3 col-sm-4 col-6 mt-3'>
                        <label
                          htmlFor="salaryHead"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          Salary Head<span className='required'>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control w-100 mt-2"
                          id="salaryHead"
                          name="salaryHead"
                          value={slryStrct?.salaryHead}
                          placeholder="Enter Basic pay of employee Here..."
                          onKeyPress={checkSpecialChar}
                          onChange={(e) => onHandleChange(e)}
                        />
                      </div>
                      <div className='col-md-3 col-sm-4 col-6 mt-3'>
                        <label
                          htmlFor="printName"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          Print Name<span className='required'>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control w-100 mt-2"
                          id="printName"
                          name="printName"
                          value={slryStrct?.printName}
                          placeholder="Enter Basic pay of employee Here..."
                          onChange={(e) => onHandleChange(e)}
                          onKeyPress={checkSpecialChar}
                        />
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-1 col-3 mt-3 gridBorder'>
                        <label
                          htmlFor="formulaBased"
                          className="form-label fw-normal h6 text-capitalize"
                        >Formula Based</label>
                        <div className="form-check ">
                          <input className="form-check-input" type="radio" name="ForFixVal" id="formulaBased"
                            onChange={(e) => onHandleChange(e)}
                            checked={forFixVal === "formulaBased" ? true : false}
                          />
                        </div>

                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-1 col-3 mt-3 gridBorder'>
                        <label
                          htmlFor="fix"
                          className="form-label fw-normal h6 text-capitalize"
                        >Fixed Amount</label>
                        <div className="form-check ">
                          <input className="form-check-input" type="radio" name="ForFixVal" id="fix"
                            onChange={(e) => onHandleChange(e)}
                            checked={forFixVal === "fix" ? true : false}
                          />
                        </div>
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-1 col-3 mt-3 gridBorder'>
                        <label
                          htmlFor="none"
                          className="form-label fw-normal h6 text-capitalize"
                        >None</label>
                        <div className="form-check ">
                          <input className="form-check-input" type="radio" name="ForFixVal" id="none"
                            onChange={(e) => onHandleChange(e)}
                            checked={forFixVal === "none" ? true : false}
                          />
                        </div>
                      </div>
                      {/* <div className='col-md-3 col-sm-4 col-6 mt-3'>
                    <label
                      htmlFor="pcnt"
                      className="form-label fw-normal h6 text-capitalize"
                    >
                      Parcentage
                    </label>
                    <input
                      type="text"
                      className="form-control w-100 mt-2"
                      id="pcnt"
                      name="pcnt"
                      value={slryStrct?.pcnt}
                      placeholder="Enter Basic pay of employee Here..."
                      onChange={(e) => onHandleChange(e)}
                      onKeyPress={onlynumerical}
                      disabled={forFixVal === "fix" ? true : false}
                    />
                  </div> */}
                      <div className='col-md-3 col-sm-4 col-6 mt-3'>
                        <label
                          htmlFor="maxvalue"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          Maximum value
                        </label>
                        <input
                          type="text"
                          className="form-control w-100 mt-2"
                          id="maxvalue"
                          name="maxvalue"
                          value={slryStrct?.maxvalue}
                          placeholder="Enter Basic pay of employee Here..."
                          onChange={(e) => onHandleChange(e)}
                          onKeyPress={onlynumerical}
                          disabled={forFixVal === "formulaBased" ? false : true}
                        />
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-1 col-3 mt-3 gridBorder'>
                        <label
                          htmlFor="lop"
                          className="form-label fw-normal h6 text-capitalize"
                        >Deduct loss of pay</label>
                        <div className="form-check ">
                          <input className="form-check-input" type="checkbox" name="lop" id="lop"
                            value={slryStrct?.lop}
                            onChange={(e) => onHandleChange(e)}
                            checked={slryStrct?.lop}
                          />
                        </div>
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-1 col-3 mt-3 gridBorder'>
                        <label
                          htmlFor="wod"
                          className="form-label fw-normal h6 text-capitalize"
                        >Ignore week day off </label>
                        <div className="form-check ">
                          <input className="form-check-input" type="checkbox" name="wod" id="wod"
                            value={slryStrct?.wod}
                            onChange={(e) => onHandleChange(e)}
                            checked={slryStrct?.wod}
                          />
                        </div>
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-1 col-3 mt-3 gridBorder'>
                        <label
                          htmlFor="arrear_payble"
                          className="form-label fw-normal h6 text-capitalize"
                        >Arrear Payable </label>
                        <div className="form-check ">
                          <input className="form-check-input" type="checkbox" name="arrear_payble" id="arrear_payble"
                            value={slryStrct?.arrear_payble}
                            onChange={(e) => onHandleChange(e)}
                            checked={slryStrct?.arrear_payble}
                          />
                        </div>
                      </div>

                      <div className='col-md-3 col-sm-4 col-6 mt-3'>
                        <label
                          htmlFor="salary_ac"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          Payable Account<span className='required'>*</span>
                        </label>
                        <select class="form-select mt-2 w-100" value={slryStrct?.salary_ac}
                          aria-label="Default select example" name="salary_ac" id="salary_ac" onChange={e => { onHandleChange(e) }}>
                          <option value=""> --Select Payable Account Name--</option>
                          {
                            allAccList.map((data, i) => {
                              return (<option key={i} value={data.salaryac}>{data.employeeName}</option>)
                            })
                          }

                        </select>
                      </div>
                      <div className='col-md-3 col-sm-4 col-6 mt-3'>
                        <label
                          htmlFor="contra_ac"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          Contra Account<span className='required'>*</span>
                        </label>
                        <select class="form-select mt-2 w-100" value={slryStrct?.contra_ac}
                          aria-label="Default select example" name="contra_ac" id="contra_ac" onChange={e => { onHandleChange(e) }}>
                          <option value="">--Select Contra Account--</option>
                          {
                            allAccList.map((data, i) => {
                              return (<option key={i} value={data.imprestac}>{data.employeeName}</option>)
                            })
                          }
                        </select>
                      </div>
                      <div className='col-md-9 col-sm-9 col-9 mt-5 text-center'>
                        {
                          isEdit ?
                            <button className='btn btnSuccess' onClick={handleUpdate} ><span>Update</span></button> :
                            <button className='btn btnSuccess' onClick={handlesubmit} ><span>Submit</span></button>
                        }
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-4 col-4 '>
                    <div className='row'>
                      {/* <div className='col-md-12 col-sm-12 col-12 mt-3 h-18vh gridBorder'>
                  <label
                      htmlFor="query"
                      className="form-label fw-normal h6 text-capitalize"
                    >
                     Nature of stoerd procedure-Name of stoerd procedure to be executed for result 
                    </label>
                    <input
                      type="text"
                      className="form-control w-100 mt-2 h-50"
                      id="query"
                      name="query"
                      value={slryStrct?.query}
                      placeholder="Enter Nature/Name of stoerd procedure-Name Here..."
                      onChange={(e) => onHandleChange(e)}
                      disabled={forFixVal === "fix" ? true : false}
                    />
                       </div> */}
                      <div className='col-md-12 col-sm-12 col-12 h-18vh gridBorder mt-50px'>
                        <label
                          htmlFor="calcval"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          Actual Calculation Formula
                        </label>
                        <input
                          type="text"
                          className="form-control w-100 mt-2 h-60"
                          id="calcval"
                          name="calcval"
                          value={slryStrct?.calcval}
                          placeholder="Enter Actual Calculation Formula Here..."
                          onChange={(e) => onHandleChange(e)}
                          disabled={forFixVal === "fix" ? true : false}
                        />
                      </div>
                      {/* <div className='col-md-12 col-sm-12 col-12 mt-3 h-18vh gridBorder'>
                  <label
                      htmlFor="calcmethod"
                      className="form-label fw-normal h6 text-capitalize"
                    >
                      Salary Payment Calculation
                    </label>
                    <input
                      type="text"
                      className="form-control w-100 mt-2 h-60"
                      id="calcmethod"
                      name="calcmethod"
                     value={slryStrct?.calcmethod}
                      placeholder="Enter Salary Payment Calculation Here..."
                      onChange={(e) => onHandleChange(e)}
                      disabled={forFixVal === "fix" ? true : false}
                    />
                       </div> */}
                    </div>
                  </div>
                </div>
              </div></div>
            :
            structureData?.length > 0 ?
              <div className={`${uType === ALLOWED_USERTYPE.ADMIN ? "emgncy" : "emgncyFull"} table-responsive mt-3`}>
                <table className="table table-bordered border-secondary ">
                  <thead className='tblHeader'>
                    <tr>
                      <th scope="col text-white">#</th>
                      <th scope="col text-white">Created At</th>
                      <th scope="col text-white">Effective from</th>
                      <th scope="col text-white">Valid upto</th>
                      <th scope="col text-white">Type</th>
                      <th scope="col text-white">Sequence</th>
                      <th scope="col text-white">Nature</th>
                      <th scope="col text-white">Payment Head</th>
                      <th scope="col text-white">Display Name</th>
                      <th scope="col text-white" className={uType !== ALLOWED_USERTYPE.ADMIN ? "d-none" : ""}>Edit</th>
                      <th scope="col text-white" className={uType !== ALLOWED_USERTYPE.ADMIN ? "d-none" : ""}>Delete</th>
                    </tr>
                  </thead>
                  <tbody className='tblBdy'>
                    {
                      structureData?.map((data, i) => {
                        return (<tr key={i}>
                          <th scope="row">{i + 1}</th>
                          <td>{data.edate?.slice(0, 10)}</td>
                          <td>{data.wef?.slice(0, 10)}</td>
                          <td>{data.wet?.slice(0, 10)}</td>
                          <td>{data.type}</td>
                          <td>{data.seq}</td>
                          <td>{data.nature}</td>
                          <td>{data.salaryHead}</td>
                          <td>{data.printName}</td>
                          <td className={uType !== ALLOWED_USERTYPE.ADMIN ? "d-none" : ""}>
                            <button className='btn btnEdit' onClick={e => { handelEdit(data.rowpos, data.wef, data.wet, data.type, data.nature, data.seq, data.salaryHead, data.printName, data.FrmBsed, data.fix, data.lop, data.wod, data.maxvalue, data.arrear_payble, data.salary_ac, data.contra_ac, data.calcval) }}>Edit</button></td>
                          <td className={uType !== ALLOWED_USERTYPE.ADMIN ? "d-none" : ""}>
                            <button className='btn btnDelete' onClick={e => { setRowpos(data.rowpos); setIsOpencomfirm(true); }}>Delete</button></td>
                        </tr>);
                      }
                      )
                    }

                  </tbody>
                </table>
              </div>

              :
              <p className='text-white text-center mt-5'>No Data Found!!</p>
        }

      </div>
      <Modal className="fade show" isOpen={isOpencomfirm} >
        <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
          <p className=' text-center text-danger fw-bold'><VscError className='fw-bolder mx-2 my-2 h2' />Are you sure you want to Delete</p>
          <div>   <Button
            className='btnSuccess mx-2'
            onClick={() => { handelDelete(rowpos) }}
          >
            Yes
          </Button>
            <Button
              className='btnDelete mx-2'
              onClick={handalCloseconfim}
            >
              No
            </Button></div>
        </ModalBody>

      </Modal>
      <ErrorAlertBox isOpen={isOpen} setIsOpen={setIsOpen} heading={heading} errMsg={errMsg} />
      <SuccessAlertBox
        isOpen1={isOpen1}
        setIsOpen1={setIsOpen1}
        heading1={heading1}
        successMsg={successMsg}
      />
    </>
  )
}

export default SlryStructure;