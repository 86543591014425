 //--------check numerical for from---------------
function onlynumerical(event) {
    var keyCode = event.charCode;
  
    if ((keyCode >= 48 && keyCode <= 57) || (keyCode === 8) || (keyCode === 39)) {
    }
    else
    {
        event.preventDefault(); 
    }
    
 }
  //--------check Special character for from---------------
function checkSpecialChar(event) {
   var keyCode = event.charCode;
 
   if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90)|| (keyCode >= 97 && keyCode <= 122)) {
   }
   else
   {
       event.preventDefault(); 
   }
   
}
 //--------check for excel---------------
 const  onlynumericalForExcel=(eve) =>{
    let rest=false;
 if(typeof(eve)==="number")
 {
    rest=true;
 }
    return rest;
 }

  //--------check for excel---------------
  const  onlyalphbt=(event) =>{
   var keyCode = event.charCode;
 
   if ((keyCode >= 65 && keyCode <= 90)|| (keyCode >= 97 && keyCode <= 122)) {
   }
   else
   {
       event.preventDefault(); 
   }
}
 export {onlynumerical,onlynumericalForExcel,checkSpecialChar,onlyalphbt}