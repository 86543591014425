import React,{useEffect, useState} from 'react';
import ProjectCards from '../projets/ProjectCards';
import '../projets/project.css';
import SideBar from '../../common/SideBar/SideBar';
import {
    Input
} from 'reactstrap';
import axios from 'axios';
import { GrSearch } from "react-icons/gr";
import FloatingBtn from '../../common/Buttons/FloatingBtn';
import Pagination from '../manageMaster/common/cardPagination';
import {GET_EVENT, GET_PROJECT} from '../../utiles/AllApis';
import './item.css';
import { Link } from 'react-router-dom';
const ItemList=()=>
{
   let searctVal="";
    const [cardLength,setCardLength]=useState(0);
    const [showParPage,setShowParPage]=useState(8);
    const [cards,setCards]=useState();
    const [pagination,setPagination]=useState(
        {
            start:0,
            end:showParPage
        }
    );
    const paginationChange=(start,end)=>
    {
        setPagination({start:start,end:end});
    }
    const searchFilterData=(e)=>
    {
        searctVal=e.target.value;
        getAllRunningProjects();  
    }
    useEffect(()=>
    {
        getAllRunningProjects();  
    },[]);
    const getAllRunningProjects=()=>
    {
        
        axios.get(GET_EVENT).then((response) => {
            if(response.data.isSuccess===true)
            {
          
            setCardLength(response?.data?.data?.length);
             const res= response?.data.data?.filter((data=>
                    {
                    if(!searctVal)
                    {
                        return data;
                    }
                    else if(data?.projectName?.toLowerCase().includes(searctVal.toLowerCase()))
                    {
                        return data; 
                    }
                    })).slice(pagination.start,pagination.end).map((data,i)=>{
                     return( <div key={i}className='col-sm-4 col-md-3 col-6'>
                     <ProjectCards rowpos={data.rowpos} page={`itemmaster`} img={data.Logo}projectName={data.projectName}projectDetails={data.description} created={data.edate} />
                     </div>)
                 }
                 )
                 setCards(res);
            }
            else
            {
                setCards(<p className='my-5'>Opps..No Running Projects Found!!</p>)
            }
           });

    }
    return(
        <div className='container-fluid'>
        <div className='row '>
        <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
         <SideBar/>
         </div>
         <div className='col-md-10 col-12 col-sm-12 '>
     <div className='mx-4 my-1'>
         <div className='row'>
         <h3 className='py-3 text-center mailHeading theamColor'>RUNNING PROJECTS</h3>
    <div className='row'>
    <div className='col-md-8'></div>
    <div className='col-md-4 col-11 col-sm-11 my-2 px-3'>
            <GrSearch className='searchIconNew'/>
        <Input
          id="seaarch"
          name="search"
          placeholder="Search Here...."
          type="text" 
          onChange={searchFilterData}
        />
        </div>
    </div>

       {cards}
     
              <div className='my-3'>
                   <Pagination cardLength={cardLength}showParPage={showParPage}paginationChange={paginationChange}/> 
                {/* <FloatingBtn className="tracking-in-expand-fwd-bottom" href='/ItemList/form' usehtmlFor="Add Item"/> */}
                </div>
                  
         </div>
     </div>
     </div>
                  
                  </div>
              </div>
    )
}
export default ItemList;