import React,{useEffect, useState} from 'react';
import {
    CardGroup,
    Card,
    // CardImg,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,

} from 'reactstrap';
import ButtonEdit from '../../common/Buttons/ButtonEdit';
import ButtonDelete from '../../common/Buttons/ButtonDelete';
const Dpt=(props)=>
{
 
  const[redirectionForm,setRedirectionForm]=useState(`${props.formRedirection}?id=${props.rowpos}`) 
    return(

<CardGroup className='crdGrp  tracking-in-expand-fwd-bottom m-0'>
  <Card className='crdBody'>
    {/* <CardImg
      alt={props.alt}
      src="https://picsum.photos/318/180"
      top
      width="100%"
    /> */}
    <CardBody className='myshadowcard'>
    <div>
      {/* <CardTitle tag="h6">
      {props.id}
      </CardTitle> */}
  <div className='mainHeadiing'>
  <CardSubtitle
        className="mb-2 text-muted mytitle"
        tag="h6"
      >
       {props.name}
      </CardSubtitle>
  </div>
      <hr className="cardLine m-2"/>
      <div className='empDetails'>
     <CardText className='text-start'>
      <span className='fw-bold'> Mobile:</span> {props.mobile===""?"--NA--":props.mobile}
      </CardText >
      <CardText className='text-start'>
      <span className='fw-bold'>Altr Mobile:</span> {props.contactMobile===""?"--NA--":props.contactMobile}
      </CardText>
      <CardText className='text-start'>
      <span className='fw-bold'>Contact Name:</span> {props.contactName===""?"--NA--":props.contactName}
      </CardText>
      <CardText className='text-start'>
      <span className='fw-bold'>GST No:</span> {props.gst===""?"--NA--":props.gst}
      </CardText>
      <CardText className='text-start'>
      <span className='fw-bold'>Address:</span> {props.address}
      </CardText>
      <CardText className='text-start'>
      <span className='fw-bold'>Email:</span> {props.email===""?"--NA--":props.email}
      </CardText>
     </div>
     </div>
      {/* <ButtonSuccess btnTitle="Add"/> */}
     <ButtonEdit btnTitle="Edit" href={`${props.formRedirection}?id=${props.rowpos}`}/> 
      <ButtonDelete btnTitle="Delete"usehtmlFor="dept"rowpos={props.rowpos}onDelete={props.onDelete}/> 
    </CardBody>
  </Card>
</CardGroup>
    )
}
export default Dpt;