import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from 'react-router-dom';
import { GET_ALL_PROJECTS,GET_runningprojects } from '../../utiles/AllApis';

function DashDesign() {
    const [isLoading, setLoading] = useState(false);
    const [viewAllPro, setViewAllPro] = useState(false);
    const [one, setone] = useState(66);
    const [projectsCard,setprojectsCard] = useState([]);
    const [running,setrunning] = useState([]);
    const projectsCardForDashboard=[];
    var runningpro = [];
    useEffect(() => {
        axios.get(GET_runningprojects).then((response) => {
            setLoading(false);
          if(response.data.isSuccess===true)
          {
            // var dataRunning= response.data.data.filter((data=>{
            //     if(data.lst_InternalDocuments.length===0)
            //     {
            //         return data; 
            //     }
            //     }))
                setrunning(response.data.data);
                 
            }
           });
        axios.get(GET_ALL_PROJECTS).then((response)=>{
            if(response.data.isSuccess===true)
            {
                setprojectsCard(response.data.data);
            }
        })
    }, []);
const handleViewAllPro=()=>
{
    viewAllPro?setViewAllPro(false):setViewAllPro(true);
}
    return (
        <>
            <div className='dashboard_main'>
             <div className='main_card_box box_1'>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "80%", margin: "auto" }} ><h4 className='mt-3'>All Projects</h4>
                    {
                        viewAllPro?<button onClick={handleViewAllPro}>View Top Five</button>:
                        <button onClick={handleViewAllPro}>view all</button>
                    }
                    </div>
                    <div id="" className='inner'>
                    {
                    viewAllPro?
                        projectsCard?.map((data,i)=>
                        {
                           return( <Link key={i}to={`/projectList/${data.project_ID}`} style={{ textDecoration: 'none' }}>
                           <div className='allproject_shadow_card  shadow_1'>
                               <div className='d-flex all_project_card_body' >
                                   <div className='project_all_cards'>
                                       <h4 className='px-3 py-1'>{data.projectName.slice(0,20)}</h4>
                                       <p className='px-3 py-1'>{data.edate.slice(0,10)}</p>
                                   </div>
                                   <div className='project_imgbox'>
                                       <img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' />
                                   </div>
                               </div>
                           </div>
                           </Link>)
                        })
                     :
                         <>
                         <Link to={`/projectList/${projectsCard[0]?.project_ID}`} style={{ textDecoration: 'none' }}>
                            <div className='allproject_shadow_card  shadow_1'>
                                <div className='d-flex all_project_card_body' >
                                    <div className='project_all_cards'>
                                        <h4 className='px-3 py-1'>{projectsCard[0]?.projectName.slice(0,20)}</h4>
                                        <p className='px-3 py-1'>{projectsCard[0]?.edate.slice(0,10)}</p>
                                    </div>
                                    <div className='project_imgbox'>
                                        <img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' />
                                    </div>
                                </div>
                            </div>
                            </Link>
                              <Link to={`/projectList/${projectsCard[1]?.project_ID}`} style={{ textDecoration: 'none' }}>
                                <div className='allproject_shadow_card shadow_2'>
                                    <div className='d-flex all_project_card_body' >
                                        <div className='project_all_cards'>
                                            <h4 className='px-3 py-1'>{projectsCard[1]?.projectName.slice(0,20)}</h4>
                                            <p className='px-3 py-1'>{projectsCard[1]?.edate.slice(0,10)}</p>
                                        </div>
                                        <div className='project_imgbox'>
                                            <img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' />
                                        </div>
                                    </div>
                                </div>
                                </Link>
                                <Link style={{ textDecoration: 'none' }} to={`/projectList/${projectsCard[2]?.project_ID}`}>
                                <div className='allproject_shadow_card shadow_3'>
                                    <div className='d-flex all_project_card_body' >
                                        <div className='project_all_cards'>
                                            <h4 className='px-3 py-1'>{projectsCard[2]?.projectName.slice(0,20)}</h4>
                                            <p className='px-3 py-1'>{projectsCard[2]?.edate.slice(0,10)}</p>
                                        </div>
                                        <div className='project_imgbox'>
                                            <img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' />
                                        </div>
                                    </div>
                                </div>
                                </Link>
                                <Link to={`/projectList/${projectsCard[3]?.project_ID}`} style={{ textDecoration: 'none' }}>
                                <div className='allproject_shadow_card shadow_4'>
                                    <div className='d-flex all_project_card_body' >
                                        <div className='project_all_cards'>
                                            <h4 className='px-3 py-1'>{projectsCard[3]?.projectName.slice(0,20)}</h4>
                                            <p className='px-3 py-1'>{projectsCard[3]?.edate.slice(0,10)}</p>
                                        </div>
                                        <div className='project_imgbox'>
                                            <img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' />
                                        </div>
                                    </div>
                                </div>
                                </Link>
                                <Link to={`/projectList/${projectsCard[4]?.project_ID}`} style={{ textDecoration: 'none' }}>
                                <div className='allproject_shadow_card shadow_5'>
                                    <div className='d-flex all_project_card_body' >
                                        <div className='project_all_cards'>
                                            <h4 className='px-3 py-1'>{projectsCard[4]?.projectName.slice(0,20)}</h4>
                                            <p className='px-3 py-1'>{projectsCard[4]?.edate.slice(0,10)}</p>
                                        </div>
                                        <div className='project_imgbox'>
                                            <img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' />
                                        </div>
                                    </div>
                                </div>
                                </Link> 
                            </>
                   } 
                   {
                     
                   }
                </div>
                </div>
                <div className='main_card_box box_2'>
                    <div style={{ height: "234px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "80%", margin: "auto" }} ><h4 className='mt-3'>Running Projects and their deadlines</h4></div>
                        <div id="inner" style={{height:"203px",overflow:"scroll"}} >
                            {
                                running?.map((c,i)=>
                                {
                                    if(i<=15)
                                    {
                                        return( <Link to={`/projectList/${c.project_ID}`} key={i}style={{ textDecoration: 'none' }}>
                                        <div className='project_deadline'>
                                            <div className='project_deadline_decoration0'></div>
                                           <div>
                                           <h4 className='px-3 py-1'>{c.projectName.slice(0,20)}</h4>
                                            <p className='date'><span>✅</span>Date :{c.edate.slice(0,10)}</p>
                                            <p className='details'>{c.category}</p>
                                           </div>
                                        </div>
                                        </Link>)
                                    }
                               
                                    else{
                                        return(<p>No Running Projects Found</p>)
                                    }
                                })
                            }
                      
                            {/* <Link to={`/projectList/${running[1]?.project_ID}`} style={{ textDecoration: 'none' }}>
                            <div className='project_deadline'>
                            <div className='project_deadline_decoration1'></div>
                              <div>
                              <h4 className='px-3 py-1'>{running[1]?.projectName.slice(0,20)}</h4>
                                <p className='date'><span>✅</span>Date : {running[1]?.edate.slice(0,10)}</p>
                                <p className='details'>{running[1]?.category}</p>
                              </div>
                            </div>
                            </Link>
                            <Link to={`/projectList/${running[2]?.project_ID}`} style={{ textDecoration: 'none' }}>
                            <div className='project_deadline'>
                            <div className='project_deadline_decoration2'></div>
                                <div>
                                <h4 className='px-3 py-1'>{running[2]?.projectName.slice(0,20)}</h4>
                                <p className='date'><span>✅</span>Date : {running[2]?.edate.slice(0,10)}</p>
                                <p className='details'>{running[2]?.category}</p>
                                </div>
                            </div>
                            </Link>
                            <Link to={`/projectList/${running[3]?.project_ID}`} style={{ textDecoration: 'none' }}>
                            <div className='project_deadline'>
                            <div className='project_deadline_decoration3'></div>
                               <div>
                               <h4 className='px-3 py-1'>{running[3]?.projectName.slice(0,20)}</h4>
                                <p className='date'><span>✅</span>Date : {running[3]?.edate.slice(0,10)}</p>
                                <p className='details'>{running[3]?.category}</p>
                               </div>
                            </div>
                            </Link>
                            <Link to={`/projectList/${running[4]?.project_ID}`} style={{ textDecoration: 'none' }}>
                            <div className='project_deadline'>
                            <div className='project_deadline_decoration0'></div>
                               <div>
                               <h4 className='px-3 py-1'>{running[4]?.projectName.slice(0,20)}</h4>
                                <p className='date'><span>✅</span>Date : {running[4]?.edate.slice(0,10)}</p>
                                <p className='details'>{running[4]?.category}</p>
                               </div>
                            </div>
                            </Link>
                            <Link to={`/projectList/${running[5]?.project_ID}`} style={{ textDecoration: 'none' }}>
                            <div className='project_deadline'>
                            <div className='project_deadline_decoration1'></div>
                               <div>
                               <h4 className='px-3 py-1'>{running[5]?.projectName.slice(0,20)}</h4>
                                <p className='date'><span>✅</span>Date : {running[5]?.edate.slice(0,10)}</p>
                                <p className='details'>{running[5]?.category}</p>
                               </div>
                            </div>
                            </Link>
                            <Link to={`/projectList/${running[6]?.project_ID}`} style={{ textDecoration: 'none' }}>
                            <div className='project_deadline'>
                            <div className='project_deadline_decoration2'></div>
                               <div>
                               <h4 className='px-3 py-1'>{running[6]?.projectName.slice(0,20)}</h4>
                                <p className='date'><span>✅</span>Date : {running[6]?.edate.slice(0,10)}</p>
                                <p className='details'>{running[6]?.category}</p>
                               </div>
                            </div>
                            </Link>
                            <Link to={`/projectList/${running[7]?.project_ID}`} style={{ textDecoration: 'none' }}>
                            <div className='project_deadline'>
                            <div className='project_deadline_decoration3'></div>
                               <div>
                               <h4 className='px-3 py-1'>{running[7]?.projectName.slice(0,20)}</h4>
                                <p className='date'><span>✅</span>Date : {running[7]?.edate.slice(0,10)}</p>
                                <p className='details'>{running[7]?.category}</p>
                               </div>
                            </div>
                            </Link> */}
                        </div>

                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "80%", margin: "auto" }} ><h4>TasK Assigned</h4></div>
                    <div style={{ height: "204px",overflow:"scroll" }}>
                       
                     <hr className='myhr' />
                     <div className='task_ass'>
                        <div><img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' /></div>
                        <div style={{marginLeft:"12px"}}>
                            <p className='task_ass_head'>Delhi Tourism Tender</p>
                            <span>Ankit Rana <i>(project manager)</i></span>
                            <p>Start Working on Delhi Tourism Tender.</p>
                        </div>
                        
                     </div>
                     <hr className='myhr' />
                     <div className='task_ass'>
                        <div><img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' /></div>
                        <div style={{marginLeft:"12px"}}>
                            <p className='task_ass_head'>Delhi Tourism Tender</p>
                            <span>Ankit Rana <i>(project manager)</i></span>
                            <p>Start Working on Delhi Tourism Tender.</p>
                        </div>
                        
                     </div>
                     <hr className='myhr' />
                     <div className='task_ass'>
                        <div><img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' /></div>
                        <div style={{marginLeft:"12px"}}>
                            <p className='task_ass_head'>Delhi Tourism Tender</p>
                            <span>Ankit Rana <i>(project manager)</i></span>
                            <p>Start Working on Delhi Tourism Tender.</p>
                        </div>
                        
                     </div>
                     <hr className='myhr' />
                     <div className='task_ass'>
                        <div><img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' /></div>
                        <div style={{marginLeft:"12px"}}>
                            <p className='task_ass_head'>Delhi Tourism Tender</p>
                            <span>Ankit Rana <i>(project manager)</i></span>
                            <p>Start Working on Delhi Tourism Tender.</p>
                        </div>
                        
                     </div>
                    
                     <hr className='myhr' />
                     <div className='task_ass'>
                        <div><img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' /></div>
                        <div style={{marginLeft:"12px"}}>
                            <p className='task_ass_head'>Delhi Tourism Tender</p>
                            <span>Ankit Rana <i>(project manager)</i></span>
                            <p>Start Working on Delhi Tourism Tender.</p>
                        </div>
                        
                     </div>
                    </div>
                </div>
                <div className='main_card_box box_3'>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "80%", margin: "auto" }} ><h4 className='mt-3'>Project Name</h4></div>

                    <div className='progress_gap'>
                        <CircularProgressbar
                            value={one}
                            text={`${one}%`}
                            strokeWidth={5}
                        />
                    </div>
                    <center >
                        <h4 className="f_12">Tender Value</h4>
                        <h4 className=" ">&#8377;12,875</h4>
                        <h6 className="line_height f_8">Compared to other’s</h6>
                    </center>
                    <div className=' project_lines ' style={{ fontSize: "18px",display:"flex",justifyContent:"space-between",width:"80%",margin:"auto" }} >
                        <div className='line_height'>
                            <p>L1</p>
                            <p>L2</p>
                            <p>L3</p>
                        </div>
                        <div className='line_height'>
                            <p>8756</p>
                            <p>545</p>
                            <p>356</p>
                        </div>
                    </div>
                    <h4 className="f_12 px-3">Tender Value</h4>
                    <br />
                    <table className='TenderValTbl'>
                        <tbody>
                        <tr>
                            <td>vendor1</td>
                            <td>5980</td>
                            <td>22,765</td>
                            <td>🔻</td>
                        </tr>
                        <tr>
                            <td>vendor2</td>
                            <td>5980</td>
                            <td>22,765</td>
                            <td>🔻</td>
                        </tr>
                        <tr>
                            <td>vendor3</td>
                            <td>5980</td>
                            <td>22,765</td>
                            <td>🔻</td>
                        </tr>
                        <tr>
                            <td>vendor4</td>
                            <td>5980</td>
                            <td>22,765</td>
                            <td>🔻</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default DashDesign