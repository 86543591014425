import { Button } from '@mui/material'
import React from 'react'

function Applyleave() {
    return (
        <>
            <div className='formbg'>
            <center><h3 style={{color:"#cf3636",fontWeight:"800"}}>APPLY FOR LEAVE</h3></center>
            <br /><br />
                <div className='row'>
                    
                    <div className='d-flexed' style={{width:"70%",margin:"auto"}}>
                        <div className="mb-3 animatingTop">
                            <label className="form-label">Leave Start Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                            <input type="date"
                               max="9999-12-31"
                                className="form-control " />

                        </div>
                        <div className="mb-3 animatingTop">
                            <label className="form-label"> Leave End Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                            <input type="date"
                                  max="9999-12-31"
                                className="form-control " />

                        </div>
                        <div className="mb-3 animatingTop">
                            <label className="form-label">LEAve TYPe &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                            <br />  <br />
                          <select className="form-label" style={{width:"200px"}}> 
                            <option>vacation</option>
                            <option>quitting</option>
                            <option>sick</option>
                            <option>other</option>
                          </select>

                        </div>
                       
                    </div>
                    <div style={{width:"70%",margin:"auto"}}>
                    <label className="form-label">LEAve TYPe &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <br /><br />
                   <textarea className='textarea' style={{width:"97%"}} ></textarea>
                   <br />

                   <br /><br />
                   <center><Button style={{background:"green",color:"#fff"}}>REQUST LEAVE FOR 4 days</Button></center>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Applyleave