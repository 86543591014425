import React from 'react';
var xlsx = require("xlsx");

function ExceltoJson() {


    const readUploadFile = (e) => {
       
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
            
                const json = xlsx.utils.sheet_to_json(worksheet);

                const workbookHeaders = xlsx.readFile(data, { sheetRows: 1 });
                const columnsArray = xlsx.utils.sheet_to_json(workbookHeaders.Sheets[sheetName], { header: 1 })[0];


              
                if(columnsArray[0] === "PROJECT_ID"&& columnsArray[1] === "LOCATION" && columnsArray[2] ==="DESCRIPTION" && columnsArray[3] === "WIDTH" && columnsArray[4] === "HEIGHT" && columnsArray[5] ==="UNIT" && columnsArray[6] === "AREA" && columnsArray[7] === "QUANTITY" && columnsArray[8] === "REMARKS" && columnsArray[9] === "RATE_TYPE" && columnsArray[10] === "RATE_AMOUNT" && columnsArray[11] ==="TOTAL_AMOUNT" && columnsArray[12] === "STATUS" && columnsArray[13] === "GROUPING" && columnsArray[14] ==="HEAD"){
                 console.log(json)
                }
                else{
                    alert("modifying any of the headers is not allowed")
                   
                }
       


            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }



  return (
    <>
    <div>ExceltoJson</div>
  <div className='d-flex'>
    <div className='mb-3'>
    <label htmlFor="upload">Upload File</label>
    <input
        type="file"
        name="upload"
        id="upload"
        onChange={readUploadFile}
    />
    </div>
  </div>

    </>
  )
}

export default ExceltoJson