'use strict';
import React, { useEffect, useState, useRef } from 'react';
import {
  useParams, Link, useNavigate, useLocation
} from "react-router-dom";
import { VscError } from 'react-icons/vsc';
// import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import { DataGrid } from "@mui/x-data-grid";
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import SideBar from '../../common/SideBar/SideBar';
import Multiselect from 'multiselect-react-dropdown';
import { Button } from "@mui/material";
import pdfImg from '../../assets/pdf.png';
import Progress from 'react-progressbar';
// import { PDFDocument, PDFName, PDFNumber } from 'pdf-lib';
import axios, { CancelToken, isCancel } from 'axios';
import {
  UNREJECT_PROJECT, MERGE_PDF, GET_ALL_CMPNY_WORK_DOC, DELETE_PROJECT, UNASSIGN_COMPANY_DOC,
  ASSIGN_COMPANY_DOC, CREATE_DOCUMENT, GET_PROJECTBYID, UPDATE_PROJECT, TENDER_STATE,
  GET_TEAMS_BY_PROJECT_ID, GET_EMP_DATAILS_BY_MCODE, GET_DOCUMENT, DELETE_MERGED_PDF_DOCUMENT, ALLOWED_USERTYPE
} from '../../utiles/AllApis';
import ok from "../../assets/ok.gif"
import RunningProject from './runningProject/RunningProject';
import { jsPDF } from "jspdf";
import {
  Modal,
  ModalBody
} from 'reactstrap';
import ErrorAlertBox from '../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../common/AlertBox/SuccessAlertBox';
import DataGrids from '../../common/table/DataGrid';
import ProjectInfo from './Component/ProjectInfo';
const initialDnDState = {
  draggedFrom: null,
  draggedTo: null,
  isDragging: false,
  originalOrder: [],
  updatedOrder: []
}

const DragToReorderList = (props) => {
  const [list, setList] = React.useState(props.ProjectDocList);
  props.setList(props.ProjectDocList);
  const [dragAndDrop, setDragAndDrop] = React.useState(initialDnDState);
  // onDragStart fires when an element
  // starts being dragged
  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: props.ProjectDocList
    });
    // Note: this is only for Firefox.
    // Without it, the DnD won't work.
    // But we are not using it.
    event.dataTransfer.setData("text/html", '');
  }
  // onDragOver fires when an element being dragged
  // enters a droppable area.
  // In this case, any of the items on the list
  const onDragOver = (event) => {

    // in order for the onDrop
    // event to fire, we have
    // to cancel out this one
    event.preventDefault();
    let newList = dragAndDrop.originalOrder;

    // index of the item being dragged
    const draggedFrom = dragAndDrop.draggedFrom;

    // index of the droppable area being hovered
    const draggedTo = Number(event.currentTarget.dataset.position);

    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter((item, index) => index !== draggedFrom);

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo)
    ];

    if (draggedTo !== dragAndDrop.draggedTo) {

      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo
      })
    }

  }

  const onDrop = (event) => {

    props.setList(dragAndDrop.updatedOrder);
    console.log(dragAndDrop.updatedOrder);
    setList(dragAndDrop.updatedOrder);
    props.setFinalArrayData(dragAndDrop.updatedOrder);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false
    });
  }
  const onDragLeave = () => {

    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: 1
    });

  }

  // Not needed, just for logging purposes:
  React.useEffect(() => {

    console.log("Dragged From: ", dragAndDrop && dragAndDrop.draggedFrom);
    console.log("Dropping Into: ", dragAndDrop && dragAndDrop.draggedTo);
  }, [dragAndDrop])

  React.useEffect(() => {

    console.log("List updated!");
    console.log(list);
    props.setList(list);
    props.setFinalArrayData(list);
  }, [list])
  //Remove Documents form List
  const removeDoc = (docId) => {
    const arrList = props.ProjectDocList.filter((data, i) => {
      if (data.document_ID !== docId) {
        return data;
      }
    })
    setList(arrList);
    props.setFinalArrayData(arrList);
    props.setList(arrList);
  }
  return (
    <tbody>
      {props.ProjectDocList?.map((item, index) => {
        console.log(item);
        return (
          <tr
            key={index}
            data-position={index}
            draggable
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onDragLeave={onDragLeave}
            className={dragAndDrop && dragAndDrop.draggedTo === Number(index) ? "dropArea" : ""}
          >
            <td>{index + 1}</td>
            <td>{item?.subType}</td>
            <td>{item?.type}</td>
            <td><p className='text-danger cursor-pointer float-start' onClick={e => { removeDoc(item.document_ID) }}>Remove</p></td>
          </tr>
        )
      })}
    </tbody>
  )
};
const ProjectDetail = (props) => {

  const [heading, setHeading] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [dltdocument_ID, setDltdocument_ID] = useState("");
  const { projectName } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [secStep, setSecStep] = useState(false);
  const [documents, setdocuments] = useState(false);
  const [mergDltPop, setMergDltPop] = useState(false);
  const [isSubmitFile, setIsSubmitFile] = useState(false);
  const [clearListPop, setClearListPop] = useState(false);
  const [eligibilityDoc, setEligibilityDoc] = useState([]);
  const [ProjectDocList, setProjectDocList] = useState([]);
  const [Internal, setInternalDoc] = useState([]);
  const [resultList, SetResultList] = useState([]);
  const [data, setdata] = useState([])
  // const [costSheet,setCostSheet]= useState("");
  const [wonsuccess, setwonsuccess] = useState(false);
  const [addremark, setaddremark] = useState("")
  const [intrested, setintrested] = useState(true);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenErr, setIsOpenErr] = useState(false);
  const [isOpenLost, setIsOpenLost] = useState(false);
  const [isOpenPrint, setIsOpenPrint] = useState(false);
  const [pdfLen, setPdfLen] = useState(0);
  const [won, setwon] = useState(false)
  // const[enableOprt,setEnableOprt]=useState(false)
  const [yesok, setyesok] = useState(false)
  const [sheet, SetSheets] = useState(false)
  const [yespopup, setyespopup] = useState(false)
  const [nopopup, setnopopup] = useState(false);
  const [rejectpopoup, setRejectpopoup] = useState(false);
  const [documentimg, setdocumentimg] = useState([]);
  const [viewTeams, setViewTeams] = useState(false);
  const [uploadedPrecentage, setUploadedPrecentage] = useState(0);
  const [secSepShow, setsecSepShow] = useState(false); // we need to change  this state 
  // const  [RProjects,setRProjects]=useState();
  // const  [FProjects,setFProjects]=useState();
  const [tblMerge, setTblMerge] = useState("");
  const [finalArrayData, setFinalArrayData] = useState([]);
  let finalArray = [];
  const [eligibilityDocExtn, setEligibilityDocExtn] = useState("");
  const [cmpntDocs, setCmpntDocs] = useState();
  const [internalDocs, setInternalDocs] = useState();
  const [dataTable, setDataTable] = useState();
  const [viewCompyDoc, setViewCompyDoc] = useState(false);
  const [viewInternalDoc, setViewInternalDoc] = useState(false);
  const [viewUploadPrstnDoc, setViewUploadPrstnDoc] = useState(false);
  const [viewUploadMoreDoc, setViewUploadMoreDoc] = useState(false);
  const [viewUploadResultDoc, setViewUploadResultDoc] = useState(false);
  const [uploadfile, setuploadfile] = useState("");
  const [comDocList, setcmpDoList] = useState("");
  const [docVal, setDoc1Val] = useState({ "name": "--Select Document--" });
  const [comDocId, setcmpDocId] = useState([]);
  const [comDocName, setcmpDocName] = useState([]);
  const [workDocId, setWorkDocId] = useState([]);
  const [workDocName, setWorkDocName] = useState([]);
  const [GetDocName, setGetDocName] = useState("");
  const [list, setList] = useState([]);
  // const [list1,setList1]=useState([]);
  const taledata = [];
  const cancelFileUpload = useRef(null);
  const [eligibilityDocView, setEligibilityDocView] = useState("");
  const [openEligibilityDoc, setOpenEligibilityDoc] = useState(false);
  const [showMrgFinalBtn, setShowMrgFinalBtn] = useState(false);
  const [proReject, setProjectRejection] = useState(false);
  const handalOpenErr = () => { setIsOpenErr(true); }
  const handalOpen1 = () => { setIsOpen1(true); }
  //const urlVal=`/projectList/${projectName}`;
  const urlVal = "";
  const hadldleOpenEligibilityDoc = (path, extention, type) => {
    if (extention === "pdf" || extention === "xlsx" || extention === "csv") {
      window.open(
        path,
        '_blank'
      );
      // setEligibilityDocView(<div className='col-sm-12 col-md-12 col-12 text-center'><a href={path} target="blank"><img className='w-75 h-75' src={pdfImg}alt="Eligibility Document" /></a></div>);
    }
    else {
      setEligibilityDocView(<div className='col-sm-12 col-md-12 col-12 text-center'><a href={path} target="blank"><img className='w-100 h-75' src={path} alt={type} /></a></div>)
      setOpenEligibilityDoc(true);
    }

  };

  const hadldleCloseEligibilityDoc = () => { setOpenEligibilityDoc(false) };
  const columnsForCmpnyDoc = [

    { field: 'name', headerName: 'Document Name', width: 500 },
    { field: 'path', headerName: 'Documets', width: 500 },
    // {
    //   field: "Add",
    //   headerName: "Add Document",
    //   width: 150,
    //   disableClickEventBubbling: true,
    //   renderCell: (params) => {

    //     return (
    //       params.row.hiringDate.split("T")[0]
    //     );
    //   }
    // },

  ]
  const DocListcol = [{ field: 'subType', headerName: 'Document Name', width: 477 },
  { field: 'type', headerName: 'Document Type', width: 477 },

  ]

  const columns = [

    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'department', headerName: 'Department', width: 150 },
    {
      field: "hiringDate",
      headerName: "Hiring Date",
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params.row.hiringDate.split("T")[0]}</p>
        );
      }
    },
    { field: 'mail', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Mobile No.', width: 150 },

  ]
  const navigate = useNavigate();

  const CmpDocListing = (val) => {
    const result = Object.values(val);
    let cpmDoc = [];
    let cpmDocID = [];
    result?.map((c, i) => {
      cpmDocID.push(c.id);
      cpmDoc.push(c.name);
    })

    setcmpDocName(cpmDoc);
    setcmpDocId(cpmDocID);
  }
  const WorkDocListing = (val) => {
    const result = Object.values(val);
    let workDoc = [];
    let workDocID = [];
    result?.map((c, i) => {
      workDocID.push(c.id);
      workDoc.push(c.name);
    })

    setWorkDocName(workDoc);
    setWorkDocId(workDocID);
  }
  const getTeamMembers = () => {
    setLoading(true);
    axios.post(GET_TEAMS_BY_PROJECT_ID, { "Project_ID": projectName }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {

        response?.data?.data?.map((data, i) => {
          setLoading(true);
          axios.post(GET_EMP_DATAILS_BY_MCODE, { "EmployeeID": data.emP_MCODE }).then((response) => {
            setLoading(false);
            if (response?.data?.isSuccess === true) {

              taledata.push(response?.data?.data[0]);
            }

            // settabledata(...taledata,response?.data?.data);
          });
          setDataTable(<DataGrid
            rows={taledata}
            columns={columns}
            getRowId={(row) => row.rowpos}
            pageSize={10}
            // checkboxSelection
            onSelectionModelChange={(ids) => {
            }}
          />)
        }
        )
      }

    });
  }
  const location = useLocation();
  useEffect(() => {
    getTeamMembers();
    var config = {
      method: 'post',
      url: GET_PROJECTBYID,
      headers: {
        'Content-Type': 'application/json'
      },
      data: { "ROWPOS": projectName }
    };
    const ProjectArray = [];
    setLoading(true);
    axios(config)
      .then(function (response) {

        setProjectRejection(response.data.data[0].reject);
        setLoading(false);
        if (response.data.isSuccess === true) {
          var oleEDate = `${new Date(response.data.data[0].eventDate).getFullYear()}/${new Date(response.data.data[0].eventDate).getMonth() + 1}/${new Date(response.data.data[0].eventDate).getDate()}`;
          if (oleEDate === "1990/1/1") {
            response.data.data[0].eventDate = "--NA--";
          }
          else {
            response.data.data[0].eventDate = `${new Date(response.data.data[0].eventDate).toLocaleString([], { weekday: 'short' })} ${new Date(response.data.data[0].eventDate).toLocaleString([], { day: '2-digit' })} ${new Date(response.data.data[0].eventDate).toLocaleString([], { month: 'short' })}`;
          }
          var oleeventToDate = `${new Date(response.data.data[0].eventToDate).getFullYear()}/${new Date(response.data.data[0].eventToDate).getMonth() + 1}/${new Date(response.data.data[0].eventToDate).getDate()}`;
          if (oleeventToDate === "1990/1/1") {
            response.data.data[0].eventToDate = "--NA--";
          }
          else {
            var a = `${new Date(response.data.data[0].eventToDate).toLocaleString([], { weekday: 'short' })}`;
            response.data.data[0].eventToDate = `${new Date(response.data.data[0].eventToDate).toLocaleString([], { weekday: 'short' })} ${new Date(response.data.data[0].eventToDate).toLocaleString([], { day: '2-digit' })} ${new Date(response.data.data[0].eventToDate).toLocaleString([], { month: 'short' })}`;
          }
          var olelDateOfPreBidQueries = `${new Date(response.data.data[0].lDateOfPreBidQueries).getFullYear()}/${new Date(response.data.data[0].lDateOfPreBidQueries).getMonth() + 1}/${new Date(response.data.data[0].lDateOfPreBidQueries).getDate()}`;
          if (olelDateOfPreBidQueries === "1990/1/1") {
            response.data.data[0].lDateOfPreBidQueries = "--NA--";
          }
          var oleLastFillingDate = `${new Date(response.data.data[0].lastFillingDate).getFullYear()}/${new Date(response.data.data[0].lastFillingDate).getMonth() + 1}/${new Date(response.data.data[0].lastFillingDate).getDate()}`;
          if (oleLastFillingDate === "1990/1/1") {
            response.data.data[0].lastFillingDate = "--NA--";
          }
          var oleldateOfSubmissionBid = `${new Date(response.data.data[0].ldateOfSubmissionBid).getFullYear()}/${new Date(response.data.data[0].ldateOfSubmissionBid).getMonth() + 1}/${new Date(response.data.data[0].ldateOfSubmissionBid).getDate()}`;
          if (oleldateOfSubmissionBid === "1990/1/1") {
            response.data.data[0].ldateOfSubmissionBid = "--NA--";
          }
          var oleopenFinancialBids = `${new Date(response.data.data[0].openFinancialBids).getFullYear()}/${new Date(response.data.data[0].openFinancialBids).getMonth() + 1}/${new Date(response.data.data[0].openFinancialBids).getDate()}`;
          if (oleopenFinancialBids === "1990/1/1") {
            response.data.data[0].openFinancialBids = "--NA--";
          }
          var olepreBidMeetingDate = `${new Date(response.data.data[0].preBidMeetingDate).getFullYear()}/${new Date(response.data.data[0].preBidMeetingDate).getMonth() + 1}/${new Date(response.data.data[0].preBidMeetingDate).getDate()}`;
          if (olepreBidMeetingDate === "1990/1/1") {
            response.data.data[0].preBidMeetingDate = "--NA--";
          }
          var oletechnicalBidOpenDate = `${new Date(response.data.data[0].technicalBidOpenDate).getFullYear()}/${new Date(response.data.data[0].technicalBidOpenDate).getMonth() + 1}/${new Date(response.data.data[0].technicalBidOpenDate).getDate()}`;
          if (oletechnicalBidOpenDate === "1990/1/1") {
            response.data.data[0].technicalBidOpenDate = "--NA--";
          }
          var olepresentationDate = `${new Date(response.data.data[0].presentationDate).getFullYear()}/${new Date(response.data.data[0].presentationDate).getMonth() + 1}/${new Date(response.data.data[0].presentationDate).getDate()}`;
          if (olepresentationDate === "1990/1/1") {
            response.data.data[0].presentationDate = "--NA--";
          }

          if (response.data.data[0].state === 'RUNNING' || response.data.data[0].state === 'COMPLETED') {
            setsecSepShow(true);
          }
          response?.data?.data?.map((data, i) => {

            ProjectArray.push(data);
          })
          setLoading(true);
          axios.post(GET_ALL_CMPNY_WORK_DOC, { "Type": "COMPANY_DOCUMENT" }).then((response) => {
            var array1 = [];
            setLoading(false);
            if (response.data.isSuccess === true) {

              const resData = response.data.data.filter((data, i) => {
                if (data.type.includes("COMPANY_DOCUMENT")) {
                  return data;
                }

              }
              )
              resData?.map((data, i) => {
                array1.push({ "name": data.subType, "id": data.rowpos });
              })

            }
            setCmpntDocs(
              <Multiselect
                style={{ height: "100%" }}
                id="cmpDoc"
                placeholder="Choose Company Document"
                options={array1}
                displayValue="name"
                isObject={true}
                showCheckbox={true}
                onSelect={(event) => {
                  CmpDocListing(event);
                }}
                onRemove={(event) => {
                  CmpDocListing(event);
                }}

              />);
          });
          setLoading(true);
          axios.post(GET_ALL_CMPNY_WORK_DOC, { "Type": "WorkDoc" }).then((response) => {

            var array1 = [];
            setLoading(false);

            if (response.data.isSuccess === true) {

              const resData = response.data.data.filter((data, i) => {

                if (data.type.includes("WORKORDER_DOCUMENT")) {
                  return data;
                }
              }
              )
              response.data.data?.map((data, i) => {
                array1.push({ "name": data.subType, "id": data.rowpos });
              })


            }
            setInternalDocs(<Multiselect
              style={{ height: "100%", background: "#3330" }}
              id="WorkDoc"
              options={array1}
              displayValue="name"
              placeholder="Choose Work Order & Completions Certificate"
              isObject={true}
              showCheckbox={true}
              onSelect={(event) => {
                WorkDocListing(event);
              }}
              onRemove={(event) => {
                WorkDocListing(event);
              }}
            />);
          });
        }
        else {

          SetSheets(true)
          setdocuments(false);

        }
        var isintrested = response.data.data[0].interested;
        const pdf_doc = response.data.data[0].lst_documents.filter((data, i) => {
          if (data.type === "MERGED_FILE") {
            return data;
          }

        })
        const result_doc = response.data.data[0].lst_documents.filter((data, i) => {
          if (data.type === "RESULT_DOCUMENT") {
            return data;
          }

        })
        SetResultList(result_doc);
        setPdfLen(pdf_doc.length);
        const li_doc = response.data.data[0].lst_documents.filter((data, i) => {
          if (data.type === "COMPANY_DOCUMENT" || data.type === "WORKORDER_DOCUMENT" || data.type === "PRESENTATION_DOCUMENT" || data.type === "EXTRA_DOCUMENT") {
            return data;
          }

        })
        setProjectDocList([...li_doc, ...response.data.data[0].lst_CompanyDocuments, ...response?.data?.data[0]?.lst_InternalDocuments]);
        if (isintrested === true) {
          setintrested(false)
          SetSheets(true)
        }
        else {
          setintrested(true)
        }
        var won = response.data.data[0].won;

        if (won === true && isintrested === true) {
          setwon(false)

        }
        else {
          setwon(true)
        }
        setdata(response.data.data);
        setdocumentimg(response.data.data[0]?.generic_docs);
        setEligibilityDoc(response.data.data[0]?.eligibility_docs[response.data.data[0]?.eligibility_docs.length - 1]);

      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const setunassignedDocList = (val) => {
    const result = Object.values(val);
    let cpmDoc = [];
    let cpmDocID = [];
    result?.map((c, i) => {
      cpmDocID.push(c.document_ID);
      cpmDoc.push(c.type);
    })

    setcmpDocName(cpmDoc);
    setcmpDocId(cpmDocID);
    setClearListPop(false);
    setLoading(true);
    unassignedDoc(cpmDoc, cpmDocID);
    //finalArray
  }
  const unassignedDoc = (cpmDoc, cpmDocID) => {

    axios.post(UNASSIGN_COMPANY_DOC, {
      "Project_ID": projectName,

      "lst_subType": cpmDoc,

      "lst_documentID": cpmDocID

    }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setHeading1("Success");
        setsuccessMsg("Document Unassigns Successfully!");
        handalOpen1();
        //window.location.reload(); 
      }
      else {
        setHeading("Error!");
        setErrMsg("Somthing Went Wrong");
        handalOpenErr();
        return;
      }

    });
  }
  const onSelect = (val) => {
    setcmpDoList(...comDocList, val);
  }
  const MergerPdf = async () => {
    window.scrollTo(0, 0);

    if (list.length !== 0) {
      setLoading(true);
      await axios.post(MERGE_PDF,
        {
          "Media_ID": projectName,
          "lst_Media": list
        }).then((response) => {
          setLoading(false);
          if (response.data.isSuccess === true) {
            window.location.reload();
          }
          else {
            alert(response.data.message);
          }

        });
    }
    else {
      alert("No Document Found!!!")
    }

  }
  const getCmpDocList = (e) => {
    setDoc1Val({ ...docVal, "name": e.target.value });
  }
  const handleyes = (sendState) => {
    // setyespopup(true)
    window.location.href = "#"
    var data = JSON.stringify({
      "ROWPOS": projectName,
      "Interested": sendState == "FILING" ? true : false,
      "Remarks": ""
    });

    var config = {
      method: 'post',
      url: UPDATE_PROJECT,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        if (response.data.isSuccess === true) {

          setyesok(true)
        }
        setTimeout(() => {
          window.location.reload();
        }, 4000)
        SetSheets(true)
        setintrested(false)
      })
      .catch(function (error) {
        console.log(error);
      });

  }
  const handleRejectPopUp = () => {
    setnopopup(true);
  }
  const cross = () => {
    setyespopup(false)
    setnopopup(false)
  }

  const closeLostPopo = () => {
    setRejectpopoup(false);
  }
  const readthisremark = (e) => {

    setaddremark(e.target.value);
  }
  const notintrested = () => {
    var data = JSON.stringify({
      "ROWPOS": projectName,
      "Interested": false,
      "Remarks": addremark,
      "REJECT": true
    });
    var config = {
      method: 'post',
      url: UPDATE_PROJECT,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setnopopup(false);
          window.location.href = "/projectList?type=rejected";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const onWonClick = () => {
    setwon(true)
    var data = JSON.stringify({
      "ROWPOS": projectName,
      "Interested": true,
      "Remarks": "",
      "WON": true
    });

    var config = {
      method: 'post',
      url: UPDATE_PROJECT,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        if (response.data.isSuccess === true) {

          setwonsuccess(true)
          setwon(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  setTimeout(() => {
    setwonsuccess(false)
  }, 10000);
  const onLostClick = () => {
    setRejectpopoup(true);
  }
  const onLostReview = () => {
    closeLostPopo();
    if (addremark === "") {
      setHeading("Submission Failed!");
      setErrMsg("Fields Cannot Empty");
      handalOpenErr();
      return;
    }
    var data = JSON.stringify({
      "ROWPOS": projectName,
      "Lost": true,
      "Remarks": addremark,
      "WON": false
    });

    var config = {
      method: 'post',
      url: UPDATE_PROJECT,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setHeading1("Success");
          setsuccessMsg("you Lost This Project Your remarks send successfully");
          handalOpen1();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const deleteproject = () => {
    setLoading(true);
    axios.post(DELETE_PROJECT, { "ROWPOS": projectName }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        window.location.href = "/projectList";
      }

    })
  }
  const redirecturl = `/projectList/${projectName}/form`;
  const stepSecProjectName = window.sessionStorage.getItem("stepSecProjectName");
  const handleOpenTeams = () => {
    setViewTeams(true);
  }
  const handleCloseTeams = () => {
    setViewTeams(false);
  }
  const handleOpenCmpyDoc = () => {
    setLoading(true);
    axios.post(GET_DOCUMENT, { "Project_ID": projectName }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        const resData = response.data.data.filter((data, i) => {
          if (data.type.includes("COMPANY_DOCUMENT")) {
            return data;
          }
        }
        )
        setCmpntDocs(resData);
      }
    });
    setViewCompyDoc(true);
    handleCloseInternalDoc();
    handleClosePresentationDoc();
  }
  const handleCloseCmpyDoc = () => {
    setViewCompyDoc(false);
  }
  const handleOpenInternalDoc = () => {
    setViewInternalDoc(true);
    handleCloseCmpyDoc();
    handleClosePresentationDoc();
    setLoading(true);
    axios.post(GET_DOCUMENT, { "Project_ID": projectName }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        const resData = response.data.data.filter((data, i) => {
          if (data.type.includes("WORKORDER_DOCUMENT")) {
            return data;
          }
        }
        )
        setInternalDocs(resData);
      }
    });
  }
  const handleCloseInternalDoc = () => {
    setViewInternalDoc(false);
  }
  const handleOpenPresentationDoc = () => {
    setViewUploadPrstnDoc(true);
    handleClosePresentationDoc1();
    setViewUploadResultDoc(false);
  }
  const handleClosePresentationDoc = () => {
    setViewUploadPrstnDoc(false);
  }
  const handleOpenPresentationDoc1 = () => {
    setViewUploadMoreDoc(true);
    setViewUploadResultDoc(false);
    handleClosePresentationDoc();
  }
  const handleOpenPresentationDoc2 = () => {
    setViewUploadResultDoc(true);
    setViewUploadMoreDoc(false);
    handleClosePresentationDoc();
  }
  const handleClosePresentationDoc1 = () => {
    setViewUploadMoreDoc(false);
  }
  const handlefile = (e) => {
    var name = e?.target?.files[0]?.name?.split("");

    var validate = name?.reverse().join("").split(".")[0];
    if (e?.target.id !== "Result_file") {
      if (validate === "xslx" || validate === "piz" || validate === "kpa") {
        alert("please select image file !");
        e.target.value = null;
        return;
      }
    }
    setuploadfile(e.target.files[0]);
  }

  function uploadfiles() {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", uploadfile);
    data.append("ID", projectName);
    data.append("SubType", GetDocName);
    data.append("name", GetDocName);
    data.append("Type", "PRESENTATION_DOCUMENT");
    //setLoading(true);
    setIsSubmitFile(true);
    axios.post(CREATE_DOCUMENT, data,
      {
        onUploadProgress: (data) => {
          const { loaded, total } = data;
          let percent = Math.floor((loaded * 100) / total);
          if (percent < 100) {
            setUploadedPrecentage(percent);
          }
        }, cancelToken: new CancelToken(cancel => cancelFileUpload.current = cancel)
      })
      .then(function (response) {
        //setLoading(false);
        if (response.data.isSuccess === true) {
          setUploadedPrecentage(100);
          setTimeout(() => {
            setIsSubmitFile(false);
            window.location.reload();
          }, 1000)

        }
        else {
          setHeading("Submission Failed!");
          setErrMsg("Faield to create file");
          handalOpenErr();
          return;
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function uploadfiles1() {

    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", uploadfile);
    data.append("ID", projectName);
    data.append("SubType", GetDocName);
    data.append("name", GetDocName);
    data.append("Type", "EXTRA_DOCUMENT");
    // setLoading(true);
    setIsSubmitFile(true);
    axios.post(CREATE_DOCUMENT, data, {
      onUploadProgress: (data) => {
        const { loaded, total } = data;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          setUploadedPrecentage(percent);
        }
      }, cancelToken: new CancelToken(cancel => cancelFileUpload.current = cancel)
    })
      .then(function (response) {
        // setLoading(false);
        if (response.data.isSuccess === true) {
          setUploadedPrecentage(100);
          setTimeout(() => {
            setIsSubmitFile(false);
            window.location.reload();
          }, 1000)
        }
        else {
          setHeading("Submission Failed!");
          setErrMsg("failed to upload file");
          handalOpenErr();
          return;
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function uploadResultDoc() {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", uploadfile);
    data.append("ID", projectName);
    data.append("SubType", GetDocName);
    data.append("name", GetDocName);
    data.append("Type", "RESULT_DOCUMENT");
    // setLoading(true);
    setIsSubmitFile(true);
    axios.post(CREATE_DOCUMENT, data, {
      onUploadProgress: (data) => {
        const { loaded, total } = data;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          setUploadedPrecentage(percent);
        }
      }, cancelToken: new CancelToken(cancel => cancelFileUpload.current = cancel)
    })
      .then(function (response) {
        // setLoading(false);
        if (response.data.isSuccess === true) {
          setUploadedPrecentage(100);
          setTimeout(() => {
            setIsSubmitFile(false);
            window.location.reload();
          }, 1000)
        }
        else {
          setHeading("Submission Failed!");
          setErrMsg("failed to upload file");
          handalOpenErr();
          return;
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const [showMultiDoc1, setShowMultiDoc1] = useState(false);
  const handlecDoc1 = () => {

    if (showMultiDoc1 === true) {
      // setShowMultiDoc1(false);
      // setDoc1(projectDetails.Category)
    }
    else {
      setShowMultiDoc1(true);
    }
  }
  const deletePdfFile = (id) => {
    setLoading(true);
    axios.post(DELETE_MERGED_PDF_DOCUMENT, {
      "ROWPOS": id,

    }).then((response) => {
      setLoading(false);
      setMergDltPop(false);
      if (response.data.isSuccess === true) {
        setHeading1("Success");
        setsuccessMsg("Document Deleted successfully!");
        handalOpen1();
        //window.location.reload(); 
      }
      else {
        setHeading("Error!");
        setErrMsg("Somthing Went Wrong");
        handalOpenErr();
        return;
      }

    });
  }
  const cancelUpload = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current("user has cacel the file upload");
    }
  }

  const addCmpnyDoc = () => {
    setLoading(true);

    axios.post(ASSIGN_COMPANY_DOC, {

      "Type": "COMPANY_DOCUMENT",

      "Project_ID": projectName,

      "lst_subType": comDocName,

      "lst_documentID": comDocId

    }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setHeading1("Success");
        setsuccessMsg("Document add successfully!");
        handalOpen1();
        //window.location.reload(); 
      }
      else {
        setHeading("Error!");
        setErrMsg("Somthing Went Wrong");
        handalOpenErr();
        return;
      }

    });

  }
  const exportToPDFPopUp = () => {
    setIsOpenPrint(true);
  }
  const exportToPDF = () => {
    const doc = new jsPDF('p', 'pt', 'a3');
    doc.setTextColor(255, 0, 0);
    doc.html(document.querySelector("#content"), {
      callback: function (pdf) {
        window.open(doc.output('bloburl'))
        // pdf.save("mtPDf.pdf");
      }
    }, "#000");

  }
  const costcolumns = [
    { field: "name", headerName: "Name" },
    { field: "discription", headerName: "Description", width: 150 },
    { field: "head", headerName: "Head", width: 130 },
    { field: "internal_Amt", headerName: "Internal Amt", width: 130 },
    // { field: "internal_Rate", headerName: "internal_Rate", width: 130 },
    { field: "location", headerName: "Location", width: 110 },
    { field: "qty", headerName: "Quantity", width: 110 },
    { field: "size", headerName: "Size", width: 110 },
    { field: "unit", headerName: "Unit", width: 110 },
    { field: "remark", headerName: "Remark", width: 110 },
    {
      field: "ALLOCATE VENDOR",
      headerName: "Allocate",
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      // renderCell: () => {
      //   return (
      //     <button className="btn btn-success" onClick={handleAllbrand}>
      //       Allocate Vendor
      //     </button>
      //   );
      // },
    },
  ];
  const openBOQ = () => {
    console.log(data);
    let isBOQ = false;
    let BOQDOC = {};
    data[0]?.lst_documents?.filter((data, i) => {
      if (data.type === "BOQ_DOCUMENT") {
        isBOQ = true;
        BOQDOC = data;
      }
    }
    )
    isBOQ ?
      hadldleOpenEligibilityDoc(BOQDOC.path, BOQDOC.path.split(".")[BOQDOC.path.split(".").length - 1], BOQDOC.type) :
      alert("No BOQ Found For " + data[0].projectName);
  }
  // const openCostSheet=()=>
  // {
  //   data[0].costSheet.length>0?
  //   setCostSheet(<DataGrids cardsList={data[0].costSheet} columns={costcolumns}/>)
  //   :
  //   alert("No Internal Cost Sheet Found For "+data[0].projectName);
  // }
  const unRejectTenderPopUp = () => {
    setIsOpen(true);
  }
  const handalClosePopUp = () => {
    setIsOpen(false);
  }
  const unRejectTender = () => {
    setLoading(true);
    axios.post(UNREJECT_PROJECT, {
      "ROWPOs": projectName
    }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        window.location.reload();
      }
      else {
        alert("Somthing went wrong");
      }

    });

  }
  const addWorkDoc = () => {
    setLoading(true);
    axios.post(ASSIGN_COMPANY_DOC, {

      "Type": "WORKORDER_DOCUMENT",

      "Project_ID": projectName,

      "lst_subType": workDocName,

      "lst_documentID": workDocId

    }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {

        alert("Add Successfully");
        window.location.reload();
      }
      else {
        alert("Somthing went wrong");
      }

    });

  }
  const AddDocInMergeList = () => {
    //   setTblMerge(<table className="table hAuto">
    //   <thead>
    //    <tr>
    //    <th scope="row">Sr.No.</th>
    //       <th scope="col">Document Name</th>
    //       <th scope="col">Document Type</th>
    //    {/* <th scope="col">Remove</th>  */}
    //      </tr>
    //   </thead>
    //   <DragToReorderList ProjectDocList={finalArrayData}setList={setList} setFinalArrayData={setFinalArrayData}/>
    //  </table> );
    setShowMrgFinalBtn(true);
  }
  const onDrop = (data) => {
    // => banana 
  }
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //SEND TO PREVIOUE PAGE
  const handleGoToPrv = (e) => {
    // alert("hello")
    navigate(-1);
  }
  const updateProject = () => {
    var data = JSON.stringify({
      "ROWPOS": projectName,
      "Interested": false,
      "Remarks": "",
      "WON": false
    });

    var config = {
      method: 'post',
      url: UPDATE_PROJECT,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        if (response.data.isSuccess === true) {
          window.location.reload();
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  if (secSepShow === true && props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN || props.tokectDetails?.logintype === ALLOWED_USERTYPE.CREATIVE || props.tokectDetails?.logintype === ALLOWED_USERTYPE['CREATIVE-LEAD']) {

    return (
      <RunningProject UserType={props.UserType} tokectDetails={props.tokectDetails} />
    )

  }

  else {
    return (
      <div className='row'>
        {
          isLoading ? <div className='loaderDiv'>
          </div> : null
        }
        {
          (props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN) ? (
            <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
              <SideBar ref={componentRef} />
            </div>
          ) : null
        }



        <div className={`${props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN
          ? 'col-md-12 col-10 col-sm-10 '
          : 'col-md-12 col-12 col-sm-12'} myview`}>

          {
            yesok ?
              <div className='popupbox'>
                <img style={{ height: "100vh", width: "100%" }} src={ok} />
              </div>
              : ""
          }
          {
            wonsuccess ?

              <div className='popupbox wonpopup' >
                <img style={{ height: "100vh", width: "100%" }} src="https://raw.githubusercontent.com/fufu70/react-confetti-canvas/HEAD/assets/canvas.gif" />

                <div className='wonpopup ' >
                  <h3 className='winnertext '>
                    <span className='tracking-in-expand-fwd-top'>W</span>
                    <span className='tracking-in-expand-fwd-bottom'>I</span>
                    <span className='tracking-in-expand-fwd-top'>N</span>
                    <span className='tracking-in-expand-fwd-bottom'>N</span>
                    <span className='tracking-in-expand-fwd-top'>E</span>
                    <span className='tracking-in-expand-fwd-bottom'>R</span>
                  </h3>

                </div>
              </div>
              : ""
          }

          {/* {
  yespopup ? 
    
<div className='popupbox'>
<div  className='popup'>
  <div className='cross' style={{cursor:"pointer"}} onClick={cross}>X</div>
<center><h6>UPLOAD DOCUMENT</h6></center>
<br/><br/>
<div className='contentflex'>
<div className="mb-3 typefile">
      <label className="form-label label">Lastname</label>
      <input className="form-control small" type="file" />
    
    </div>
    <div className="mb-3 typefile">
      <label className="form-label  label">Lastname</label>
      <input  type="file" className="form-control inputbg small"         
       />
    
    </div>
  
</div>
<br></br><br></br>
<div className="mb-3 ">
      <label className="form-label label">DAtE</label>
      <input  type="date"   max="9999-12-31" className="form-control inputbg small" />
    
    </div>

    <br></br>
    <center><Button style={{width:"200px",background:"green"}}  variant="contained" >SUBMIT NOW</Button>  </center>        

  </div>
</div>   :""
} */}

          {
            nopopup ?


              <div className='popupbox'>
                <div className='popup'>
                  <div className='cross' style={{ cursor: "pointer" }} onClick={cross}>X</div>
                  <center><h6>Reason for Tender Rejection</h6></center>
                  <br /><br />

                  <br></br>
                  <div className="mb-3 ">

                    <center> <textarea style={{ width: "400px", heigth: "450px", color: "green" }} value={addremark} onChange={(e) => readthisremark(e)} /></center>

                  </div>

                  <br></br>
                  <center><Button style={{ width: "200px", background: "green" }} variant="contained" onClick={notintrested} >SUBMIT NOW</Button>  </center>

                </div>
              </div>

              : ""
          }
          {
            rejectpopoup ?
              <div className='popupbox'>
                <div className='popup'>
                  <div className='cross' style={{ cursor: "pointer" }} onClick={closeLostPopo}>X</div>
                  <center><h6>Reason for Tender lost</h6></center>
                  <br /><br />

                  <br></br>
                  <div className="mb-3 ">

                    <center> <textarea style={{ width: "400px", heigth: "450px", color: "green" }} value={addremark} onChange={(e) => readthisremark(e)} /></center>

                  </div>

                  <br></br>
                  <center><Button style={{ width: "200px", background: "green" }} variant="contained" onClick={onLostReview} >SUBMIT NOW</Button>  </center>

                </div>
              </div>

              : ""
          }
          <div className='px-4 '>
            <span className="cursor-pointer"><a className="mt-5" href="#" onClick={e => { handleGoToPrv(e) }}>Go Back</a></span>
            {data[0]?.state === TENDER_STATE.FILING ? <span className="float-end cursor-pointer"><a href="#" onClick={e => { updateProject(e) }}>Send Into Previous Stage</a></span> : ""}
            {data[0]?.state === TENDER_STATE.REJECTED ?
              <div className="rejtBtn" onClick={unRejectTenderPopUp}>unrejected</div>
              : ""}

            <ProjectInfo project={data[0]} tokectDetails={props.tokectDetails} />
          </div>
          <>
            <div className="row my-3 ">

              {props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ?
                <>
                  {
                    data[0]?.lst_documents?.filter((data, i) => {
                      if (data.type === "ELIGIBILITY_DOCUMENT" || data.type === "PROJECT_DOCUMENT" || data.type === "BOQ_DOCUMENT") {
                        return data;
                      }
                    }).map((data, i) => {
                      return (
                        <div className='col-sm-4 col-md-4 col-4 text-center mx-auto' key={i}>
                          <button className='btn btnSuccess' onClick={e => { hadldleOpenEligibilityDoc(data.path, data.path.split(".")[data.path.split(".").length - 1], data.type) }}>View {data.type}</button>
                        </div>
                      )
                    })

                  }
                  {
                    data[0]?.lst_documents?.filter((data, i) => {
                      if (data.type === "CORRIGENDUM_DOCUMENT") {
                        return data;
                      }
                    }).map((data, i) => {
                      return (
                        <div className='col-sm-4 col-md-4 col-4 text-center mx-auto mt-2' key={i}>
                          <button className='btn btnSuccess' onClick={e => { hadldleOpenEligibilityDoc(data.path, data.path.split(".")[data.path.split(".").length - 1], data.type) }}>View Corrigendum Document {i + 1}</button>
                        </div>
                      )
                    })
                  }
                </>
                :
                null
              }


            </div>
            <center>
              {
                data[0]?.state === TENDER_STATE.NEW_ARR ?
                  <>
                    <button className='description-btn yes' onClick={e => { handleyes("NEW") }}>Accept</button>
                    <button className='description-btn no' onClick={handleRejectPopUp}>Reject</button></> :
                  null
              }
              {
                data[0]?.state !== TENDER_STATE.NEW_ARR ?
                  data[0]?.state === TENDER_STATE.REJECTED ? <div className='text-center'><span className='h5 colorSec'>Reason for Reject : </span><span className='h6 text-white'>{data[0]?.remarks}</span></div> :
                    data[0]?.state === TENDER_STATE.NEW ?

                      <div className='details-li d-inline-block fw-bold pb-5'>
                        <>
                          <button className='description-btn yes' onClick={e => { handleyes("FILING") }}>Start Filing</button>
                          <button className='description-btn no' onClick={handleRejectPopUp}>Reject</button></>
                      </div>
                      :
                      <div className='px-3 text-center'>
                        <div className='row'>

                          <div className='col-md-6'>
                            <h4 className='theamColor mb-4'>Choose Company Documents</h4>
                            <table className="table hAuto">
                              <thead>
                                <tr>
                                  <th scope="col">Document Name</th>
                                  {/* <th scope="col">Documents</th> */}
                                </tr>
                              </thead>
                              <tbody>


                                <tr>
                                  <td scope="row">
                                    {cmpntDocs}
                                  </td>
                                  <td>
                                    <button className='btn btnSuccess' onClick={e => { addCmpnyDoc(e) }}>Add</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className='col-md-6'>
                            <h4 className='theamColor mb-4'>Choose Work Order & Completions Certificate</h4>
                            <table className="table hAuto">
                              <thead>
                                <tr>
                                  <th scope="col">Document Name</th>
                                  {/* <th scope="col">Documents</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td scope="row">

                                    {internalDocs}
                                  </td>
                                  <td>
                                    <button className='btn btnSuccess' onClick={e => { addWorkDoc(e) }}>Add</button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className='col-md-4'>
                            <button className=' btn btnSuccess tracking-in-expand-fwd-top' onClick={e => { handleOpenPresentationDoc() }}>Upload Technical Presentation Documents</button>
                          </div>
                          <div className='col-md-4'>
                            <button className=' btn btnSuccess tracking-in-expand-fwd-top' onClick={e => { handleOpenPresentationDoc1() }}>Upload More Documents</button>
                          </div>
                          <div className='col-auto mt-3'>
                            <button className=' btn btnSuccess tracking-in-expand-fwd-top' onClick={e => { handleOpenPresentationDoc2() }}>Upload Result Documents</button>
                          </div>
                          {resultList.length > 0 ?
                            <div className='col-md-4'>
                              <button className=' btn btnSuccess tracking-in-expand-fwd-top' onClick={e => { hadldleOpenEligibilityDoc(resultList[0].path, resultList[0].path.split(".")[resultList[0].path.split(".").length - 1], 'RESULT_DOCUMENT') }} >View Result Document</button>  </div>
                            : null
                          }


                        </div>
                      </div>
                  :
                  null
              }
              {/*show uploade presentation Documents */}
              {
                viewUploadPrstnDoc ?
                  <div className="row py-5">
                    <div className="col-md-12 col-sm-12 col-12">
                      <h3
                        style={{
                          color: "#0f533d",
                          textTransform: "uppercase",
                          fontWeight: "900",
                          textAlign: "center",

                          // marginTop: "-30px",
                        }}
                        className="mb-2"
                      >
                        {" "}
                        <span className="text-white h4 text-capitalize" style={{ paddingLeft: "8%" }}> Upload Technical Presentation Document{" "}</span>
                        <p className="float-end px-4 cursor-pointer " onClick={e => { handleClosePresentationDoc() }} >❌</p>
                      </h3>
                    </div>

                    <div className="col-md-4 col-sm-4 col-4"></div>
                    <div className="col-md-4 col-sm-4 col-4 py-3 formClass" >
                      <div className="my-5">
                        <label className="form-label text-capitalize">Document Name</label> <br />
                        <input type="text" placeholder='Enter Document Name..' onChange={e => { setGetDocName(e.target.value) }} className="w-100" />
                      </div>
                      <div className="mt-5 mb-2">
                        <label className="form-label text-capitalize">Upload Document</label> <br />
                        <input type="file"
                          onChange={(e) => handlefile(e)}
                          className="form-control w-100" />

                      </div>
                      {isSubmitFile ? <span className='text-white text-start '>{`${uploadedPrecentage}%`}</span> : ""}

                      <div style={{ height: "4%" }} className="text-start my-3">
                        <div
                          className="progress-bar progress-bar-striped bg-success "
                          style={{
                            width: `${uploadedPrecentage}%`,
                            backgroundColor: "#198754",
                            height: "100%",
                          }}
                          aria-valuenow={uploadedPrecentage}
                        ></div>
                      </div>

                      {isSubmitFile ?
                        <div className='col-auto my-3'>
                          <span className="btn btn-danger text-primary cursor-pointer text-white"
                            onClick={() => cancelUpload()}
                            style={{
                              fontSize: "14px",

                            }}>
                            Cancel
                          </span>

                        </div> :
                        <div className='col-auto my-3'>
                          <span className="btn  text-primary cursor-pointer text-white"
                            style={{
                              borderRadius: 5,
                              backgroundColor: "green",
                              fontSize: "14px",
                              border: "transparent",
                              color: "#fff"
                            }}
                            onClick={e => { uploadfiles() }}>
                            Submit
                          </span>
                        </div>
                      }
                    </div>
                  </div> : null
              }
              {
                viewUploadMoreDoc ?
                  <div className="row py-5">
                    <div className="col-md-12 col-sm-12 col-12">
                      <h3
                        style={{
                          color: "#0f533d",
                          textTransform: "uppercase",
                          fontWeight: "900",
                          textAlign: "center",
                          // marginTop: "-30px",
                        }}
                        className="mb-2"
                      >
                        {" "}
                        <span className="text-white h4 text-capitalize" style={{ paddingLeft: "8%" }}> Upload More Document{" "}</span>

                        <p className="float-end px-4 cursor-pointer " onClick={e => { handleClosePresentationDoc1() }} >❌</p>
                      </h3>
                    </div>

                    <div className="col-md-4 col-sm-4 col-4"></div>
                    <div className="col-md-4 col-sm-4 col-4 py-3 formClass" >
                      <div className="my-5">
                        <label className="form-label text-capitalize">Document Name</label> <br />

                        <input type="text" placeholder='Enter Document Name..' onChange={e => { setGetDocName(e.target.value) }} className="w-100" />
                      </div>
                      <div className="my-5">
                        <label className="form-label text-capitalize">Upload Document</label> <br />
                        <input type="file"
                          onChange={(e) => handlefile(e)}
                          className="form-control w-100" />

                      </div>
                      {isSubmitFile ? <span className='text-white text-start '>{`${uploadedPrecentage}%`}</span> : ""}
                      <div style={{ height: "4%" }} className="text-start my-3">
                        <div
                          className="progress-bar progress-bar-striped bg-success "
                          style={{
                            width: `${uploadedPrecentage}%`,
                            backgroundColor: "#198754",
                            height: "100%",
                          }}
                          aria-valuenow={uploadedPrecentage}
                        ></div>
                      </div>
                      {isSubmitFile ? <
                        div className='col-auto my-3'>
                        <span className="btn btn-danger text-primary cursor-pointer text-white"
                          onClick={() => cancelUpload()}
                          style={{
                            fontSize: "14px",

                          }}>
                          Cancel
                        </span>

                      </div> :
                        <div className='col-auto my-3'>
                          <span className="btn  text-primary cursor-pointer text-white"
                            style={{
                              borderRadius: 5,
                              backgroundColor: "green",
                              fontSize: "14px",
                              border: "transparent",
                              color: "#fff"
                            }}
                            onClick={e => { uploadfiles1() }}>
                            Submit
                          </span>
                        </div>
                      }
                    </div>
                  </div> : null
              }

              {
                viewUploadResultDoc ?
                  <div className="row py-5">
                    <div className="col-md-12 col-sm-12 col-12">
                      <h3
                        style={{
                          color: "#0f533d",
                          textTransform: "uppercase",
                          fontWeight: "900",
                          textAlign: "center",
                          // marginTop: "-30px",
                        }}
                        className="mb-2"
                      >
                        {" "}
                        <span className="text-white h4 text-capitalize" style={{ paddingLeft: "8%" }}> Upload Result Document</span>

                        <p className="float-end px-4 cursor-pointer " onClick={e => { handleClosePresentationDoc1() }} >❌</p>
                      </h3>
                    </div>

                    <div className="col-md-4 col-sm-4 col-4"></div>
                    <div className="col-md-4 col-sm-4 col-4 py-3 formClass" >
                      <div className="my-5">
                        <label className="form-label text-capitalize">Document Name</label> <br />

                        <input type="text" placeholder='Enter Document Name..' onChange={e => { setGetDocName(e.target.value) }} className="w-100" />
                      </div>
                      <div className="my-5">
                        <label className="form-label text-capitalize">Upload Document</label> <br />
                        <input type="file"
                          id='Result_file'
                          onChange={(e) => handlefile(e)}
                          className="form-control w-100" />

                      </div>
                      {isSubmitFile ? <span className='text-white text-start '>{`${uploadedPrecentage}%`}</span> : ""}
                      <div style={{ height: "4%" }} className="text-start my-3">
                        <div
                          className="progress-bar progress-bar-striped bg-success "
                          style={{
                            width: `${uploadedPrecentage}%`,
                            backgroundColor: "#198754",
                            height: "100%",
                          }}
                          aria-valuenow={uploadedPrecentage}
                        ></div>
                      </div>
                      {isSubmitFile ?
                        <div className='col-auto my-3'>
                          <span className="btn btn-danger text-primary cursor-pointer text-white"
                            onClick={() => cancelUpload()}
                            style={{
                              fontSize: "14px",

                            }}>
                            Cancel
                          </span>

                        </div> :
                        <div className='col-auto my-3'>
                          <span className="btn  text-primary cursor-pointer text-white"
                            style={{
                              borderRadius: 5,
                              backgroundColor: "green",
                              fontSize: "14px",
                              border: "transparent",
                              color: "#fff"
                            }}
                            onClick={e => { uploadResultDoc() }}>
                            Submit
                          </span>
                        </div>
                      }
                    </div>
                  </div> : null
              }
            </center>
            {
              data[0]?.state !== TENDER_STATE.REJECTED && data[0]?.state !== TENDER_STATE.NEW ? ProjectDocList.length > 0 &&
                (data[0]?.interested?.toString() === 'true' || data[0]?.won?.toString() === 'true') ?
                <> <div className='row px-4'>
                  <h4
                    className="py-3 my-4 text-center text-capitalize"
                    style={{
                      fontWeight: "700",
                      color: "white",

                    }}

                  >
                    All Documents List
                  </h4>
                  <div style={{ height: "50vh" }}>
                    <DataGrid
                      rows={ProjectDocList}
                      columns={DocListcol}
                      pageSize={5}
                      getRowId={(row) => row.document_ID}
                      rowsPerPageOptions={[5]}
                      checkboxSelection
                      onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const selectedRowData = ProjectDocList.filter((row) =>
                          selectedIDs.has(row.document_ID.toString())
                        );
                        finalArray = selectedRowData;
                        setFinalArrayData(selectedRowData);
                        setShowMrgFinalBtn(true);
                      }}
                    />


                  </div>
                  {
                    finalArrayData.length > 0 ?
                      <div className="row">
                        <div className='col-md-6 col-lg-6 col-6 col-sm-6'>
                          <div className='text-center my-4'><button className='btn btnSuccess cursor-pointer' onClick={e => { AddDocInMergeList(e) }}>Add Merge List</button></div>
                        </div>
                        <div className='col-md-6 col-lg-6 col-6 col-sm-6'>
                          <div className='text-center my-4 cursor-pointer'><button className='btn btn-danger' onClick={() => { setClearListPop(true) }}>Clear List</button></div>
                        </div>
                      </div>
                      : ""
                  }
                  {
                    finalArrayData.length > 0 ?
                      // tblMerge
                      <table className="table hAuto">
                        <thead>
                          <tr>
                            <th scope="row">Sr.No.</th>
                            <th scope="col">Document Name</th>
                            <th scope="col">Document Type</th>
                            {/* <th scope="col">Remove</th>  */}
                          </tr>
                        </thead>
                        <DragToReorderList ProjectDocList={finalArrayData} setList={setList} setFinalArrayData={setFinalArrayData} />
                      </table>

                      : ""
                  }
                </div>
                  {
                    showMrgFinalBtn && finalArrayData.length > 0 ?
                      <div className='text-center'><button className='btn btnSuccess cursor-pointer' onClick={e => { MergerPdf(e) }}>Merge Documents</button></div>
                      : null
                  }

                </> : null : null
            }
            {
              data[0]?.state !== TENDER_STATE.REJECTED ?
                <div className='row my-3'>
                  {
                    data[0]?.lst_documents?.filter((data, i) => {
                      if (data.type === "MERGED_FILE") {
                        return data;
                      }
                    })
                      .map((data, i) => {
                        return (<>
                          <div className='col-sm-3' key={i}>
                            <a href={data.path} target="blank">
                              <iframe className='cursor-pointer' src={data.path} width="100%" height="300px" >
                              </iframe>
                            </a>
                            <div className='text-white my-2'>{new Date(data.edate).toLocaleString("en-IN", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).split(':', 2).join(':')}</div>
                            <button className='btn btnDelete mx-1 my-2' onClick={e => { setMergDltPop(true); setDltdocument_ID(data.document_ID) }}>Delete </button>
                          </div>
                        </>)

                      })
                  }

                </div>
                : null
            }
            {
              data[0]?.state !== TENDER_STATE.NEW_ARR ?
                data[0]?.state !== TENDER_STATE.REJECTED ?
                  //  data[0]?.lost===false && data[0]?.interested?.toString()=== 'true'?
                  data[0]?.state !== TENDER_STATE.NEW ?
                    <div className='row'>
                      <div className='col-sm-2'></div>
                      <div className='col-sm-3'>
                        <a href='#' ><button className='description-btn yes ' onClick={onWonClick}  >Won</button></a>
                      </div>
                      <div className='col-sm-3'>
                        <button className='description-btn no' onClick={onLostClick} >Lost</button>
                      </div>
                      <div className='col-sm-3'>
                        <button className='description-btn btnSecondary' onClick={handleRejectPopUp}>Reject</button>
                      </div>

                    </div>
                    : data[0]?.lost === true ? <div className='text-center'><span className='h5 colorSec'>Reason for Lost : </span><span className='h6'>{data[0]?.remarks}</span></div>
                      : null : null
                : null
            }

            {
              openEligibilityDoc ?
                <div className="popupbox" style={{ top: "-1%", zIndex: "111" }}>
                  <div className='popup' style={{ width: "50%", position: "fixed" }}>

                    <div className='px-3'>
                      <div className='row'>
                        <p className='text-secondary h4'>Document Preview<span style={{ float: "right", cursor: "pointer" }} onClick={e => { hadldleCloseEligibilityDoc(e) }}>❌</span></p>

                        {
                          eligibilityDocView
                        }
                      </div>
                    </div>

                  </div>
                </div>
                : null
            }


            {/*show company Documents */}
            {
              viewCompyDoc ?

                <div style={{ height: "100vh", width: "100%" }}>
                  <p className="float-end cursor-pointer p-4" onClick={(e) => handleCloseCmpyDoc()}>
                    ❌
                  </p>

                  <div className="formbg">
                    <center>
                      {" "}
                      <h3
                        className="tracking-in-expand-fwd-bottom"
                        style={{
                          fontWeight: "900",
                          textTransform: "uppercase",
                          letterSpacing: "4px",
                          color: "rgb(9, 82, 85)",
                          marginTop: "-60px"
                        }}
                      >
                        Company Documents List
                      </h3>
                    </center>
                    <br /><br />

                    <div style={{ width: "80%", margin: "auto" }}>
                      <div className="table">

                        <DataGrid
                          rows={cmpntDocs}
                          columns={columnsForCmpnyDoc}
                          getRowId={(row) => row.rowpos}
                          pageSize={10}
                          // checkboxSelection
                          onSelectionModelChange={(ids) => {
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                : null
            }
            {/*show Internal Documents */}
            {
              viewInternalDoc ?
                <div style={{ height: "100vh", width: "100%" }}>
                  <p className="float-end cursor-pointer p-4" onClick={(e) => handleCloseInternalDoc()}>
                    ❌
                  </p>

                  <div className="formbg">
                    <center>
                      {" "}
                      <h3
                        className="tracking-in-expand-fwd-bottom"
                        style={{
                          fontWeight: "900",
                          textTransform: "uppercase",
                          letterSpacing: "4px",
                          color: "rgb(9, 82, 85)",
                          marginTop: "-60px"
                        }}
                      >
                        Internal Documents List
                      </h3>
                    </center>
                    <br /><br />

                    <div style={{ width: "80%", margin: "auto" }}>
                      <div className="table">
                        <DataGrid
                          rows={internalDocs}
                          columns={columnsForCmpnyDoc}
                          getRowId={(row) => row.rowpos}
                          pageSize={10}
                          // checkboxSelection
                          onSelectionModelChange={(ids) => {
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                : null
            }

          </>
          <Modal className="fade show" isOpen={isOpen} >
            <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
              <h4 className=' text-center  fw-bold  successColor'>Confirmation</h4>
              <p className=' text-center text-danger fw-bold'>Are you sure You want to unreject this tendor.</p>
              <div>   <Button
                className='btnSuccess mx-2'
                onClick={() => { unRejectTender() }}
              >
                Yes
              </Button>
                <Button
                  className='btnDelete mx-2'
                  onClick={handalClosePopUp}
                >
                  No
                </Button></div>
            </ModalBody>

          </Modal>
          <Modal className="fade show" isOpen={mergDltPop} >
            <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
              <h4 className=' text-center  fw-bold  successColor'>Confirmation</h4>
              <p className=' text-center text-danger fw-bold'>Are you sure You want to Delete Document.</p>
              <div>   <Button
                className='btnSuccess mx-2'
                onClick={() => { deletePdfFile(dltdocument_ID) }}
              >
                Yes
              </Button>
                <Button
                  className='btnDelete mx-2'
                  onClick={() => { setMergDltPop(false) }}
                >
                  No
                </Button></div>
            </ModalBody>
          </Modal>
          <Modal className="fade show" isOpen={clearListPop} >
            <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
              <h4 className=' text-center  fw-bold  successColor'>Confirmation</h4>
              <p className=' text-center text-danger fw-bold'>Are you sure You want to Clear/Unassign Document Documents.</p>
              <div>   <Button
                className='btnSuccess mx-2'
                onClick={() => { setunassignedDocList(finalArrayData) }}
              >
                Yes
              </Button>
                <Button
                  className='btnDelete mx-2'
                  onClick={() => { setClearListPop(false) }}
                >
                  No
                </Button></div>
            </ModalBody>
          </Modal>
          <Modal className="fade show" isOpen={isOpenLost} >
            <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
              <h4 className=' text-center  fw-bold  successColor'>Confirmation</h4>
              <p className=' text-center text-danger fw-bold'>Are you sure You want to Lost this tendor.</p>
              <div>   <Button
                className='btnSuccess mx-2'
                onClick={() => { unRejectTender() }}
              >
                Yes
              </Button>
                <Button
                  className='btnDelete mx-2'
                  onClick={handalClosePopUp}
                >
                  No
                </Button></div>
            </ModalBody>

          </Modal>

          <ErrorAlertBox isOpen={isOpenErr} setIsOpen={setIsOpenErr} heading={heading} errMsg={errMsg} />
          <SuccessAlertBox href={urlVal} isOpen1={isOpen1} setIsOpen1={setIsOpen1} heading1={heading1} successMsg={successMsg} />
        </div>
      </div>)

  }
}
export default ProjectDetail;