import React,{ useState } from 'react';
import './button.css';
import {
    Button,
    Modal,
    ModalFooter,
    ModalBody,
    ModalHeader,
  } from "reactstrap";
  import {DELETE_EMPLOYEE} from '../../utiles/AllApis';
import axios from 'axios';
const ButtonDelete=(props)=>
{
    const [isOpen,setIsOpen]=useState(false);
    const handalOpen=()=>{setIsOpen(true);}
    const handalClose=()=>{setIsOpen(false);}
    const submitRemarks=()=>
    {
        handalClose();
    }
 

return(
  
    <>
      {props.btnhtmlFor==="noInterest"?
       <><button className='btn btnDelete mx-1' onClick={handalOpen}>{props.btnTitle} </button>
<Modal className=" fade show"isOpen={isOpen}  >
<ModalHeader className='brdBtm0'
  close={<button className="close" onClick={handalClose}>×</button>}
>
<span className='theamColor'>Remarks</span>   
</ModalHeader>
<ModalBody>
<div className="mb-3">
<label htmlFor="floatingTextarea2"className="form-label">Add Remarks</label>
<textarea className="form-control" placeholder="Add Remarks here..." id="remarks" style={{height: "130px"}}></textarea>

</div>
</ModalBody>
<ModalFooter className='brdTop0'>
  <Button
    color="primary"
    onClick={submitRemarks}
  >
    submit
  </Button>
 

</ModalFooter>
</Modal></>:<button className='btn btnDelete mx-1' onClick={(e)=>{props.onDelete(props.rowpos)}}>{props.btnTitle} </button>}
   
</>
)
}
export default ButtonDelete;