import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CountUp from 'react-countup';

import axios, { CancelToken, isCancel } from 'axios';
import { Button } from "@mui/material";
import { UPLOAD_IMPREST_SHEET, UPLOAD_TRAVEL_EXPENSE_EXCEL } from '../../utiles/AllApis';


const SheetForm1 = (props) => {

    const [name, setname] = useState("")

    const [size, setsize] = useState("")
    const [days, setdays] = useState("")

    const [height, setheight] = useState("")
    const [width, setwidth] = useState("")
    const [ratetype, setratetype] = useState("")
    const [rateAmt, setrateAmt] = useState("")
    const [totalAmt, settotalAmt] = useState("")
    const [status, setstatus] = useState("")
    const [grouping, setgrouping] = useState("")
    const [projectname, setprojectname] = useState("")
    const [heads, setheads] = useState("")
    const [location, setlocation] = useState("")
    const [area, setarea] = useState("")
    const [quantity, setquantity] = useState("")
    const [unit, setunit] = useState("")
    const [description, setdescription] = useState("")
    const [remark, setremark] = useState("")

    const [isDisabled, setIsDisabled] = useState(false);
    const [areadisabled, setareadisabled] = useState(true)

    const [projectList, setProjectList] = useState([]);
    const [headList, setHeadsList] = useState([]);

    // useEffect(() => {
    //     setarea(height * width)
    //     settotalAmt(height * width * quantity * rateAmt)
    // }, [height, width, quantity, rateAmt])

    const submitImprest = () => {

        var imprest = [
            {
                "PARTICULAR": name,
                "DATE": location,
                "RECEIVED": size,
                "REMARK": remark,
                "EXPENSE": days
            }
        ];
        var data = {
            "projectId": 0,
            "type": "type",
            "imprest": imprest
        };

        var config = {
            method: 'post',
            url: UPLOAD_IMPREST_SHEET,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {

                if (response.data.isSuccess === true) {
                    // alert("Cost sheet Successfully submited")
                    window.location.assign('/expense/travel_expense');

                }
                else {
                    alert(response.data.message);
                }

            })
            .catch(function (error) {
                console.log(error);
            });


    }
    const submitConveyance = () => {

        var expense = [
            {
                "Date": location,
                "Days": ratetype,
                "From": status,
                "To": height,
                "Mode_of_Transport": width,
                "Kilometers": area,
                "Rate_per_kms": rateAmt,
                "Remarks": remark
            }
        ];

        var data = {
            "projectId": 0,
            "type": "type",
            "expense": expense
        };

        var config = {
            method: 'post',
            url: UPLOAD_TRAVEL_EXPENSE_EXCEL,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {

                if (response.data.isSuccess === true) {
                    window.location.assign('/expense/travel_expense');

                }
                else {
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    {

        return (
            <div>
                <>
                    <div className=" pt-4" style={{ width: "75%", margin: "auto" }} >
                        <center>
                            {
                                props.sheet ?

                                    <center>

                                        <div className="d-flexed formedia " style={{
                                            width: "90%",
                                            padding: "5px 40px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            color: "#fff !important"
                                        }}>
                                            <div className="mb-3">
                                                <label

                                                    className="form-label"
                                                >
                                                    DATE
                                                </label>
                                                <br />
                                                <input
                                                    type="date"
                                                    name="Area"
                                                    className="form-control small"
                                                    value={location}
                                                    onChange={(e) => setlocation(e.target.value)}

                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label"> PARTICULAR</label>
                                                <br />
                                                <input
                                                    type="text"
                                                    value={name}
                                                    onChange={(e) => setname(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">RECEIVED</label>
                                                <input
                                                    type="number"
                                                    value={size}
                                                    onChange={(e) => setsize(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                        </div>
                                        <div className=" formedia " style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            width: "75%",
                                            margin: "auto"
                                        }}>



                                            <div className="mb-3">
                                                <label className="form-label">EXPENSE</label>
                                                <input
                                                    type="number"
                                                    value={days}
                                                    onChange={(e) => setdays(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">REMARKS</label>
                                                <input
                                                    type="text"
                                                    name="remarks"
                                                    className="form-control small"
                                                    value={remark}
                                                    onChange={(e) => setremark(e.target.value)}

                                                />

                                            </div>

                                        </div>





                                        <br />
                                        <div className="mb-3">
                                            <Button
                                                className='animatingBottom'
                                                style={{
                                                    borderRadius: 5,
                                                    backgroundColor: "green",
                                                    padding: "6px 12px",
                                                    fontSize: "14px",


                                                }} variant="contained" onClick={submitImprest} >Add Imprest</Button>
                                        </div>

                                    </center>
                                    :
                                    <center>

                                        <div className=" formedia " style={{
                                            width: "90%",
                                            padding: "5px 40px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            color: "#fff !important"
                                        }}>
                                            <div className="mb-3">
                                                <label

                                                    className="form-label"
                                                >
                                                    DATE
                                                </label>
                                                <input
                                                    type="date"
                                                    name="Area"
                                                    className="form-control small"
                                                    value={location}
                                                    onChange={(e) => setlocation(e.target.value)}

                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Days</label>
                                                <input
                                                    type="number"
                                                    value={ratetype}
                                                    onChange={(e) => setratetype(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">FROM LOCATION</label>
                                                <input
                                                    type="text"
                                                    value={status}
                                                    onChange={(e) => setstatus(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                        </div>
                                        <div className=" formedia " style={{
                                            width: "90%",
                                            padding: "5px 40px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            color: "#fff !important"
                                        }}>
                                            {/* 
                                            <div className="mb-3">
                                                <label className="form-label">TO LOCATION</label>
                                                <br />
                                                <select aria-label="Default select example" style={{ width: "200px" }} id="heads" name="Department" value={unit} onChange={(e) => { setunit(e.target.value) }}>
                                                    <option>--Select--</option>
                                                    <option value="SQFT">SQUARE FEET</option>
                                                    <option value="SQMRT">SQUARE METER</option>
                                                    <option value="R_FT">R_FT</option>
                                                    <option value="R_MTR">R_MTR</option>
                                                </select>
                                            </div> */}
                                            <div className="mb-3">
                                                <label className="form-label">TO LOCATION</label>
                                                <input
                                                    type="text"
                                                    value={height}
                                                    onChange={(e) => setheight(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">MODE OF TRANSPORT</label>
                                                <input
                                                    type="text"
                                                    value={width}
                                                    onChange={(e) => setwidth(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">KILOMETERS</label>
                                                <input
                                                    type="number"
                                                    name="Area"
                                                    className="form-control small"
                                                    value={area}
                                                    onChange={(e) => setarea(e.target.value)}

                                                />

                                            </div>
                                        </div>

                                        <div className=" formedia " style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            width: "75%",
                                            margin: "auto"
                                        }}>

                                            <div className="mb-3">
                                                <label className="form-label">RATE PER KILOMETERS</label>
                                                <input
                                                    type="number"
                                                    value={rateAmt}
                                                    onChange={(e) => setrateAmt(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">REMARKS</label>
                                                <input
                                                    type="text"
                                                    name="quantity"
                                                    className="form-control small"
                                                    value={remark}
                                                    onChange={(e) => setremark(e.target.value)}
                                                />


                                            </div>
                                        </div>


                                        <div className="formedia text-center" style={{

                                            display: "flex",
                                            justifyContent: "center",
                                            color: "#fff !important"
                                        }}>



                                            <div className="mb-3">
                                                <br />
                                                <Button
                                                    className='animatingBottom'
                                                    style={{
                                                        borderRadius: 5,
                                                        backgroundColor: "green",
                                                        padding: "6px 12px",
                                                        fontSize: "14px",


                                                    }} variant="contained" onClick={submitConveyance} > ADD CONVEYANCE</Button>
                                            </div>

                                        </div>

                                    </center>
                            }

                        </center>





                    </div>
                </>
            </div>
        )
    }
}

export default SheetForm1;