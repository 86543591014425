import './App.css';
import { useEffect, useState, createContext } from 'react';
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import Header from './common/Header';
import Login from './modules/login/Login';
import ManageMaster from './modules/manageMaster/manageMaster';
import MasterFrom from './modules/manageMaster/forms/MasterFrom';
import ProjectsList from './modules/projets/ProjectsList';
import ItemList from './modules/itemMaster/ItemList';
import SheetMain from './modules/itemMaster/SheetMain';
import ProjectDetail from './modules/projets/ProjectDetail';
import ProjectDetailsOPT from './modules/projets/ProjectDetailsOPT';
import ProDetails from './modules/projets/ProDetails';
import ProjectForm from './modules/projets/forms/ProjectForm';
import UpdateFile from './modules/projets/forms/UpdateFile';
import DocumentUpload from './modules/projets/forms/DocumentUpload';
import Dashboard from './modules/Dashboard/Dashboard';
// import Reports from './components/reports/reports';
import Notfound from './components/Notfound';
// import Projectdetails from './components/Projectdetails';
import CostsheetForm from './components/CostsheetForm';
import Mychart from './modules/Dashboard/DashboardChart/Mychart';
import { ALLOWED_USERTYPE } from './utiles/AllApis';
import Itemlistdetails from './modules/itemMaster/Itemlistdetails';
import Dailyupdate from "./modules/itemMaster/dailyupdate"
import Footer from './common/Footer';
import Reports from './modules/Reports/Reports';
import Dash from './common/Dash';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from './modules/projets/carousel';
import Getcookie from "./modules/login/Getcookie";
import Calender from './modules/projets/Calender';
import Applyleave from './components/tagmaster/Applyleave';
import ExceltoJson from './components/tagmaster/ExceltoJson';
//import Employeeprofile from './modules/Employeemanagement/Employeeprofile';
import Employeeprofile from './modules/HRModule/LandingHRPage';
import HrEmployeForm from './components/HrEmployeForm';
import Todos from './components/Todos';
import ProductionRider from './modules/projets/runningProject/ProductionRider';
import OperationDashboard from './modules/Operation/dashboard';
import ProjectsDashboard from './modules/UTypePro/Dashboard';
import Loginpage from './modules/login/Login';
//import Attendance from './modules/attendance/Attendance';
import { ENCRYPTION_KEY } from './utiles/AllApis';
import { ChechExpiryCookie } from './utiles/ChechExpiryCookie';
import GenerateSalary from './modules/HRModule/MainPage/GenerateSalary/GenerateSalary';
import TravelExpense from './modules/Expense/Travel_Expense/TravelExpense';
import GeneralExpense from './modules/Expense/Generel_Expense/GeneralExpense';
import Sheet1 from './modules/itemMaster/Sheet1';
var CryptoJS = require("crypto-js");
const loginData = createContext();
function App() {
  let { userId } = useParams();
  const [UserType, setUserType] = useState("");
  const [UserCode, setUserCode] = useState(Getcookie("Umcode"));
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState([]);
  const [userDetails, setUserDetails] = useState({
    "UserType": "",
    "mcode": ""
  });
  if ((Getcookie("ERPUser") === null || Getcookie("ERPUser") === "" && Getcookie("ERPUser") !== undefined))
  //||(Getcookie(".AspNetCore.Cookies")===null || Getcookie(".AspNetCore.Cookies")==="" && Getcookie(".AspNetCore.Cookies")!==undefined))
  {
    return (

      <>  <Header userDetails={userDetails} isAuthenticated={false} />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login userDetails={userDetails} setUserDetails={setUserDetails} setIsAuthenticated={setIsAuthenticated} setUser={setUser} />} />
            <Route path="*" element={<Login userDetails={userDetails} setUserDetails={setUserDetails} setIsAuthenticated={setIsAuthenticated} setUser={setUser} />} />
          </Routes>
        </BrowserRouter>
      </>
    )
  }
  else {

    const decrypted = CryptoJS.AES.decrypt(Getcookie("ERPUser"), ENCRYPTION_KEY);
    const tokectDetails = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));




    // = tokectDetails.logintype === 'PROJECTS' ? ProDetails : ;

    let ProjectComponent;
    if (tokectDetails.logintype === 'PROJECTS') {
      ProjectComponent = ProDetails
    }
    else if (tokectDetails.logintype === 'CREATIVE') {
      ProjectComponent = ProjectDetail
    }
    else if (tokectDetails.logintype === 'CREATIVE-LEAD') {
      ProjectComponent = ProjectDetail
    }

    else if (tokectDetails.logintype === 'OPERATIONS') {
      ProjectComponent = ProjectDetailsOPT
    }
    else {
      ProjectComponent = ProjectDetail
    }
    // <Route path="/projectList/:projectName"element={<ProjectDetailsOPT tokectDetails={tokectDetails}/>}/>
    //  if(tokectDetails.logintype===ALLOWED_USERTYPE.ADMIN)
    if (1 === 1) {
      return (
        <loginData.Provider value={tokectDetails}>
          <Header userDetails={userDetails} isAuthenticated={true} />
          <BrowserRouter>
            <Routes>
              <Route exact="true" path="/" element={<Dashboard tokectDetails={tokectDetails} />} />
              <Route exact="true" path="/loginpage" element={<Loginpage tokectDetails={tokectDetails} />} />
              <Route exact="true" path="/employeeprofile" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route exact="true" path="/dashboard" element={<Dashboard tokectDetails={tokectDetails} />} />
              <Route exact="true" path="/projectlist/form" element={<ProjectForm tokectDetails={tokectDetails} />} />
              <Route exact="true" path="/projectlist/updateform" element={<UpdateFile tokectDetails={tokectDetails} />} />
              <Route path="/manageMaster/:masterName" element={<ManageMaster tokectDetails={tokectDetails} />} />
              <Route path="/manageMaster/:masterName/:docType" element={<ManageMaster tokectDetails={tokectDetails} />} />
              <Route path="/manageMaster/:masterName/form" element={<MasterFrom tokectDetails={tokectDetails} />} />
              <Route path="/projectList" element={<ProjectsList tokectDetails={tokectDetails} />} />
              <Route path="/ItemMaster" element={<ItemList tokectDetails={tokectDetails} />} />
              <Route path="/reports" element={<Reports tokectDetails={tokectDetails} />} />
              <Route path="/ItemMaster/:projectName" element={<Itemlistdetails tokectDetails={tokectDetails} />} />
              <Route path="/todos" element={<Todos />} />

              <Route path="/projectList/:projectName" element={<ProjectComponent tokectDetails={tokectDetails} />} />
              {/*         
        <Route path="/projectList/:projectName"element={<ProDetails tokectDetails={tokectDetails}/>}/> */}
              <Route path="/projectList/:projectName/ProductionRider" element={<ProductionRider />} />
              <Route path="/projectList/form" element={<ProjectForm />} />
              <Route path="/expense/form1" element={<Sheet1 />} />
              <Route path="/ItemList/form/:projectName" element={<SheetMain />} />
              <Route path="/projectList/:projectName/form" element={<DocumentUpload />} />
              <Route path="hrEmployee" element={<HrEmployeForm />} />
              <Route path="/itemmaster/cost" element={<CostsheetForm />} />
              <Route path="/expense/travel_expense" element={<TravelExpense tokectDetails={tokectDetails} />} />


              <Route path="/dailyUpdate" element={<Dailyupdate />} />
              <Route path="/carousel" element={<Carousel />} />
              <Route path="/dash" element={<Dash />} />
              <Route path="/projectList/:projectName/form" element={<DocumentUpload />} />
              <Route exact="true" path="/chart" element={<Mychart />} />
              <Route exact="true" path="/reports" element={<Reports />} />
              <Route path="*" element={<Notfound />} />
              <Route path="/calender" element={<Calender />} />
              <Route path="/applyleave" element={<Applyleave />} />
              <Route path="/exceltojson" element={<ExceltoJson />} />
              <Route path="/employeeprofile/projects" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/dashboard" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/Master" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/personal" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/timeOff" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/documents" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/emergency" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/training" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/projects/:id" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/attendance" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/help" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/qualification" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/empChanges" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/salarySlab" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/salaryStructure" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/mnthalyData" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/generateSalary/:id" element={<Employeeprofile tokectDetails={tokectDetails} />} />

            </Routes>
          </BrowserRouter>
          {/* <Footer /> */}
        </loginData.Provider>
      );
    }
    else if (tokectDetails.logintype === ALLOWED_USERTYPE.OPERATIONS) {
      return (<>
        <Header userDetails={userDetails} isAuthenticated={true} />
        <BrowserRouter>
          <Routes>
            <Route path="/expense/travel_expense" element={<TravelExpense tokectDetails={tokectDetails} />} />
            <Route path="/expense/general_expense" element={<GeneralExpense tokectDetails={tokectDetails} />} />
            <Route path="/" element={<OperationDashboard tokectDetails={tokectDetails} />} />
            <Route path="/oprtn_Project" element={<OperationDashboard tokectDetails={tokectDetails} />} />
            <Route path="/projectList" element={<ProjectsList tokectDetails={tokectDetails} />} />
            <Route path="/projectList/:projectName" element={<ProjectDetailsOPT tokectDetails={tokectDetails} />} />
            <Route path="/projectList/form" element={<ProjectForm tokectDetails={tokectDetails} />} />
            <Route path="/ItemMaster/:projectName" element={<Itemlistdetails tokectDetails={tokectDetails} />} />
            <Route exact="true" path="/projectlist/updateform" element={<UpdateFile tokectDetails={tokectDetails} />} />
            <Route path="*" element={<Notfound />} />
          </Routes></BrowserRouter></>)
    }
    else if (tokectDetails.logintype === ALLOWED_USERTYPE.PROJECTS) {
      return (<>
        <Header userDetails={userDetails} isAuthenticated={true} />
        <BrowserRouter>
          <Routes>
            <Route path="/expense/travel_expense" element={<TravelExpense tokectDetails={tokectDetails} />} />
            <Route path="/expense/general_expense" element={<GeneralExpense tokectDetails={tokectDetails} />} />
            <Route path="/" element={<ProjectsDashboard tokectDetails={tokectDetails} />} />
            {/* <Route  path="/ProjectDashboard"  element={<Dashboard tokectDetails={tokectDetails}/>} />          */}
            <Route path="/ProjectDashboard" element={<ProjectsDashboard tokectDetails={tokectDetails} />} />
            <Route path="/projectList" element={<ProjectsList tokectDetails={tokectDetails} />} />
            <Route path="/projectList/:projectName" element={<ProDetails tokectDetails={tokectDetails} />} />
            <Route path="/projectList/form" element={<ProjectForm tokectDetails={tokectDetails} />} />
            <Route path="/ItemMaster/:projectName" element={<Itemlistdetails tokectDetails={tokectDetails} />} />
            <Route exact="true" path="/projectlist/updateform" element={<UpdateFile tokectDetails={tokectDetails} />} />
            <Route path="*" element={<Notfound />} />
          </Routes></BrowserRouter></>)
    }
    else {
      return (
        <>
          <Header userDetails={userDetails} isAuthenticated={true} />
          <BrowserRouter>
            <Routes>

              <Route exact="true" path="/" element={<Dashboard tokectDetails={tokectDetails} />} />
              <Route path="/expense/travel_expense" element={<TravelExpense tokectDetails={tokectDetails} />} />
              <Route path="/expense/general_expense" element={<GeneralExpense tokectDetails={tokectDetails} />} />
              <Route exact="true" path="/loginpage" element={<Loginpage tokectDetails={tokectDetails} />} />
              <Route exact="true" path="/employeeprofile" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route exact="true" path="/dashboard" element={<Dashboard tokectDetails={tokectDetails} />} />
              <Route exact="true" path="/projectlist/form" element={<ProjectForm tokectDetails={tokectDetails} />} />
              <Route exact="true" path="/projectlist/updateform" element={<UpdateFile tokectDetails={tokectDetails} />} />
              <Route path="/manageMaster/:masterName" element={<ManageMaster tokectDetails={tokectDetails} />} />
              <Route path="/manageMaster/:masterName/:docType" element={<ManageMaster tokectDetails={tokectDetails} />} />
              <Route path="/manageMaster/:masterName/form" element={<MasterFrom tokectDetails={tokectDetails} />} />
              <Route path="/projectList" element={<ProjectsList tokectDetails={tokectDetails} />} />
              <Route path="/ItemMaster" element={<ItemList tokectDetails={tokectDetails} />} />
              <Route path="/reports" element={<Reports tokectDetails={tokectDetails} />} />
              <Route path="/ItemMaster/:projectName" element={<Itemlistdetails tokectDetails={tokectDetails} />} />
              <Route path="/todos" element={<Todos />} />
              <Route path="/projectList/:projectName" element={<ProjectDetail tokectDetails={tokectDetails} />} />
              <Route path="/projectList/:projectName/ProductionRider" element={<ProductionRider />} />
              <Route path="/projectList/form" element={<ProjectForm />} />
              <Route path="/ItemList/form/:projectName" element={<SheetMain />} />
              <Route path="/projectList/:projectName/form" element={<DocumentUpload />} />
              <Route path="hrEmployee" element={<HrEmployeForm />} />
              <Route path="/itemmaster/cost" element={<CostsheetForm />} />
              <Route path="/dailyUpdate" element={<Dailyupdate />} />
              <Route path="/carousel" element={<Carousel />} />
              <Route path="/dash" element={<Dash />} />
              <Route path="/projectList/:projectName/form" element={<DocumentUpload />} />
              <Route exact="true" path="/chart" element={<Mychart />} />
              <Route exact="true" path="/reports" element={<Reports />} />
              <Route path="*" element={<Notfound />} />
              <Route path="/calender" element={<Calender />} />
              <Route path="/applyleave" element={<Applyleave />} />
              <Route path="/exceltojson" element={<ExceltoJson />} />
              <Route path="/employeeprofile/projects" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/dashboard" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/Master" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/personal" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/timeOff" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/documents" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/emergency" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/training" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/projects/:id" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/attendance" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/help" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/qualification" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/empChanges" element={<Employeeprofile tokectDetails={tokectDetails} />} />
              <Route path="/employeeprofile/salarySlab" element={<Employeeprofile tokectDetails={tokectDetails} />} />
            </Routes>
          </BrowserRouter>
          {/* <Footer /> */}
        </>

      );
    }
  }

}
export default App
export { loginData }
