import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import EmpSearch from '../../../../common/EmpSearch/EmpSearch';
import axios from 'axios';
import {VscError} from 'react-icons/vsc';
import './qualification.css';
import {GET_QUALIFICATION_BY_MCODE,ALLOWED_USERTYPE,GET_ALL_QUALIFICATION,ADD_QUALIFICATION_BY_MCODE,UPDATE_QUALIFICATION_BY_MCODE,
  GET_ALL_QUALIFICATION_BY_MCODE,DELETE_QUALIFICATION}from '../../../../utiles/AllApis';
import ErrorAlertBox from '../../../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../../../common/AlertBox/SuccessAlertBox';
import {Modal,ModalBody} from 'reactstrap';
function Qualification(props) {
  const [isLoading, setLoading] = useState(false);
  const [qualificationdata, setQualificationdata] = useState([])
  const [uType,setUType]=useState(props.tokectDetails.logintype);
   const [userID,setUserID]=useState(props.tokectDetails.mcode);
  const [selecedUserID,setSelectedUserID]=useState(uType===ALLOWED_USERTYPE.ADMIN?"":props.tokectDetails.mcode);
  const [seletedeData,setseletedeData]=useState({});
  const [isadd, setIsadd] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
const [isOpen, setIsOpen] = useState(false);
const [isOpencomfirm, setIsOpencomfirm] = useState(false);
const [isEdit, setIsEdit] = useState(false);
const[successMsg, setsuccessMsg]=useState("");
const[rowpos, setRowpos]=useState("");
const [male, setMale] = useState(false);
const [femail, setFemale] = useState(false);
const [nominee, setNominee] = useState(false);
const[heading, setHeading]=useState("");
const[errMsg, setErrMsg]=useState("");
const[heading1, setHeading1]=useState("");
const handalOpenErr=()=>{setIsOpen(true);}
const handalOpen1=()=>{setIsOpen1(true);}
const handalCloseconfim=()=>{setIsOpencomfirm(false);}
  const [qualificationDetails,setQualificationDetails]=useState(
    {
      "EmployeeID": selecedUserID,
      "qualification": "",
      "passingDate": "",
      "institution": ""
    });
  useEffect(() => {
     
    setQualificationDetails({...qualificationDetails,"EmployeeID":selecedUserID})
    getDetails();
  },[selecedUserID])
  const addDetails=()=>
  {
    setIsadd(true);  
    setQualificationDetails({});
    setIsEdit(false);

  }
  //------------get all relative details--------
  function getDetails()
  {
    if(uType===ALLOWED_USERTYPE.ADMIN)
    {
        getAllEmgncyDtls(selecedUserID);
    }
    else {
      getAllEmgncyDtls(userID);
    }
  }
  //---------------get all Relative/emergency details--------------
  const getAllEmgncyDtls=(mcode)=>
  {
    //-----------gey all details------------
  if(mcode=="")
  {
    setLoading(true);
    axios.get(GET_ALL_QUALIFICATION).then((response) => {
      setLoading(false);
      if(response.data.isSuccess===true)
      {
        setQualificationdata(response.data.data);
      }
      else
      {
        setQualificationdata([]);
      }
      
    })
  }
      //-----------gey details by userMCode------------
  else
  {
    setLoading(true);
    axios.post(GET_ALL_QUALIFICATION_BY_MCODE,{
      "EmployeeID": mcode
  }).then((response) => {
      setLoading(false);
      if(response.data.isSuccess===true)
      {
        setQualificationdata(response.data.data);
      }
      else
      {
        setQualificationdata([]);
      }
    })
  }
  }
    //---------------get relatives details--------------
    const onHandleChange=(e)=>
    {
      setQualificationDetails({...qualificationDetails,[e.target.name]:e.target.value}); 
    }
  //---------add Emergency relative details---------------
  const handlesubmit = () => {
    if(qualificationDetails?.qualification==""||qualificationDetails?.passingDate==""||qualificationDetails?.institution=="")
    {
      setHeading("Failed"); 
      setErrMsg("Mandatory fields cannot empty");
      handalOpenErr();
    }
    else{
      if(new Date(qualificationDetails.passingDate)>new Date())
      {
      setHeading("Failed"); 
      setErrMsg("Passing Date cannot greater then Today's date.");
      handalOpenErr();
      return;
      }
      setLoading(true);
      axios.post(ADD_QUALIFICATION_BY_MCODE,{
        "EmployeeID": selecedUserID,
        "Qualification": qualificationDetails.qualification,
        "PassingDate": qualificationDetails.passingDate,
        "Institution": qualificationDetails.institution
      }).then((response) => {
        setLoading(false);
      if(response.data.isSuccess===true)
      {
      setQualificationDetails({});
        setHeading1("Success"); 
        setsuccessMsg("Contact added successfully");
        handalOpen1();
        setIsadd(false);
        getDetails();
        setMale(false);
        setFemale(false);
        setNominee(false);
      }
      else{
        setHeading("Failed"); 
        setErrMsg(response.data.message);
        handalOpenErr();
      }
      })
    }
  }

    //---------update Qualification details---------------
    const handleUpdate = () => {
      setQualificationDetails(
        {
          "EmployeeID": selecedUserID,
          "qualification": "",
          "passingDate": "",
          "institution": ""
        });
        setIsEdit(false);
        if(qualificationDetails?.qualification==""||qualificationDetails?.passingDate==""||qualificationDetails?.institution=="")
        {
          setHeading("Failed"); 
          setErrMsg("Mandatory fields cannot empty");
          handalOpenErr();
        }
      else{
        if(new Date(qualificationDetails.passingDate)>new Date())
        {
        setHeading("Failed"); 
        setErrMsg("Passing Date cannot greater then Today's date.");
        handalOpenErr();
        return;
        }
        setLoading(true);
        axios.post(UPDATE_QUALIFICATION_BY_MCODE,{
            "EmployeeID": selecedUserID,
            "Qualification": qualificationDetails.qualification,
            "PassingDate": qualificationDetails.passingDate,
            "Institution": qualificationDetails.institution,
            "ROWPOS":rowpos
        }).then((response) => {
          setLoading(false);
        if(response.data.isSuccess===true)
        {
          setIsadd(false);
          setHeading1("Success"); 
          setsuccessMsg("Record update successfully..");
          handalOpen1();
          getDetails();
      setQualificationDetails({});
        }
        else{
          setHeading("Failed"); 
          setErrMsg(response.data.message);
          handalOpenErr();
        }
    
        })
      }
    }
  
  //-------------Edit emergency details------------------
  const handelEdit  = (id) => {
   // setLoading(true);
   setRowpos(id);
    setIsEdit(true);
    setIsadd (true);
    axios.post(GET_QUALIFICATION_BY_MCODE,{"ROWPOS":id}).then((response) => {
      //setLoading(false);
    if(response.data.isSuccess===true)
    {
     setQualificationDetails(response.data.data[0]);
    }
    })
  }
    //-------------Delete emergency details------------------
    const handelDelete  = (id) => {
      handalCloseconfim();
   setRowpos(id);
      setLoading(true);
      axios.post(DELETE_QUALIFICATION,{"ROWPOS":id}).then((response) => {
        setLoading(false);
      if(response.data.isSuccess===true)
      {
        getDetails();
      }
      else{
        setHeading("Failed"); 
        setErrMsg(response.data.message);
        handalOpenErr();
      }
    })
  }
  return (
    <>
     {
    isLoading?  <div className='loaderDiv'>
    </div>:null
  }
      <div className='emp-aboutdetailmaincontent'>
      <div className={uType===ALLOWED_USERTYPE.ADMIN?'mnusTopMrgn':''}>
        <h5 className='text-white fw-bold mt-2 text-center'>Qualification Details</h5>
        <hr className='myhr text-white' />
       </div>
       {
        uType===ALLOWED_USERTYPE.ADMIN?
       <EmpSearch uType={uType}setIsadd={setIsadd} isadd={isadd}setUserID={setSelectedUserID} isEdit={isEdit}empData={seletedeData}/>
        :
        <div className='row'>
            <div className='col-md-2 col-lg-2 col-sm-2 col-2 mt-1'>
        {
          isadd ?<button className='btn btnSuccess'onClick={e=>{setIsadd(false);}}>View List</button>
          :
          <button className='btn btnSuccess'onClick={e=>{addDetails()}}>Add Details</button>
        }
     </div> 
          </div>
       }
       {
        isadd?
        <div className={uType===ALLOWED_USERTYPE.ADMIN?'mt-5':'mnusTopMrgn'}>
                <h5 className='SecoHead text-center'>Add Details</h5>
                <hr className='myhr text-white' />
        <div className='emp_profile_form'>
          <div className='row'> 
          <div className='col-md-3 col-sm-3 col-3 mt-3'>
            <label
                    htmlFor="Qualification"
                    className="form-label fw-normal h6 text-capitalize"
                  >
                    Qualification<span className='required'>*</span>
                  </label>
                      <select class="form-select mt-2 w-75"  value={qualificationDetails?.qualification}
                      aria-label="Default select example" name="qualification" id="qualification"onChange={e=>{onHandleChange(e)}}>
                    <option value="">--Select Qualification--</option>
                    <option value="PHD">PHD</option>
                    <option value="Masters">Masters</option>
                    <option value="Becholers">Becholers</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="High_school">High School</option>
        </select>
          </div>
          <div className='col-md-3 col-sm-3 col-3 mt-3'>
            <label
                    htmlFor="PassingDate"
                    className="form-label fw-normal h6 text-capitalize"
                  >
                    Passing Date<span className='required'>*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control w-75 mt-2"
                    value={qualificationDetails?.passingDate?.split("T")[0]}
                    id="passingDate"
                    name="passingDate"
                    max="9999-12-31"
                    onChange={(e) => onHandleChange(e)}
                  />
            </div>
            <div className='col-md-4 col-sm-4 col-4 mt-3'>
            <label
                    htmlFor="Institution"
                    className="form-label fw-normal h6 text-capitalize"
                  >
                  Institution<span className='required'>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control w-100 mt-2"
                    id="institution"
                    name="institution"
                     value={qualificationDetails?.institution}
                    placeholder="Enter Institution Name Here..."
                    onChange={(e) => onHandleChange(e)}
                  />
            </div>
    
          <div className='col-md-2 col-sm-2 col-2 mt-5 text-center'>
            {
              isEdit?
          <button className='btn btnSuccess' onClick={handleUpdate} ><span>Update</span></button>:
          <button className='btn btnSuccess' onClick={handlesubmit} ><span>Submit</span></button>
            }
          </div>
          </div>
        </div></div>
        :
         qualificationdata.length>0?
         <div className={`${uType===ALLOWED_USERTYPE.ADMIN?"emgncy":"emgncyFull"} table-responsive mt-3`}>
         <table className="table table-bordered border-secondary ">
                <thead className='tblHeader'>
                    <tr>
                       <th scope="col text-white">#</th>
                       <th scope="col text-white">Created At</th>
                       <th scope="col text-white">Employee Name</th>
                       <th scope="col text-white">Qualification</th>
                       <th scope="col text-white">Passing Date</th>
                       <th scope="col text-white">Institution</th>
                       <th scope="col text-white"className={uType!==ALLOWED_USERTYPE.ADMIN?"d-none":""}>Edit</th>
                       <th scope="col text-white" className={uType!==ALLOWED_USERTYPE.ADMIN?"d-none":""}>Delete</th>
                    
                    
                   </tr>
                </thead>
                <tbody className='tblBdy'>
                    {
                          qualificationdata.map((data, i) => 
                          {
                            return(    <tr key={i}>
                                <th scope="row">{i+1}</th>
                                <td>{data.edate.slice(0,10)}</td>
                                <td>{data.empName}</td>
                                <td>{data.qualification}</td>
                                <td>{data.passingDate.slice(0,10)}</td>
                                <td>{data.institution}</td>
                                <td className={uType!==ALLOWED_USERTYPE.ADMIN?"d-none":""}><button className='btn btnEdit'onClick={e=>{handelEdit(data.rowpos)}}>Edit</button></td>
                                <td className={uType!==ALLOWED_USERTYPE.ADMIN?"d-none":""}><button className='btn btnDelete' onClick={e=>{setRowpos(data.rowpos);setIsOpencomfirm(true);}}>Delete</button></td>
                            </tr>);
                          }
                        )
                    }
                
                </tbody>
                        </table>
         </div>
     
        :
          <p className='text-white text-center mt-5'>No Data Found!!</p>
        }
       
      </div>
      <Modal className="fade show"isOpen={isOpencomfirm} >
  <ModalBody  className='text-center tracking-in-expand-fwd-bottom'>
<p className=' text-center text-danger fw-bold'><VscError className='fw-bolder mx-2 my-2 h2'/>Are you sure you want to Delete</p>
<div>   <Button
     className='btnSuccess mx-2'
     onClick={()=>{handelDelete(rowpos)}}
    >
      Yes
    </Button>
    <Button
     className='btnDelete mx-2'
     onClick={handalCloseconfim}
    >
      No
    </Button></div>
  </ModalBody>

  </Modal>
         <ErrorAlertBox isOpen={isOpen} setIsOpen={setIsOpen} heading={heading} errMsg={errMsg} />
      <SuccessAlertBox
        isOpen1={isOpen1}
        setIsOpen1={setIsOpen1}
        heading1={heading1}
        successMsg={successMsg}
      /> 
    </>
  )
}

export default Qualification;