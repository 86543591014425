import React from 'react'
import Cookie from 'js-cookie'

const Setcookie = (cookiename,usrin)=>{
    var now = new Date();
     now.setTime(now.getTime() + (175 * 60 * 1000));//for 2.55 hr
            // now.setTime(now.getTime() + (1 * 60 * 1000));//for 1 min
    Cookie.set(cookiename,usrin,{
        expires:now,   
        secure:true,
        sameSite:'strict',
        path:'/'
    })
    console.log("cookies time: " +now);
}

export default Setcookie;