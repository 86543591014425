import React, { useEffect, useState } from 'react';

import { Button } from "@mui/material";
import axios from 'axios';
import { GET_PROJECT, GET_HEADS, INSERT_BRANDSHEET, GET_PROJECTBYID, INSERT_COST, GET_EVENT, GET_EVENTBYID, GET_EVENTNAMEBYID } from '../../utiles/AllApis';
import { useParams } from "react-router-dom";
import {Alert} from "../../modules/alert/alert"
import CountUp from 'react-countup';


const SheetForm = (props) => {

    const { projectName } = useParams()
    const [name, setname] = useState("")
    const [proId, setProId] = useState(projectName)
    const [size, setsize] = useState("")
    const [days, setdays] = useState("")
    const [amount, setamount] = useState("")
    const [internalamt, setinternalamt] = useState("")
    const [internalrate, setinternalrate] = useState("")
    const [height, setheight] = useState("")
    const [width, setwidth] = useState("")
    const [ratetype, setratetype] = useState("")
    const [rateAmt, setrateAmt] = useState("")
    const [totalAmt, settotalAmt] = useState("")
    const [status, setstatus] = useState("")
    const [grouping, setgrouping] = useState("")
    const [projectname, setprojectname] = useState("")
    const [heads, setheads] = useState("")
    const [location, setlocation] = useState("")
    const [area, setarea] = useState(null)
    const [quantity, setquantity] = useState("")
    const [unit, setunit] = useState("")
    const [description, setdescription] = useState("")
    const [remark, setremark] = useState("")

    const [isDisabled, setIsDisabled] = useState(false);
    const [areadisabled, setareadisabled] = useState(true)

    const [projectList, setProjectList] = useState([]);
    const [headList, setHeadsList] = useState([]);

    useEffect(() => {
        setarea(height * width)
        settotalAmt(height * width*quantity*rateAmt)
    }, [height, width,quantity,rateAmt])

    const submitcost = () => {
        var data = JSON.stringify({
            "Project_ID": projectName,
            "Name": name,
            "location": location,
            "Discription": description,
            "Size": size,
            "Area": area,
            "Days": days,
            "Unit": unit,
            "Qty": quantity,
            "Amt": amount,
            "Internal_Rate": internalrate,
            "Internal_Amt": internalamt,
            "Remark": remark
        });
         console.log(data);
          
        var config = {
            method: 'post',
            url: INSERT_COST,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                
                if (response.data.IsSuccess === true) {
                    alert("Cost sheet Successfully submited")
                    window.location.href = `/projectList/${projectName}`
                }
                else{
                    alert(response.data.message);
                }

            })
            .catch(function (error) {
                console.log(error);
            });


    }
    const submitbrand = () => {
        var data = JSON.stringify({
            "Project_ID": proId,
            "Location": location,
            "Description": description,
            "Width": width,
            "Height": height,
            "Unit": unit,
            "Area": area,
            "Quantity": quantity,
            "Remarks": remark,
            "RateType": ratetype,
            "RateAmount": rateAmt,
            "TotalAmount": totalAmt,
            "Status": status,
            "Grouping": grouping,
            "Head": heads
        });

        var config = {
            method: 'post',
            url: INSERT_BRANDSHEET,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                 
                if(response.data.isSuccess===true)
                {
                    alert("brand sheet successfully added !")
                window.location.href = `/projectList/${projectName}`
                }
                else{
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    {

        useEffect(() => {
            axios.get(GET_PROJECT).then((response) => {
                console.log("getproject", response.data.data)
           
                setProjectList(response.data.data);

            });
            axios.post(GET_EVENTNAMEBYID,{ "Project_ID": projectName}).then((response) => {
                console.log( response.data)
                
                
            
              })
            axios.post(GET_HEADS).then((response) => {
                console.log(response)

                setHeadsList(response.data.data);

            });
            var config = {
                method: 'post',
                url: GET_PROJECTBYID,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: { "ROWPOS": projectName }
            };

            axios(config)
                .then(function (response) {
                    console.log(response)
                    var thisrowpos = response.data.data[0].rowpos;
                    console.log(thisrowpos)
                    setprojectname(thisrowpos)
                    setIsDisabled(true)

                })
                .catch(function (error) {
                    console.log(error);
                });

        }, []);
        return (
            <div>
                <>
                    <div className=" pt-4" style={{width:"75%",margin:"auto"}} >
                        <center>
                            {
                                props.sheet ?

                                    <center>

                                        <div className="d-flexed formedia space">
                                            <div className="mb-3">
                                                <label

                                                    className="form-label"
                                                >
                                                    Location
                                                </label>
                                                <br />
                                                <input
                                                    type="text"
                                                    name="Area"
                                                    className="form-control small"
                                                    value={location}
                                                    onChange={(e) => setlocation(e.target.value)}

                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label"> Name</label>
                                                <br />
                                                <input
                                                    type="text"
                                                    value={name}
                                                    onChange={(e) => setname(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Size</label>
                                                <input
                                                    type="number"
                                                    value={size}
                                                    onChange={(e) => setsize(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flexed formedia space">
                                           
                                            <br />
                                         
                                            <div className="mb-3">
                                                <label className="form-label">Days</label>
                                                <input
                                                    type="number"
                                                    value={days}
                                                    onChange={(e) => setdays(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Area</label>
                                                <input
                                                    type="number"
                                                    name="Area"
                                                    className="form-control small"
                                                    value={area}
                                                    onChange={(e) => setarea(e.target.value)}
                                                 
                                                />

                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Quantity</label>
                                                <input
                                                    type="number"
                                                    name="quantity"
                                                    className="form-control small"
                                                    value={quantity}
                                                    onChange={(e) => setquantity(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flexed formedia space">
                                            <div className="mb-3">
                                                <label className="form-label">Unit</label>
                                     
                                              <br />
                                                <select aria-label="Default select example" style={{ width: "200px" }} id="heads" name="Department" value={unit} onChange={(e) => { setunit(e.target.value) }}>
                                                    <option>--Select--</option>
                                                    <option value="SQFT">SQUARE FEET</option>
                                                    <option value="SQMRT">SQUARE METER</option>
                                                    <option value="R_FT">R_FT</option>
                                                    <option value="R_MTR">R_MTR</option>
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Amount</label>
                                                <input
                                                    type="number"
                                                    value={amount}
                                                    onChange={(e) => setamount(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Internal Amount</label>
                                                <input
                                                    type="number"
                                                    value={internalamt}
                                                    onChange={(e) => setinternalamt(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                        </div>
                                       
                                        
                                        <div className="d-flexed formedia space">
                                           
                                            <div className="mb-3">
                                                <label className="form-label">Internal Rate</label>
                                                <input
                                                    type="number"
                                                    value={internalrate}
                                                    onChange={(e) => setinternalrate(e.target.value)}
                                                    className="form-control small mydate"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="exampleFormControlInput1"
                                                    className="form-label"
                                                    style={{ marginRight: "400px" }}
                                                >
                                                    Description
                                                </label>
                                                <input
                                                    type="text"
                                                    name="desc"
                                                    className="form-control small"
                                                    value={description}
                                                    onChange={(e) => setdescription(e.target.value)}

                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label
                                                    htmlFor="exampleFormControlInput1"
                                                    className="form-label"
                                                >
                                                    Remarks
                                                </label>
                                                <input
                                                    type="text"
                                                    name="desc"
                                                    className="form-control small"
                                                    value={remark}
                                                    onChange={(e) => setremark(e.target.value)}
                                                />
                                            </div>


                                        </div>
                                        
                                        <br />
                                        <div className="mb-3">
                                            <Button
                                                className='animatingBottom'
                                                style={{
                                                    borderRadius: 5,
                                                    backgroundColor: "green",
                                                    padding: "6px 12px",
                                                    fontSize: "14px",


                                                }} variant="contained" onClick={submitcost} >Add Cost</Button>
                                        </div>

                                    </center>
                                    :
                                    <center>

                                        <div className="d-flexed formedia space">
                                            <div className="mb-3">
                                                <label

                                                    className="form-label"
                                                >
                                                    Location
                                                </label>
                                                <input
                                                    type="text"
                                                    name="Area"
                                                    className="form-control small"
                                                    value={location}
                                                    onChange={(e) => setlocation(e.target.value)}

                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Rate Type</label>
                                                <input
                                                    type="text"
                                                    value={ratetype}
                                                    onChange={(e) => setratetype(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Status</label>
                                                <input
                                                    type="text"
                                                    value={status}
                                                    onChange={(e) => setstatus(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flexed formedia space">

                                            <div className="mb-3">
                                                <label className="form-label">Unit</label>
                                                <br />
                                                <select aria-label="Default select example" style={{ width: "200px" }} id="heads" name="Department" value={unit} onChange={(e) => { setunit(e.target.value) }}>
                                                    <option>--Select--</option>
                                                    <option value="SQFT">SQUARE FEET</option>
                                                    <option value="SQMRT">SQUARE METER</option>
                                                    <option value="R_FT">R_FT</option>
                                                    <option value="R_MTR">R_MTR</option>
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Height</label>
                                                <input
                                                    type="number"
                                                    value={height}
                                                    onChange={(e) => setheight(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Width</label>
                                                <input
                                                    type="number"
                                                    value={width}
                                                    onChange={(e) => setwidth(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                        </div>
                                       
                                        <div className="d-flexed formedia space">                                                                         
                                            <div className="mb-3">
                                                <label className="form-label">Area</label>
                                                <input
                                                    type="number"
                                                    name="Area"
                                                    className="form-control small"
                                                    value={area}
                                                    onChange={(e) => setarea(e.target.value)}
                                                    disabled={areadisabled}
                                                />

                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Unit Amount</label>
                                                <input
                                                    type="text"
                                                    value={rateAmt}
                                                    onChange={(e) => setrateAmt(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Quantity</label>
                                                <input
                                                    type="number"
                                                    name="quantity"
                                                    className="form-control small"
                                                    value={quantity}
                                                    onChange={(e) => setquantity(e.target.value)}
                                                />


                                            </div>
                                        </div>
                                        <div className="d-flexed formedia space">
                                          
                                            <div className="mb-3">
                                                <label className="form-label">Total Amount</label>
                                                <input
                                                    type="text"
                                                    value={totalAmt}
                                                    onChange={(e) => settotalAmt(e.target.value)}
                                                    className="form-control small"
                                                    disabled={isDisabled}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="exampleFormControlInput1"
                                                    className="form-label"
                                                    style={{ marginRight: "400px" }}
                                                >
                                                    Description
                                                </label>
                                                <input
                                                    type="text"
                                                    name="desc"
                                                    className="form-control small"
                                                    value={description}
                                                    onChange={(e) => setdescription(e.target.value)}

                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label
                                                    htmlFor="exampleFormControlInput1"
                                                    className="form-label"
                                                >
                                                    Remarks
                                                </label>
                                                <input
                                                    type="text"
                                                    name="desc"
                                                    className="form-control small"
                                                    value={remark}
                                                    onChange={(e) => setremark(e.target.value)}
                                                />
                                            </div>
                                            
                                        </div>
                                        <div className="d-flexed formedia space">
                                           
                                        </div>
                                        <div className="d-flexed formedia space">
                                            <div className="mb-3">
                                                <label className="form-label">Grouping</label>
                                                <input
                                                    type="text"
                                                    value={grouping}
                                                    onChange={(e) => setgrouping(e.target.value)}
                                                    className="form-control small"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <br />
                                               <div className='totalamount_brand' style={{fontSize:"24px",height:"60px",background:"#096358",borderRadius:"8px",boxShadow:"0 0 10px 0 rgba(0,0,0,0.9)",width:"300px",color:"#fff",paddingTop:"7px",fontWeight:"700",cursor:"not-allowed"}}>&#x20b9;&nbsp;&nbsp;<CountUp end={totalAmt} /></div>
                                            </div>
                                            
                                            <div className="mb-3">
                                                <br />
                                                <Button
                                                    className='animatingBottom'
                                                    style={{
                                                        borderRadius: 5,
                                                        backgroundColor: "green",
                                                        padding: "6px 12px",
                                                        fontSize: "14px",


                                                    }} variant="contained" onClick={submitbrand} > Add Brand</Button>
                                            </div>

                                        </div>

                                    </center>
                            }

                        </center>





                    </div>
                </>
            </div>
        )
    }
}

export default SheetForm;