import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import SideBar from "../../common/SideBar/SideBar";
import { NEW_PRO_COUNT,GET_ALL_PROJECTS ,GET_ALLTODO,EDIT_TODO ,CREATE_TODO,GET_allprojects,FILING_PRO_COUNT,
  RUNNING_PRO_COUNT,COMPLETED_PRO_COUNT,REJECTED_PRO_COUNT} from "../../utiles/AllApis";
import "./dashboard.css";
import BarChart from "./DashboardChart/BarChart1";
import FolderIcon from "@mui/icons-material/Folder";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Icon } from "@mui/material";
import DashDesign from "./DashDesign";
import Getcookie   from "../../modules/login/Getcookie";

const Dashboard = () => {
   
  if((Getcookie("ERPUser")===null || Getcookie("ERPUser")==="" && Getcookie("ERPUser")!==undefined))
  {
    window.location.href="/";
  }
  const [isLoading, setLoading] = useState(false);
  const [barChart, setBarChart] = useState(false);
  const [lineChart, setLineChart] = useState(false);
  const [pieChart, setPieChart] = useState(false);
  const [editable,seteditable] = useState( "contenteditable='true'")
  const [createTodo,setcreateTodo] = useState(false)
  const [todoCategory,setToDoCategory] = useState("green");
  const [toDoDate,setToDoDate] = useState("");
  const [subject,setsubject] = useState("")
  const [description,setdescription] = useState("")
  const [rowpos,setrowpos] = useState("")
  const [todoID,settodoID] = useState("")


  const [alltodo,setalltodo] = useState([])
  const column = [
    // { field: 'id', headerName: 'ID' },
    {
      field: "edate",
      headerName: "Created  Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return 
        <p className='text-white mt-2 f-14'>{params?.row?.edate?.split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-")}</p>
      },
    },
    { field: "projectName", headerName: "PROJECT Name", width: 200 },
    { field: "clientName", headerName: "CLIENT Name", width: 200 },
    {
      field: "DETAILS",
      headerName: "DETAILS",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            style={{ background: "#1e2020e8", color: "#fff" }}
            onClick={(e) => {
              viewproject(params.row.rowpos);
            }}
            color="primary"
          >
            {" "}
            DETAILS
          </Button>
        );
      },
    },
  ];

  const columns = [
    // { field: 'id', headerName: 'ID' },
    {
      field: "edate",
      headerName: "Created  Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return 
        <p className='text-white mt-2 f-14'>{params.row.edate
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-")}</p>
      },
    },
    { field: "projectName", headerName: "PROJECT Name", width: 200 },
    { field: "clientName", headerName: "CLIENT Name", width: 200 },
    {
      field: "DETAILS",
      headerName: "DETAILS",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            style={{ background: "#1e2020e8", color: "#fff" }}
            onClick={(e) => {
              viewproject(params.row.rowpos);
            }}
            color="primary"
          >
            {" "}
            DETAILS
          </Button>
        );
      },
    },
  ];

  const [newCount, setNewCount] = useState(0);
  const [eventsCount, seteventsCount] = useState(0);
  const [filinglength, setfilinglength] = useState(0);
  const [Completelength, setcompletelength] = useState(0);
  const [rejectlength, setRejectlength] = useState(0);
  const [projectlist, setprojectlist] = useState([]);
  const [eventlist, seteventlist] = useState([]);
  const [filinglist, setfilinglist] = useState([]);

  const [thisproject, setthisproject] = useState([]);
  const [thisevent, setthisevent] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelector('.myview').style.width = '74.7%'
    //----------API call for New Projects----------------
    setLoading(true);
    axios.get(NEW_PRO_COUNT).then((response) => {
      setLoading(false);
        setNewCount(response.data.data);
    });
    //----------API call for filing Projects----------------
    setLoading(true);
    axios.get(FILING_PRO_COUNT).then((response) => {
      setLoading(false);
        setfilinglength(response.data.data);
    });
        //----------API call for Running Projects----------------
    setLoading(true);
    axios.get(RUNNING_PRO_COUNT).then((response) => {
      setLoading(false);
        seteventsCount(response.data.data);
    });
        //----------API call for Completed Projects----------------
    setLoading(true);
    axios.get(COMPLETED_PRO_COUNT).then((response) => {
      setLoading(false);
      setcompletelength(response.data.data);
    });
        //----------API call for Rejected Projects----------------
    setLoading(true);
    axios.get(REJECTED_PRO_COUNT).then((response) => {
      setLoading(false);
        setRejectlength(response.data.data);
    });
  }, []);
  const handlethisprojectcell = (params) => {
    setthisproject(params);
  };
  const viewproject = (rowpos) => {
    
    navigate(`/projectList/${rowpos}`);
  };

  const handlethiseventcell = (params) => {
    setthisevent(params);
  };
  const viewevent = (rowpos) => {
    navigate(`/ItemMaster/${rowpos}`);
  };

  // todo get all
  const getAllTodoList=()=>
  {
    var config = {
      method: 'get',
      url: GET_ALLTODO,
      headers: {"Content-Type":"application/json; charset=utf-8" }
    };
    axios(config)
    .then(function (response) {
       
      setalltodo(response.data.data)
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  useEffect(()=>{
    getAllTodoList();
  },[]);

  const createTodos = () =>{
    setcreateTodo(true)
    setsubject("")
    setdescription("")
  }

  const removetodoForm = ()=>{
    setcreateTodo(false)
  }


  // new create todo 

  const createSubmitTodo = ()=>{
    if(subject ==="" || description === "" || toDoDate===""){
      alert("Fields can't be Empty");
    }
    else if(rowpos === "" ){
      var loginmcode = Getcookie("Umcode");

      var data = JSON.stringify({
        "IS_TODO": true,
        "MCODE": loginmcode,
        "Subject": subject,
        "Remarks": description,
        "ROWPOS": todoID,
        "Category":todoCategory,
        "Date":toDoDate,
      });
      var config = {
        method: 'post',
        url: CREATE_TODO,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        if(response.data.isSuccess===true)
        {
          alert("todo created Successfully !")
          setcreateTodo(false);
          getAllTodoList();
        }
        else
        {
          alert("Something went wrong!");
        }
        setToDoDate("");
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    
    else{
      var data = JSON.stringify({
        "MCODE": rowpos,
        "Subject": subject,
        "Remarks": description,
        "ROWPOS": todoID,
        "Category":todoCategory,
        "ToDate":toDoDate
      });
      var config = {
        method: 'post',
        url: EDIT_TODO,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      axios(config)
      .then(function (response) {
         
        if(response.data.isSuccess === true){
          setcreateTodo(false);
          getAllTodoList();
        }
        else{
          alert("Todo Updation Failed ! due to an internal error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    }
  }

// HANDLE  EDIT TODO

const handleEditTodo =(item)=>{
setcreateTodo(true);
var clr=item.color===""?"green":item.color;
setToDoCategory(clr);
setsubject(item.subject);
setdescription(item.remarks);
setrowpos(item.emP_MCODE);
settodoID(item.rowpos);
setToDoDate(item.toDate.split('T')[0]);
}
const goNewprojects = ()=>{
  navigate("/projectList?type=new");
}
const goFilingprojects = ()=>{
  navigate("/projectList?type=filing");
}
const goRunningprojects = ()=>{
  navigate("/projectList?type=running");
}
const goCompletedprojects = ()=>{
  navigate("/projectList?type=completed");
}
const goRejectprojects = ()=>{
  navigate("/projectList?type=rejected");
}

  return (
    <div className="container-fluid">
      {isLoading ? <div className="loaderDiv"></div> : null}
      <div className="row ">
        <div className="col-md-2 col-12 col-sm-12 mainSideBar px-0" style={{position:"absolute"}}>
          <SideBar />
        </div>
        <div className="col-md-10 col-12 col-sm-12 py-3 headerBgColor myview" style={{marginLeft:"70px"}}>
          <div className="row">
            <div className="col-md-2 col-sm-2 col-2 dashboaedBox">
              {" "}
              <div
                className="focus dashboardgrid tracking-in-expand-fwd-top box1"
                onClick={goNewprojects}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="totalbg totalbg1">
                   <FolderIcon className="mx-1" /> 
                  NEW
                </div>
                <div className="ProCount totalbg1">{newCount}</div>
              </div>
            </div>
            <div className="col-md-2 col-sm-2 col-2 dashboaedBox">
              <div
                className="focus dashboardgrid tracking-in-expand-fwd-top box2"
                onClick={goFilingprojects}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="totalbg totalbg2">
                   <FolderIcon className="mx-1" /> 
                  FILING{" "}
                </div>

                <div className="ProCount totalbg2">{filinglength}</div>
              </div>
            </div>
            <div className="col-md-2 col-sm-2 col-2 dashboaedBox">
              {" "}
              <div
                className="focus dashboardgrid tracking-in-expand-fwd-top box3"
                onClick={goRunningprojects}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="totalbg totalbg3">
                   <FolderIcon className="mx-1" /> 
                  RUNNING
                </div>
                <div className="ProCount totalbg3">{eventsCount}</div>
              </div>
            </div>
            <div className="col-md-2 col-sm-2 col-2 dashboaedBox">
              {" "}
              <div
                className="focus dashboardgrid tracking-in-expand-fwd-top box4"
                onClick={goCompletedprojects}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className="totalbg totalbg4">
                 <FolderIcon className="mx-1" /> 
                  COMPLETE{" "}
                </span>
                <span className="ProCount totalbg4">{Completelength}</span>
              </div>
            </div>
            <div className="col-md-2 col-sm-2 col-2 dashboaedBox">
              {" "}
              <div
                className="focus dashboardgrid tracking-in-expand-fwd-top box5"
                onClick={goRejectprojects}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="totalbg totalbg5">
                   <FolderIcon className="mx-1" /> 
                REJECTED
                </div>
                <div className="ProCount totalbg5">{rejectlength}</div>
              </div>
            </div>
            <div className="responsivebox animatingTop">
            </div>
{/* ___________________________ */}
          <hr className="myhr" />
            <div className="row ">
              <DashDesign />
              <div className="col-sm-6 col-6 BarExample">
              </div>
              <div className="col-sm-12 col-12"></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
{
  createTodo ?
 <>
  <div className="center_formTodo px-2 py-3"> <p style={{float:"right"}} >
  <select className="black_select" value={todoCategory} onChange={e=>{setToDoCategory(e.target.value)}} >
     <option value="green" >🟢&nbsp;Green</option>
     <option value="yellow" >🟡&nbsp;Yellow</option>
     <option value="red" >🔴&nbsp;Red</option>
</select><span style={{cursor:"pointer"}} onClick={removetodoForm}>❌</span></p>
  <label className="">Subject<span className='required'>*</span>
 </label>
<input type="text" placeholder="Task Title" style={{width:"58%"}}  id="subjectTodo" value={subject} onChange={(e)=>setsubject(e.target.value)} />
              
<hr className="myhr my-1" />
<label>Description<span className='required'>*</span></label>
<textarea type="text" style={{width:"96%",height:"18%"}} placeholder="🖋 Add description" value={description} onChange={(e)=>setdescription(e.target.value)}  id="descriptionTodo" ></textarea>
<hr className="myhr" />
<div className="d-flexed" style={{width:"100%"}}>
<label>Date<span className='required'>*</span></label>
<input type="date" id="Todo_date" max="9999-12-31"value={toDoDate} onChange={e=>{setToDoDate(e.target.value)}}/>
{/* <input type="time" /> */}
</div>
<hr className="myhr" />
<button className="btnCreatodo btntodo" onClick={createSubmitTodo} style={{backgrouund:"#ED3237"}}>Submit Todo</button>
</div>
 </>
:""
}
      <div className="rightBox">
        <h5>Upcoming To do list</h5>
        <hr className="myhr" />
       {
        createTodo ?
        <div className="todoBOX" style={{heigth:"220px",overflow:"scroll"}}>
        {
          alltodo?.map((item,index)=>(
              <div key={index}>
              <h6 style={{float:"right",marginTop:"2px",cursor:"pointer"}} onClick={()=>handleEditTodo(item)} >🖊</h6>
               <li >{item.color === 'green'?"🟢":"" ||item.color === 'yellow'?"🟡":"" || item.color === 'red'? "🔴":"" }&nbsp;&nbsp;{item.subject} <p className="editable" style={{fontSize:"10px",color:"grey",textAlign:"inherit"}}>{item.remarks}</p><span>{item.toDate?.slice(0,10)}</span></li>
               
          <hr className="myhr" />
              </div>
          ))
        }
          

        </div>
        :
        <div className="todoBOX" style={{heigth:"220px",overflow:"scroll"}}>
        {
          alltodo?.map((item,index)=>(
              <div key={index}>
              <h6 style={{float:"right",marginTop:"2px",cursor:"pointer"}} onClick={()=>handleEditTodo(item)} >📝</h6>
              <li >{item.color === 'green'?"🟢":"" ||item.color === 'yellow'?"🟡":"" || item.color === 'red'? "🔴":"" }&nbsp;&nbsp;{item.subject} <p className="editable" style={{fontSize:"10px",color:"grey",textAlign:"inherit"}}>{item.remarks}</p><span>{item.toDate?.slice(0,10)}</span></li>
               
          <hr className="myhr" />
              </div>
          ))
        }
          

        </div>
       }
       <button className="btnCreatodo" onClick={createTodos} style={{backgrouund:"#fff"}}>Create To do List</button>
        <br />
       <div className="flexRightbox" style={{display:"flex",justifyContent:"space-between"}}> <h5>Announcement</h5><p>view all</p></div>
      <div style={{height:"213px",overflow:"scroll"}}>
      <div className="announcementCard anncard1">
        <div className="imgbox">
          <img src="https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247" />
        </div>
        <div className="textarea"><h6>Owners notifications</h6>
        <span>Physical Bid submitted for National</span>
        <br/>
        <span>22 feb 2022 || 10:33 AM</span></div>
       </div>
       <div className="announcementCard anncard2">
        <div className="imgbox">
          <img src="https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247" />
        </div>
        <div className="textarea"><h6>Directors notifications</h6><span>22 feb 2022 || 10:33 AM</span></div>
       </div>
       <div className="announcementCard anncard3">
        <div className="imgbox">
          <img src="https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247" />
        </div>
        <div className="textarea"><h6>Additional notifications</h6><span>22 feb 2022 || 10:33 AM</span></div>
       </div>
       <div className="announcementCard anncard4">
        <div className="imgbox">
          <img src="https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247" />
        </div>
        <div className="textarea"><h6>Team Members</h6><span>22 feb 2022 || 10:33 AM</span></div>
       </div>
       <div className="announcementCard anncard5">
        <div className="imgbox">
          <img src="https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247" />
        </div>
        <div className="textarea"><h6>Intern notification notifications</h6><span>22 feb 2022 || 10:33 AM</span></div>
       </div>
       <div className="announcementCard anncard6">
        <div className="imgbox">
          <img src="https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247" />
        </div>
        <div className="textarea"><h6>Owners notifications</h6><span>22 feb 2022 || 10:33 AM</span></div>
       </div>
      </div>
      </div>
  
    </div>
  );
};
export default Dashboard;
