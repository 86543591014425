import React from 'react';
import ButtonSuccess from '../../../common/Buttons/ButtonSuccess';
//import ButtonDelete from '../../../common/Buttons/ButtonDelete';
import { useNavigate } from 'react-router-dom';
const DocumentUpload=(props)=>
{
  const navigate=useNavigate();
   const secoStep=`/projectList/${window.location.href.split("/")[4]}`;
   const docSubmit=()=>
   {
    window.sessionStorage.setItem("stepSecProjectName",window.location.href.split("/")[4]);
    navigate(secoStep);
   }
    return(
        <div className='ligthBg'>
<div className='container pt-4 '>
<div className='row'>
        <div className='col-sm-3 col-md-3 col-12'>
        </div>
        <div className='col-sm-6 col-md-6 col-12 crdGrp formBg'>
        <h4 className='py-3 text-center theamColor'>Upload Document</h4>
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">Cast Sheet </label>
  <input type="file" className="form-control" id="castSheet" />
</div>
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">Brand Sheet </label>
  <input type="file" className="form-control" id="brandSheet" />
</div>
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">Result Date</label>
  <input type="date"  max="9999-12-31" className="form-control" id="resultDate"/>
</div> 
<div className="mb-3 text-center">
{/* <ButtonSuccess btnTitle="won" btnhtmlFor="won"/>
  <ButtonDelete btnTitle="lost" btnhtmlFor="noInterest"/> */}
  <button className='btn btnSuccess mx-1' onClick={docSubmit}>Submit</button>
      
 
  
</div> 

        </div> 
    </div>
</div>
</div>   
    )
}
export default DocumentUpload;