import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';

function BarChart(props) {
     
  const [barData, setBarData] = useState({
    labels: ['New', 'Filing', 'Running', 'Complete'],
    datasets: [
        {
            label: 'Count',
            data: [
                props.newCount,
                props.filingCount,
                props.runningCount,
                props.completeCount
            ],
            backgroundColor: [
                '#1f3674',
                '#681b21',
                '#1a9930',
                '#cd8138'
            ],
            borderWidth: 3
        }
    ]
});
// set options
const [barOptions, setBarOptions] = useState({
    options: {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true
                    }
                }
            ]
        },
        title: {
            display: true,
            text: 'Data Orgranized In Bars',
            fontSize: 25
        },
        legend: {
            display: true,
            position: 'top'
        }
    }
});

  // return JSX
  return (
    <div className="BarExample">
        <Bar
            data={barData}
             options={barOptions.options} 
            />
    </div>
);
}
export default BarChart;