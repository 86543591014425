import { LineStyle } from '@material-ui/icons';
import { Button } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react'
import SideBar from '../../common/SideBar/SideBar';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';

function Calender() {

    const [value, setvalue] = useState(new Date());

    const onChange = (value)=>{
        setvalue(value)
        console.log(value)

    }
    
    const [attendence,setattendence] = useState(true)
    const [leave,setleave] =useState(false)
    const [sty,setsty] = useState({
        width:"400px",
          background:"red",
          borderBottom:"3px solid red",
          color:"#fff"
      })
      const [lsty,setlsty] =useState({
        width:"400px",
        background:"#fff",
        borderBottom:"3px solid red",
        color:"red"
      })

    const mark = [
        '14-08-2022',
        '21-09-2022',
        '05-09-2022'
    ]
   
   

const handleAttendence =()=>{

    setattendence(true)
    setleave(false)
    setsty({
        width:"400px",
        background:"red",
        borderTop:"3px solid red",
        color:"#fff"
      })
      setlsty({
        width:"400px",
        background:"#fff",
        borderTop:"3px solid red",
        color:"red"
      })
}

const handleleave =()=>{

    setattendence(false)
    setleave(true)
    setlsty({
        width:"400px",
        background:"red",
        borderTop:"3px solid red",
        color:"#fff"
      })
      setsty({
        width:"400px",
        background:"#fff",
        borderTop:"3px solid red",
        color:"red"
      })
}


function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }


 var from = convert(value.toString().split(",")[0]);
 var to = convert(value.toString().split(",")[1]);
 
 console.log(from ,to )


 

    return (
        <>

     <div className='container-fluid'>
     <div className='row '>
     <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
       <SideBar/>
       </div> 
       
     <div className='col-md-10 col-12 col-sm-12 py-3'>
     <div className='row'>


        <div style={{width:"59%",margin:"auto",height:"40px",marginTop: "30px",display: "flex", justifyContent: "center"}}>
            <Button style={sty} onClick={handleAttendence}>ATTENDENCE</Button>
            <Button style={lsty} onClick={handleleave}>LEAVE REQUEST</Button>
        </div>
            {
                attendence ?
                <div style={{ display: "flex", justifyContent: "center" }}>
                <Calendar
                    
                    tileClassName={({ date, view }) => {
                        if (mark.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                            return 'highlight'
                        }
                    }}
                   
                   />

            </div>
            :
            ""
            }
            {
                leave ?
                <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                <Calendar
                 
                    value={value}
                    onChange={onChange}
                    selectRange={true}
                   />

            </div>
           <div style={{height:"300px",width:"100%"}}>
            <br />
            <br />
           <center> <textarea rows="5" cols="90" placeholder={`tell me about your leave from ${from} to ${to}` }></textarea></center>
           <br/><br />
      <center><Button style={{background:"green",color:"#fff"}}> Request Leave  </Button></center>
           </div>
       
                </>
            :""
            }
   </div>
        </div>
        </div></div>
        </>

    )
}

export default Calender

