import React, { useState } from 'react';
import {
  CardGroup,
  Card,
  // CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardImg,

} from 'reactstrap';

import ButtonEdit from '../../common/Buttons/ButtonEdit';
import ButtonDelete from '../../common/Buttons/ButtonDelete';
import { Link } from 'react-router-dom';
const ManageMasterTable = (props) => {

  const handleOpen = () => { props.setShowDetails(props.rowpos) }




  return (
    <>

      <CardGroup className='h-100 tracking-in-expand-fwd-top '>
        <Card className='crdBody '>
          <CardBody className='myshadowcard py-2'>
            <div className='cursor-pointer' >
              <Link to={`/manageMaster/employee_master/form?id=${props.rowpos}`} style={{ textDecoration: "none" }}>
                <CardSubtitle
                  className="my-2 mytitle"
                  tag="h6"
                  onClick={e => { handleOpen(e) }}
                >
                  {props.firstName} {props.lastName}
                </CardSubtitle>
                <hr className="cardLine m-2" />
                <div className='empDetails'>
                  <CardText className='text-start'>
                    <span className=''>Employee ID:</span>  {props.rowpos}
                  </CardText>
                  <CardText className='text-start'>
                    <span className=' '>Department:</span> {props.dept}
                  </CardText>
                  <CardText className='text-start'>
                    <span className=' '>Mobile No.:</span> {props.phone}
                  </CardText>
                  <CardText className='text-start'>
                    <span className=' '> Email:</span> {props.mail}
                  </CardText>
                  <CardText className='text-start'>
                    <span className=' '> Hiring Date:</span> {props?.hiringDate?.split("T")[0]}
                  </CardText>
                </div>
              </Link>
            </div>

            <div className='deleteBtnDiv cursor-pointer'>
              <ButtonDelete btnTitle="Delete" usehtmlFor="emp" rowpos={props.rowpos} onDelete={props.onDelete} />
            </div>
          </CardBody>
        </Card>
      </CardGroup>


    </>
  )
}
export default ManageMasterTable;