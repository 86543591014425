import { Button } from '@mui/material';
import './timeOff.css';
import axios from 'axios';
import { VscError } from 'react-icons/vsc';
import React, { useEffect, useState } from 'react';
import { DELETE_LEAVE_MASTER, UPDATE_LEAVE_MASTER, APPLY_LEAVES, GET_LEAVE, GET_LEAVE_BY_MCOCE, UPDATE_LEAVE, ALLOWED_USERTYPE, GET_LEAVE_MASTER, CREATE_LEAVE_MASTER, GET_LEAVE_MASTER_BY_ROWPOS } from '../../../../utiles/AllApis';
import Empsearch from '../../../../common/EmpSearch/EmpSearch';
import { Modal, ModalBody } from 'reactstrap';
import ErrorAlertBox from '../../../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../../../common/AlertBox/SuccessAlertBox';
import { onlynumerical } from "../../../../GenericFunction/GenericFunction";
function EmpLeave(props) {
  const [isLoading, setLoading] = useState(false);
  const [uType, setUType] = useState(props.tokectDetails.logintype);
  const [userID, setUserID] = useState(props.tokectDetails.mcode);
  const [selecedUserID, setSelectedUserID] = useState(uType === ALLOWED_USERTYPE.ADMIN ? "" : props.tokectDetails.mcode);
  const [isadd, setIsadd] = useState(false);
  const [seletedeData, setseletedeData] = useState({});
  const [allleavesdata, setAllleavesdata] = useState([]);
  const [leaveType, setLeaveType] = useState([]);
  const [leavedTake, setLeavesTake] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [rowpos, setRowpos] = useState("");
  const [approvalPopUp, setApprovalPopUp] = useState(false);
  const [askTym, setAskTym] = useState(false);
  const [half, setHalf] = useState(false);
  const [weekDayOFf, setWeekDayOFf] = useState(false);
  const [isMsterEdit, setIsMsterEdit] = useState(false);
  const [ddtSly, setDdtSly] = useState(false);
  const [statutory, setStatutory] = useState(false);
  const [Acc, aetAcc] = useState(false);
  const [leaveId, setLeaveId] = useState("");
  const [leaveStatus, setLeaveStatus] = useState("");
  const [fDate, setFDate] = useState("");
  const [tDate, setTDate] = useState("");
  const [leaveBalance, setLeaveBalance] = useState("");
  const [leaveUsed, setLeaveUsed] = useState("");
  const [EmpMcode, setEmpMcode] = useState("");
  const [formSelection, setFormSelection] = useState("APPLY_LEAVE");
  const [isOpen1, setIsOpen1] = useState(false);
  const [masterAdd, setMasterAdd] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpencomfirm, setIsOpencomfirm] = useState(false);
  const [heading, setHeading] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const onHandleOpen = () => { setApprovalPopUp(true) }
  const onHandleclose = () => { setApprovalPopUp(false) }
  const handalOpenErr = () => { setIsOpen(true); }
  const handalOpen1 = () => { setIsOpen1(true); }
  const handalCloseconfim = () => { setIsOpencomfirm(false); }
  const [LeaveData, setLeateData] = useState({});
  const [LeaveMasterData, setLeaveMasterData] = useState({
    seq: "",
    lcode: "",
    description: "",
    dedSalDays: 0,
    lvPerYr: 0,
    lvReset: "",
    accumulateDays: 0,
    accumulateMaxDays: 0,
    statutory: "",
    accumulate: "",
    askTime: true
  }
  )
  useEffect(() => {
    setLeateData({ ...LeaveData, "EmployeeID": selecedUserID });
    getDetails();
  }, [selecedUserID]);
  useEffect(() => {
    getAllLeaveType();
  }, []);
  //---------get deatiles------------
  function getDetails() {
    if (uType === ALLOWED_USERTYPE.ADMIN) {
      loadAllLeaves(selecedUserID);
    }
    else {
      loadAllLeaves(userID);
    }
  }
  //---------get All leave type------------
  function getAllLeaveType() {
    axios.get(GET_LEAVE_MASTER).then((response) => {
      if (response.data.isSuccess === true) {
        setLeaveType(response.data.data);
      }
      else {
        setLeaveType([]);
      }
    })
  }
  const loadAllLeaves = (userID) => {
    if (userID === "") {
      setLoading(true);
      axios.get(GET_LEAVE).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setAllleavesdata(response.data.data);
        }
        else {
          setAllleavesdata([]);
        }
      })
    }
    else {
      setLoading(true);
      axios.post(GET_LEAVE_BY_MCOCE, {

        "EmployeeID": userID

      }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setAllleavesdata(response.data.data);
        }
        else {
          setAllleavesdata([]);
        }
      })
    }
  }
  //-----------Approve Leave---------------
  const leaveUpdate = (id, status, fDate, tDate, empMcode, bal, userd) => {
    setLeaveId(id);
    setLeaveStatus(status);
    setFDate(fDate);
    setTDate(tDate);
    onHandleOpen();
    setEmpMcode(empMcode);
    setLeaveBalance(bal);
    setLeaveUsed(userd);
  }
  //---------get apply Leave Data----------
  const onHandleChange = (e) => {
    setLeateData({ ...LeaveData, [e.target.name]: e.target.value });
    let diffInMilli = 0, toDate;
    if (e.target.name === "dtLeaveToDate") {
      toDate = e.target.value;
    }
    if (LeaveData.dtLeaveFromDate !== undefined && toDate !== undefined) {

      diffInMilli = (Math.abs(new Date(LeaveData.dtLeaveFromDate) - new Date(e.target.value)));
      setLeavesTake(Math.floor(diffInMilli / (1000 * 60 * 60 * 24)));
      setLeateData({ ...LeaveData, "iBalanceLeave": 24 - (Math.floor(diffInMilli / (1000 * 60 * 60 * 24))) });
      setLeateData({ ...LeaveData, "iUsedLeave": Math.floor(diffInMilli / (1000 * 60 * 60 * 24)) });
      setLeateData({ ...LeaveData, [e.target.name]: e.target.value });
    }
  }
  //---------get Leave MAster Data----------
  const onHandleChangeMaster = (e) => {
    if (e.target.name === "askTime") {
      setAskTym(e.target.checked ? true : false);
    }
    else if (e.target.name === "half") {
      setHalf(e.target.checked);
    }
    else if (e.target.name === "includeHDO") {
      setWeekDayOFf(e.target.checked);
    }
    else if (e.target.name === "dedSal") {
      setDdtSly(e.target.checked);
    }
    else if (e.target.name === "statutory") {
      setStatutory(e.target.checked);
    }
    else if (e.target.name === "accumulate") {
      aetAcc(e.target.checked);
    }
    if (e.target.name === "askTime" || e.target.name === "half" || e.target.name === "includeHDO" || e.target.name === "dedSal" || e.target.name === "statutory" || e.target.name === "accumulate") {
      e.target.value = e.target.checked;
    }
    setLeaveMasterData({ ...LeaveMasterData, [e.target.name]: e.target.value });
  }
  //for Apply Leave
  const applyForLeave = () => {
    if (LeaveData.dtLeaveFromDate === "" || LeaveData.dtLeaveToDate === "" || LeaveData.strLeaveReason === "" || LeaveData.strLCode === "") {
      setHeading("Failed");
      setErrMsg("Fields cannot empty");
      handalOpenErr();
      return;
    }
    else {
      var fDate = `${new Date(LeaveData.dtLeaveFromDate).getFullYear()}-${new Date(LeaveData.dtLeaveFromDate).getMonth() + 1}-${new Date(LeaveData.dtLeaveFromDate).getDate()}`;
      var TDate = `${new Date(LeaveData.dtLeaveToDate).getFullYear()}-${new Date(LeaveData.dtLeaveToDate).getMonth() + 1}-${new Date(LeaveData.dtLeaveToDate).getDate()}`;
      var TodayDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;

      if (LeaveData.dtLeaveFromDate > LeaveData.dtLeaveToDate) {
        setHeading("Failed");
        setErrMsg("From date cannot greater then to date");
        handalOpenErr();
        return
      }
      if (fDate < TodayDate || TDate < TodayDate) {
        setHeading("Failed");
        setErrMsg("Invalid Date to apply leave..Date Should be greater then today date");
        handalOpenErr();
        return;
      }
    }
    setLoading(true);
    axios.post(APPLY_LEAVES,
      {
        "EmployeeID": selecedUserID,

        "dtLeaveFromDate": fDate,

        "dtLeaveToDate": TDate,

        "strLeaveReason": LeaveData.strLeaveReason,

        "strLCode": LeaveData.strLCode,

        "strLeaveStatus": 'PENDING'
      }
    ).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setHeading1("Success");
        setsuccessMsg("Leave Apply successfully..");
        handalOpen1();
        setIsadd(false);
        getDetails();
        loadAllLeaves(LeaveData.EmployeeID);
        setLeateData({});
      }
      else {
        setHeading("Failed");
        setErrMsg(response.data.message);
        handalOpenErr();
      }
    })
  }
  //store remarks
  const handlChange = (e) => {
    setRemarks(e.target.value);
  }
  //-------------update leave--------------------
  const UpdateLeaveData = () => {
    let config;
    if (leaveStatus === "APPROVED") {
      config = {

        "ROWPOS": leaveId,

        "EmployeeID": EmpMcode,

        "iBalanceLeave": 24 - leavedTake,

        "iUsedLeave": leavedTake,

        "dtLeaveFromDate": fDate,

        "dtLeaveToDate": tDate,

        "strLeaveRemark": remarks,

        "strLeaveStatus": leaveStatus
      }
    }
    else {
      config = {

        "ROWPOS": leaveId,

        "EmployeeID": EmpMcode,

        "iBalanceLeave": leaveBalance,

        "iUsedLeave": leaveUsed,

        "dtLeaveFromDate": fDate,

        "dtLeaveToDate": tDate,

        "strLeaveRemark": remarks,

        "strLeaveStatus": leaveStatus
      }

    }
    setLoading(true);
    axios.post(UPDATE_LEAVE, config
    ).then((response) => {
      setLoading(false);
      onHandleclose();
      if (response.data.isSuccess === true) {
        setHeading1("Success");
        setsuccessMsg("Leave update successfully..");
        handalOpen1();
        setIsadd(false);
        getDetails();
      }
      else {
        setHeading("Failed");
        setErrMsg("Mandatory fields cannot empty");
        handalOpenErr();
      }
    })
  }
  //------------create Leave Master----------------
  const createLeaveMaster = () => {
    if (LeaveMasterData.seq === "" || LeaveMasterData.lcode === "" || LeaveMasterData.description === "") {
      setHeading("Failed");
      setErrMsg("Fill Mendetory fields!!");
      handalOpenErr();
      return;
    }
    else {
      // setLoading(true);
      axios.post(CREATE_LEAVE_MASTER, LeaveMasterData).then((response) => {
        // setLoading(false);
        if (response.data.isSuccess === true) {
          setHeading1("Success");
          setsuccessMsg("Leave Added successfully..");
          handalOpen1();
          getAllLeaveType();
          setMasterAdd(false);
        }
        else {
          setHeading("Failed");
          setErrMsg(response.data.message);
          handalOpenErr();
        }
      })
    }
  }
  //-------------Edit Leave Master ------------------
  const handelEdit = (id) => {
    setRowpos(id);
    setIsMsterEdit(true);
    setMasterAdd(true);
    setIsadd(true);
    axios.post(GET_LEAVE_MASTER_BY_ROWPOS, { "ROWPOS": id }).then((response) => {
      //setLoading(false);
      if (response.data.isSuccess === true) {
        setLeaveMasterData(response.data.data);
        setAskTym(response.data.data.askTime);
        setHalf(response.data.data.half);
        setWeekDayOFf(response.data.data.includeHDO);
        setDdtSly(response.data.data.dedSal);
        setStatutory(response.data.data.statutory);
        aetAcc(response.data.data.accumulate);
      }
    })
  }
  //-------------Update Leave Master ------------------
  const UpdateLeaveMaster = (id) => {
    setLeaveMasterData(
      {
        seq: "",
        lcode: "",
        description: "",
        dedSalDays: 0,
        lvPerYr: 0,
        lvReset: "",
        accumulateDays: 0,
        accumulateMaxDays: 0,
        statutory: "",
        accumulate: "",
        askTime: true
      })
    setAskTym(false);
    setHalf(false);
    setWeekDayOFf(false);
    setIsMsterEdit(false);
    setDdtSly(false);
    setStatutory(false);
    aetAcc(false);
    if (LeaveMasterData.seq === "" || LeaveMasterData.lcode === "" || LeaveMasterData.description === "") {
      setHeading("Failed");
      setErrMsg("Fill Mendetory fields!!");
      handalOpenErr();
      return;
    }
    else {
      // setLoading(true);
      axios.post(UPDATE_LEAVE_MASTER,
        {
          "rowpos": rowpos,
          "seq": LeaveMasterData.seq,
          "lcode": LeaveMasterData.lcode,
          "description": LeaveMasterData.description,
          "dedSalDays": LeaveMasterData.dedSalDays,
          "lvPerYr": LeaveMasterData.lvPerYr,
          "lvReset": LeaveMasterData.lvReset,
          "accumulateDays": LeaveMasterData.accumulateDays,
          "accumulateMaxDays": LeaveMasterData.accumulateMaxDays,
          "statutory": LeaveMasterData.statutory,
          "accumulate": LeaveMasterData.accumulate,
          "askTime": LeaveMasterData.askTime,
          "half": LeaveMasterData.half,
          "includeHDO": LeaveMasterData.includeHDO,
          "dedSal":LeaveMasterData.dedSal

        }).then((response) => {
          // setLoading(false);
          if (response.data.isSuccess === true) {
            setHeading1("Success");
            setsuccessMsg("Update successfully..");
            handalOpen1();
            getAllLeaveType();
            setMasterAdd(false);
          }
          else {
            setHeading("Failed");
            setErrMsg(response.data.message);
            handalOpenErr();
          }
        })
    }
  }
  //-------------Delete emergency details------------------
  const handelDelete = (id) => {
    handalCloseconfim();
    setRowpos(id);
    setLoading(true);
    axios.post(DELETE_LEAVE_MASTER, { "ROWPOS": id }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        getAllLeaveType();
        setMasterAdd(false);
      }
      else {
        setHeading("Failed");
        setErrMsg(response.data.message);
        handalOpenErr();
      }
    })
  }
  const addDetails = () => {
    setIsadd(true);
    //  setRelativeDetails({});
    //  setMale(false);setIsEdit(false);
    //  setFemale(false);
    //  setNominee(false); 
  }
  return (
    <>
      {
        isLoading ? <div className='loaderDiv'>
        </div> : null
      }
      <div className='emp-aboutdetailmaincontent'>
        <div className={uType === ALLOWED_USERTYPE.ADMIN ? 'mnusTopMrgn' : ''}>
          <h5 className='text-white fw-bold mt-2 text-center'>Time Off</h5>
        </div>
        {
          uType === ALLOWED_USERTYPE.ADMIN && formSelection === "APPLY_LEAVE" ?
            <Empsearch uType={uType} setIsadd={setIsadd} isadd={isadd} setUserID={setSelectedUserID} empData={seletedeData} />
            :
            <div className='row'>
              {uType !== ALLOWED_USERTYPE.ADMIN ?
                <div className='col-md-2 col-lg-2 col-sm-2 col-2 mt-1'>
                  {
                    isadd ? <button className='btn btnSuccess' onClick={e => { setIsadd(false); }}>View List</button>
                      :
                      <button className='btn btnSuccess' onClick={e => { addDetails() }}>Add Details</button>
                  }
                </div> : ""
              }
            </div>
        }
        {
          uType === ALLOWED_USERTYPE.ADMIN ?
            <>
              <div className='row'>
                <div className='col-sm-6 col-lg-6 col-md-6 col-6'>
                  {
                    formSelection === "LEAVE_MASTER" ?
                      masterAdd ?
                        <button className={`newSuccBtn px-3 mx-1`}
                          onClick={() => { setMasterAdd(false) }}
                        >View List</button>
                        :
                        <button className={`newSuccBtn px-3 mx-1`}
                          onClick={() => { setMasterAdd(true) }}
                        >ADD</button> : ""
                  }

                </div>
                <div className='col-sm-3 col-lg-3 col-md-3 col-3'>
                  <button className={`newShdBtn px-3 mx-1  ${formSelection === "APPLY_LEAVE" ? "activeCls" : null}`}
                    onClick={() => { setFormSelection("APPLY_LEAVE") }}>Apply Leave</button>
                </div>
                <div className='col-sm-3 col-lg-3 col-md-3 col-3'>
                  <button className={`newShdBtn px-3 mx-1  ${formSelection === "LEAVE_MASTER" ? "activeCls" : null}`}
                    onClick={() => { setFormSelection("LEAVE_MASTER") }}>Leave Master</button>
                </div>
              </div></>
            : ""
        }

        {
          formSelection === "APPLY_LEAVE" ?
            isadd ?
              <>
                <div className='mt-4'>
                  <hr className='myhr text-white' />
                  <h5 className='SecoHead text-center'>Add Leave</h5>
                  <div className='emp_profile_form'>
                    <div className='row'>
                      <div className='col-md-3 col-sm-3 col-3 mt-3'>
                        <label
                          htmlFor="dtEmergContactDOB"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          From date<span className='required'>*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control w-75 mt-2"
                          value={LeaveData?.dtLeaveFromDate?.split("T")[0]}
                          id="dtLeaveFromDate"
                          name="dtLeaveFromDate"
                          max="9999-12-31"
                          onChange={(e) => onHandleChange(e)}
                        />
                      </div>
                      <div className='col-md-3 col-sm-3 col-3 mt-3'>
                        <label
                          htmlFor="dtEmergContactDOB"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          To Date<span className='required'>*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control w-75 mt-2"
                          value={LeaveData?.dtLeaveToDate?.split("T")[0]}
                          id="dtLeaveToDate"
                          name="dtLeaveToDate"
                          max="9999-12-31"
                          onChange={(e) => onHandleChange(e)}
                        />
                      </div>

                      <div className='col-md-3 col-sm-3 col-3 mt-3'>
                        <label
                          htmlFor="strRelationship"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          Leave required<span className='required'>*</span>
                        </label>
                        <select class="form-select mt-2 w-75"
                          //value={relativeDetails?.strLCode}
                          aria-label="Default select example" name="strLCode" id="strLCode"
                          onChange={e => { onHandleChange(e) }}
                        >
                          <option value="">--Select leave required--</option>
                          {
                            leaveType?.map((data, i) => {
                              return (<option value={data.lcode}>{data.description}</option>);
                            })
                          }
                        </select>
                      </div>

                      <div className='col-md-3 col-sm-3 col-3 mt-3'>
                        <label
                          htmlFor="dtEmergContactDOB"
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          Reason<span className='required'>*</span>
                        </label>
                        <textarea className="form-control" placeholder="Add Reason for leave." id="remarks" name="strLeaveReason" style={{ height: "130px" }}
                          onChange={e => { onHandleChange(e) }}></textarea>
                      </div>
                      <div className='col-md-12 col-sm-12 col-12 mt-4 text-center'>
                        <button className='btn btnSuccess' onClick={e => applyForLeave(e)} ><span>Submit</span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              :
              allleavesdata.length > 0 ?
                <div className={`${uType === ALLOWED_USERTYPE.ADMIN ? "emgncy" : "emgncyFull"} table-responsive mt-3`}>
                  <table className="table table-bordered border-secondary ">
                    <thead className='tblHeader'>
                      <tr>
                        <th scope="col text-white">#</th>
                        <th scope="col text-white">Edate</th>
                        <th scope="col text-white">Leave Required</th>
                        <th scope="col text-white">Name</th>
                        <th scope="col text-white">Leave From</th>
                        <th scope="col text-white">Leave To</th>
                        <th scope="col text-white">Reason</th>
                        <th scope="col text-white">Status</th>
                        <th scope="col text-white" className={uType !== ALLOWED_USERTYPE.ADMIN ? "d-none" : ""}>Update</th>
                      </tr>
                    </thead>
                    <tbody className='tblBdy'>
                      {
                        allleavesdata.map((data, i) => {
                          return (<tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{data.dtLeaveTakingDate.slice(0, 10)}</td>
                            <td>{data.leaveType}</td>
                            <td>{data.employeeName}</td>
                            <td>{data.dtLeaveFromDate.slice(0, 10)}</td>
                            <td>{data.dtLeaveToDate.slice(0, 10)}</td>
                            <td>{data.strLeaveReason}</td>
                            <td>{data.strLeaveStatus}</td>
                            <td className={uType !== ALLOWED_USERTYPE.ADMIN ? "d-none" : ""}>
                              {
                                data.strLeaveStatus.toUpperCase() === "PENDING" ?
                                  <>      <button className='btn btnEdit mx-1'
                                    onClick={e => { leaveUpdate(data.iLeaveRowpos, 'APPROVED', data.dtLeaveFromDate, data.dtLeaveToDate, data.employeeID, data.iBalanceLeave, data.iUsedLeave) }}
                                  >Approve</button>
                                    <button className='btn btnDelete mx-1'
                                      onClick={e => { leaveUpdate(data.iLeaveRowpos, 'REJECTED', data.dtLeaveFromDate, data.dtLeaveToDate, data.employeeID, data.iBalanceLeave, data.iUsedLeave) }}
                                    >Reject</button></> : ""
                              }

                            </td>
                          </tr>);
                        }
                        )
                      }

                    </tbody>
                  </table>
                </div>

                :
                <p className='text-white text-center mt-5'>No Data Found!!</p>
            :
            masterAdd ?
              <>
                <div className='mt-3'>
                  <hr className='myhr text-white' />
                  <h5 className='SecoHead text-center'>Leave Master</h5>
                  <div className='emp_Time_form'>
                    <div className='row'>
                      <div className='col-md-3 col-sm-3 col-3 mt-3 gridBorder'>
                        <div className='row'>
                          <div className='col-md-3 col-sm-3 col-3 mt-3 gridBorder'>
                            <label
                              htmlFor="dtEmergContactDOB"
                              className="form-label fw-normal h6 text-capitalize"
                            >SL No.<span className='required'>*</span></label>
                            <input
                              type="text"
                              className="form-control w-75 mt-2"
                              value={LeaveMasterData?.seq}
                              id="seq"
                              name="seq"
                              onChange={(e) => onHandleChangeMaster(e)}
                              onKeyPress={onlynumerical}
                            />
                          </div>
                          <div className='col-md-9 col-sm-9 col-9 mt-3 gridBorder'>
                            <label
                              htmlFor="dtEmergContactDOB"
                              className="form-label fw-normal h6 text-capitalize"
                            >Leave Description<span className='required'>*</span></label>
                            <input
                              type="text"
                              className="form-control w-75 mt-2"
                              value={LeaveMasterData?.description}
                              id="description"
                              name="description"
                              onChange={(e) => onHandleChangeMaster(e)}
                            />
                          </div>
                        </div>

                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-1 col-3 mt-3 gridBorder'>
                        <label
                          htmlFor="dtEmergContactDOB"
                          className="form-label fw-normal h6 text-capitalize"
                        >Abrivation <span className='required'>*</span></label>
                        <input
                          type="text"
                          className="form-control w-75 mt-2"
                          value={LeaveMasterData?.lcode}
                          id="abb"
                          name="lcode"
                          onChange={(e) => onHandleChangeMaster(e)}
                        />
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-1 col-3 mt-3 gridBorder'>
                        <label
                          htmlFor="dtEmergContactDOB"
                          className="form-label fw-normal h6 text-capitalize"
                        >Ask Time</label>
                        <div className="form-check ">
                          <input className="form-check-input" type="checkbox" name="askTime" id="askTime"
                            onChange={(e) => onHandleChangeMaster(e)}
                            checked={askTym}
                          />
                        </div>

                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-1 col-3 mt-3 gridBorder'>
                        <label
                          htmlFor="dtEmergContactDOB"
                          className="form-label fw-normal h6 text-capitalize"
                        >Allow Half days leave</label>
                        <div className="form-check ">
                          <input className="form-check-input" type="checkbox" name="half" id="half"
                            onChange={(e) => onHandleChangeMaster(e)}
                            checked={half}
                          />
                        </div>
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-1 col-3 mt-3 gridBorder'>
                        <label
                          htmlFor="dtEmergContactDOB"
                          className="form-label fw-normal h6 text-capitalize"
                        >Include Holiday & Week day Off</label>
                        <div className="form-check ">
                          <input className="form-check-input" type="checkbox" name="includeHDO" id="includeHDO"
                            onChange={(e) => onHandleChangeMaster(e)}
                            checked={weekDayOFf}
                          />
                        </div>
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-1 col-3 mt-3 gridBorder'>
                        <label
                          htmlFor="dtEmergContactDOB"
                          className="form-label fw-normal h6 text-capitalize"
                        >Deduct Salary</label>
                        <div className="form-check ">
                          <input className="form-check-input" type="checkbox" name="dedSal" id="dedSal"
                            onChange={(e) => onHandleChangeMaster(e)}
                            checked={ddtSly}
                          />
                        </div>
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-1 col-3 mt-3 gridBorder'>
                        <label
                          htmlFor="dtEmergContactDOB"
                          className="form-label fw-normal h6 text-capitalize"
                        >Deduct Days</label>
                        <input
                          type="text"
                          className="form-control w-75 mt-2"
                          value={LeaveMasterData?.dedSalDays}
                          id="dedSalDays"
                          name="dedSalDays"
                          onChange={(e) => onHandleChangeMaster(e)}
                          onKeyPress={onlynumerical}
                        />
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-1 col-3 mt-3 gridBorder'>
                        <label
                          htmlFor="dtEmergContactDOB"
                          className="form-label fw-normal h6 text-capitalize"
                        >Statutory Leave</label>
                        <div className="form-check ">
                          <input className="form-check-input" type="checkbox" name="statutory" id="statutory" value="male"
                            onChange={(e) => onHandleChangeMaster(e)}
                            checked={statutory}
                          />
                        </div>
                      </div>
                      <div className='col-md-2 col-lg-2 col-sm-2 col-3 mt-3 gridBorder'>
                        <div className='row'>
                          <div className='col-md-6 col-lg-6 col-sm-6 col-3 mt-3 gridBorder'>
                            <label
                              htmlFor="dtEmergContactDOB"
                              className="form-label fw-normal h6 text-capitalize"
                            >Days Per Year</label>
                            <input
                              type="text"
                              className="form-control w-75 mt-2"
                              value={LeaveMasterData?.lvPerYr}
                              id="lvPerYr"
                              name="lvPerYr"
                              onChange={(e) => onHandleChangeMaster(e)}
                              onKeyPress={onlynumerical}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-6 col-3 mt-3 gridBorder'>
                            <label
                              htmlFor="dtEmergContactDOB"
                              className="form-label fw-normal h6 text-capitalize"
                            >Reset Feature</label>
                            <select class="form-select mt-2 w-75 p-0" value={LeaveMasterData?.lvReset}
                              aria-label="Default select example" name="lvReset" id="lvReset" onChange={(e) => onHandleChangeMaster(e)}>
                              <option value="">--Select Relation--</option>
                              <option value="N/A">N/A</option>
                              <option value="YEARLY">YEARLY</option>
                              <option value="NO">NO</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 col-lg-3 col-sm-3 col-3 mt-3 gridBorder'>
                        <div className='row'>
                          <div className='col-md-4 col-lg-4 col-sm-4 col-3 mt-3 gridBorder'>
                            <label
                              htmlFor="dtEmergContactDOB"
                              className="form-label fw-normal h6 text-capitalize"
                            >Accumulate Leave</label>
                            <div className="form-check ">
                              <input className="form-check-input" type="checkbox" name="accumulate" id="accumulate"
                                onChange={(e) => onHandleChangeMaster(e)}
                                onKeyPress={onlynumerical}
                                checked={Acc}
                              />
                            </div>
                          </div>
                          <div className='col-md-4 col-lg-4 col-sm-4 col-3 mt-3 gridBorder'>
                            <label
                              htmlFor="dtEmergContactDOB"
                              className="form-label fw-normal h6 text-capitalize"
                            >Accumulate Days</label>
                            <input
                              type="text"
                              className="form-control w-75 mt-2"
                              value={LeaveMasterData?.accumulateDays}
                              id="accumulateDays"
                              name="accumulateDays"
                              onChange={(e) => onHandleChangeMaster(e)}
                              onKeyPress={onlynumerical}
                            />
                          </div>
                          <div className='col-md-4 col-lg-4 col-sm-4 col-3 mt-3 gridBorder'>
                            <label
                              htmlFor="dtEmergContactDOB"
                              className="form-label fw-normal h6 text-capitalize"
                            >Accumulate Max days</label>
                            <input
                              type="text"
                              className="form-control w-75 mt-2"
                              value={LeaveMasterData?.accumulateMaxDays}
                              id="accumulateMaxDays"
                              name="accumulateMaxDays"
                              onChange={(e) => onHandleChangeMaster(e)}
                              onKeyPress={onlynumerical}
                            />
                          </div>
                        </div>

                      </div>
                      <div className='col-md-7 col-sm-7 col-7 mt-5 text-center'>
                        {
                          isMsterEdit ?
                            <button className='btn btnSuccess' onClick={e => UpdateLeaveMaster(e)} ><span>Update</span></button> :
                            <button className='btn btnSuccess' onClick={e => createLeaveMaster(e)} ><span>Submit</span></button>

                        }
                      </div>
                    </div>
                  </div>
                </div>
              </>
              :
              leaveType.length > 0 ?
                <div className='table-responsive mt-3 emgncy'>
                  <table className="table table-bordered border-secondary ">
                    <thead className='tblHeader'>
                      <tr>
                        {/* <th scope="col text-white">#</th>
                          <th scope="col text-white">Created At</th> */}
                        <th scope="col text-white">sequence</th>
                        <th scope="col text-white">Leave Description</th>
                        <th scope="col text-white">Abrivation</th>
                        <th scope="col text-white">Edit</th>
                        <th scope="col text-white">Delete</th>
                      </tr>
                    </thead>
                    <tbody className='tblBdy'>
                      {
                        leaveType.map((data, i) => {
                          return (
                            <tr key={i}>
                              {/* <th scope="row">{i+1}</th> */}
                              {/* <td>{data.edate.slice(0,10)}</td> */}
                              <td>{data.seq}</td>
                              <td>{data.description}</td>
                              <td>{data.lcode}</td>
                              <td><button className='btn btnEdit'
                                onClick={e => { handelEdit(data.rowpos) }}
                              >Edit</button></td>
                              <td><button className='btn btnDelete'
                                onClick={e => { setRowpos(data.rowpos); setIsOpencomfirm(true); }}
                              >Delete</button></td>

                            </tr>);
                        }
                        )
                      }

                    </tbody>
                  </table>
                </div>

                :
                <p className='text-white text-center mt-5'>No Data Found!!</p>

        }
        {/* Approval Popup*/}
        {
          approvalPopUp ?
            <div className='popup' style={{ width: "50%", position: "fixed" }}>
              <p style={{ float: "right", cursor: "pointer" }} onClick={e => { onHandleclose(e) }}>❌</p>
              <p className='text-dark'>Enter Your Remarks for <span className='text-capitalize'>{leaveStatus}</span> this Leave </p>

              <div className='px-3'>
                <div className="mb-4">
                  {/* <label for="exampleFormControlTextarea1" className="form-label">Remarks</label> */}
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Enter your Remarks here...' onChange={e => { handlChange(e) }}></textarea>
                </div>
                <div className="mb-4 text-center">
                  <button className='btn btn-success' onClick={e => { UpdateLeaveData(e) }}>Submit</button>
                </div>
              </div>

            </div> : null

        }
        <Modal className="fade show" isOpen={isOpencomfirm} >
          <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
            <p className=' text-center text-danger fw-bold'><VscError className='fw-bolder mx-2 my-2 h2' />Are you sure you want to Delete</p>
            <div>   <Button
              className='btnSuccess mx-2'
              onClick={() => { handelDelete(rowpos) }}
            >
              Yes
            </Button>
              <Button
                className='btnDelete mx-2'
                onClick={handalCloseconfim}
              >
                No
              </Button></div>
          </ModalBody>

        </Modal>
        <ErrorAlertBox isOpen={isOpen} setIsOpen={setIsOpen} heading={heading} errMsg={errMsg} />
        <SuccessAlertBox
          isOpen1={isOpen1}
          setIsOpen1={setIsOpen1}
          heading1={heading1}
          successMsg={successMsg}
        />
      </div>
    </>
  )
}

export default EmpLeave;