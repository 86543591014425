import React from 'react'

const HrEmployeForm = () => {
    return (
        <>
            <div className='d-flex hrEmployeeform' style={{ justifyContent: "space-between", width: "85%", margin: "auto" }}>
                <div className='col-md-5 col-sm-5 col-5'>
                    <h4 style={{ color: "#fff" }}> Dates</h4>
                    <hr className='myhr' />
                    <p><span className='text-white'>JOINING GROUP :</span><input type="text" /></p>
                    <p><span className='text-white'>CONTACT / JOINING :</span><input type="text" /></p>
                    <p><span className='text-white'>PROBATION :</span><input type="text" /></p>
                    <p><span className='text-white'>CONFIRMATION :</span><input type="text" /></p>
                    <p><span className='text-white'>CONTRACT LEAVING :</span><input type="text" /></p>
                    <p><span className='text-white'>REASON FOR LEAVING :</span><input type="text" /></p>
                    <p><span className='text-white'>NEXT INCREMENT :</span><input type="text" /></p>
                </div>

                <div className='col-md-5 col-sm-5 col-5'>
                    <h4 style={{ color: "#fff" }}> Status</h4>
                    <hr className='myhr' />
                    <p><span className='text-white'>GRADE:</span><input type="text" /></p>
                    <p><span className='text-white'>DEPARTMENT:</span><input type="text" /></p>
                    <p><span className='text-white'>DESIGNATION:</span><input type="text" /></p>
                    <p><span className='text-white'>LOCATION:</span><input type="text" /></p>
                    <p><span className='text-white'>CATEGORY:</span><input type="text" /></p>
                    <p><span className='text-white'>SHIFT:</span><input type="text" /></p>
                    <p><span className='text-white'>TIMIMG SHEET:</span><input type="text" /></p>

                </div>
            </div>

            <hr className='myhr' />
            <br /><br />
            <div className='d-flex hrEmployeeform' style={{ justifyContent: "space-between", width: "85%", margin: "auto" }}>
                <div className='col-md-5 col-sm-5 col-5'>
                    <h4 style={{ color: "#fff" }}> Dates</h4>
                    <hr className='myhr' />
                    <p><span className='text-white'>JOINING GROUP :</span><input type="text" /></p>
                    <p><span className='text-white'>CONTACT / JOINING :</span><input type="text" /></p>
                    <p><span className='text-white'>PROBATION :</span><input type="text" /></p>
                    <p><span className='text-white'>CONFIRMATION :</span><input type="text" /></p>
                    <p><span className='text-white'>CONTRACT LEAVING :</span><input type="text" /></p>
                    <p><span className='text-white'>REASON FOR LEAVING :</span><input type="text" /></p>
                    <p><span className='text-white'>NEXT INCREMENT :</span><input type="text" /></p>
                </div>

                <div className='col-md-5 col-sm-5 col-5'>
                    <h4 style={{ color: "#fff" }}> Status</h4>
                    <hr className='myhr' />
                    <p><span className='text-white'>GRADE:</span><input type="text" /></p>
                    <p><span className='text-white'>DEPARTMENT:</span><input type="text" /></p>
                    <p><span className='text-white'>DESIGNATION:</span><input type="text" /></p>
                    <p><span className='text-white'>LOCATION:</span><input type="text" /></p>
                    <p><span className='text-white'>CATEGORY:</span><input type="text" /></p>
                    <p><span className='text-white'>SHIFT:</span><input type="text" /></p>
                    <p><span className='text-white'>TIMIMG SHEET:</span><input type="text" /></p>

                </div>
            </div>

        </>
    )
}

export default HrEmployeForm;