import { Button } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";

function CostsheetForm() {
    const [projectname, setprojectname] = useState("");
    const [projectlogo, setprojectlogo] = useState("");
    const [department, setdepartment] = useState("");
    const [category, setcategory] = useState("");
    const [description, setdescription] = useState("");
    const [maxcount, setmaxcount] = useState("");
    const [price, setprice] = useState("");
    const [ownername, setownername] = useState("");
    const [showMultiSelect, setshowMultiSelect] = useState(false);
    const [openingdate, setopeningdate] = useState("");
    const [alert, setalert] = useState(false);
    const [alertmsg, setalertmsg] = useState("");
    const [tableData, setTableData] = useState([]);
    const [lastdate, setlastdate] = useState("");
    const [updatebtn, setupdatebtn] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [plusbtn, setplusbtn] = useState(false);
    const [plusbtnview, setplusbtnview] = useState("none");
    const [minusbtnview, setminusbtnview] = useState("block");

    const muliSelecetData = () => {
        setshowMultiSelect(true);
    };

    const onHandleChange1 = () => { };


    const addproject =()=>{
      

    }

    return (
        <div>
            <>
                <div className="formbg">
                    <center>
                        <div className="d-flex space">
                            <div className="mb-3">
                                <label className="form-label">Head</label>
                                <input
                                    type="text"
                                    value={projectname}
                                    onChange={(e) => setprojectname(e.target.value)}
                                    className="form-control small"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Loacaiton</label>
                                <input
                                    type="text"
                                    value={projectlogo}
                                    onChange={(e) => setprojectlogo(e.target.value)}
                                    className="form-control small"
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                   
                                    className="form-label"
                                >
                                   Description
                                </label>
                                <select
                                    className="form-select"
                                   
                                    id="dprtName"
                                    name="Department"
                                    value={category}
                                    onChange={(e) => {
                                        setcategory(e);
                                    }}
                                >
                                    <option value=""> --Select Department Name--</option>
                                </select>
                            </div>
                        </div>
                        <div className="d-flex space">
                            <div className="mb-3">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                >
                                    Department Name
                                </label>
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    id="dprtName"
                                    name="Department"
                                    value={department}
                                    onChange={(e) => {
                                        setdepartment(e);
                                    }}
                                >
                                    <option value=""> --Select Department Name--</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Project owner Name</label>
                                <input
                                    type="text"
                                    value={ownername}
                                    onChange={(e) => setownername(e.target.value)}
                                    className="form-control small"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">opening Date</label>
                                <input
                                    type="date"
                                    value={openingdate}
                                    onChange={(e) => setopeningdate(e.target.value)}
                                    className="form-control small"
                                    max="9999-12-31"
                                />
                            </div>
                        </div>
                        <div className="d-flex space">
                            <div className="mb-3">
                                <label className="form-label">Choose Document Type</label>
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    id="docType"
                                    name="docType"
                                    onClick={muliSelecetData}
                                ></select>
                                {showMultiSelect ? (
                                    <div>
                                        <ul className="list-group multiSelectBox">
                                            <li className="list-group-item">
                                                <input
                                                    type="checkbox"
                                                    name="Document"
                                                    className="mx-2"
                                                    id="AadharCard"
                                                    onChange={(e) => onHandleChange1(e)}
                                                    value="AadharCard"
                                                />
                                                Aadhar Card
                                            </li>
                                            <li className="list-group-item">
                                                <input
                                                    type="checkbox"
                                                    name="Document"
                                                    className="mx-2"
                                                    id="PanCard"
                                                    onChange={(e) => onHandleChange1(e)}
                                                    value="PanCard"
                                                />
                                                Pan Card
                                            </li>
                                            <li className="list-group-item">
                                                <input
                                                    type="checkbox"
                                                    name="Document"
                                                    className="mx-2"
                                                    id="VoterId"
                                                    onChange={(e) => onHandleChange1(e)}
                                                    value="VoterId"
                                                />
                                                Voter Id
                                            </li>
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label className="form-label">LAst Date</label>
                                <input
                                    type="date"
                                    value={lastdate}
                                    onChange={(e) => setlastdate(e.target.value)}
                                    className="form-control small"
                                    max="9999-12-31"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Project Description</label>
                                <input
                                    type="text"
                                    value={description}
                                    onChange={(e) => setdescription(e.target.value)}
                                    className="form-control small"
                                />
                            </div>
                        </div>
                        <br />
                        <br />
                        <Button
                            style={{ width: "200px", background: "green" }}
                            variant="contained"
                            onClick={addproject}
                        >
                            ADD PROJECT
                        </Button>
                    </center>
                </div>
            </>
        </div>
    );
}

export default CostsheetForm;
