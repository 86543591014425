import axios from 'axios';
import React, { useEffect, useState } from 'react'

import "react-circular-progressbar/dist/styles.css";
import { Link } from 'react-router-dom';
import { GET_fillingprojects,GET_runningprojects,GET_allprojects,GET_completeprojects,GET_rejectedProjects } from '../../utiles/AllApis';
import DashCards from '../../common/card/DashCards';

function DashDesign() {
    const [isLoading, setLoading] = useState(false);
    const [viewAllPro, setViewAllPro] = useState(true);
    const [running,setrunning] = useState([]);
    const [newPro,setNewPRo] = useState([]);
    const [filingPro,setFilingPro] = useState([]);
    const [rejectPro,setRejectPro] = useState([]);
    const [completedPro,setColpletedPro] = useState([]);
    useEffect(() => {
        axios.get(GET_allprojects).then((response)=>{
            if(response.data.isSuccess===true)
            {
                setNewPRo(response.data.data);
            }
        })
        axios.get(GET_fillingprojects).then((response)=>{
            if(response.data.isSuccess===true)
            {
                setFilingPro(response.data.data);
            }
        })
        axios.get(GET_runningprojects).then((response) => {
            setLoading(false);
          if(response.data.isSuccess===true)
          {
                setrunning(response.data.data);
            }
           });

        axios.get(GET_completeprojects).then((response)=>{
            if(response.data.isSuccess===true)
            {
                setColpletedPro(response.data.data);
            }
        })
        axios.get(GET_rejectedProjects).then((response)=>{
            if(response.data.isSuccess===true)
            {
                setRejectPro(response.data.data);
            }
        })
    }, []);

//-----------create new project
const handelAddNew=()=>
{
    window.location.href="/projectList/form";
}
    return (
        <>
            <div className='dashboard_main'>
            <div className='main_card_box box_1 col-lg-2 col-sm-3 col-md-2 col-6'>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "80%", margin: "auto" }} ><h4 className='mt-3'>New Projects</h4>
                    <button onClick={()=>{handelAddNew()}}>Add</button>
                    </div>
                    <div id="" className='inner'>
                    {
                    newPro?.map((data,i)=>
                        {
                           return( <Link key={i}to={`/projectList/${data?.project_ID}`} style={{ textDecoration: 'none' }}>
                        <DashCards projectName={data.projectName.slice(0,20)} date={data.edate.slice(0,10)}/>
                           </Link>)
                        })
                   } 
                </div>
                </div>
                <div className='main_card_box box_1 col-lg-2 col-sm-3 col-md-2 col-6'>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "80%", margin: "auto" }} ><h4 className='mt-3'>Filing</h4>
                    </div>
                    <div id="" className='inner'>
                    {
                      filingPro?.map((data,i)=>
                        {
                           return( 
                           <Link key={i}to={`/projectList/${data?.project_ID}`}style={{ textDecoration: 'none' }}>
                            <DashCards projectName={data.projectName.slice(0,20)} date={data.edate.slice(0,10)}/>
                           </Link>)
                        })
                   } 
                </div>
                </div>
                <div className='main_card_box box_1 col-lg-2 col-sm-3 col-md-2 col-6'>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "80%", margin: "auto" }} ><h4 className='mt-3'>Running</h4>
                    </div>
                    <div id="" className='inner'>
                    {
                      
                    running?.map((data,i)=>
                        {
                           return( 
                           <Link key={i}to={`/projectList/${data?.project_ID}`}style={{ textDecoration: 'none' }}>
                            <DashCards projectName={data.projectName.slice(0,20)} date={data.edate.slice(0,10)}/>
                           </Link>)
                        })
                   } 
                </div>
                </div>
                <div className='main_card_box box_1 col-lg-2 col-sm-3 col-md-2 col-6'>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "80%", margin: "auto" }} ><h4 className='mt-3'>Completed</h4>
                    </div>
                    <div id="" className='inner'>
                    {
                    completedPro?.map((data,i)=>
                        {
                           return( <Link key={i}to={`/projectList/${data?.project_ID}`} style={{ textDecoration: 'none' }}>
                           <DashCards projectName={data.projectName.slice(0,20)} date={data.edate.slice(0,10)}/>
                           </Link>)
                        })
                   } 
                </div>
                </div>
                <div className='main_card_box box_1 col-lg-2 col-sm-3 col-md-2 col-6'>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "80%", margin: "auto" }} ><h4 className='mt-3'>Rejected</h4>
                    </div>
                    <div id="" className='inner'>
                    {
                    rejectPro?.map((data,i)=>
                        {
                           return( <Link key={i}to={`/projectList/${data?.project_ID}`} style={{ textDecoration: 'none' }}>
                           <DashCards projectName={data.projectName.slice(0,20)} date={data.edate.slice(0,10)}/>
                           </Link>)
                        })
                   } 
                </div>
                </div>
            </div>
        </>
    )
}

export default DashDesign