import React, { useEffect, useState } from 'react';
 import { BsBarChartLine,BsBarChartSteps,BsCalendarEventFill, BsFilterLeft, BsFilterRight,BsBagFill,BsPerson } from "react-icons/bs";
 import RecyclingIcon from '@mui/icons-material/Recycling';
import { NavLink } from 'react-router-dom';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import { useLocation } from 'react-router-dom';
import AttendenceIcon from '@mui/icons-material/CoPresent';
import ProjectsIcon from '@mui/icons-material/AccountTree';
import PersonIcon from '@mui/icons-material/Person';
import LeaveIcon from '@mui/icons-material/HolidayVillage';
import EmergencyIcon from '@mui/icons-material/Bloodtype';
import SchoolIcon from '@mui/icons-material/School';
import TrainigIcon from '@mui/icons-material/DynamicFeed';
import DocIcon from '@mui/icons-material/Summarize';
import HeplIcon from '@mui/icons-material/LiveHelp';
import DashIcon from '@mui/icons-material/SpaceDashboard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PaymentsIcon from '@mui/icons-material/Payments';
import './sidbar.css';
const HrSideBar=(Props)=>{
    const location = useLocation();
    const [burger,setburger] = useState(true)
    const [plus,setplus] = useState("menuListingItems");
    const [minus,setminus] = useState("menuListingItems");

    const HRMenuList = [
        {
            "name": "Dashboard",
            "url": "/employeeprofile/dashboard",
            "icon":<DashIcon/>,
        },
        {
            "name": "Master",
            "url": "/employeeprofile/Master",
            "icon":<BedroomParentIcon/>,
        },
        {
            "name": "My Projects",
            "url": "/employeeprofile/projects",
            "icon":<ProjectsIcon/>,
        },
        {
            "name": "Personal",
            "url": "/employeeprofile/personal",
             "icon":<PersonIcon/>,
        },
        {
            "name": "Time Off",
            "url": "/employeeprofile/timeoff",
            "icon":<LeaveIcon/>,
        },
        {
            "name":"Expense",
            "url":"/expense/travel_expense",
            "icon":<BsBarChartLine />
        },
       
        {
            "name": "Emergency",
            "url": "/employeeprofile/emergency",
            "icon":<EmergencyIcon/>,
        },
        {
            "name": "Qualification",
            "url": "/employeeprofile/qualification",
            "icon":<SchoolIcon/>,
        },
        {
            "name": "Attendance",
            "url": "/employeeprofile/attendance",
            "icon":<AttendenceIcon/>,
        },
        {
            "name": "Salary Slab",
            "url": "/employeeprofile/salarySlab",
            "icon":<MonetizationOnIcon/>,
        },
        {
            "name": "Salary Changes",
            "url": "/employeeprofile/empChanges",
            "icon":<AttendenceIcon/>,
        },
        {
            "name": "Salary Structure",
            "url": "/employeeprofile/salaryStructure",
            "icon":<PaymentsIcon/>,
        },
      
        {
            "name": "Daily Updates",
            "url": "/employeeprofile/training",
            "icon":<TrainigIcon/>,
        },
        {
            "name": "My Documents",
            "url": "/employeeprofile/documents",
            "icon":<DocIcon/>,
        },
        {
            "name": "Monthly Data",
            "url": "/employeeprofile/mnthalyData",
            "icon":<RecyclingIcon/>,
        },
        {
            "name": "Help",
            "url": "/employeeprofile/help",
            "icon":<HeplIcon/>,
        },

    ]
    let progress = document.getElementById("progressbar");
//  let totalheight = document.body.scrollWidth - window.innerWidth;
//  window.onscroll = function () {
//      let progressheight = (window.pageYOffset / totalheight) * 100;
//      progress.style.width = progressheight + "%";
//   }
       useEffect(()=>{
         document.querySelector('.mainSideBar').style.width = '75px';
         document.querySelector('.myview').style.width = '94%';
        var obj = document.getElementsByClassName('leftNavText');
          for (var i = 0; i < obj.length; i++) {
             obj[i].style.display = 'block'
            }
    setburger(false)
    var obj = document.getElementsByClassName('leftNavText')
    for (var i = 0; i < obj.length; i++) {
       obj[i].style.display = 'none'
      }
      },[])
    const handleExpandBurger =()=>{
        
        setburger(false)
        document.querySelector('.mainSideBar').style.width = '75px'

        var dashboardWidth =location?.pathname?.slice(1)
          document.querySelector('.myview').style.width = '94%';
          document.querySelector('.myview').style.left = '6%' 
        document.querySelector('.myview').style.position = 'absolute' 
        
        // if(dashboardWidth === "Dashboard"){
        //  //   document.querySelector('.myview').style.width = '100%'
        //     document.body.style.overflow = "hidden";
        // }
        // else{
        //    // document.querySelector('.myview').style.width = '100%' 
        //     document.body.style.overflow = "scroll";
        // }
        var obj = document.getElementsByClassName('leftNavText')
          for (var i = 0; i < obj.length; i++) {
             obj[i].style.display = 'none'
            }
    }
    const handlereduceBurger =()=>{
        // alert("open")
        setburger(true) 
        document.querySelector('.mainSideBar').style.width = '100%'
        document.querySelector('.myview').style.width = '83%' 
        document.querySelector('.myview').style.left = '17%' 
        document.querySelector('.myview').style.position = 'absolute' 
        document.body.style.overflow = "scroll";
        var obj = document.getElementsByClassName('leftNavText')
          for (var i = 0; i < obj.length; i++) {
             obj[i].style.display = 'block'
            }
    }
return(
  <>
  {/* <div id="progressbar"></div>
    <div id="scrollpath "></div> */}
  <ul className='HRsideBar'>
 {
    burger ? 
    <li  className={plus}>
        <span  style={{marginLeft:"135px",position:"absolute",zIndex:"9999"}} className='px-2 sidebarspan drawrOpen' onClick={handleExpandBurger} ><BsFilterRight /></span>
  </li>
  :
  <li  className={minus}>
  <span  className='px-2 sidebarspan drawrClose' onClick={handlereduceBurger} style={{position:"absolute",zIndex:"9999"}} ><BsFilterLeft /></span>
</li>
 }
    <br />
  {
  HRMenuList?.map((data,i)=>
  {
      return( 
          <li key={i} className=" menuListingItems ">
            
          <NavLink to={`${data.url}`} exact="true" activeclassname="active"  style={{ textDecoration: 'none' }} className="aInFullWidth py-2">
             <span className='px-2 sidebarspan' >{data.icon}  &nbsp;&nbsp;<span className='leftNavText' style={{width:"138px"}}>{data.name}</span></span>
          </NavLink>
          </li>
      )
  })
  }
  </ul>
  </>
)
}
export default HrSideBar;