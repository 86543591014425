import React, { useState } from 'react';
import './TopSection.css';

function TopSection() {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  // Get today's date in the format: YYYY-MM-DD
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      {/* Search Bar */}
      <center> <div className='top-section'>
      <form className="search-form">
          <input type="text" placeholder="Tap here to search..." className="search-input" />
          <select className="dropdown-dr">
            <option value="all">Dashboard</option>
            <option value="category1">Category 1</option>
            <option value="category2">Category 2</option>
            <option value="category3">Category 3</option>
          </select>
          {/* <button type="submit">Submit</button> */}
        </form>
      </div></center>

     {/* Heading and Today's Date Section */}
     <div className="col-md-12">
        <div className="heading-section">
          <h2>WELCOME HR MODULE</h2>
           <p className="today-date"> {getTodayDate()}</p>
        </div>
      </div>
      {/* dropdown section */}
      <select className="ware-drop">
      <option value="all">Warehouse 1</option>
            <option value="category1">Warehouse 2</option>
            <option value="category2">Warehouse 3</option>
            <option value="category3">Warehouse 4</option>
          </select>
      {/* Date Range and Buttons Section */}
      <div className="top-section">
      <div className="date-range-section">
          <label htmlFor="from-date" style={{ color: 'white' }}>
         <p className='paratext'> Date Range </p> 
          </label>
          <input
            type="date"
            id="from-date"
            value={fromDate}
            onChange={handleFromDateChange}
          />      
        
          <label htmlFor="to-date">
          </label>
          <input
            type="date"
            id="to-date"
            value={toDate}
            onChange={handleToDateChange}
          />
         <button className="button">Apply</button>
    

      <div className="buttons-section">
        <button className="button">Button 1</button>
        <button className="button">Button 2</button>
        <button className="button">Button 3</button>
      </div>
     </div> 
    </div>
    <div className="bttnn-section">
        <button className="btnnn">Master</button>
        <button className="btnnn">Item</button>
        <button className="btnnn">Category</button>
      </div> 
    </>
  );
}

export default TopSection;

