import React, { useEffect,useState } from 'react';
import {
    CardGroup,
    Card,
     CardImg,
    CardBody,
    CardTitle,
    CardSubtitle,

} from 'reactstrap';
import { differenceInDays } from 'date-fns'
import { useNavigate } from 'react-router-dom';
import ButtonDelete from '../../common/Buttons/ButtonDelete';
const ProjectCards=(props)=>{
    const navigate =useNavigate();
    //const [days,getDays]=useState();
    const date1 = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()); // the later date
    const date2 = new Date(new Date(props.ldateOfSubmissionBid).getFullYear(),new Date(props.ldateOfSubmissionBid).getMonth(),new Date(props.ldateOfSubmissionBid).getDate()); 
const daysToStart = differenceInDays(date2, date1);
const Expdays = differenceInDays(date1,date2);
    return(
<CardGroup className='crdGrp btn tracking-in-expand-fwd-top'style={{paddingBottom:"12px"}} >
<CardBody
            style={{ height: '75%', overflow: 'hidden', position: 'relative' }}
            onClick={
                props.page === 'itemmaster'
                    ? () => { navigate(`/ItemMaster/${props.rowpos}`); }
                    : props.page === 'DRAFT'
                        ? () => { navigate(`/projectList/updateform?id=${props.rowpos}&state=DRAFT`); }
                        : () => { navigate(`/projectList/${props.rowpos}`, { state: { tokectDetails: props.tokectDetails ,projectName:props.projectName}}); }
            }
        >
      <CardTitle tag="h5" className='tracking-in-expand-fwd-bottom projecthead' style={{color:"red",textTransform:"capitalize"}}>
      {props.projectName}<span style={{color:"#efcc77"}}>{props.Lost!==undefined?props.Lost===true?" (LOST)":null:null}</span> 
      </CardTitle>
      <CardSubtitle
        className={`${props.projectDetails===""?"text-white":"text-danger"} projectDetails tracking-in-expand-fwd-bottom`}
        tag="h6"style={{textTransform:"capitalize"}}
      > {props.projectDetails===""?"(Quick Creation)":props.projectDetails} 
      </CardSubtitle>
      <CardSubtitle  className='blinkText'>
{props.ldateOfSubmissionBid!==undefined?
props.ldateOfSubmissionBid.split("T")[0]==="1990-01-01"?null:
        `${new Date(props.ldateOfSubmissionBid).getFullYear()}-${new Date(props.ldateOfSubmissionBid).getMonth()}-${new Date(props.ldateOfSubmissionBid).getDate()}`===`${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`?`(Tender Expiring Today)`:
        date2>date1?`(Tender Expiring in ${daysToStart} days)`:
        `(Tender Expired)`:null}
        {/*${Expdays} days ago*/}
      </CardSubtitle>
    </CardBody>
    <div className='w-100 text-start'> 
    {
      props.isHRpage===true? "":<span className=''>
      {/* <ButtonDelete btnTitle="Delete" usehtmlFor="project"rowpos={props.rowpos}onDelete={props.onDelete}/> */}
      </span>
    }
      <div className='text-center'>
      <i style={{fontFamily:"italic",fontSize:"12px" ,color:"#efcc77",fontWeight:"800"}}>Created on ( {props.created?.slice(0,10)} )</i>
      </div>
       </div>
</CardGroup>
    )
}
export default ProjectCards;