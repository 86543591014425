import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CREATE_TEAM, GET_Employee, GET_PROJECTBYID, GET_TEAMS_BY_PROJECT_ID,GET_EMP_DATAILS_BY_MCODE } from "../../utiles/AllApis";
import Gridboxevent from "./gridboxevent"

function Itemlistdetails() {
  const { projectName } = useParams();

  const [showallocate,setshowallocate] = useState(false)

  const [offer, setoffers] = useState([]);
  const [dropdown, setdropdown] = useState([]);
  const [allocateDate, setallocateDate] = useState("");
  const [empname, setempname] = useState("");
  const [dataTable,setDataTable]=useState();
  // const [taledata,settabledata] = useState([]);
  const taledata=[];
  const columns = [

    { field: 'firstName', headerName: 'First Name', width: 150   },
    { field: 'lastName', headerName: 'Last Name', width: 150   },
    { field: 'department', headerName: 'Department', width: 150 },
    {
      field: "hiringDate",
      headerName: "Hiring Date",
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
  
        return (
          <p className='text-white mt-2 f-14'>{params?.row?.hiringDate?.split("T")[0]}</p>
        );
      }
    },
    { field: 'mail', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Mobile No.', width: 150 },

  ]

  useEffect(() => {
    var config = {
      method: "post",
      url: GET_PROJECTBYID,
      headers: {
        "Content-Type": "application/json",
      },
      data: { ROWPOS: projectName },
    };

    axios(config)
      .then(function (response) {
   
        // setdata(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
      });

    axios.get(GET_Employee).then((response) => {
      setdropdown(response.data.data);
      
    });
    getTeamMember();
  }, []);
  const handleEmpAllocate = () => {
    var data = JSON.stringify({
      "Project_ID": projectName,
      "EMP_MCODE": empname,
      "Date": allocateDate
    });
    
    var config = {
      method: 'post',
      url: CREATE_TEAM,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      alert(response.data.message);
      if(response.data.isSuccess===true)
      {
        getTeamMember();
      }
      else{
document.getElementById("empAllocateDate").value="";
document.getElementById("empName").value="";
      }
      showALLocate();
   
  //  setallocateDate("")
  //     setempname("")
      
    })
    .catch(function (error) {
      console.log(error);
    });
    

  };

  const hideallocate = ()=>{
    setshowallocate(true)
  }
  const showALLocate =()=>{
    setshowallocate(false)
  }
const getTeamMember=()=>
{
  axios.post(GET_TEAMS_BY_PROJECT_ID,{"Project_ID": projectName}).then((response) => {
    if(response.data.isSuccess===true)
    {
response?.data?.data?.map((data,i)=>
    {
  
axios.post( GET_EMP_DATAILS_BY_MCODE,{"EmployeeID":data.emP_MCODE}).then((response) => {
   
  taledata.push(response?.data?.data[0]);
// settabledata(...taledata,response?.data?.data);
   });
    setDataTable(<DataGrid
rows={taledata}
columns={columns}
getRowId={(row) => row.rowpos}
pageSize={10}
// checkboxSelection
onSelectionModelChange={(ids) => {
  const selectedIDs = new Set(ids);
  const selectedRows = dropdown.filter((row) =>
    selectedIDs.has(row.rowpos),
 
  );

  // setSelectedRows(selectedRows);
  // setfirstname(selectedRows.mcode)

}}
{...dropdown}
/>)
    }
   )
  }
   
  });
}
  return (
    <div className="">
      <center>
       
{
  showallocate ?

  <div className="row py-5">
    <div className="col-md-12 col-sm-12 col-12">
  <h3
    style={{
      color: "#0f533d",
      textTransform: "uppercase",
      fontWeight: "900",
      textAlign:"center",
       
      // marginTop: "-30px",
    }}
    className="mb-2"
  >
    {" "}
    <span style={{paddingLeft:"8%"}}>  ALLOCATE Employee{" "}</span>
   
    <p className="float-end px-4 " onClick={showALLocate}>❌</p>
  </h3>
    </div>
  
    <div className="col-md-4 col-sm-4 col-4"></div>
    <div className="col-md-4 col-sm-4 col-4 py-3 formClass" >
    <div className="my-5">
      <label className="form-label">EMPLOYEE NAME</label> <br />
      <select
        className="w-75"
        aria-label="Default select example"
        style={{ width: "200px" }}
        value={empname}
        id="empName"
        onChange={(e) => setempname(e.target.value)}
      >
        <option>select employee</option>
        {dropdown?.map((emp, i) => (
          <option key={i} value={emp.employeeID}>{emp.firstName}{emp.lastName}</option>
        ))}
      </select>
    </div>
    <div className="my-5">
      <label className="form-label">
        Allocate Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </label>
      <input
        type="date"
        id="empAllocateDate"
        value={allocateDate}
        onChange={(e) => setallocateDate(e.target.value)}
        className="form-control w-75"
        max="9999-12-31"
      />
    </div>
    <div className="my-5">
      <button
        style={{
          borderRadius: 5,
          backgroundColor: "green",
          padding: "6px 20px",
          fontSize: "14px",
          border: "transparent",
          color: "#fff",
          marginTop: "20px",
        }}
        onClick={handleEmpAllocate}
      >
        SUBMIT
      </button>
    </div>
  {/* <div className='table animatingBottom' style={{width:"80%"}}>
{dataTable}

</div> */}
    </div>
  </div>

:
<>


{/* <Gridbox /> */}
<Gridboxevent />
       
        <button onClick={hideallocate}
         style={{
          borderRadius: 5,
          backgroundColor: "green",
          padding: "6px 20px",
          fontSize: "14px",
          border: "transparent",
          color: "#fff",
          marginTop: "20px",
          textTransform:"uppercase"
        }}
        >allocate employee &nbsp;<ion-icon name="arrow-forward-outline"></ion-icon>
         </button>
        <br /><br /><br /><br /><br /><br />
</>
}
      </center>
    </div>
  );
}

export default Itemlistdetails;
