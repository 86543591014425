import React, { useEffect, useState } from 'react'
import { Container, Button, Link } from 'react-floating-action-button';
import { BsPlusLg } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
 const ElementFloatingBtn = (props) => {
     const navigate =useNavigate();
     const tooltip=`Add ${props.usehtmlFor}`;
     
    //  const href=props.href;
    const href="/manageMaster/element_master/form";
    return (
        <div style={{right:'50vw'}}>
        <Container id="elementPoint">
            <Button
                tooltip={tooltip}
                icon="fas fa-plus"
                rotate={false}
                onClick={() => {navigate(href)}} > <BsPlusLg/>
                </Button>
        </Container>
        </div>

    )
}
export default ElementFloatingBtn;