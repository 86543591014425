import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { VscError } from 'react-icons/vsc';
import './salarySlab.css';
import {
  GET_SALARY_SLAB_BY_ROWPOS, ALLOWED_USERTYPE, GET_SALARY_SLAB, CREATE_SALARY_SLAB, UPDATE_SALARY_SLAB,
  DELETE_SALARY_SLAB
} from '../../../../utiles/AllApis';
import ErrorAlertBox from '../../../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../../../common/AlertBox/SuccessAlertBox';
import { Modal, ModalBody } from 'reactstrap';
function SalarySlab(props) {
  const [isLoading, setLoading] = useState(false);
  const [salarySlabdata, setsalarySlabdata] = useState([])
  const [uType, setUType] = useState(props.tokectDetails.logintype);
  const [userID, setUserID] = useState(props.tokectDetails.mcode);
  const [selecedUserID, setSelectedUserID] = useState(uType === ALLOWED_USERTYPE.ADMIN ? "" : props.tokectDetails.mcode);
  const [seletedeData, setseletedeData] = useState({});
  const [isadd, setIsadd] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpencomfirm, setIsOpencomfirm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [rowpos, setRowpos] = useState("");
  const [male, setMale] = useState(false);
  const [femail, setFemale] = useState(false);
  const [nominee, setNominee] = useState(false);
  const [heading, setHeading] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [salaryHead, setSalaryHead] = useState("");
  const [heading1, setHeading1] = useState("");
  const handalOpenErr = () => { setIsOpen(true); }
  const handalOpen1 = () => { setIsOpen1(true); }
  const handalCloseconfim = () => { setIsOpencomfirm(false); }
  const [salarySlabDetails, setSalarySlabDetails] = useState(
    {
      "wef": "",
      "wet": "",
      "salaryFrom": "",
      "salaryTo": "",
      "age": "",
      "percentage": "",
      "amt": "",
      "salaryHead": salaryHead,
    });
  useEffect(() => {
    getDetails();
  }, [])
  const addDetails = () => {
    setIsadd(true);
    setSalarySlabDetails({});
    setMale(false); setIsEdit(false);
    setFemale(false);
    setNominee(false);
  }
  //------------get all relative details--------
  function getDetails() {
    if (uType === ALLOWED_USERTYPE.ADMIN) {
      getAllSalarySlabDtls(selecedUserID);
    }
    else {
      getAllSalarySlabDtls(userID);
    }
  }
  //---------------get all Relative/emergency details--------------
  const getAllSalarySlabDtls = (mcode) => {
    axios.get(GET_SALARY_SLAB).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setsalarySlabdata(response.data.data);
        console.log(response.data.data);
      }
      else {
        setsalarySlabdata([]);
      }

    })
  }
  //---------------get relatives details--------------
  const onHandleChange = (e) => {
    setSalarySlabDetails({ ...salarySlabDetails, [e.target.name]: e.target.value });
  }
  //---------add Emergency relative details---------------
  const handlesubmit = () => {
    if (salaryHead === "" || salarySlabDetails?.wef === "" || salarySlabDetails?.wet === "" || salarySlabDetails?.salaryFrom === "" || salarySlabDetails?.salaryTo === "" || salarySlabDetails?.age === "" || salarySlabDetails?.percentage === "" || salarySlabDetails?.amt === "") {
      setHeading("Failed");
      setErrMsg("Mandatory fields cannot empty");
      handalOpenErr();
    }
    else {
      setLoading(true);
      axios.post(CREATE_SALARY_SLAB, {
        "wef": salarySlabDetails.wef,
        "wet": salarySlabDetails.wet,
        "salaryFrom": salarySlabDetails.wsalaryFromf,
        "salaryTo": salarySlabDetails.salaryTo,
        "age": salarySlabDetails.age,
        "percentage": salarySlabDetails.percentage,
        "amt": salarySlabDetails.amt,
        "salaryHead": salaryHead,
      }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setSalarySlabDetails({});
          setHeading1("Success");
          setsuccessMsg("Salary Slab added successfully");
          handalOpen1();
          setIsadd(false);
          getDetails();
        }
        else {
          setHeading("Failed");
          setErrMsg(response.data.message);
          handalOpenErr();
        }
      })
    }
  }

  //---------update Emergency relative details---------------
  const handleUpdate = () => {
    if (salaryHead === "" || salarySlabDetails?.wef === "" || salarySlabDetails?.wet === "" || salarySlabDetails?.salaryFrom === "" || salarySlabDetails?.salaryTo === "" || salarySlabDetails?.age === "" || salarySlabDetails?.percentage === "" || salarySlabDetails?.amt === "") {
      setHeading("Failed");
      setErrMsg("Mandatory fields cannot empty");
      handalOpenErr();
      return;
    }
    setLoading(true);
    axios.post(UPDATE_SALARY_SLAB, {
      "wef": salarySlabDetails.wef,
      "wet": salarySlabDetails.wet,
      "salaryFrom": salarySlabDetails.wsalaryFromf,
      "salaryTo": salarySlabDetails.salaryTo,
      "age": salarySlabDetails.age,
      "percentage": salarySlabDetails.percentage,
      "amt": salarySlabDetails.amt,
      "salaryHead": salaryHead,
      "ROWPOS": rowpos
    }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setSalaryHead("");
        setHeading1("Success");
        setsuccessMsg("Record update successfully..");
        handalOpen1();
        setIsadd(false);
        getDetails();
        setSalarySlabDetails({});
        setMale(false);
        setFemale(false);
        setNominee(false);
      }
      else {
        setHeading("Failed");
        setErrMsg(response.data.message);
        handalOpenErr();
      }
    })
  }

  //-------------Edit emergency details------------------
  const handelEdit = (id) => {
    // setLoading(true);
    setRowpos(id);
    setIsEdit(true);
    setIsadd(true);
    axios.post(GET_SALARY_SLAB_BY_ROWPOS, { "ROWPOS": id }).then((response) => {
      //setLoading(false);
      if (response.data.isSuccess === true) {
        setSalarySlabDetails(response.data.data);
        setSalaryHead(response.data.data.salaryHead);
      }
    })
  }
  //-------------Delete emergency details------------------
  const handelDelete = (id) => {
    handalCloseconfim();
    setRowpos(id);
    setLoading(true);
    axios.post(DELETE_SALARY_SLAB, { "ROWPOS": id }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {

        getDetails();
      }
      else {
        setHeading("Failed");
        setErrMsg(response.data.message);
        handalOpenErr();
      }
    })
  }
  return (
    <>
      {
        isLoading ? <div className='loaderDiv'>
        </div> : null
      }
      <div className='emp-aboutdetailmaincontent'>
        <div className={'mnusTopMrgn'}>
          <h5 className='text-white fw-bold mt-2 text-center'>Salary Slab</h5>
          <hr className='myhr text-white' />
        </div>
        <div className='row'>
          <div className='col-md-2 col-lg-2 col-sm-2 col-2 mt-1'>
            {
              isadd ? <button className='btn btnSuccess' onClick={e => { setIsadd(false); }}>View List</button>
                :
                <button className='btn btnSuccess' onClick={e => { addDetails(); setSalaryHead(""); }}>Add Details</button>
            }
          </div>
        </div>
        {
          isadd ?
            <div className='mnusTopMrgn'>
              <h5 className='SecoHead text-center'>Add Details</h5>
              <hr className='myhr text-white' />
              <div className='emp_profile_form'>
                <div className='row'>
                  <div className='col-md-4 col-sm-4 col-4 mt-3'>
                  </div>
                  <div className='col-md-4 col-sm-4 col-4 mt-3 text-center'>
                    <label
                      htmlFor="salaryHead"
                      className="form-label fw-normal h6 text-capitalize"
                    >
                      Salary Component <span className='required'>*</span>
                    </label>
                    <select class="form-select mt-2" value={salaryHead}
                      aria-label="Default select example" name="salaryHead" id="salaryHead" onChange={e => { setSalaryHead(e.target.value) }}>
                      <option value="">--Salary Component--</option>
                      <option value="EMP_ESL">Employer ESL</option>
                      <option value="EMP_PF">Employer PF</option>
                      <option value="ESL">ESL</option>
                      <option value="PF">PF</option>
                    </select>
                  </div>
                  {
                    salaryHead !== "" ?
                      <>
                        <div className='col-md-4 col-sm-4 col-4 mt-3'>
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 mt-3'>
                          <label
                            htmlFor="wef"
                            className="form-label fw-normal h6 text-capitalize"
                          >
                            Effective Date<span className='required'>*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control w-75 mt-2"
                            value={salarySlabDetails?.wef?.split("T")[0]}
                            id="wef"
                            name="wef"
                            max="9999-12-31"
                            onChange={(e) => onHandleChange(e)}
                          />
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 mt-3'>
                          <label
                            htmlFor="wet"
                            className="form-label fw-normal h6 text-capitalize"
                          >
                            Valid Upto<span className='required'>*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control w-75 mt-2"
                            value={salarySlabDetails?.wet?.split("T")[0]}
                            id="wet"
                            name="wet"
                            max="9999-12-31"
                            onChange={(e) => onHandleChange(e)}
                          />
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 mt-3'>
                          <label
                            htmlFor="salaryFrom"
                            className="form-label fw-normal h6 text-capitalize"
                          >
                            Salary from<span className='required'>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control w-75 mt-2"
                            id="salaryFrom"
                            name="salaryFrom"
                            value={salarySlabDetails?.salaryFrom}
                            placeholder="Enter Salary From Here..."
                            onChange={(e) => onHandleChange(e)}
                          />
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 mt-3'>
                          <label
                            htmlFor="salaryTo"
                            className="form-label fw-normal h6 text-capitalize"
                          >
                            Salary to<span className='required'>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control w-75 mt-2"
                            id="salaryTo"
                            name="salaryTo"
                            value={salarySlabDetails?.salaryTo}
                            placeholder="Enter Salary To Here..."
                            onChange={(e) => onHandleChange(e)}
                          />
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 mt-3'>
                          <label
                            htmlFor="age"
                            className="form-label fw-normal h6 text-capitalize"
                          >
                            Age<span className='required'>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control w-75 mt-2"
                            id="age"
                            name="age"
                            value={salarySlabDetails?.age}
                            placeholder="Enter Age Here..."
                            onChange={(e) => onHandleChange(e)}
                          />
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 mt-3'>
                          <label
                            htmlFor="percentage"
                            className="form-label fw-normal h6 text-capitalize"
                          >
                            Percentage<span className='required'>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control w-75 mt-2"
                            id="percentage"
                            name="percentage"
                            value={salarySlabDetails?.percentage}
                            placeholder="Enter Percentage Here..."
                            onChange={(e) => onHandleChange(e)}
                          />
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 mt-3'>
                          <label
                            htmlFor="amt"
                            className="form-label fw-normal h6 text-capitalize"
                          >
                            Add Amount<span className='required'>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control w-75 mt-2"
                            id="amt"
                            name="amt"
                            value={salarySlabDetails?.amt}
                            placeholder="Enter Amount Here..."
                            onChange={(e) => onHandleChange(e)}
                          />
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 mt-5 text-center'>
                          {
                            isEdit ?
                              <button className='btn btnSuccess' onClick={handleUpdate} ><span>Update</span></button> :
                              <button className='btn btnSuccess' onClick={handlesubmit} ><span>Submit</span></button>
                          }
                        </div>
                      </>
                      : <p className='text-white mt-4'>-----------Please select Salary Compoent-------------</p>
                  }
                </div>
              </div></div>
            :
            salarySlabdata.length > 0 ?
              <div className='emgncy table-responsive mt-3'>
                <table className="table table-bordered border-secondary ">
                  <thead className='tblHeader'>
                    <tr>
                      <th scope="col text-white">#</th>
                      <th scope="col text-white">Effective From</th>
                      <th scope="col text-white">Upto</th>
                      <th scope="col text-white">Salary From</th>
                      <th scope="col text-white">Salary To</th>
                      <th scope="col text-white">Age</th>
                      <th scope="col text-white">Percentage</th>
                      <th scope="col text-white">Add Amount</th>
                      <th scope="col text-white">Salary Head</th>
                      <th scope="col text-white">Edit</th>
                      <th scope="col text-white">Delete</th>


                    </tr>
                  </thead>
                  <tbody className='tblBdy'>
                    {
                      salarySlabdata.map((data, i) => {
                        return (<tr key={i}>
                          <th scope="row">{i + 1}</th>
                          <td>{data.wef.slice(0, 10)}</td>
                          <td>{data.wet.slice(0, 10)}</td>
                          <td>{data.salaryFrom}</td>
                          <td>{data.salaryTo}</td>
                          <td>{data.age}</td>
                          <td>{data.percentage}</td>
                          <td>{data.amt}</td>
                          <td>{data.salaryHead}</td>
                          <td ><button className='btn btnEdit' onClick={e => { handelEdit(data.rowpos) }}>Edit</button></td>
                          <td ><button className='btn btnDelete' onClick={e => { setRowpos(data.rowpos); setIsOpencomfirm(true); }}>Delete</button></td>
                        </tr>);
                      }
                      )
                    }

                  </tbody>
                </table>
              </div>

              :
              <p className='text-white text-center mt-5'>No Data Found!!</p>
        }

      </div>
      <Modal className="fade show" isOpen={isOpencomfirm} >
        <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
          <p className=' text-center text-danger fw-bold'><VscError className='fw-bolder mx-2 my-2 h2' />Are you sure you want to Delete</p>
          <div>   <Button
            className='btnSuccess mx-2'
            onClick={() => { handelDelete(rowpos) }}
          >
            Yes
          </Button>
            <Button
              className='btnDelete mx-2'
              onClick={handalCloseconfim}
            >
              No
            </Button></div>
        </ModalBody>

      </Modal>
      <ErrorAlertBox isOpen={isOpen} setIsOpen={setIsOpen} heading={heading} errMsg={errMsg} />
      <SuccessAlertBox
        isOpen1={isOpen1}
        setIsOpen1={setIsOpen1}
        heading1={heading1}
        successMsg={successMsg}
      />
    </>
  )
}

export default SalarySlab;