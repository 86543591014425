import React, { useEffect, useState } from 'react'
import { Container, Button, Link } from 'react-floating-action-button';
import { BsPlusLg } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
 const FloatingBtnN = (props) => {
     const navigate =useNavigate();
     const tooltip=`Add ${props.usehtmlFor}`;
     const href=`/manageMaster/type_master/form?bcd=${props.QuerPara}`;
     debugger;
    return (
        <div style={{right:'8vw'}}>
        <Container >
            <Button
                tooltip={tooltip}
                icon="fas fa-plus"
                rotate={false}
                onClick={() => {navigate(href)}} > <BsPlusLg/>
                </Button>
        </Container>
        </div>
    )
}
export default FloatingBtnN;