import React from 'react'

function Notfound() {
  const godashboard =()=>{
    window.location.href = "/dashboard"
  }
  return (
   
   <>
   
   <div className='notfound'>
   <img className='' style={{height:"90vh",width:"100%"}} src="https://cdn.dribbble.com/users/2771385/screenshots/16267270/media/4d6fd1687943c6f90e7cb93111050c65.gif" />     
   {/* <button onClick={godashboard} style={{position:"absolute",top:'45%',left:"45%",padding:"5px 20px",background:"red",border:"2px solid red",color:"#fff",borderRadius:"8px"}}> Go To Dashboard </button> */}
    </div>
   </>
  )
}

export default Notfound;