import React from 'react'
import separator from '../../assets/SEPARATOR.png'
import Setcookie from "./Setcookie"
function HeaderDemo(props) {

const handlebutton =(UserType)=>{
  props.button(false);
 props.setUserDetails({...props.userDetails,"UserType":UserType});
}
  return (
    <div className='headerDemo'>
 <p  className="cursor-pointer" onClick={()=>{handlebutton("TENDERS")}}>TENDERS  <span> <img className='sepratorimage'  style={{ height:"23px", marginTop:"-3px" }} src={separator}/></span></p>
  <p className="cursor-pointer" onClick={()=>{handlebutton("PROJECTS")}}>PROJECTS<span> <img className='sepratorimage'  style={{ height:"23px", marginTop:"-3px" }} src={separator}/></span></p>
  <p className="cursor-pointer" onClick={()=>{handlebutton("OPERATIONS")}}>OPERATIONS<span> <img className='sepratorimage'  style={{ height:"23px", marginTop:"-3px" }} src={separator}/></span></p>
  <p className="cursor-pointer" onClick={()=>{handlebutton("CREATIVE")}}>CREATIVE <span> <img className='sepratorimage'  style={{ height:"23px", marginTop:"-3px" }} src={separator}/></span></p>
  <p className="cursor-pointer" onClick={()=>{handlebutton("ACCOUNTS")}}>ACCOUNTS <span> <img className='sepratorimage'  style={{ height:"23px", marginTop:"-3px" }} src={separator}/></span></p>
  <p className="cursor-pointer" onClick={()=>{handlebutton("PERSONNEL")}}>PERSONNEL <span> <img className='sepratorimage'  style={{ height:"23px", marginTop:"-3px" }} src={separator}/></span></p>
  <p className="cursor-pointer" onClick={()=>{handlebutton("HR")}}>HR <span> <img className='sepratorimage'  style={{ height:"23px", marginTop:"-3px" }} src={separator}/></span></p>
  <p className="cursor-pointer" onClick={()=>{handlebutton("NOTIFICATIONS")}}>NOTIFICATIONS <span> <img className='sepratorimage'  style={{ height:"23px", marginTop:"-3px" }} src={separator}/></span></p>
  <p className="cursor-pointer" onClick={()=>{handlebutton("PRODUCTION")}}>PRODUCTION <span> <img className='sepratorimage'  style={{ height:"24px", marginTop:"-3px" }} src={separator}/></span></p>
  <button color="white" onClick={()=>{handlebutton("ADMIN")}}>Enter</button>  
    </div>
  )
}

export default HeaderDemo
