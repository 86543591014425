import React, { useEffect, useState } from 'react'
import './myProfile.css';
import { InputLabel, TextField } from '@mui/material';
import { GET_EMP_DATAILS_BY_MCODE, UPDATE_EMPLOYEE } from '../../../../utiles/AllApis';
import axios from 'axios';
import { onlynumerical } from "../../../../GenericFunction/GenericFunction";
function MyProfile(props) {
  const [isLoading, setLoading] = useState(false);
  const [submitbtn, setsubmitbtn] = useState(false)
  const [isEdit, setIsEdit] = useState(false);
  const [userCode, setUserCode] = useState("")
  const [phone, setphone] = useState("")
  const [isMarried, setIsMarried] = useState(false);
  const [isUnMarried, setIsUnMarried] = useState(false);
  const [mail, setmail] = useState("")
  const [firstname, setfirstname] = useState("")
  const [lastname, setlastname] = useState("")
  const [department, setdepartment] = useState("")
  const [hiredate, sethiredate] = useState("")
  const [initial, setinitial] = useState("")
  const [qualification, setqualification] = useState("")
  const [married, setmarried] = useState("")
  const [location, setlocation] = useState("")
  const [designation, setdesignation] = useState("");
  const [dob, setdob] = useState("");
  const [userDetails, setUserDetails] = useState(
    {
      "rowpos": "",
      "strInitial": "",
      "EmployeeID": "",
      "firstName": "",
      "lastName": "",
      "strDesignation": "",
      "department": '',
      "strLocation": "",
      "dtDOB": "",
      "hiringDate": "",
      "bMarried": "",
      "strQualification": "",
      "mail": "",
      "phone": "",
      "userType":""
    });
  useEffect(() => {
    let userID = props.tokectDetails.mcode;
    setUserCode(userID);
    //----------function call for get emp details-------------------
    getEmpDetails(userID);
  }, []);
  const  getEmpDetails=(ID)=>
  {
    setLoading(true);
    axios.post(GET_EMP_DATAILS_BY_MCODE, { "EmployeeID": ID }).then((response) => {
      setLoading(false);
      if(response.data.isSuccess===true)
      {
        setUserDetails(response.data.data[0]);
        response.data.data[0].bMarried?
        setIsMarried(true):
        setIsUnMarried(true)
      }
    })
  }
  const editemployee = () => {

    setsubmitbtn(true);
  }

  const onhandleupdate = () => {
    setLoading(true);
    axios.post(UPDATE_EMPLOYEE,userDetails
    ).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        getEmpDetails(userCode);
        setIsEdit(false);
      }
      else {
        alert("something went wrong !")
      }

    })
  }
  //------------GET FORM DETAILS-------------------
  const onHandleChange=(e)=>
  {
    if(e.target.name==="bMarried")
  {
    setIsMarried(true);
    setIsUnMarried(false);
   if(e.target.value==="Married")
   {
    e.target.value=true;
   }
   else if(e.target.value==="Unmarried")
   {
    setIsMarried(false);
    setIsUnMarried(true);
    e.target.value=false;
   }
  }
    setUserDetails({...userDetails,[e.target.name]:e.target.value});

  }
  return (
    <>
     {
    isLoading?  <div className='loaderDiv'>
    </div>:null
  }

  <div className='row'>
  <h5 className='fw-bold text-center text-white'>PERSONAL</h5>
  <hr className='myhr text-white' />
        <h5 className='SecoHead fw-bold text-center'>Basic Information</h5>
    
    
{ isEdit?
<div className='row prsonalFor'> 
<div className='col-md-4 col-sm-4 col-4 mt-3 '>
<label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                 salutation<span className='required'>*</span>
                  </label>
                  <select
                    className="form-select w-50 mt-2"
                    aria-label="Default select example"
                    id="strInitial"
                    value={userDetails.strInitial}
                    name="strInitial"
                    onChange={(e) => onHandleChange(e)}
                  >
                    <option value=""> --Select Salutation--</option>
                    <option value="MR.">MR.</option>
                    <option value="MS.">MS</option>
                    <option value="MRS.">MRS.</option>
                  </select>
   </div>
   <div className='col-md-4 col-sm-4 col-4 mt-3'>
   <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                    First Name<span className='required'>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control w-50 mt-2"
                    id="firstName"
                    name="firstName"
                    value={userDetails.firstName}
                    placeholder="Enter Employee Name Here..."
                    onChange={(e) => onHandleChange(e)}
                  />
   </div>
   <div className='col-md-4 col-sm-4 col-4 mt-3'>
   <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                    Last Name<span className='required'>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control w-50 mt-2"
                    id="lastName"
                    name="lastName"
                    value={ userDetails.lastName}
                    placeholder="Enter Employee Name Here..."
                    onChange={(e) => onHandleChange(e)}
                  />
   </div>
   <div className='col-md-4 col-sm-4 col-4 mt-3'>
   <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                    Employee Email<span className='required'>*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control w-50 mt-2"
                    id="empEmail"
                    name="mail"
                    value={ userDetails.mail}
                    placeholder="Enter Employee Email Here..."
                    onChange={(e) => onHandleChange(e)}
                  />
   </div>
   <div className='col-md-4 col-sm-4 col-4 mt-3'>
   <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                    Department Name<span className='required'>*</span>
                  </label>
                  <select
                    className="form-select w-50 mt-2"
                    aria-label="Default select example"
                    id="dprtName"
                    name="department"
                    value={ userDetails.department}
                    onChange={(e) => onHandleChange(e)}
                  >
                    <option value=""> --Select Department Name--</option>
                    <option value="tenders"> TENDERS</option>
                    <option value="accounts"> ACCOUNTS</option>
                    <option value="creative"> CREATIVE</option>
                    <option value="production"> PRODUCTIONS</option>
                    <option value="HR"> HR</option>
                  </select>
   </div>
   <div className='col-md-4 col-sm-4 col-4 mt-3'>
   <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                    Mobile No.<span className='required'>*</span>
                  </label>
                  <input
                    type="tel"
                    className="form-control w-50 mt-2"
                    maxLength="10"
                    id="phone"
                    value={ userDetails.phone}
                    name="phone"
                    placeholder="Enter Employee Mobile No. Here..."
                    onChange={(e) => onHandleChange(e)}
                  onKeyPress={onlynumerical}
                  />
   </div>
   <div className='col-md-4 col-sm-4 col-4 mt-3'>
   <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                    Hiring Date<span className='required'>*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control w-50 mt-2"
                    name="hiringDate"
                    id="hiringDate"
                    value={ userDetails?.hiringDate?.split("T")[0]}
                    max="9999-12-31"
                    onChange={(e) => onHandleChange(e)}
                    disabled
                  />
   </div>
   <div className='col-md-4 col-sm-4 col-4 mt-3'>
   <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                    Location<span className='required'>*</span>
                  </label>
                  <select
                    className="form-select w-50 mt-2"
                    aria-label="Default select example"
                    id="strLocation"
                    name="strLocation"
                    value={ userDetails.strLocation}
                    onChange={(e) => onHandleChange(e)}
                  >
                    <option value=""> --Select Office Location--</option>
                    <option value="Noida">Noida</option>
                  </select>
   </div>
   <div className='col-md-4 col-sm-4 col-4 mt-3'>
   <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                    Date Of Birth<span className='required'>*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control w-50 mt-2"
                    value={userDetails.dtDOB.split("T")[0]}
                    id="dtDOB"
                    name="dtDOB"
                    max="9999-12-31"
                    onChange={(e) => onHandleChange(e)}
                  />
   </div>
   <div className='col-md-4 col-sm-4 col-4 mt-3'>
   <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"style={{width:"38%"}}
                  >
                  Marital status
                  </label>
                  <div className="form-check ">
  <input className="form-check-input " type="radio" name="bMarried" id="bMarried" value="Married"  
 onChange={(e) => onHandleChange(e)} checked={isMarried}/>
  <label className="form-check-label  text-white h6" htmlFor="exampleRadios1">
    Married
  </label>
</div>
<div className="form-check">
  <input className="form-check-input" type="radio" name="bMarried" id="bMarried2" value="Unmarried"  
onChange={(e) => onHandleChange(e)}checked={isUnMarried} />
  <label className="form-check-label text-white" htmlFor="exampleRadios2">
    Unmarried
  </label>
</div>
   </div>
   <div className='col-md-4 col-sm-4 col-4 mt-3'>
   <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                 Designation<span className='required'>*</span>
                  </label>
                  <select
                    className="form-select w-50 mt-2"
                    aria-label="Default select example"
                    id="strDesignation"
                    name="strDesignation"
                    value={userDetails.strDesignation}
                    onChange={(e) => onHandleChange(e)}
                  >
                    <option value=""> --Select Designation--</option>
                    <option value="Owner/Director">Owner/Director</option>
                    <option value="additional_director">additional director</option>
                    <option value="Manager">Manager</option>
                    <option value="Team">Team</option>
                    <option value="Intern">Intern</option>
                  </select>
   </div>
   <div className='col-md-4 col-sm-4 col-4 mt-3'>
   <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                 Qualification<span className='required'>*</span>
                  </label>
                  <select
                    className="form-select w-50 mt-2"
                    aria-label="Default select example"
                    id="strQualification"
                    name="strQualification"
                    value={userDetails.strQualification}
                    onChange={(e) => onHandleChange(e)}
                  >
                    <option value=""> --Select Qualification--</option>
                    <option value="PHD">PHD</option>
                    <option value="Masters">Masters</option>
                    <option value="Becholers">Becholers</option>
                    <option value="intermediate">intermediate</option>
                    <option value="High_school">High School</option>
                  </select>
   </div>
   <div className='col-md-4 col-sm-4 col-4 mt-3'>
   <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                 USER TYPE<span className='required'>*</span>
                  </label>
                  <select
                    className="form-select w-50 mt-2"
                    aria-label="Default select example"
                    id="userType"
                    value={userDetails.userType}
                    name="userType"
                    onChange={(e) => onHandleChange(e)}
                    disabled
                  >
                    <option value=""> --Select UserType--</option>
                    <option value="ADMIN">ADMIN</option>
                    <option value="OPERATIONS">OPERATION</option>
                    <option value="PRODUCTION">PRODUCTION</option>
                  </select>
   </div>
   <div className='col-md-4 col-sm-4 col-4 mt-4'>
   <button className='btn btnSuccess' onClick={onhandleupdate}><span>UPDATE</span></button>
    </div>

</div>:

          <>
              <div className='col-md-4 col-sm-4 col-4 my-3'>
   <span> <label htmlFor="strInitial" className="text-white h6 ">Full Name : 
   <span className='userDtls'>{userDetails.strInitial} {userDetails.firstName} {userDetails.lastName}</span></label>
        </span>
          </div>
          <div className='col-md-4 col-sm-4 col-4 my-3'>
        <label htmlFor="department" className="text-white h6">Department :</label>
        <span className='userDtls'>{userDetails.department}</span>
          </div>
          <div className='col-md-4 col-sm-4 col-4 my-3'>
        <label htmlFor="phone" className="text-white h6">Phone :</label>
        <span className='userDtls'>{userDetails.phone}</span>
          </div>
          <div className='col-md-4 col-sm-4 col-4 my-3'>
        <label htmlFor="hiringDate" className="text-white h6">Hiring Date :</label>
        <span className='userDtls'>{new Date(userDetails.hiringDate).toLocaleDateString()}</span>
          </div>
          <div className='col-md-4 col-sm-4 col-4 my-3'>
        <label htmlFor="strQualification" className="text-white h6">Qualification :</label>
        <span className='userDtls'>{userDetails.strQualification}</span>
          </div>
          <div className='col-md-4 col-sm-4 col-4 my-3'>
        <label htmlFor="mail" className="text-white h6">Email :</label>
        <span className='userDtls'>{userDetails.mail}</span>
          </div>
          <div className='col-md-4 col-sm-4 col-4 my-3'>
        <label htmlFor="strLocation" className="text-white h6">Location :</label>
        <span className='userDtls'>{userDetails.strLocation}</span>
          </div>
          <div className='col-md-4 col-sm-4 col-4 my-3'>
        <label htmlFor="strDesignation" className="text-white h6">Designation :</label>
        <span className='userDtls'>{userDetails.strDesignation}</span>
          </div>
          <div className='col-md-4 col-sm-4 col-4 my-3'>
        <label htmlFor="bMarried" className="text-white h6">Marital status  :</label>
        <span className='userDtls'>{userDetails.bMarried?"Married":"Unmarried"}</span>
          </div>
          <div className='col-md-4 col-sm-4 col-4 my-3'>
        <label htmlFor="dtDOB" className="text-white h6">D.O.B.  :</label>
        <span className='userDtls'>{new Date(userDetails.dtDOB).toLocaleDateString()}</span>
          </div>
          <div className='col-md-4 col-sm-4 col-4 my-3'>
        <label htmlFor="userType" className="text-white h6">UserType  :</label>
        <span className='userDtls'>{userDetails.userType}</span>
          </div>
          <div className='col-md-4 col-sm-4 col-4 my-3'>
          <button className='btn btnSuccess' onClick={e=>{setIsEdit(true);}}><span>EDIT</span></button>
          </div>
          </>
}
    </div>

    </>
  )
}

export default MyProfile;