import React,{useState} from 'react';
import {Link } from "react-router-dom";
import { Button } from "@mui/material";
import { jsPDF } from "jspdf";
import './details.css';
import {
  Modal,
  ModalBody
  } from 'reactstrap';
  import {TENDER_STATE,UNREJECT_PROJECT,ALLOWED_USERTYPE} from '../../../utiles/AllApis';
  import axios from 'axios';
import ErrorAlertBox from '../../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../../common/AlertBox/SuccessAlertBox';
const ProjectInfo=(props)=>
{
 console.log(props.logintype)
    const [isOpenPrint, setIsOpenPrint] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const unRejectTenderPopUp=()=>
{
  setIsOpen(true);
}
const handalClosePopUp=()=>
{
  setIsOpen(false);
}
    const exportToPDFPopUp=()=>
    {
      setIsOpenPrint(true);
    }
    const exportToPDF=()=>
    {
      const doc = new jsPDF('p','pt','a3');
      doc.setTextColor(255,0,0);
     doc.html(document.querySelector("#content"),{
      callback:function(pdf)
      {
        window.open(doc.output('bloburl'))
        // pdf.save("mtPDf.pdf");
      }
     },"#000");
    
    }
    //----------reject projects-----------------
    const unRejectTender=()=>
{
  setLoading(true);
  axios.post(UNREJECT_PROJECT,{
    "ROWPOs": props.projectName
}).then((response) => {
  setLoading(false);
    if(response.data.isSuccess===true)
    {
      window.location.reload(); 
    }
    else
    {
     handalClosePopUp();
    }
   
  });
  
}
return( 
    <>
        <div className='projectdetails'>
              <div  className="mt-2">
              <p className="text-center h4 fw-bold my-2"><span className="text-white">SYNOPSIS</span></p>
                <h5 className='text-center SecoHead proNameSec d-block pt-2 h3 fw-bold'><span >Event Name : </span>{props?.project?.projectName}<span className='text-white h3'>{props?.project?.lost===true?" (Lost)":null}</span> </h5>
                <h5 className='text-center SecoHead proNameSec d-block pt-2 h3 fw-bold'><span>Project Name : </span>{props?.project?.projectDescription}<span className='text-white h3'>{props?.project?.lost===true?" (Lost)":null}</span> </h5>
          
              </div>
              {
               props?.project?.state===TENDER_STATE.REJECTED?
                ""
                :
                props.tokectDetails.logintype==="ADMIN"?
                 <Link to={`/projectList/updateform?id=${props?.project?.project_ID}`}>
                 <div  style={{color:"#fff",float:"right",background:"rgb(9, 99, 88)",padding:"5px 10px",fontSize:"14px",borderRadius:"6px",cursor:"pointer"}}><ion-icon name="create"></ion-icon>EDIT</div>
                 </Link>:" "
              }

{
               props?.project?.state===TENDER_STATE.REJECTED?
                ""
                :
                props.tokectDetails.logintype==="TENDERS"?
                 <Link to={`/projectList/updateform?id=${props?.project?.project_ID}`}>
                 <div  style={{color:"#fff",float:"right",background:"rgb(9, 99, 88)",padding:"5px 10px",fontSize:"14px",borderRadius:"6px",cursor:"pointer"}}><ion-icon name="create"></ion-icon>EDIT</div>
                 </Link>:" "
              }
              <button className='exptBtn' onClick={exportToPDFPopUp}>Export to PDF</button> 
              <div  className=" pt-4  Project_details row">
              <div className='col-md-1 col-sm-1 col-1'></div>
         <div className='col-md-5 col-sm-5 col-5'  >{/*ref={inputRef}*/}
         <p><span className='tracking-in-expand-fwd-top'>CLIENT NAME :</span>{props?.project?.clientName===""?"--NA--":props?.project?.clientName}</p>
           <p><span className='tracking-in-expand-fwd-bottom'>Event Date :</span>{props?.project?.eventDate==="--NA--"?"TBC":`${props?.project?.eventDate} to ${props?.project?.eventToDate}`}</p>
           {/* <p><span className='tracking-in-expand-fwd-bottom'>Event To Date :</span>{project?.eventToDate==="--NA--"?"--NA--":new Date(props?.project?.eventToDate).toUTCString().split(':', 2).join(':')}</p> */}
                <p><span className='tracking-in-expand-fwd-bottom'>CATEGORY :</span>{props?.project?.category===""?"--NA--":props?.project?.category}</p>
                <p><span className='tracking-in-expand-fwd-bottom'>Project Lead :</span>{props?.project?.ownerName===""?"--NA--":props?.project?.ownerName}</p>
                <p><span className='tracking-in-expand-fwd-top'>PROJECT TYPE :</span>{props?.project?.projectType===""?"--NA--":props?.project?.projectType}</p> 
                <p><span className='tracking-in-expand-fwd-top'>ESTIMATED PROJECT COST :</span>{props?.project?.estimatedProjectCost===""?"--NA--": `${props?.project?.estimatedProjectCost}/-`}</p>  
                <p><span className='tracking-in-expand-fwd-bottom'>EARNEST MONEY DEPOSIT(EMD) :</span>{props?.project?.emd===""?"--NA--":`${props?.project?.emd}/-`}</p>
                <p><span className='tracking-in-expand-fwd-top'>TENDER DOCUMENT FEE :</span>{props?.project?.tenderDocumentFee===""?"--NA--":`${props?.project?.tenderDocumentFee}/-`}</p> 
                <p><span className='tracking-in-expand-fwd-top'>PROCESS OF SUBMISSION OF BID :</span>{props?.project?.processOfSubmissionBid===""?"--NA--":props?.project?.processOfSubmissionBid}</p> 
                <p><span className='tracking-in-expand-fwd-bottom'>DATE OF PRE-BID MEETING:</span>{props?.project?.preBidMeetingDate==="--NA--"?"--NA--":new Date(props?.project?.preBidMeetingDate).toLocaleString("en-IN", { year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit', minute: '2-digit'}).split(':', 2).join(':')}</p>
                <p><span className='tracking-in-expand-fwd-bottom'>LAST DATE OF PRE-BID QUERIES :</span>{props?.project?.lDateOfPreBidQueries==="--NA--"?"TBC":new Date(props?.project?.lDateOfPreBidQueries).toLocaleString("en-IN", { year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit', minute: '2-digit'}).split(':', 2).join(':')}</p>
         </div>
         <div className='col-md-6 col-sm-6 col-6'>
              <p><span className='tracking-in-expand-fwd-bottom'>LAST DATE OF SUBMISSION BIDS :</span>{props?.project?.ldateOfSubmissionBid==="--NA--"?"TBC":new Date(props?.project?.ldateOfSubmissionBid).toLocaleString("en-IN", { year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit', minute: '2-digit'})}</p>
              <p><span className='tracking-in-expand-fwd-bottom'>DATE OF OPENING TECHNICAL BIDS :</span>{props?.project?.technicalBidOpenDate==="--NA--"?"TBC":new Date(props?.project?.technicalBidOpenDate).toLocaleString("en-IN", { year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit', minute: '2-digit'}).split(':', 2).join(':')}</p>
           <p><span className='tracking-in-expand-fwd-bottom'>Date of OPENING Financial Bids :</span>{props?.project?.openFinancialBids==="--NA--"?"TBC":new Date(props?.project?.openFinancialBids).toLocaleString("en-IN", { year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit', minute: '2-digit'}).split(':', 2).join(':')}</p>
              <p><span className='tracking-in-expand-fwd-bottom'>PRESENTATION DATE :</span>{props?.project?.presentationDate==="--NA--"?"TBC":new Date(props?.project?.presentationDate).toLocaleString("en-IN", { year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit', minute: '2-digit'}).split(':', 2).join(':')}</p>
              <p><span className='tracking-in-expand-fwd-bottom'>EVALUATION :</span>{props?.project?.evaluation===""?"--NA--":props?.project?.evaluation}</p>
            <p><span className='tracking-in-expand-fwd-bottom'>CONSORTIUM ALLOWED :</span>{props?.project?.consortionAllowed===true?"YES":"NO"}</p> 
              {/* {props?.project?.remarks!==""? <p><span className='tracking-in-expand-fwd-top'>PROJECT FILING STATUS :</span>{props?.project?.interested=== true?"✔"  :"❌"}</p>:null} */}
           <> <span className='tracking-in-expand-fwd-bottom'>ELIGIBILITY ISSUE(IF ANY) : </span><span className='textTranNone text-white'>{props?.project?.evaluationIssue===""?"--NA--":props?.project?.evaluationIssue.split('\n').map((item,i)=>{return (<span key={i} className='text-white'style={{fontWeight:"400"}}>{item}<br/></span>)})}</span></><br/>
           <> <span className='tracking-in-expand-fwd-bottom'>SCORING ISSUE(IF ANY) : </span><span className='textTranNone text-white'>{props?.project?.scoringIssue===""?"--NA--":props?.project?.scoringIssue.split('\n').map((item,i)=>{return (<span key={i} className='text-white'style={{fontWeight:"400"}}>{item}<br/></span>)})}</span></>
            <p><span className='tracking-in-expand-fwd-bottom'>EVENT LOCATION :</span>{props?.project?.eventLocation===""?"--NA--":props?.project?.eventLocation}</p> 
            
            <> <span className='tracking-in-expand-fwd-bottom'>REMARKS : </span><span className='textTranNone text-white'>{props?.project?.proRemarks===""?"--NA--":props?.project?.proRemarks.split('\n').map((item,i)=>{return (<span key={i} className='text-white'style={{fontWeight:"400"}} >{item}<br/></span>)})}</span></>
           {/* <> <span className='tracking-in-expand-fwd-bottom'>DESCRIPTION : </span><span className='textTranNone text-white'>{props?.project?.projectDescription===""?"--NA--":props?.project?.projectDescription}</span></> */}

       </div>
            </div>

        <Modal size="lg" className="fade show POPbOX"isOpen={isOpenPrint}  >
  <ModalBody  className='text-center tracking-in-expand-fwd-bottom' >
  <p className=" h4 fw-bold my-2"><span className="textMainGreen">PDF Preview</span></p>
    <div id="content"> 
    <p className=" h4 fw-bold my-2"><span className="text-dark">SYNOPSIS</span></p>
             <h5 className='text-center SecoHead proNameSec d-block pt-2 h3 fw-bold'><span >Event Name : </span>{props?.project?.projectName}<span className='text-white h3'>{props?.project?.lost===true?" (Lost)":null}</span> </h5>
                <h5 className='text-center SecoHead proNameSec d-block pt-2 h3 fw-bold'><span>Project Name : </span>{props?.project?.projectDescription}<span className='text-white h3'>{props?.project?.lost===true?" (Lost)":null}</span> </h5>
            <div className=" py-4 Project_detailsPrint row" >
       <div className='col-md-6 col-sm-6 col-6 ext-dark'>
       <p><span className='tracking-in-expand-fwd-top fw-bold'>CLIENT NAME : </span><span className='lettSpacePdf'>{props?.project?.clientName===""?"--NA--":props?.project?.clientName}</span></p>
         <p><span className='tracking-in-expand-fwd-bottom fw-bold'>Event Date : </span><span className='lettSpacePdf'>{props?.project?.eventDate==="--NA--"?"TBC":`${props?.project?.eventDate} to ${props?.project?.eventToDate}`}</span></p>
              <p><span className='tracking-in-expand-fwd-bottom fw-bold'>CATEGORY : </span><span className='lettSpacePdf'>{props?.project?.category===""?"--NA--":props?.project?.category}</span></p>
              <p><span className='tracking-in-expand-fwd-bottom fw-bold'>Project Lead : </span><span className='lettSpacePdf'>{props?.project?.ownerName===""?"--NA--":props?.project?.ownerName}</span></p>
              <p><span className='tracking-in-expand-fwd-top fw-bold'>PROJECT TYPE : </span><span className='lettSpacePdf'>{props?.project?.projectType===""?"--NA--":props?.project?.projectType}</span></p> 
              <p><span className='tracking-in-expand-fwd-top fw-bold '>ESTIMATED PROJECT COST : </span><span className='lettSpacePdf'>{props?.project?.estimatedProjectCost===""?"--NA--": `${props?.project?.estimatedProjectCost} /-`}</span></p>  
              <p><span className='tracking-in-expand-fwd-bottom fw-bold '>EARNEST MONEY DEPOSIT (EMD) : </span><span className='lettSpacePdf'>{props?.project?.emd===""?"--NA--":`${props?.project?.emd} /-`}</span></p>
              <p><span className='tracking-in-expand-fwd-top fw-bold wSpac'>PROCESS OF SUBMISSION OF BID : </span><span className='lettSpacePdf'>{props?.project?.processOfSubmissionBid===""?"--NA--":props?.project?.processOfSubmissionBid}</span></p> 
              <p><span className='tracking-in-expand-fwd-bottom fw-bold wSpac'>DATE OF PRE-BID MEETING : </span ><span className='lettSpacePdf'>{props?.project?.preBidMeetingDate==="--NA--"?"--NA--":new Date(props?.project?.preBidMeetingDate).toLocaleString("en-IN" , { year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit', minute: '2-digit'}).split(':', 2).join(':')}</span></p>
       </div>
       <div className='col-md-6 col-sm-6 col-6'>
              <p><span className='tracking-in-expand-fwd-bottom fw-bold wSpac'>LAST DATE OF PRE-BID QUERIES : </span><span className='lettSpacePdf'>{props?.project?.lDateOfPreBidQueries==="--NA--"?"TBC":new Date(props?.project?.lDateOfPreBidQueries).toLocaleString("en-IN" , { year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit', minute: '2-digit'}).split(':', 2).join(':')}</span></p>
            <p><span className='tracking-in-expand-fwd-bottom fw-bold wSpac'>LAST DATE OF SUBMISSION  BIDS : </span><span className='lettSpacePdf'>{props?.project?.ldateOfSubmissionBid==="--NA--"?"TBC":new Date(props?.project?.ldateOfSubmissionBid).toLocaleString("en-IN" , { year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit', minute: '2-digit'})}</span></p>
            <p><span className='tracking-in-expand-fwd-bottom fw-bold wSpac'>DATE OF  OPENING  TECHNICAL  BIDS : </span><span className='lettSpacePdf'>{props?.project?.technicalBidOpenDate==="--NA--"?"TBC":new Date(props?.project?.technicalBidOpenDate).toLocaleString("en-IN" , { year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit', minute: '2-digit'}).split(':', 2).join(':')}</span></p>
         <p><span className='tracking-in-expand-fwd-bottom fw-bold wSpac'>Date of OPENING Financial  Bids : </span><span className='lettSpacePdf'>{props?.project?.openFinancialBids==="--NA--"?"TBC":new Date(props?.project?.openFinancialBids).toLocaleString("en-IN" , { year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit', minute: '2-digit'}).split(':', 2).join(':')}</span></p>
            <p><span className='tracking-in-expand-fwd-bottom fw-bold wSpac'>PRESENTATION  DATE : </span><span className='lettSpacePdf'>{props?.project?.presentationDate==="--NA--"?"TBC":new Date(props?.project?.presentationDate).toLocaleString("en-IN" , { year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit', minute: '2-digit'}).split(':', 2).join(':')}</span></p>
            <p><span className='tracking-in-expand-fwd-bottom fw-bold'>EVALUATION : </span><span className='lettSpacePdf'>{props?.project?.evaluation===""?"--NA--":props?.project?.evaluation}</span></p>
          <p><span className='tracking-in-expand-fwd-bottom fw-bold wSpac'>CONSORTIUM  ALLOWED : </span><span className='lettSpacePdf'>{props?.project?.consortionAllowed===true?"YES":"NO"}</span></p> 
          <p><span className='tracking-in-expand-fwd-top fw-bold '>TENDER DOCUMENT FEE : </span><span className='lettSpacePdf'>{props?.project?.tenderDocumentFee===""?"--NA--":`${props?.project?.tenderDocumentFee} /-`}</span></p> 
          <p><span className='tracking-in-expand-fwd-bottom fw-bold wSpac'>EVENT LOCATION : </span><span className='lettSpacePdf'>{props?.project?.eventLocation===""?"--NA--":props?.project?.eventLocation}</span></p> 
     </div>
     <div className='col-md-12 col-sm-12 col-12'>
     <p><span className='tracking-in-expand-fwd-bottom fw-bold'>ELIGIBILITY  ISSUE (IF ANY) : </span><span className='lettSpacePdf'>{props?.project?.evaluationIssue===""?"--NA--":props?.project?.evaluationIssue.split('\n').map((item,i)=>{return (<span key={i}>{item}<br/></span>)})}</span></p> 
    </div>
     <div className='col-md-12 col-sm-12 col-12'>
          <p><span className='tracking-in-expand-fwd-bottom fw-bold'>SCORING  ISSUE (IF ANY) : </span><span className='lettSpacePdf'>{props?.project?.scoringIssue===""?"--NA--":props?.project?.scoringIssue.split('\n').map((item,i)=>{return (<span key={i}>{item}<br/></span>)})}</span></p> 
    </div>
     <div className='col-md-12 col-sm-12 col-12'>
          <p> <span className='tracking-in-expand-fwd-bottom fw-bold wSpac'>REMARKS : </span><span className='lettSpacePdf textTranNone'>{props?.project?.proRemarks===""?"--NA--":props?.project?.proRemarks.split('\n').map((item,i)=>{return (<span key={i}>{item}<br/></span>)})}</span></p>
     </div>
   
    

          </div>
    </div>
 
<div>   
  <button
     className='succBtn mx-2'
     onClick={()=>{exportToPDF()}}
    >
     Get PDF
    </button>
    <button
     className='failerBtn'
     onClick={e=>{setIsOpenPrint(false)}}
    >
      Close
    </button></div>
  </ModalBody>
  </Modal>
         </div>
         {/*for unreject model */}
         <Modal className="fade show"isOpen={isOpen} >
  <ModalBody  className='text-center tracking-in-expand-fwd-bottom'>
  <h4 className=' text-center  fw-bold  successColor'>Confirmation</h4>   
<p className=' text-center text-danger fw-bold'>Are you sure You want to unreject this tendor.</p>
<div>   <Button
     className='btnSuccess mx-2'
     onClick={()=>{unRejectTender()}}
    >
      Yes
    </Button>
    <Button
     className='btnDelete mx-2'
     onClick={handalClosePopUp}
    >
      No
    </Button></div>
  </ModalBody>

  </Modal>
         </>)


}
export default ProjectInfo;