import React, { useEffect, useState } from 'react';
import {GET_Employee, CREATE_DOCUMENT, GETALL_PROJECT_EVENT, GET_CLIENT,GET_PRO_CAT,TENDER_STATE,
   GET_DOCUMENT, GET_DOCUMENTBYID, GET_PROJECTBYID, GET_VENDOR, UPDATE_PROJECTV2,ALLOWED_USERTYPE}
    from '../../../utiles/AllApis';
import axios from 'axios';
import ErrorAlertBox from '../../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../../common/AlertBox/SuccessAlertBox';
import SideBar from '../../../common/SideBar/SideBar';
import { DataGrid } from '@mui/x-data-grid';
import { Navigate } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
// import Projectdetails from '../../../components/Projectdetails';
import Icon from '@mui/material/Icon';

const UpdateFile=(props)=>
{
  const navigate = useNavigate()
  const ProjectID = window.location.href.split("&")[0].split("=")[1]
  const rediLink=`/projectList/${ProjectID}`;
  console.log(rediLink);
  const [isLoading, setLoading] = useState(false);
  const column = [
    // { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'DOCUMENT NAME', width: 300 },
    { field: 'toDate', headerName: 'TO DATE', width: 300 },
    { field: 'path', headerName: 'IMAGE', width: 400 },
   
  ]

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0,10);
  

  const [error,setError]=useState("");
  const [docError,detDocError]=useState("");
  const [projectDetails,setProjetcDetails]=useState({
    "ProjectName": "",

    "ProjectType": "",

    "Category": "",
    
    "OwnerName":"",

    "CLIENT_MCODE": "",  // An mcode will be passed here

    "Remarks": "",

    "Won": false,

    "Interested": false,

    "EstimatedProjectCost": 0,

    "TenderDocumentFee": 0,

    "EMD": 0,

    "ProcessOfSubmissionBid": "",

    "PresentationDate": "1990-01-01",

    "OpenFinancialBids":"1990-01-01",

    "DateOfOpeningTecBid":"1990-01-01",

    "Evaluation": "",

    "EvaluationIssue": "",

    "ScoringIssue": "",
    
    "LdateOfPreBidQueries": "1990-01-01",

    "LdateOfSubmissionBid": "",

    "DateOfOpeningTecBid": "1990-01-01", 

    "PreBidMeetingDate":"1990-01-01",

    "eventDate":"1990-01-01",

    "eventToDate":"1990-01-01",

    "EventLocation":"",
    
    "CLIENTNAME":"",

    "RECEIVED_ACCEP_DATE":"1990-01-01",
        
    "RECEIVED_WORKORDER_DATE":"1990-01-01",
        
    "SECURITY_DEPOSIT_DATE":"1990-01-01",
        
    "EMD_RELEASED_DATE":"1990-01-01",

    "STATE":TENDER_STATE.FILING,

    "proRemarks":"",
    "prodesc":""
    
});
const SubmissionBIDList=['ONLINE','OFFLINE'];
const ProjectCategoryList=
['Cultural','Sports',
'Virtual(Digital)','Rallies',
'Conclave & Awards','PM (VVIP Events)',
'International','Opening & Closing',
'Media','Stall Design',
'Projection Mapping','Drone Show',
'Concert','Exhibition',
'Religious','Firework','Empanelment','Other'
]
const setProjectCategoryName=(catVal)=>
{
  const str = catVal.join(',');
  setcategories(catVal);
}
const setSubmissionBIDListName=(BIDVal)=>
{
  const str = BIDVal.join(',');
  setProcessOfBID(BIDVal);
}
const[heading, setHeading]=useState("");
const[showMultiSelect, setShowMultiSelect]=useState(false);
const [showMultiTENDER,setshowMultiTENDER ]= useState(false)
const[errMsg, setErrMsg]=useState("");
const[heading1, setHeading1]=useState("");
const[successMsg, setsuccessMsg]=useState("");
const [isOpen,setIsOpen]=useState(false);
const[cName,setClientName] =useState("");
const[proLeadName,setProLeadName] =useState("");
const [dropdown,setdropdown] = useState([])
const [dropdownclient,setdropdownclient] =useState([])
const [allvendordropdown,setallvendordropdown] =useState([])
const [selectedvendor,setselectedvendor] = useState("")
const [categories,setcategories] =useState([]);
const [BOQdocs,setBOQdocs] =useState("");
// const [erm,setERM] =useState("")
const [multidocs,setmultidocs] = useState("")
const [projectType,setProjecttype] =useState("");
const [won,setWon]=useState(false);
const [lost,setLost]=useState(false);
const [intrested,setIntrested]=useState(false);
const [multiProcessOfSubmissionOfBid,setMultiProcessOfSubmissionOfBid] =useState("");
const [showMultiProcessOfSubmissionOfBid,setshowMultiProcessOfSubmissionOfBid] =useState(false);
const [showdocs,setshowdocs] =useState(false)
const [carouseldata,setcarouseldata] = useState([])
const [doculist,setdoculist ] =useState([]);
const [Emplist,setemplist ] =useState([]);
const [todaysdate,settodaysdate] =useState("")
const [addmorebtn,setaddmorebtn] = useState("addmore")
const [processOfBId,setProcessOfBID]=useState([]);
const[projectID,setprojectId]=useState("");

const [eligibilityfile,seteligibilityfile] = useState("");
const [corrigendumfile,setCorrigendumfile] = useState("");
const [corrigendumfile2,setCorrigendumfile2] = useState("");
const [corrigendumfile3,setCorrigendumfile3] = useState("");
const [corrigendumfile4,setCorrigendumfile4] = useState("");
const [corrigendumfile5,setCorrigendumfile5] = useState("");
const [proCatList,setProCatList]=useState([]);
const [projectmyltidocs,setprojectmyltidocs] =useState("");

const [ownerName, setProOwner]=useState("--Select Project Lead--");
const [evaluation,setEvaluation]=useState("");
const handalOpen=()=>{setIsOpen(true);}
const [isOpen1,setIsOpen1]=useState(false);
const handalOpen1=()=>{setIsOpen1(true);}
var optClient=[];
var optLead=[];
const [clientSeach,setClientSeach] =useState("");
const [leadSeach,setLeadSeach] =useState("");
const [selectedClient,setSelectedClient] =useState({});
const [selectedLead,setSelectedLead] =useState({});
useEffect(()=>{
 setprojectId( window.location.href.split("&")[0].split("=")[1]);

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0,10);
  settodaysdate(date)
axios.post(GET_DOCUMENT).then((response) => {
  setdoculist(response.data.data);
})
      var thisrowpos = window.location.href.split("&")[0].split("=")[1]
      axios.post(GETALL_PROJECT_EVENT,{"ROWPOS": thisrowpos}).then((response)=>{
       if(response.data.data !== null)
       {
        setshowdocs(true)
         setLost(response.data.data[0].lost);
        setaddmorebtn("addmoredisable");
        if(response.data.data[0].category!=="")
        {
          setcategories(response.data.data[0].category.split(","));
        }
   
         setmultidocs(response.data.data[0].document)
         if(response.data.data[0].projectType!=="")
         {
          setProjecttype(response.data.data[0].projectType);
         }
         else{
          setProjecttype("--Select Project Type--");
         }
         setEvaluation(response.data.data[0].evaluation);
         if(response.data.data[0].processOfSubmissionBid!=="")
         {
          setProcessOfBID(response.data.data[0].processOfSubmissionBid.split(","));
         }
       setWon(response.data.data[0].won);
       setIntrested(response.data.data[0].interested);
        setProjetcDetails({"EstimatedProjectCost":response.data.data[0].estimatedProjectCost});
        //document.getElementById("ownerId").value=response.data.data[0].emP_MCODE;
        setSelectedClient({value:response.data.data[0].clienT_MCODE,label:response.data.data[0].clientName});
        // document.getElementById("clientName").value=response.data.data[0].clienT_MCODE;
        document.getElementById("ProjectName").value = response.data.data[0].projectName;
         if(response.data.data[0].category!=="")
         {
          document.getElementsByClassName("Category").value = response.data.data[0].category;
         }
         if(response.data.data[0].consortionAllowed==true)
         {
          document.getElementById("ConsortionAllowedT").checked=true;
          document.getElementById("ConsortionAllowedF").checked=false;

         }
         else
         {
          document.getElementById("ConsortionAllowedT").checked=false;
          document.getElementById("ConsortionAllowedF").checked=true;

         }
         document.getElementById("TenderDocumentFee").value = response.data.data[0].tenderDocumentFee;
         document.getElementById("PresentationDate").value = response.data.data[0].presentationDate.split(':', 2).join(':');
         document.getElementById("PreBidMeetingDate").value = response.data.data[0].preBidMeetingDate.split(':', 2).join(':');
         document.getElementById("EMD").value=response.data.data[0].emd;
         document.getElementById("EvaluationIssue").value = response.data.data[0].evaluationIssue;
         document.getElementById("ScoringIssue").value = response.data.data[0].scoringIssue;
         document.getElementById("LdateOfPreBidQueries").value = response.data.data[0].lDateOfPreBidQueries.split(':', 2).join(':');
         document.getElementById("LdateOfSubmissionBid").value = response.data.data[0].ldateOfSubmissionBid.split(':', 2).join(':');
         document.getElementById("DateOfOpeningTecBid").value = response.data.data[0].technicalBidOpenDate.split(':', 2).join(':');
         document.getElementById("OpenFinancialBids").value = response.data.data[0].openFinancialBids.split(':', 2).join(':');
         document.getElementById("eventDate").value = response.data.data[0].eventDate.split('T')[0];
         document.getElementById("eventToDate").value = response.data.data[0].eventToDate.split('T')[0];
         document.getElementById("EventLocation").value = response.data.data[0].eventLocation;
         document.getElementById("proRemarks").value = response.data.data[0].proRemarks;
         console.log(response.data.data[0])
         if(response.data.data[0].projectDescription!=null){

           document.getElementById("prodesc").value = response.data.data[0].projectDescription;
         }
         setClientName(response.data.data[0].clienT_MCODE);
         setProLeadName(response.data.data[0].emP_MCODE);
          }
          getclient(response.data.data[0].clientName,response.data.data[0].clienT_MCODE);
          getProLead(response.data.data[0].ownerName,response.data.data[0].emP_MCODE);
      })
      getAllCat();
 
    },[])
    //--------------get all category-----------------------
    const getAllCat=()=>
    {
      axios.get(GET_PRO_CAT).then((response) => {
         
        if(response.data.isSuccess===true)
        {
          var arr=[];
          response?.data?.data.map((data,i)=>
          {
            arr.push(data.name);
      
          })
          setProCatList(arr);
      
        }
      
      })
    }
    //--------------get all emplyees------------------------
    const getProLead=(lbl,val)=>
    {
       
      var obj={};
      if(lbl!=="")
      {
        obj={value:val,label:lbl};
       //setProOwner( response.data.data[0].ownerName);
      }
      else
      {
        obj={value:"",label:"--Select Project Lead--"};
      }
      axios.get(GET_Employee).then((response) => {
        if(response.data.isSuccess===true)
        {
         response.data.data.map((data,i)=>
         {
          var fullName=`${data.firstName} ${data.lastName}`
       optLead.push({value: data.employeeID, label: fullName })
       //data.lastName
     })
     setLeadSeach(<Select options={optLead}  defaultValue={obj} onChange={(e)=>onHandleChangeSearch(e,"OwnerName")}/> )
        }
    })
    }
    //--------------get all Clients------------------------
    const getclient=(lbl,val)=>
    {
      var obj={};
      if(lbl!=="")
      {
        obj={value:val,label:lbl};
       //setProOwner( response.data.data[0].ownerName);
      }
      else
      {
        obj={value:"",label:"--Select Client--"};
      }
 
      axios.get(GET_CLIENT).then((response) => {
        if(response.data.isSuccess===true)
        {
          //setdropdownclient(response.data.data);
          response.data.data.map((data,i)=>
          {
            optClient.push({value: data.mcode, label: data.clientName})
          })
          setClientSeach(<Select options={optClient} defaultValue={obj} onChange={(e)=>onHandleChangeSearch(e,"CLIENT_MCODE")}/> )
        }
        })
    }
// create  project 

const onHandleChangeSearch=(e,name)=>
{
if(name==="CLIENT_MCODE")
{
  setClientName(e.value);
}
else if(name==="OwnerName"){
  setProLeadName(e.value);
}
}
const UploadProjEligibleDOCS =async(newproject_ROWPOS)=>{

  var FormData = require("form-data");
  var data = new FormData();
  data.append("File",eligibilityfile );
  data.append("ID", newproject_ROWPOS);
  data.append("SubType", `${newproject_ROWPOS}_EligibilityDocument`);
  data.append("name", `${newproject_ROWPOS}_EligibilityDocument`);
  data.append("Type", "ELIGIBILITY_DOCUMENT");

  var config = {
    method: "post",
    url: CREATE_DOCUMENT,

    data: data,
  };
 
    await axios(config)
    .then(function (response) {   
      setLoading(true);
    //   if(response.data.isSuccess===true)
    //   {
    //    axios.post(CREATE_DOCUMENT,{
     
    //      "ROWPOS": newproject_ROWPOS,
     
    //      "Path": response.data.data.path,

    //      "Type":"ELIGIBILITY_DOCUMENT",

    //      "SubType": `${newproject_ROWPOS}_EligibilityDocument`
     
    //  })
    //    .then(function (response) {
    //     setLoading(false);
    //      if(response.data.isSuccess===false)
    //      {
    //       setHeading("Submission Failed!");
    //       setErrMsg("document Upload fail");
    //       handalOpen();
    //      }
    //    });
    //   }
    })
    .catch(function (error) {
      console.log(error);
    });
   
 
}

const uploadprojectMultipleDocs =async(newproject_ROWPOS)=>{
  var FormData = require("form-data");
  var data = new FormData();
  data.append("File",projectmyltidocs );
  data.append("ID", newproject_ROWPOS);
  data.append("SubType", `${newproject_ROWPOS}_ProjectDocument`);
  data.append("name", `${newproject_ROWPOS}_ProjectDocument`);
  data.append("Type", "PROJECT_DOCUMENT");
  var config = {
    method: "post",
    url: CREATE_DOCUMENT,
    data: data,
  };
  setLoading(true);
    await axios(config)
    .then(function (response) {
      setLoading(false);
    //   if(response.data.isSuccess===true)
    //   {
    //    axios.post(CREATE_DOCUMENT,{
     
    //      "ROWPOS": newproject_ROWPOS,
     
    //      "Path": response.data.data.path,

    //      "Type": "PROJECT_DOCUMENT",

    //      "SubType": `${newproject_ROWPOS}_ProjectDocument`
     
    //  })
    //    .then(function (response) {
    //      if(response.data.isSuccess===false)
    //      {
    //       setHeading("Submission Failed!");
    //       setErrMsg("document Upload fail");
    //       handalOpen();
    //      }
    //    });
    //   }
     
    })
    .catch(function (error) {
      console.log(error);
    });

 
}
const UploadCorrigendumDOCS =async(newproject_ROWPOS,file,name)=>{
  var FormData = require("form-data");
  var data = new FormData();
  data.append("File",file);
  data.append("ID", newproject_ROWPOS);
  data.append("SubType", name);
  data.append("name", name);
  data.append("Type", "CORRIGENDUM_DOCUMENT");
  var config = {
    method: "post",
    url: CREATE_DOCUMENT,
    data: data,
  };
  await axios(config)
  .then(function (response) {
    setLoading(false);
 if(response.data.isSuccess===false)
 {
  setHeading("Submission Failed!");
      setErrMsg("document Upload fail");
      handalOpen();
 }
  })
  .catch(function (error) {
    console.log(error);
  });
}
     //----------uploade BOQ documents-------------
     const UploadBOQDOCS =async(newproject_ROWPOS)=>{
      var FormData = require("form-data");
      var data = new FormData();
      data.append("File",BOQdocs );
      data.append("ID", newproject_ROWPOS);
      data.append("SubType", `${newproject_ROWPOS}_BOQ_Documents`);
      data.append("name", `${newproject_ROWPOS}_BOQ_Documents`);
      data.append("Type", "BOQ_DOCUMENT");
      var config = {
        method: "post",
        url: CREATE_DOCUMENT,
        data: data,
      };
      await axios(config)
        .then(function (response) {
        })
        .catch(function (error) {
          console.log(error);
        });
    }
const updateproject =async (proState)=>{
    var thisrowpos = window.location.href.split("&")[0].split("=")[1];
    if(props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN)
    {
      if(document.getElementById("ProjectName").value===""||proLeadName===""||document.getElementById("Category").value===""
      ||cName===""||document.getElementById("ProjectType").value===""
      ||document.getElementById("Evaluation").value==="")
      {
      setHeading("Submission Failed!");
       setErrMsg("Fields Cannot Empty");
       handalOpen();
       return;
      }
    }
    else{
      if(document.getElementById("ProjectName").value==="")
      {
      setHeading("Submission Failed!");
       setErrMsg("Fields Cannot Empty");
       handalOpen();
       return;
      }
    }
      if(document.getElementById("LdateOfSubmissionBid").value==="")
      {
        setHeading("Submission Failed!");
        setErrMsg("Date and Time both mandatory in date-time field");
        handalOpen();
      }
      setLoading(true);
      if(eligibilityfile!=="")
      {
        await UploadProjEligibleDOCS(thisrowpos);
      }
      if(projectmyltidocs!=="")
      {
        await uploadprojectMultipleDocs(thisrowpos);
      }
      if(corrigendumfile!=="")
      {
        await UploadCorrigendumDOCS(thisrowpos,corrigendumfile,`${thisrowpos}_CORRIGENDUM_DOCUMENT1`);
      }
      if(corrigendumfile2!=="")
      {
        await UploadCorrigendumDOCS(thisrowpos,corrigendumfile2,`${thisrowpos}_CORRIGENDUM_DOCUMENT2`);
      }
      if(corrigendumfile3!=="")
      {
        await UploadCorrigendumDOCS(thisrowpos,corrigendumfile3,`${thisrowpos}_CORRIGENDUM_DOCUMENT3`);
      }
      if(corrigendumfile4!=="")
      {
        await UploadCorrigendumDOCS(thisrowpos,corrigendumfile4,`${thisrowpos}_CORRIGENDUM_DOCUMENT4`);
      }
      if(corrigendumfile5!=="")
      {
        await UploadCorrigendumDOCS(thisrowpos,corrigendumfile5,`${thisrowpos}_CORRIGENDUM_DOCUMENT5`);
      }
      if(BOQdocs!=="")
      {
        await UploadBOQDOCS(thisrowpos,BOQdocs,`${thisrowpos}_BOQ_DOCUMENT5`);
      } 
      var ca;
     if(document.getElementById("ConsortionAllowedT").checked==true)
     {
       ca=true;
     }
     else{
      ca=false;
     }
     if(document.getElementById("eventDate").value!=="1990-01-01")
     {
       if(document.getElementById("eventDate").value>document.getElementById("eventToDate").value)
       {
         setHeading("Submission Failed!");
         setErrMsg("Event starting data cannot greater then event end date");
         handalOpen();
         return;
       }
     }
      var data = JSON.stringify({
        "ROWPOS": thisrowpos,
        "ProjectName": document.getElementById("ProjectName").value,
        "Category": categories.toString(),
        "OwnerName":proLeadName,
        "ProjectType": document.getElementById("ProjectType").value,
        "CLIENT_MCODE": cName,
        "TenderDocumentFee":document.getElementById("TenderDocumentFee").value,
        "ProcessOfSubmissionBid":processOfBId.toString(),
        "EMD": document.getElementById("EMD").value ,
        "PresentationDate":  document.getElementById("PresentationDate").value,
        "PreBidMeetingDate":  document.getElementById("PreBidMeetingDate").value,
        "Evaluation": document.getElementById("Evaluation").value,
        "EvaluationIssue":  document.getElementById("EvaluationIssue").value,
        "ScoringIssue":  document.getElementById("ScoringIssue").value,
        "EstimatedProjectCost": document.getElementById("EstimatedProjectCost").value,
        "LDateOfPreBidQueries": document.getElementById("LdateOfPreBidQueries").value,
        "LdateOfSubmissionBid": document.getElementById("LdateOfSubmissionBid").value,
        "TechnicalBidOpenDate": document.getElementById("DateOfOpeningTecBid").value,
        "eventDate": document.getElementById("eventDate").value,
        "eventToDate": document.getElementById("eventToDate").value,
        "openFinancialBids": document.getElementById("OpenFinancialBids").value,
        "eventLocation": document.getElementById("EventLocation").value,
        "ConsortionAllowed": ca,
        "Won": won,
        "Lost":lost,
        "Interested":intrested,
        "state":proState,
        "proRemarks":document.getElementById("proRemarks").value,
        "ProjectDescription":document.getElementById("prodesc").value
      });
      var config = {
        method: 'post',
        url: UPDATE_PROJECTV2,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
      .then(async function (response) {
        setLoading(false);
      if(response.data.isSuccess===true)
      {
        setHeading1("Success");
        setsuccessMsg("Project Update Successfully");
        handalOpen1();
      }

      })
      .catch(function (error) {
        console.log(error);
      });
    }
const handleBOQDocs = (e)=>{
  var files = e.target.files[0];
  if(files.name.split(".")[files.name.split(".").length-1].toUpperCase()!=="CSV" && files.name.split(".")[files.name.split(".").length-1].toUpperCase()!=="XLSX" && files.name.split(".")[files.name.split(".").length-1].toUpperCase()!=="XLS"&& files.name.split(".")[files.name.split(".").length-1].toUpperCase()!=="ODS")
  {
    setHeading("Submission Failed!");
    setErrMsg("BOQ Document should only Excel file");
    handalOpen();
   document.getElementById("BOQdocs").value="";
  }
  else
  {
    setBOQdocs(files);
  }
}
  const onHandleChange=(e)=>
  {
     
    let projectLogo=e.target.value.split('.');
    if(e.target.name==="Logo")
    {
      if(projectLogo[projectLogo.length-1]!=="png")
      {
        setError("* Project docs must be in png file");
        e.target.value="";
      }
      else{
        setError("")
      }
    }
    setProjetcDetails({...projectDetails,[e.target.name]:e.target.value})
  }
  // console.log(projectDetails.vendor)

const handleshow =()=>{
//   setadditionals(true)
//   if(additionals === true){
//     setadditionals(false)
//   }
}


const handleELigibilty = (e) =>{
  var file = e.target.files[0]
  if(file.name.split(".")[file.name.split(".").length-1]!=="pdf")
  {
    setHeading("Submission Failed!");
    setErrMsg("Only PDF files are allowed");
    handalOpen();
  }
  else{
    if(file.size>31457280)
    {
      setHeading("Submission Failed!");
      setErrMsg("Maximum 30 MB File Allowed");
      handalOpen();
    }
    else
    {
      seteligibilityfile(file);
    }
    
  }
  
}
const handleCorrigendum = (e) =>{ 

  var file = e.target.files[0]
  // setCorrigendumfile(file)
  if(file.name.split(".")[file.name.split(".").length-1]!=="jpg" &&file.name.split(".")[file.name.split(".").length-1]!=="pdf"&&file.name.split(".")[file.name.split(".").length-1]!=="jpeg"&&file.name.split(".")[file.name.split(".").length-1]!=="png")
  {
    setHeading("Submission Failed!");
    setErrMsg("Only PDF files are allowed");
    handalOpen();
  }
  else if(file.name.split(".")[file.name.split(".").length-1]==="jpg" ||file.name.split(".")[file.name.split(".").length-1]==="jpeg"||file.name.split(".")[file.name.split(".").length-1]==="png"||file.name.split(".")[file.name.split(".").length-1]==="pdf")
  {
    if(file.size>31457280)
    {
      setHeading("Submission Failed!");
      setErrMsg("Maximum 30 MB File Allowed");
      handalOpen();
    }
    else{
      if(e.target.id=="corrigendumdoc1")
      {
       setCorrigendumfile(file);
      }
      else if(e.target.id=="corrigendumdoc2")
      {
       setCorrigendumfile2(file);
      }
      else if(e.target.id=="corrigendumdoc3")
      {
       setCorrigendumfile3(file);
      }
      else if(e.target.id=="corrigendumdoc4")
      {
       setCorrigendumfile4(file);
      }
      else if(e.target.id=="corrigendumdoc5")
      {
       setCorrigendumfile5(file);
      }
    }
  }
}
const handleprojectmultiDocs = (e)=>{
   
  var files = e.target.files[0];
  console.log(files.name.split(".")[1]);
  if(files.name.split(".")[files.name.split(".").length-1]!=="jpg" &&files.name.split(".")[files.name.split(".").length-1]!=="pdf"&&files.name.split(".")[files.name.split(".").length-1]!=="jpeg"&&files.name.split(".")[files.name.split(".").length-1]!=="png")
  {
    setHeading("Submission Failed!");
    setErrMsg("Only PDF files are allowed");
    handalOpen();
  }
  else if(files.name.split(".")[files.name.split(".").length-1]==="jpg" ||files.name.split(".")[files.name.split(".").length-1]==="jpeg"||files.name.split(".")[files.name.split(".").length-1]==="png"||files.name.split(".")[files.name.split(".").length-1]==="pdf")
  {
    if(files.size>104900000)
    {
      setHeading("Submission Failed!");
      setErrMsg("Maximum 50 MB file allowed");
      handalOpen();
    }
    else{
      setprojectmyltidocs(files);
    }
  }
}
    return(
        <>
<div className='row'>
{
    isLoading?  <div className='loaderDiv'>
    </div>:null
  }
        {
              props.tokectDetails?.logintype !== ALLOWED_USERTYPE.OPERATIONS?
              <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
                 <SideBar/>
              </div>:null
              }
    <div className='col-md-10 col-12 col-sm-12 formBg myview formbg'style={{width:"100%",margin:"auto"}}>
        <h4 className='py-3 text-center theamColor'>Update Project</h4>
        <h6 className='text-danger text-center'>* Please fill date and time both in date-time field</h6>
        <div className='createForm'>
        <div className={addmorebtn} onClick={handleshow}>ADD MORE<ion-icon name="caret-down-outline"></ion-icon></div>
       

  <div className='d-flexed' style={{justifyContent:"space-between"}}>
  <div className="mb-3"> 
  <label htmlFor="exampleFormControlInput1" className="form-label">Event Name <span className='required'>*</span></label>
  <input type="text" className="form-control mt-3" id="ProjectName" name="ProjectName" placeholder="Enter Project Name Here.."onChange={(e)=>onHandleChange(e)}/>

</div>
<div className="mb-3">
  <label htmlFor="floatingTextarea2"className="form-label">Project Name</label>
  <textarea className="form-control mt-3"  name="prodesc"id="prodesc" placeholder="Enter Description Here..." onChange={(e)=>onHandleChange(e)}></textarea>
  </div>
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">Project documents 
  { props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN?<span className='required'>*</span>:""}
  </label>
  <input type="file" className="form-control mt-3" id="projectdocs" name="projectdocs" onChange={(e)=>handleprojectmultiDocs(e)} multiple/>
  <span className='text-danger py-1 fw-bold'>{error}</span>
</div>

  </div>
<div className='d-flexed' style={{justifyContent:"space-between"}}>
<div className="mb-3">
<Icon  style={{right:"65%",top:"45%",position:"absolute",cursor:"pointer",color:"#00a859"}}onClick={e=>{
  window.location.href=`/manageMaster/client_master/form?FormProject`}}>add_circle</Icon>
  <label htmlFor="exampleFormControlInput1" className="form-label mt-3">Client Name 
  { props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN?<span className='required'>*</span>:""}
  </label>
{clientSeach}

</div>
<div className="mb-3">
<Icon  style={{left:"85%",position:"relative",cursor:"pointer",color:"#00a859"}}onClick={e=>{window.location.href=`/manageMaster/cat_master/form?FormProject`}}>add_circle</Icon>
  <label htmlFor="exampleFormControlInput1"  className="form-label">Project Category
  { props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN?<span className='required'>*</span>:""}
  </label>
  <Multiselect
       id="Category"
       className='mt-3'
       selectedValues={categories}
       placeholder="--Select Project Category--"
        options={proCatList}
          isObject={false}
        showCheckbox={true}
         onSelect={(event)=>{
          setProjectCategoryName(event);
         }}
         onRemove={(event)=>{
          setProjectCategoryName(event);
         }}
    
      />
</div>

<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">Project Lead
  { props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN?<span className='required'>*</span>:""}
  </label>
  {leadSeach}
</div>

</div>
<div className='d-flexed' style={{justifyContent:"space-between"}}>
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">PROJECT TYPE 
  { props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN?<span className='required'>*</span>:""}
  </label>
  <select className="form-select mt-3" aria-label="Default select example" id="ProjectType" name="ProjectType"onChange={(e)=>onHandleChange(e)}>
  {/* <option value={multiprojecttype}>{multiprojecttype}</option> */}
  <option value={projectType}>{projectType}</option>
  <option value="OPEN">OPEN</option>
  <option value="LIMITED">LIMITED</option>
  <option value="EMPANELMENT">EMPANELMENT</option>
  </select> 
</div> 
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">ELIGIBILITY DOCUMENT
  { props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN?<span className='required'>*</span>:""}
  </label>
  <input type="file" className="form-control mt-3" id="ProjectEligebilitydoc" name="ProjectEligebilitydoc"  onChange={(e)=>handleELigibilty(e)} />
  <span className='text-danger py-1 fw-bold'>{error}</span>
</div>
<div className="mb-3">
    <label htmlFor="exampleFormControlInput1" className="form-label">Last Date of Submission bids
    { props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN?<span className='required'>*</span>:""}
    </label>
    <input type="datetime-local" className="form-control mt-3" name="LdateOfSubmissionBid"id="LdateOfSubmissionBid"onChange={(e)=>onHandleChange(e)}  defaultValue={todaysdate} max="9999-12-31T59:59:59"/>
  </div>
  
  </div>
   <div className='d-flexed' style={{justifyContent:"space-between"}}>
   <div className="mb-3">
    <label htmlFor="exampleFormControlInput1" className="form-label">Evaluation
    { props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN?<span className='required'>*</span>:""}
    </label>  
   <br/>
 <select  className="form-control mt-3" id="Evaluation"  name="Evaluation"onChange={(e)=>onHandleChange(e)}>
 <option value={evaluation}>{evaluation}</option>
        <option value="QCBS (80:20)">QCBS (80:20)</option>
        <option value="QCBS (70:30)">QCBS (70:30)</option>
        <option value="QCBS (60:40)">QCBS (60:40)</option>
        <option value="QCBS (50:50)">QCBS (50:50)</option>
        <option value="QCBS (40:60)">QCBS (40:60)</option>
        <option value="QCBS (30:70)">QCBS (30:70)</option>
        <option value="LCBS">LCBS</option>
        <option value="EOI/Empanelment">EOI/Empanelment</option>
        <option value="L1">L1</option>
    </select>
  </div>
    <div className="mb-3">
<label htmlFor="exampleFormControlInput1" className="form-label">Estimated Project Cost</label>
<input type="number" className="form-control mt-3" id="EstimatedProjectCost" name="EstimatedProjectCost"onChange={(e)=>onHandleChange(e)} value={projectDetails.EstimatedProjectCost}/>
</div>

<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">Earnest Money Deposit</label>
  <input type="number" className="form-control mt-3" name="EMD"id="EMD"onChange={(e)=>onHandleChange(e)} defaultValue={0}/>
 </div> 
 
  </div>
  <div className='d-flexed' style={{justifyContent:"space-between"}}>
  <div className="mb-3">
    <label htmlFor="exampleFormControlInput1" className="form-label">Tender Document Fee </label>
    <input type="number" className="form-control mt-3" name="TenderDocumentFee"id="TenderDocumentFee"onChange={(e)=>onHandleChange(e)} defaultValue={0}/>
  </div> 
  <div className="mb-3">
    <label htmlFor="exampleFormControlInput1" className="form-label">Process Of Submission of Bid</label>
    <Multiselect
       id="ProcessOfSubmissionBid"
       selectedValues={processOfBId}
       className='mt-3'
       placeholder="--Select Process Of Submission of Bid--"
        options={SubmissionBIDList}
          isObject={false}
        showCheckbox={true}
         onSelect={(event)=>{
          setSubmissionBIDListName(event);
         }}
         onRemove={(event)=>{
          setSubmissionBIDListName(event);
         }}
    
      />
  </div>  
  <div className="mb-3">
  <label htmlFor="floatingTextarea2"className="form-label">Last Date of Pre-bid Queries</label>
  <input type="datetime-local" className="form-control mt-3" id="LdateOfPreBidQueries" name="LdateOfPreBidQueries"onChange={(e)=>onHandleChange(e)} defaultValue={todaysdate} max="9999-12-31T59:59:59"/>
  
  </div>
  
  </div>
  <div className='d-flexed' style={{justifyContent:"space-between"}}>
  <div className="mb-3">
    <label htmlFor="exampleFormControlInput1" className="form-label">Date Of Opening Technical bids</label>
    <input type="datetime-local" className="form-control mt-3" id="DateOfOpeningTecBid"name="DateOfOpeningTecBid" onChange={(e)=>onHandleChange(e)} defaultValue={todaysdate} max="9999-12-31T59:59:59"/>
  </div> 
  <div className="mb-3">
  <label htmlFor="floatingTextarea2"className="form-label">Presentation Date</label>
  <input type="datetime-local" className="form-control mt-3" id="PresentationDate" name="PresentationDate" onChange={(e)=>onHandleChange(e)} defaultValue={todaysdate} max="9999-12-31T59:59:59"/>
  </div>
  <div className="mb-3">
  <label htmlFor="floatingTextarea2"className="form-label">Date of Pre-Bid Meeting</label>
  <input type="datetime-local" className="form-control mt-3" id="PreBidMeetingDate" name="PreBidMeetingDate" onChange={(e)=>onHandleChange(e)} defaultValue={todaysdate}max="9999-12-31T59:59:59" />
  </div>
 
  </div>
  
  <div className='d-flexed' style={{justifyContent:"space-between"}}>
  <div className="mb-3">
  <label htmlFor="floatingTextarea2"className="form-label">Date Of Opening Finacial bids</label>
  <input type="datetime-local" className="form-control mt-3" id="OpenFinancialBids" name="OpenFinancialBids" onChange={(e)=>onHandleChange(e)} defaultValue={todaysdate}max="9999-12-31T59:59:59" />
  </div> 
  <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">CORRIGENDUM DOCUMENT 1</label>
  <input type="file" className="form-control mt-3" id="corrigendumdoc1" name="corrigendumdoc"  onChange={(e)=>handleCorrigendum(e)} />
  <span className='text-danger py-1 fw-bold'>{error}</span>
    </div>
    <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">CORRIGENDUM DOCUMENT 2 </label>
  <input type="file" className="form-control mt-3" id="corrigendumdoc2" name="corrigendumdoc"  onChange={(e)=>handleCorrigendum(e)} />
  <span className='text-danger py-1 fw-bold'>{error}</span>
</div> 

</div>
<div className='d-flexed' style={{justifyContent:"space-between"}}>
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">CORRIGENDUM DOCUMENT 3 </label>
  <input type="file" className="form-control mt-3" id="corrigendumdoc3" name="corrigendumdoc"  onChange={(e)=>handleCorrigendum(e)} />
  <span className='text-danger py-1 fw-bold'>{error}</span>
</div> 
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">CORRIGENDUM DOCUMENT 4 </label>
  <input type="file" className="form-control mt-3" id="corrigendumdoc4" name="corrigendumdoc"  onChange={(e)=>handleCorrigendum(e)} />
  <span className='text-danger py-1 fw-bold'>{error}</span>
</div> 
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">CORRIGENDUM DOCUMENT 5 </label>
  <input type="file" className="form-control mt-3" id="corrigendumdoc5" name="corrigendumdoc"  onChange={(e)=>handleCorrigendum(e)} />
  <span className='text-danger py-1 fw-bold'>{error}</span>
</div> 

</div>
  <div className='d-flexed' style={{justifyContent:"space-between"}}>
  <div className="mb-3">
  <label htmlFor="floatingTextarea2"className="form-label d-block">Event fORM and To Date</label>
  <input type="date" className="form-control mt-3 w-50 d-inline px-0" id="eventDate"name="eventDate" onChange={(e)=>onHandleChange(e)}  max="9999-12-31T11:59:59"/>
  <input type="date" className="form-control mt-3 w-50 d-inline px-0" id="eventToDate" name="eventToDate" onChange={(e)=>onHandleChange(e)}  max="9999-12-31T11:59:59"/>
 
  </div>
  <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">CONSORTIUM ALLOWED</label>
                  <div className="form-check  mt-3">
  <input className="form-check-input mt-1" type="radio" name="ConsortionAllowed" id="ConsortionAllowedT" value={true}  onClick={(e)=>onHandleChange(e)}/>
  <label className="form-check-label mt-3 text-white" htmlFor="exampleRadios1">
    Yes
  </label>
</div>
<div className="form-check  mt-3">
  <input className="form-check-input mt-1" type="radio" name="ConsortionAllowed" id="ConsortionAllowedF" value={false} onClick={(e)=>onHandleChange(e)}/>
  <label className="form-check-label mt-3 text-white" htmlFor="exampleRadios2">
    No
  </label>
</div>
                </div>
  <div className="mb-3">
    <label htmlFor="exampleFormControlInput1" className="form-label">Eligibility Issue(If any)</label>
    <textarea className="form-control mt-3" name="EvaluationIssue"id="EvaluationIssue" placeholder="Enter Eligibility Issue Here..." onChange={(e)=>onHandleChange(e)}></textarea>
    {/* <input type="datetime-local" className="form-control" name="EvalutionIssue"id="EvalutionIssue"onChange={(e)=>onHandleChange(e)} defaultValue={todaysdate} max="9999-12-31T59:59:59"/> */}
  </div> 
  
  </div>
  <div className='d-flexed' style={{justifyContent:"space-between"}}>
  <div className="mb-3">
  <label htmlFor="floatingTextarea2"className="form-label">Scoring Issue(If any)</label>
  <textarea className="form-control mt-3"  name="ScoringIssue"id="ScoringIssue" placeholder="Enter Scoring Issue Here..." onChange={(e)=>onHandleChange(e)}></textarea>
  </div>
     <div className="mb-3">
  <label htmlFor="floatingTextarea2"className="form-label">Event Location</label>
  <textarea className="form-control mt-3"  name="EventLocation"id="EventLocation" placeholder="Enter Event Location Here..." onChange={(e)=>onHandleChange(e)}></textarea>
 
  </div>
  <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">BOQ Documents</label>
  <input type="file" className="form-control mt-3" id="BOQdocs" name="BOQdocs" onChange={(e)=>handleBOQDocs(e)} />
</div>

  </div>
  <div className='d-flexed' style={{justifyContent:"space-between"}}>
  <div className="mb-3">
  <label htmlFor="floatingTextarea2"className="form-label">Remarks</label>
  <textarea className="form-control mt-3"  name="proRemarks"id="proRemarks" placeholder="Enter Remarks Here..." onChange={(e)=>onHandleChange(e)}></textarea>
  </div>
 </div>
<center>
  {
    window.location.href.split("&").length===2?
    window.location.href.split("&")[1].split("=")[1]==="DRAFT"? <button className='btn btnPrimary mx-1' onClick={e=>{updateproject(TENDER_STATE.NEW)}}>SUBMIT</button>:
     <button className='btn btnPrimary mx-1' onClick={e=>{updateproject("")}}>UPDATE </button>
     :<button className='btn btnPrimary mx-1' onClick={e=>{updateproject("")}}>UPDATE </button>
  }
 </center>
        </div>
        </div>
        <ErrorAlertBox isOpen={isOpen} setIsOpen={setIsOpen}heading={heading}errMsg={errMsg}/>
<SuccessAlertBox href={rediLink} isOpen1={isOpen1} setIsOpen1={setIsOpen1}heading1={heading1}successMsg={successMsg}/>
    </div>

{/* <Carousel carouseldata={carouseldata} />
<br /><br /><br /> */}

</>   
    )
}
export default UpdateFile;