import React, { useState,useEffect,useRef} from "react";
import axios from "axios";
import {useParams,useLocation } from "react-router-dom";
import {Modal,ModalBody} from 'reactstrap';
import Getcookie from '../../../modules/login/Getcookie';
import { DataGrid,GridToolbarContainer, GridToolbarExport  } from "@mui/x-data-grid";
import SideBar from '../../../common/SideBar/SideBar';
import {VscError} from 'react-icons/vsc';
import { onlynumerical } from '../../../GenericFunction/GenericFunction';
import makeAnimated from 'react-select/animated';
import {
  GET_EVENTBYID,DELETE_INDENT_PR,GET_ELEMENT_TYPE,
   GET_ALL_ELEMENTS, CREATE_INDENT_PR, GET_INDENT_PR,GET_INDENT_PR_BY_ID,UPDATE_INDENT_PR,
} from "../../../utiles/AllApis";
import { Button } from "@mui/material";
import ErrorAlertBox from "../../../common/AlertBox/ErrorAlertBox";
import SuccessAlertProduct from "../../../common/AlertBox/SuccessAlertProduct";
import Icon from '@mui/material/Icon';
import Select from 'react-select'
function ProductionRider(props) {
    const { projectName } = useParams();
    const location = useLocation();
    if(location?.state?.isMsterNav)
     {
      getAllElements();
      }
const [Delivered,setDelivered] = useState("1990-01-01");

    const [ElementsArr,setElementsArr] = useState([]);
    const [checked, setChecked] = useState([]);
    const [isElementSelected, setIsElementSelected] = useState(false);
    const [selectedElement,setselectedElement] = useState({"value":"","label":"--Select Element Name--"});
    const [selectedElementBcd,setSelectedElementBcd] = useState("");
    const [selectedCatBcd,setSelectedCatBcd] = useState("");
    const [SelectedCategory,setSelectedCategory] = useState("");
    const [isdisable,setisdisable] = useState(true);
    const handalOpen1=()=>{setIsOpen1(true);}
    const [showForm,setShowForm] = useState(false);
    const [areaDis,setAreaDis] = useState(false);
    const [PR_id,setPR_id]=useState('');
    const [isOpen,setIsOpen]=useState(false);
    const[heading, setHeading]=useState("");
    const[itemType, setItemType]=useState("");
    const[errMsg, setErrMsg]=useState("");
const[heading1, setHeading1]=useState("");
const[successMsg, setsuccessMsg]=useState("");
const [isOpen1,setIsOpen1]=useState(false);
const handalOpen=()=>{setIsOpen(true);}
    const handalCloseDelete= () => {
      setIsOpenDeletePopUp(false);
    };
    const [ElemntCategory,setElemntCategory] = useState([]);
const [searchableElement,setSearchableElement]=useState("");
const [searchableElementType,setSearchableElementType]=useState("");

    const [selectOptionsProduct, setSelectOptionsProduct] = useState([]);
const [Length,setLength] = useState(0);
const [Width,setWidth] = useState(0);
const [Height,setHeight] = useState(0);
const [rate,setRate] = useState(0);
const [Amount,setAmount] = useState(0);
const [Quantity,setQuantity] = useState(0);
const [proName,setProjectName] = useState("");
const [Area,setArea] = useState(0);
const [Remark,setRemark] = useState("");
const [userType,setUSerType]=useState("");
const [isEdit, setIsEdit] = useState(false);
const [ProductRiderData, setProductRiderData] = useState([]);
const [totalPRAmt,setTotalPRAmt]=useState(0);
const [isOpenDeletePopUp, setIsOpenDeletePopUp] = useState(false);
const [dltRowpos, setDltRowpos] = useState("");
const [thisuser, setthisuser] = useState([]);
const [brandpopupcell,setbrandpopupcell] =useState(false);
const [popupcell, setpopupcell] = useState(false);
const [isLoading, setLoading] = useState(false);
const [proID, setProId] = useState('');
const ref = useRef(null);
const animatedComponents = makeAnimated();
useEffect(() => {
  setProId(window.location.href.split("/")[4]);
  setArea(Length*Width*Height);
  itemType==="CALCULATE"?
  setAmount(Area*Quantity*rate):  setAmount(Quantity*rate);
  //api call for get project name
  //setLoading(true);
  axios.post(GET_EVENTBYID,{ "Project_ID": projectName}).then((response) => {
   // setLoading(false);
      if(response.data.isSuccess===true)
      {
        setProjectName(response.data.data.projectName);
      }
      
        });
    //call function to get all elements list
    getAllElements();
  //call function of product Rider
  getProductionRider();
// --------------------------------------
    setUSerType(Getcookie("UserType"));
  }, [Area,Length,Width,Height,Quantity,rate]);

  //-----------Function for get all product rider----------------------
  const getProductionRider=()=>
  {
  var config = {
    method: 'POST',
    url: GET_INDENT_PR,
    headers: { 
      'Content-Type': 'application/json'
    },
    data:JSON.stringify({
      "Project_ID":projectName
    })
  };
  // setLoading(true);
  axios(config)
  .then(function (response) {
    // setLoading(false);
    if(response.data.isSuccess==true)
    {
      setProductRiderData(response.data.data);
      var TotalPRAmt=0;
      response.data.data.map((data,i)=>
      {
        TotalPRAmt=TotalPRAmt+data.amt;
      })
      setTotalPRAmt(TotalPRAmt);
    } // NOW MAP THIS DATA INTO TABLE ABOVE QUESTION AND ANSWER SECTION
    else
    {
      setProductRiderData([]);
    }
  })
  .catch(function (error) {
    console.log(error);
  });
  setIsOpen1(false);
  }

  //--------------Function to get all elements
  const getAllElements=()=>
  {
        //api call for get element list
        axios.get(GET_ALL_ELEMENTS).then((response)=>{
          if(response.data.isSuccess===true)
    {
     // setElementsArr(response.data.data);
      const optionsSearch=[];
      for(var i=0;i<response.data.data.length;i++)
      {
        optionsSearch.push({'value':response.data.data[i].bcd,'label':response.data.data[i].name});
      }
      setSearchableElement(<><Select options={optionsSearch} closeMenuOnSelect={true}onChange={getCheckedElmnt}
        components={animatedComponents}ref={ref} 
        defaultValue={selectedElement} autoFocus /></>)
    }
      });
  }

  //for edit PR
  const getAllElementsEdit=(selectedElement1)=>
  {
        //api call for get element list
        axios.get(GET_ALL_ELEMENTS).then((response)=>{
            
          if(response.data.isSuccess===true)
    {
      setElementsArr(response.data.data);
      const optionsSearch=[];
      for(var i=0;i<response.data.data.length;i++)
      {
        optionsSearch.push({'value':response.data.data[i].bcd,'label':response.data.data[i].name});
      }
      setSearchableElement(<><Select options={optionsSearch} closeMenuOnSelect={true}onChange={getCheckedElmnt}
        components={animatedComponents}ref={ref} 
        defaultValue={selectedElement1}
         autoFocus /></>)
    }
      });
  }
  const handalOpenDelete= (rowpos) => {
    setIsOpenDeletePopUp(true);
    setDltRowpos(rowpos);
  };
  //----------------Edit indent of production rider ---------------------------
  const handaleEdit= (rowpos) => {
     
    setIsEdit(true);
    setPR_id(rowpos);
    var config = {
      method: 'POST',
      url: GET_INDENT_PR_BY_ID,
      headers: { 
        'Content-Type': 'application/json'
      },
      data:JSON.stringify({
        "ROWPOS":rowpos
      })
    };
    //setLoading(true);
     
    axios(config)
    .then(function (response) {
      //setLoading(false);
      if(response.data.isSuccess==true)
      {
        setIsElementSelected(true);
         setElemntCategory({"label":response.data.data[0].catergory,"value":response.data.data[0].catergoryBCD});
           setselectedElement({"label":response.data.data[0].element,"value":response.data.data[0].elementBCD});
           setSelectedElementBcd(response.data.data[0].elementBCD);
            setSelectedCatBcd(response.data.data[0].catergoryBCD);
          setSelectedCategory(response.data.data[0].catergory);
          setLength(response.data.data[0].length);
          setWidth(response.data.data[0].width);
          setHeight(response.data.data[0].height);
          setAmount(response.data.data[0].amt);
          setQuantity(response.data.data[0].qty);
          setArea(response.data.data[0].catergory);
          setRemark(response.data.data[0].remark);
          setRate(response.data.data[0].rate);
          setDelivered(response.data.data[0].delivered.split("T")[0]);
          getAllElementsEdit({"label":response.data.data[0].element,"value":response.data.data[0].elementBCD});
          setselectedElement({"label":response.data.data[0].element,"value":response.data.data[0].elementBCD});
          getCheckedElmntEdit({"label":response.data.data[0].element,"value":response.data.data[0].elementBCD},{"label":response.data.data[0].catergory,"value":response.data.data[0].catergoryBCD});//get category
 
      }
      else{
        alert("hdsnf");
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  };

//----------------product rider column---------------------------
const Productcolumns = [
  {
  field: "eDate",
  headerName: "Created At",
  width: 110,
  disableClickEventBubbling: true,
  renderCell: (params) => {
    return (
      <p className='text-white mt-2 f-14'>{params?.row?.eDate?.split("T")[0]}</p>
    );
  }
},
{
  field: "delivered",
  headerName: "Delivered By",
  width: 95,
  disableClickEventBubbling: true,
  renderCell: (params) => {

    return (
      <p className='text-white mt-2 f-14'>{params?.row?.delivered?.split("T")[0]}</p>
    );
  }
},
  { field: "element", headerName: "Element Name", width: 170 },
  { field: "catergory", headerName: "Element Type", width: 170 },
  { field: "rate", headerName: "Rate", width: 65 },
  { field: "area", headerName: "Area", width: 65 },
  { field: "qty", headerName: "Qty", width: 65 },
  { field: "length", headerName: "Length", width: 65 },
  { field: "width", headerName: "Width" , width: 65},
  { field: "height", headerName: "Height", width: 65 },
  { field: "amt", headerName: "Amt" , width: 65},
  { field: "remark", headerName: "Remarks", width: 170 },
  {
    field: "EDIT",
    headerName: "Edit",
    sortable: false,
    width: 70,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <Button variant="contained" className="btnSuccessClr" onClick={()=>{handaleEdit(params.row.rowpos)}}>
          Edit
        </Button>
      );
    },
  },
  {
    field: "DELETE",
    headerName: "Delete",
    sortable: false,
    width: 70,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <Button variant="contained" className="btnDelete" onClick={()=>{handalOpenDelete(params.row.rowpos)}}>
          Delete
        </Button>
      );
    },
  },
];

const handleAllbrand =(params)=>{
  window.location.href = "#"
  setthisuser(params);
  setbrandpopupcell(true)
  setpopupcell(false)
}
const handleCheckedCat =(item,type)=>{
   
  setLength("");
  setWidth("");
  setHeight("");
  setAmount("");
  setQuantity("");
  setArea("");
  setRemark("");
  setDelivered("");
  setRate("");

  var itm=item.value;
  setSelectedCategory(itm);
  var itemType=item.label;

    setShowForm(true);
    setItemType(itemType);
  if(itemType==="CALCULATE")
  {
   setAreaDis(true);
  }
  else{
    setAreaDis(false);
  }
  }
    const checkedItems = checked?.length
? checked.reduce((total, item) => {
  return total + ", " + item
                              
  })
: "";
const getCheckedElmntEdit =(item,ElemntCategory)=>{
  setIsElementSelected(true);
   setselectedElement(item);
  //setLoading(true);
  axios.post(GET_ELEMENT_TYPE,
    {"BCD": item.value }
    ).then((response)=>{
      //setLoading(false);
    if(response.data.isSuccess === true){
       
      var newData=  response.data.data.filter((data,i)=>
      {
      if(data.icode!==ElemntCategory.value)
      {
        return data;
      }
      })
      const optionsSearch=[];
      for(var i=0;i<response.data.data.length;i++)
      {
        optionsSearch.push({'value':response.data.data[i].rowpos,'label':response.data.data[i].name});
      }
      //edit
     setSearchableElementType(<><Select options={optionsSearch} closeMenuOnSelect={true}onChange={e=>{handleCheckedCat(e,itemType)}}
        components={animatedComponents}
        defaultValue={ElemntCategory}
      
         /></>)
      setElemntCategory(newData);
    }
    else{
      setElemntCategory(response.data.data);
    }
  })
}
    const getCheckedElmnt =(item)=>{
        setIsElementSelected(true);
         setselectedElement(item);
        //setLoading(true);
        axios.post(GET_ELEMENT_TYPE,
          {"BCD": item.value }
          ).then((response)=>{
            //setLoading(false);
          if(response.data.isSuccess === true){
             
           //setisdisable(false);
            //response.data.data.splice(0,0,{"name":"--Select Element Type--","value":""})
             setElemntCategory(response.data.data);
                 const optionsSearch=[];
      for(var i=0;i<response.data.data.length;i++)
      {
        optionsSearch.push({'value':response.data.data[i].rowpos,'label':response.data.data[i].name});
      }
     setSearchableElementType(<><Select options={optionsSearch} closeMenuOnSelect={true}onChange={e=>{handleCheckedCat(e,itemType)}}
        components={animatedComponents}ref={ref} 
         /></>)
          }
          else{
            setElemntCategory(response.data.data);
          }
        })
      }
      //---------------create PR -------------------------------
      const InsertIndent =()=>{
        var date="";
        //date=`${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`;
       if(Delivered==="")
       {
        date="1990-01-01";
       }
       else{
        date=Delivered;
       }
        if(Length===""||Width===""||Height===""||Area===""||Quantity===""||Amount===""||rate==="")
       {
        setErrMsg("Field Connot blank");
        setHeading("Creation Failed!");
            handalOpen();
       }
    
       else
       {
        var data = JSON.stringify({
          "Length": Length,
          "Width": Width,
          "Height": Height,
          "Area":Area,
          "Qty": Quantity,
          "Amt": Amount,
          "Remark": Remark,
          "Delivered": date,
          "Project_ID": projectName,
          "Catergory": SelectedCategory,
          "Element": selectedElement?.value,
          "Rate":rate
        });
         
        var config = {
          method: 'post',
          url: CREATE_INDENT_PR,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        setLoading(true);
        axios(config)
        .then(function (response) {
          setLoading(false);
          if(response.data.isSuccess === true){
            handalOpen1();
            setHeading1("Success"); 
            setsuccessMsg(response.data.message);
             setselectedElement("");
             ref.current.focus();
             setElemntCategory([]);
             setLength("");
             setWidth("");
             setHeight("");
             setAmount("");
             setQuantity("");
             setArea("");
             setRemark("");
             setDelivered("");
             setSelectedCategory("");
             setRate("");
           // document.getElementById("Element_type").value="";  
          }
          else{
            setHeading("Creation Failed!");
            setErrMsg(`Opps..${response.data.message}`);
            handalOpen();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
       }
    
      }
      //-----------------------update PR----------------------------
      const UpdateIndent=()=>
      {
        var date=`${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`;
        if(Length===""||Width===""||Height===""||Area===""||Quantity===""||Amount===""||rate==="")
        {
         setErrMsg("Field Connot blank");
         setHeading("Creation Failed!");
             handalOpen();
        }
        else
        {
         var data = JSON.stringify({
          "ROWPOS":PR_id,
           "Length": Length,
           "Width": Width,
           "Height": Height,
           "Area":Area,
           "Qty": Quantity,
           "Amt": Amount,
           "Remark": Remark,
           "Delivered": Delivered,
           "Project_ID": projectName,
           "Catergory": selectedCatBcd,
           "Element": selectedElement?.value,
           "Rate":rate
         });
         
         var config = {
           method: 'post',
           url: UPDATE_INDENT_PR,
           headers: { 
             'Content-Type': 'application/json'
           },
           data : data
         };
         setLoading(true);
         axios(config)
         .then(function (response) {
           setLoading(false);
           if(response.data.isSuccess === true){
            setIsEdit(false);
             handalOpen1();
             setHeading1("Success"); 
             setsuccessMsg(response.data.message);
              setselectedElement("");
              ref.current.focus();
              setElemntCategory([]);
              setLength("");
              setWidth("");
              setHeight("");
              setAmount("");
              setQuantity("");
              setArea("");
              setRemark("");
              setDelivered("");
              setSelectedCategory("");
              setRate("");
            // document.getElementById("Element_type").value="";  
           }
           else{
             setHeading("Creation Failed!");
             setErrMsg(`Opps..${response.data.message}`);
             handalOpen();
           }
         })
         .catch(function (error) {
           console.log(error);
         });
        }
     
      }
      
//-----------------delete production rider--------------------------
const deleteProductionRider=()=>
{
  var data = JSON.stringify({
    "ROWPOS": dltRowpos,
  });
  
  var config = {
    method: 'post',
    url: DELETE_INDENT_PR,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    if(response.data.isSuccess === true){
      getProductionRider();
      setIsOpenDeletePopUp(false);
      setselectedElement("");
      setElemntCategory([]);
             setLength("");
             setWidth("");
             setHeight("");
             setAmount("");
             setQuantity("");
             setArea("");
             setRemark("");
             setDelivered("");
      ref.current.focus();
    }
    else{
      alert(response.data.message);
    }
  })
  .catch(function (error) {
    console.log(error);
  });
  
}
//seachable dropdown
const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport className="exBtn"/>
    </GridToolbarContainer>
  );
}
  return (
<>
<div className='row'>
<div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
    <SideBar/>
    </div>
    <div className='col-md-10 col-12 col-sm-12 myview'>
<p className=" h3 fw-bold my-3"><span className="text-white">Add Production Rider for </span><span className="SecoHead proNameSec d-block pt-2">{proName}</span></p>
<div className=" mt-5">
      <div className="row">
      <div className="col-sm-3 col-md-3 col-6">
      <div className="my-3 h5 fw-bold">
      <p htmlFor="exampleFormControlInput1" className="form-label text-center px-2">Element Name  <span className='required'>*</span>
       <Icon  style={{position:"absolute",cursor:"pointer",color:"#00a859"}}onClick={e=>{window.location.href=`/manageMaster/element_master/form?FormProject&id?${proID}`}}>add_circle</Icon> 
      </p>
      </div>
       {searchableElement}
{/*        
<select className="form-control" id="CLIENT_MCODE"  name="CLIENT_MCODE" onChange={(e)=>getCheckedElmnt(e.target.value)}autoFocus >


<option value={selectedElementBcd}id="clientName" >{selectedElement==""?"--Select Element--":selectedElement}</option>
  {
  ElementsArr?.filter((data)=>{if(selectedElement!==data.name)
    {
      return data;
}}).map((item, index)=>(
    <option key={index}value={item.bcd}>{item.name}</option>
  ))
}
  </select> */}
        </div>
      <div className="col-sm-3 col-md-3 col-6">
      <div className="my-3 h5 fw-bold">
      <p htmlFor="exampleFormControlInput1" className="form-label">Element Type</p>
      </div>
        {/* <input type="hidden"  className="CheckedProductionrider" />value={checkedItems} */}
  
        {
          ElemntCategory?.length > 0 ?
          <>
          {searchableElementType}
          {/* <select  className="form-control" id="Element_type" name="Element_type" onChange={(e)=>handleCheckedCat(e.target.value)}>
          {
          ElemntCategory?.filter((data)=>{if(selectedCatBcd!==data.iMicCode)
            {
              return data;
        }}).map((item, index)=>(
            <option key={index}value={item.iMicCode}>{item.name}</option>
          ))
        }
          </select> */}
          </>

          :<p style={{padding:"40px"}} className="text-danger">Please Select Element to view Its Type..</p>
        }
      </div>
      <div className="col-sm-6 col-md-6 col-12 pr_formCol_div">
      {
        SelectedCategory!="" && ElemntCategory?.length>0?
         <div>
            {/* <p className="text-white h4 fw-bold">CREATE FORM</p> */}
  <div className="PR_form pt-1 pb-0 px-3">
  <div className="row">
  <div className="col-md-4 col-sm-4 col-4 mt-3">
  <div className="mb-3 flex-input">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                   Length<span className='required'>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="PR_length"
                      // disabled={isdisable}
                      placeholder="Enter Length...."
                      value={Length}
                      onChange={e=>setLength(e.target.value)}
                      onKeyPress={onlynumerical}
                    />
                  </div>
    </div>
    <div className="col-md-4 col-sm-4 col-4 mt-3">
    <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                     Width<span className='required'>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="PR_width"
                      // disabled={isdisable}
                      placeholder="Enter Width...."
                      value={Width}
                      onChange={e=>setWidth(e.target.value)}
                      onKeyPress={onlynumerical}
                    />
                  </div>
    </div>
    <div className="col-md-4 col-sm-4 col-4 mt-3">
    <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Height<span className='required'>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="PR_width"
                      // disabled={isdisable}
                      placeholder="Enter Height...."
                      value={Height}
                      onChange={e=>setHeight(e.target.value)}
                      onKeyPress={onlynumerical}
                    />
                  </div>
    </div>
    <div className="col-md-4 col-sm-4 col-4 mt-3">
    <div className="mb-3 flex-input">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                  Area <span className='required'>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="PR_Area"
                      disabled={areaDis}
                      placeholder="Enter Area...."
                      value={Area}
                      onChange={e=>setArea(e.target.value)}
                      onKeyPress={onlynumerical}
                    />
                  </div>
      </div>
      <div className="col-md-4 col-sm-4 col-4 mt-3" >
      <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                     Quantity<span className='required'>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="PR_width"
                      // disabled={isdisable}
                      placeholder="Enter Quantity...."
                      value={Quantity}
                      onChange={e=>setQuantity(e.target.value)}
                      onKeyPress={onlynumerical}
                    />
                  </div>
              
      </div>
      <div className="col-md-4 col-sm-4 col-4 mt-3">
      <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Rate<span className='required'>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="PR_rate"
                      placeholder="Enter Rate...."
                      value={rate}
                      onChange={e=>setRate(e.target.value)}
                      onKeyPress={onlynumerical}
                    />
                  </div>
        </div>
      <div className="col-md-4 col-sm-4 col-4 mt-3">
      <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Amount <span className='required'>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="PR_width"
                      disabled={areaDis}
                      placeholder="Enter Amount...."
                      value={Amount}
                      onChange={e=>setAmount(e.target.value)}
                      onKeyPress={onlynumerical}
                    />
                  </div>
         
      </div>
      <div className="col-md-4 col-sm-4 col-4 mt-3 pr-0 PR_DateDiv">
      <div className="mb-3">
     <label htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                     Delevered By
                    </label>
                    <input
                      type="Date"
                      className="form-control date p-0"
                      id="PR_width"
                      // disabled={isdisable}
                      placeholder="Enter Delivery Date...."
                      defaultValue={Delivered}
                      onChange={e=>setDelivered(e.target.value)}
                      max="9999-12-31"
                    />
                  </div>
      </div>
      <div className="col-md-4 col-sm-4 col-4 mt-3">
      <div className="PR_text">
                  <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                    Remark
                    </label>
                    <textarea name="" id="" cols="22" rows="2" className="text-dark"
                    // disabled={isdisable}
                   placeholder="Enter Remarks...."
                    value={Remark}
                    onChange={e=>setRemark(e.target.value)}
                    />
                  </div>
      </div>
      <div className="col-md-12 col-sm-12 col-12 mt-3 text-center">
       {isEdit? <button className="btn btnSuccess" onClick={UpdateIndent}disabled={SelectedCategory===""?true:false}>Update</button>
        :<button className="btn btnSuccess" onClick={InsertIndent}disabled={SelectedCategory===""?true:false}>Submit</button>}
        </div>
  </div>
  
  </div>
        </div>:null
      } 
        </div>
        <div className="col-sm-12 col-md-12 col-12">
        <center className="text-white my-3 h4 fw-bold"> Production Rider List </center>
  <div className="tbanew">
  <DataGrid
                className=""
                rows={ProductRiderData}
                columns={Productcolumns}
                pageSize={4}
                getRowId={(row) => row.rowpos}
                onCellClick={handleAllbrand}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
                   <div className="TotlAmClaPR">
              <p>
                Total Amount :
                <span style={{ fontWeight: "800" }}>
                  &#x20b9; &nbsp;{totalPRAmt}
                </span>
              </p>
            </div>
  </div>
      </div>
      </div>
    

</div>
{/*For Loader*/}
{
    isLoading?  <div className='loaderDiv'>
    </div>:null
  }
</div>
<Modal className="fade show"isOpen={isOpenDeletePopUp} >
  <ModalBody  className='text-center tracking-in-expand-fwd-bottom'>
<p className=' text-center text-danger fw-bold'><VscError className='fw-bolder mx-2 my-2 h2'/>Are you sure you want to Delete</p>
<div>   <Button
     className='btnSuccess mx-2'
     onClick={()=>{deleteProductionRider()}}
    >
      Yes
    </Button>
    <Button
     className='btnDelete mx-2'
     onClick={handalCloseDelete}
    >
      No
    </Button></div>
  </ModalBody>

  </Modal>
  <ErrorAlertBox isOpen={isOpen} setIsOpen={setIsOpen}heading={heading}errMsg={errMsg}/>
<SuccessAlertProduct getProductionRider={getProductionRider} isOpen1={isOpen1} setIsOpen1={setIsOpen1}heading1={heading1}successMsg={successMsg}/>
</div>

</>
  );
}
export default ProductionRider;
