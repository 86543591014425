import React, { useState } from 'react'
import "./alert.css"

function Alert(props) {
  const [display,setdisplay]=useState("aletpopup")
var color = props.color;
function func(e){
  setdisplay("none")

}

const showorhide = ()=>{
  if(display === "none"){
    setdisplay("aletpopup")
  }
}
showorhide()
  return (
    <>
    <p className={display} style={{color:color}}>{props.msg}<span onClick={(e)=>func(e)}><ion-icon name="close-outline"></ion-icon></span></p> 
    </>
  )
}

export default Alert;