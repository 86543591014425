import React, { useContext } from 'react';
import MyProfile from './myProfile/MyProfile';
import Master from './EmpMaster/EmpMaster';
import EmployeeProjects from './EmpProject/EmpProject';
import HRDashboard from './Dashboard/Dashboard';
import EmpTimeOff from './tmeOff/EmpLeave';
import EmployeeEmergency from './EmpEmergency/Emengncy';
import EmployeeDocs from './Documents/EmpDoc';
import Employeetraining from './Training/Training';
import Attendance from './attendance/Attendance';
import Help from './Help/Help';
import './main.css';
import { ALLOWED_USERTYPE } from '../../../utiles/AllApis';
import Qualification from './EmpQualification/Qualification';
import EmpChanges from './EmpChanges/EmpChanges';
import SalarySlab from './SalarySlab/SalarySlab';
import MonthalyData from './monthalyData/MonthalyData';
import { loginData } from '../../../App';
import SlryStructure from './SalaryStructure/SlryStructure';
import GenerateSalary from './GenerateSalary/GenerateSalary';
const Main = (props) => {
    const loginVal = useContext(loginData);
    return (
        <>
            {
                props?.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ?
                    <div className='row'>
                        <div className='col-auto float-end'>
                            <button className='btn btn-success cursor-pointer' onClick={e => { window.location.replace("/Dashboard"); }} >
                                ADMIN CONSOLE
                            </button></div>
                    </div> : ""
            }
            {
                props.page === 'dashboard' || props.page === 'employeeprofile' ? <HRDashboard tokectDetails={props.tokectDetails} /> :
                    props.page === 'Master' ? <Master tokectDetails={props.tokectDetails} /> :
                        props.page === 'personal' ? <MyProfile tokectDetails={props.tokectDetails} /> :
                            props.page === 'projects' ? <EmployeeProjects tokectDetails={props.tokectDetails} /> :
                                props.page === 'timeoff' ? <EmpTimeOff tokectDetails={props.tokectDetails} /> :
                                    props.page === 'emergency' ? <EmployeeEmergency tokectDetails={props.tokectDetails} /> :
                                        props.page === 'documents' ? <EmployeeDocs tokectDetails={props.tokectDetails} /> :
                                            props.page === 'training' ? <Employeetraining tokectDetails={props.tokectDetails} /> :
                                                props.page === 'attendance' ? <Attendance tokectDetails={props.tokectDetails} /> :
                                                    props.page === 'help' ? <Help tokectDetails={props.tokectDetails} /> :
                                                        props.page === 'qualification' ? <Qualification tokectDetails={props.tokectDetails} /> :
                                                            props.page === 'empChanges' ? <EmpChanges tokectDetails={props.tokectDetails} /> :
                                                                props.page === 'salarySlab' ? <SalarySlab tokectDetails={props.tokectDetails} /> :
                                                                    props.page === 'salaryStructure' ? <SlryStructure tokectDetails={props.tokectDetails} /> :
                                                                        props.page === 'mnthalyData' ? <MonthalyData tokectDetails={props.tokectDetails} /> :
                                                                            <GenerateSalary tokectDetails={props.tokectDetails} userselected={props.page} />
            }

        </>
    )
}
export default Main;