import React,{useState} from 'react';
import './item.css';


import SheetForm from './SheetForm';



const SheetMain=()=>
{
    const [sheet, setSheets]=useState(true);
    const [pannel,setpannel] =useState("text-center topPanelactive");
    const [activepannel,setactivepannel]=useState("text-center topPanel");
    const [productionRider,setProductionRider]=useState("text-center topPanel")
    const viewSheet=(sheetName)=>
    {
        if(sheetName==="COST_SHEET")
        {
            setSheets(true);
            setactivepannel("text-center topPanel");
            setpannel("text-center topPanelactive");
            setProductionRider("text-center topPanel");
        }
        else if(sheetName==="BRAND_SHEET"){
            setSheets(false);
            setactivepannel("text-center topPanelactive");
            setpannel("text-center topPanel");
            setProductionRider("text-center topPanel");
        }
        else if(sheetName==="PRODUCTION_RIDER"){
            setSheets(false);
            setactivepannel("text-center topPanel")
            setpannel("text-center topPanel");
            setProductionRider("text-center topPanelactive");
        }
    }
    return(
        <>
<div className='container-fluid'>
<div className='row '>
{/* <div className={`${productionRider} col-4`}  onClick={e=>{viewSheet('PRODUCTION_RIDER')}}>Production Rider</div> */}
     <div className={`${pannel} col-6`}  onClick={e=>{viewSheet('COST_SHEET')}}>Costing</div>
    <div className={`${activepannel} col-6`} onClick={e=>{viewSheet('BRAND_SHEET')}}>Branding</div>
    
 </div>

</div>

<SheetForm sheet={sheet} />
</>
    )
}
export default SheetMain;