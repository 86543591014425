import React from 'react';
import './button.css';
import { useNavigate } from 'react-router-dom';
const ButtonEdit=(props)=>
{
    const navigate =useNavigate();
return(
    <button className='btn btnEdit mx-1' onClick={() => {navigate(`${props.href}`)}}>{props.btnTitle} </button>
)
}
export default ButtonEdit;