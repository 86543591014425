import React, { useEffect, useState, useRef } from 'react';
import {
  useParams, Link, useNavigate, useLocation
} from "react-router-dom";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'
import SideBar from '../../common/SideBar/SideBar';
import { Button } from "@mui/material";
import axios from 'axios';
import Costsheetdefault from "../../assets/Internal_costing_Format.xlsx";
import vendorSheetdefault from "../../assets/Vendor_Contact_Sheet.xlsx";
import jobSheetdefault from "../../assets/ProductionRiderSheet.xlsx";
import assestSheetdefault from "../../assets/Stock_or_Assest.xlsx";
import purchaseOrder from '../../assets/static/PurchaseOrder.xlsx'
import {
  GET_DOCUMENTBYID, GET_BRANDSHEET_BY_ID, GET_COSTSHEET_BY_ID, UNREJECT_PROJECT, TENDER_STATE, MERGE_PDF, GET_ALL_CMPNY_WORK_DOC, DELETE_PROJECT, UNASSIGN_COMPANY_DOC, GET_VENDOR_BY_PROJECT_BY_ID,
  ASSIGN_COMPANY_DOC, CREATE_DOCUMENT, GET_PROJECTBYID, UPDATE_PROJECT, GET_STCK_ASST_BY_PROID,
  GET_TEAMS_BY_PROJECT_ID, GET_EMP_DATAILS_BY_MCODE, GET_DOCUMENT, DELETE_MERGED_PDF_DOCUMENT, ALLOWED_USERTYPE
} from '../../utiles/AllApis';
import { jsPDF } from "jspdf";
import {
  Modal,
  ModalBody
} from 'reactstrap';
import ErrorAlertBox from '../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../common/AlertBox/SuccessAlertBox';
import DataGrids from '../../common/table/DataGrid';
import ExcelUpload from '../../common/excelUpload/Main';
import ProjectInfo from './Component/ProjectInfo';
const ProjectDetailsOPT = (props) => {


  const inputRef = useRef(null);
  const inputRefTwo = useRef(null);
  const [heading, setHeading] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const [ProductRiderData, setProductRiderData] = useState("");
  const [PurchaseOrder, setPurchaseOrder] = useState("")
  const [successMsg, setsuccessMsg] = useState("");
  const [dltdocument_ID, setDltdocument_ID] = useState("");
  const [countTotalbrand, setcountTotalbrand] = useState("");
  const { projectName } = useParams();
  console.log(projectName)
  const [countTotalcost, setcountTotalcost] = useState("");
  const tableRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [secStep, setSecStep] = useState(false);
  const [documents, setdocuments] = useState(false);
  const [mergDltPop, setMergDltPop] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [clearListPop, setClearListPop] = useState(false);
  const [eligibilityDoc, setEligibilityDoc] = useState([]);
  const [ProjectDocList, setProjectDocList] = useState([]);
  const [Internal, setInternalDoc] = useState([]);
  const [productionRider, setProjectionRider] = useState([]);
  const [data, setdata] = useState([])
  const [costSheet, setCostSheet] = useState([]);
  const [brandSheet, setBrandSheet] = useState([]);
  const [wonsuccess, setwonsuccess] = useState(false);
  const [addremark, setaddremark] = useState("")
  const [intrested, setintrested] = useState(true);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenErr, setIsOpenErr] = useState(false);
  const [isOpenLost, setIsOpenLost] = useState(false);
  const [isOpenPrint, setIsOpenPrint] = useState(false);
  const [isCostSheetopen, setIsCostSheetopen] = useState(false);
  const [selectedExcelType, setSelectedExcelType] = useState("");
  const [pdfLen, setPdfLen] = useState(0);
  const [won, setwon] = useState(false)
  const [enableOprt, setEnableOprt] = useState(false)
  const [yesok, setyesok] = useState(false)
  const [sheet, SetSheets] = useState(false)
  const [yespopup, setyespopup] = useState(false)
  const [nopopup, setnopopup] = useState(false);
  const [rejectpopoup, setRejectpopoup] = useState(false);
  const [documentimg, setdocumentimg] = useState([]);
  const [viewTeams, setViewTeams] = useState(false);
  const [secSepShow, setsecSepShow] = useState(false); // we need to change  this state 
  const [cmpntDocs, setCmpntDocs] = useState();
  const [internalDocs, setInternalDocs] = useState();
  const [dataTable, setDataTable] = useState();
  const [viewCompyDoc, setViewCompyDoc] = useState(false);
  const [viewInternalDoc, setViewInternalDoc] = useState(false);
  const [viewUploadPrstnDoc, setViewUploadPrstnDoc] = useState(false);
  const [viewUploadMoreDoc, setViewUploadMoreDoc] = useState(false);
  const [uploadfile, setuploadfile] = useState("");
  const [comDocList, setcmpDoList] = useState("");
  const [docVal, setDoc1Val] = useState({ "name": "--Select Document--" });
  const [comDocId, setcmpDocId] = useState([]);
  const [comDocName, setcmpDocName] = useState([]);
  const [vendor, setVenders] = useState([]);
  const [stockAsset, setStock] = useState([]);
  const [workDocId, setWorkDocId] = useState([]);
  const [workDocName, setWorkDocName] = useState([]);
  const [GetDocName, setGetDocName] = useState("");
  const [list, setList] = useState([]);
  // const [list1,setList1]=useState([]);
  const taledata = [];
  const [eligibilityDocView, setEligibilityDocView] = useState("");
  const [openEligibilityDoc, setOpenEligibilityDoc] = useState(false);
  const [showMrgFinalBtn, setShowMrgFinalBtn] = useState(false);
  const [proReject, setProjectRejection] = useState(false);
  // const [excelOpen,setExcelOpen]=useState(false);
  const handalOpenErr = () => { setIsOpenErr(true); }
  const handalOpen1 = () => { setIsOpen1(true); }
  //const urlVal=`/projectList/${projectName}`;
  const urlVal = "";
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport className="exBtn" />
      </GridToolbarContainer>
    );
  }
  const hadldleOpenEligibilityDoc = (path, extention) => {
    if (extention === "pdf" || extention === "xlsx" || extention === "csv") {
      window.open(
        path,
        '_blank'
      );
      // setEligibilityDocView(<div className='col-sm-12 col-md-12 col-12 text-center'><a href={path} target="blank"><img className='w-75 h-75' src={pdfImg}alt="Eligibility Document" /></a></div>);
    }
    else {
      setEligibilityDocView(<div className='col-sm-12 col-md-12 col-12 text-center'><a href={path} target="blank"><img className='w-100 h-75' src={path} alt="BOQ Document" /></a></div>)
      setOpenEligibilityDoc(true);
    }

  };

  const hadldleCloseEligibilityDoc = () => { setOpenEligibilityDoc(false) };
  const columnsForCmpnyDoc = [

    { field: 'name', headerName: 'Document Name', width: 500 },
    { field: 'path', headerName: 'Documets', width: 500 },
  ]
  const DocListcol = [{ field: 'subType', headerName: 'Document Name', width: 477 },
  { field: 'type', headerName: 'Document Type', width: 477 },

  ]

  const columns = [

    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'department', headerName: 'Department', width: 150 },
    {
      field: "hiringDate",
      headerName: "Hiring Date",
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params.row.hiringDate.split("T")[0]}</p>
        );
      }
    },
    { field: 'mail', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Mobile No.', width: 150 },

  ]
  const navigate = useNavigate();

  const CmpDocListing = (val) => {
    const result = Object.values(val);
    let cpmDoc = [];
    let cpmDocID = [];
    result?.map((c, i) => {
      cpmDocID.push(c.id);
      cpmDoc.push(c.name);
    })

    setcmpDocName(cpmDoc);
    setcmpDocId(cpmDocID);
  }
  const WorkDocListing = (val) => {
    const result = Object.values(val);
    let workDoc = [];
    let workDocID = [];
    result?.map((c, i) => {
      workDocID.push(c.id);
      workDoc.push(c.name);
    })

    setWorkDocName(workDoc);
    setWorkDocId(workDocID);
  }
  const getTeamMembers = () => {
    setLoading(true);
    axios.post(GET_TEAMS_BY_PROJECT_ID, { "Project_ID": projectName }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        response?.data?.data?.map((data, i) => {
          setLoading(true);
          axios.post(GET_EMP_DATAILS_BY_MCODE, { "EmployeeID": data.emP_MCODE }).then((response) => {
            setLoading(false);
            if (response?.data?.isSuccess === true) {

              taledata.push(response?.data?.data[0]);
            }
            // settabledata(...taledata,response?.data?.data);
          });
          setDataTable(<DataGrid
            rows={taledata}
            columns={columns}
            getRowId={(row) => row.rowpos}
            pageSize={10}
            // checkboxSelection
            onSelectionModelChange={(ids) => {
            }}
          />)
        }
        )
      }

    });
  }
  const location = useLocation();

  useEffect(() => {
    getTeamMembers();
    getProjBtId();
    getCostSheetProjById();
    getBrandSheetProjById();
  }, [isReload]);


  const reloadFun = (type) => {
    getallVendors()
    getTeamMembers();
    getProjBtId();
    getStockByPro();
    setexcelUopload(type);
  }








  const getProjBtId = () => {
    var config = {
      method: 'post',
      url: GET_PROJECTBYID,
      headers: {
        'Content-Type': 'application/json'
      },
      data: { "ROWPOS": projectName }
    };
    const ProjectArray = [];
    setLoading(true);
    axios(config)
      .then(function (response) {
        setProjectRejection(response.data.data[0].reject);
        setLoading(false);
        if (response.data.isSuccess === true) {
          var oleEDate = `${new Date(response.data.data[0].eventDate).getFullYear()}/${new Date(response.data.data[0].eventDate).getMonth() + 1}/${new Date(response.data.data[0].eventDate).getDate()}`;
          if (oleEDate === "1990/1/1") {
            response.data.data[0].eventDate = "--NA--";
          }
          else {
            response.data.data[0].eventDate = `${new Date(response.data.data[0].eventDate).toLocaleString([], { weekday: 'short' })} ${new Date(response.data.data[0].eventDate).toLocaleString([], { day: '2-digit' })} ${new Date(response.data.data[0].eventDate).toLocaleString([], { month: 'short' })}`;
          }
          var oleeventToDate = `${new Date(response.data.data[0].eventToDate).getFullYear()}/${new Date(response.data.data[0].eventToDate).getMonth() + 1}/${new Date(response.data.data[0].eventToDate).getDate()}`;
          if (oleeventToDate === "1990/1/1") {
            response.data.data[0].eventToDate = "--NA--";
          }
          else {
            var a = `${new Date(response.data.data[0].eventToDate).toLocaleString([], { weekday: 'short' })}`;
            response.data.data[0].eventToDate = `${new Date(response.data.data[0].eventToDate).toLocaleString([], { weekday: 'short' })} ${new Date(response.data.data[0].eventToDate).toLocaleString([], { day: '2-digit' })} ${new Date(response.data.data[0].eventToDate).toLocaleString([], { month: 'short' })}`;
          }
          var olelDateOfPreBidQueries = `${new Date(response.data.data[0].lDateOfPreBidQueries).getFullYear()}/${new Date(response.data.data[0].lDateOfPreBidQueries).getMonth() + 1}/${new Date(response.data.data[0].lDateOfPreBidQueries).getDate()}`;
          if (olelDateOfPreBidQueries === "1990/1/1") {
            response.data.data[0].lDateOfPreBidQueries = "--NA--";
          }
          var oleLastFillingDate = `${new Date(response.data.data[0].lastFillingDate).getFullYear()}/${new Date(response.data.data[0].lastFillingDate).getMonth() + 1}/${new Date(response.data.data[0].lastFillingDate).getDate()}`;
          if (oleLastFillingDate === "1990/1/1") {
            response.data.data[0].lastFillingDate = "--NA--";
          }
          var oleldateOfSubmissionBid = `${new Date(response.data.data[0].ldateOfSubmissionBid).getFullYear()}/${new Date(response.data.data[0].ldateOfSubmissionBid).getMonth() + 1}/${new Date(response.data.data[0].ldateOfSubmissionBid).getDate()}`;
          if (oleldateOfSubmissionBid === "1990/1/1") {
            response.data.data[0].ldateOfSubmissionBid = "--NA--";
          }
          var oleopenFinancialBids = `${new Date(response.data.data[0].openFinancialBids).getFullYear()}/${new Date(response.data.data[0].openFinancialBids).getMonth() + 1}/${new Date(response.data.data[0].openFinancialBids).getDate()}`;
          if (oleopenFinancialBids === "1990/1/1") {
            response.data.data[0].openFinancialBids = "--NA--";
          }
          var olepreBidMeetingDate = `${new Date(response.data.data[0].preBidMeetingDate).getFullYear()}/${new Date(response.data.data[0].preBidMeetingDate).getMonth() + 1}/${new Date(response.data.data[0].preBidMeetingDate).getDate()}`;
          if (olepreBidMeetingDate === "1990/1/1") {
            response.data.data[0].preBidMeetingDate = "--NA--";
          }
          var oletechnicalBidOpenDate = `${new Date(response.data.data[0].technicalBidOpenDate).getFullYear()}/${new Date(response.data.data[0].technicalBidOpenDate).getMonth() + 1}/${new Date(response.data.data[0].technicalBidOpenDate).getDate()}`;
          if (oletechnicalBidOpenDate === "1990/1/1") {
            response.data.data[0].technicalBidOpenDate = "--NA--";
          }
          var olepresentationDate = `${new Date(response.data.data[0].presentationDate).getFullYear()}/${new Date(response.data.data[0].presentationDate).getMonth() + 1}/${new Date(response.data.data[0].presentationDate).getDate()}`;
          if (olepresentationDate === "1990/1/1") {
            response.data.data[0].presentationDate = "--NA--";
          }

          if (response.data.data[0].won === true) {
            setsecSepShow(true);
          }
          response?.data?.data?.map((data, i) => {

            ProjectArray.push(data);
          })
        }
        else {

          SetSheets(true)
          setdocuments(false);

        }
        var isintrested = response.data.data[0].interested;
        const pdf_doc = response.data.data[0].lst_documents.filter((data, i) => {
          if (data.type === "MERGED_FILE") {
            return data;
          }

        })
        let totalCost = 0;
        let b = 0;
        response.data.data[0].costSheet?.map((sum, index) => {

          var sa = JSON.parse(JSON.stringify(sum.amt));
          sa = Number(sa);
          totalCost = sa + totalCost;
        });

        setcountTotalcost(totalCost);
        response?.data?.data[0]?._brandSheet?.map((brand, index) => {
          var BD = JSON.parse(JSON.stringify(brand.amt));
          BD = Number(BD);
          b = BD + b;
        });
        setcountTotalbrand(b)
        setPdfLen(pdf_doc.length);
        const li_doc = response.data.data[0].lst_documents.filter((data, i) => {
          if (data.type === "COMPANY_DOCUMENT" || data.type === "WORKORDER_DOCUMENT" || data.type === "PRESENTATION_DOCUMENT" || data.type === "EXTRA_DOCUMENT") {
            return data;
          }

        })
        setProjectDocList([...li_doc, ...response.data.data[0].lst_CompanyDocuments, ...response?.data?.data[0]?.lst_InternalDocuments]);
        if (isintrested === true) {
          setintrested(false);
          SetSheets(true);
        }
        else {
          setintrested(true);
        }
        var won = response.data.data[0].won;
        if (won === true && isintrested === true) {
          setwon(false);
        }
        else {
          setwon(true);
        }
        setdata(response.data.data);
        setdocumentimg(response.data.data[0]?.generic_docs);
        setEligibilityDoc(response.data.data[0]?.eligibility_docs[response.data.data[0]?.eligibility_docs.length - 1]);

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const getProdRider = () => {
    setLoading(true);
    axios.post(GET_DOCUMENTBYID, { "Project_ID": projectName })
      .then((response) => {
        setLoading(false);
        if (response.data.isSuccess) {
          const filteredData = response.data.data.filter(data => data.path.includes('PRODUCTION_RIDER'));


          setProductRiderData(filteredData);
          if (filteredData.length > 0) {
            console.log(filteredData);
          } else {
            console.log('No data includes ELEMENT_SHEET');
          }
        } else {
          setProductRiderData([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error fetching data:', error);
        setProductRiderData([]);
      });
  };
  const getPurchaseOrder = () => {
    setLoading(true);
    axios.post(GET_DOCUMENTBYID, { "Project_ID": projectName })
      .then((response) => {
        setLoading(false);
        if (response.data.isSuccess) {
          const filteredDataa = response.data.data.filter(data => data.path.includes('PURCHASE_ORDER'));


          setPurchaseOrder(filteredDataa);
          if (filteredDataa.length > 0) {
            console.log(filteredDataa);
          } else {
            console.log('No data includes Purchase Order');
          }
        } else {
          setPurchaseOrder([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error fetching data:', error);
        setProductRiderData([]);
      });
  };


  ///----------get cosesheet by id---------
  const getCostSheetProjById = () => {
    var config = {
      method: 'post',
      url: GET_COSTSHEET_BY_ID,
      headers: {
        'Content-Type': 'application/json'
      },
      data:
      {
        "Project_ID": projectName
      }
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setCostSheet(response.data.data);
        }
        else {
          setCostSheet([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //----------get brandsheet by id---------
  const getBrandSheetProjById = () => {
    var config = {
      method: 'post',
      url: GET_BRANDSHEET_BY_ID,
      headers: {
        'Content-Type': 'application/json'
      },
      data:
      {
        "Project_ID": projectName
      }
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setBrandSheet(response.data.data);
        }
        else {
          setBrandSheet([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const getallVendors = () => {
    setLoading(true);
    axios.post(GET_VENDOR_BY_PROJECT_BY_ID, { "ROWPOS": projectName }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setVenders(response.data.data);
      }
    });
  }
  const getStockByPro = () => {
    setLoading(true);
    axios.post(GET_STCK_ASST_BY_PROID, { "Pro_id": projectName }).then((response) => {
      setLoading(false);
      console.log(response.data.data);
      if (response.data.isSuccess === true) {
        setStock(response.data.data);
      }
    });
  }
  const setunassignedDocList = (val) => {

    const result = Object.values(val);
    let cpmDoc = [];
    let cpmDocID = [];
    result?.map((c, i) => {
      cpmDocID.push(c.document_ID);
      cpmDoc.push(c.type);
    })
    setcmpDocName(cpmDoc);
    setcmpDocId(cpmDocID);
    setClearListPop(false);
    setLoading(true);
    unassignedDoc(cpmDoc, cpmDocID);
  }
  const unassignedDoc = (cpmDoc, cpmDocID) => {
    axios.post(UNASSIGN_COMPANY_DOC, {
      "Project_ID": projectName,

      "lst_subType": cpmDoc,

      "lst_documentID": cpmDocID

    }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setHeading1("Success");
        setsuccessMsg("Document Unassigns Successfully!");
        handalOpen1();
        //window.location.reload(); 
      }
      else {
        setHeading("Error!");
        setErrMsg("Somthing Went Wrong");
        handalOpenErr();
        return;
      }

    });
  }
  const onSelect = (val) => {
    setcmpDoList(...comDocList, val);
  }
  const MergerPdf = async () => {
    window.scrollTo(0, 0);

    if (list.length !== 0) {
      setLoading(true);
      await axios.post(MERGE_PDF,
        {
          "Media_ID": projectName,
          "lst_Media": list
        }).then((response) => {
          setLoading(false);
          if (response.data.isSuccess === true) {
            window.location.reload();
          }
          else {
            alert(response.data.message);
          }

        });
    }
    else {
      alert("No Document Found!!!")
    }

  }
  const getCmpDocList = (e) => {
    setDoc1Val({ ...docVal, "name": e.target.value });
  }



  const closeLostPopo = () => {
    setRejectpopoup(false);
  }

  setTimeout(() => {
    setwonsuccess(false)
  }, 10000);

  const redirecturl = `/projectList/${projectName}/form`;
  const stepSecProjectName = window.sessionStorage.getItem("stepSecProjectName");
  const handleOpenTeams = () => {
    setViewTeams(true);
  }
  const handleCloseTeams = () => {
    setViewTeams(false);
  }
  const handleOpenCmpyDoc = () => {
    setLoading(true);
    axios.post(GET_DOCUMENT, { "Project_ID": projectName }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        const resData = response.data.data.filter((data, i) => {
          if (data.type.includes("COMPANY_DOCUMENT")) {
            return data;
          }
        }
        )
        setCmpntDocs(resData);
      }
    });
    setViewCompyDoc(true);
    handleCloseInternalDoc();
    handleClosePresentationDoc();
  }
  const handleCloseCmpyDoc = () => {
    setViewCompyDoc(false);
  }

  const handleCloseInternalDoc = () => {
    setViewInternalDoc(false);
  }
  const handleOpenPresentationDoc = () => {
    setViewUploadPrstnDoc(true);
    handleClosePresentationDoc1();
  }
  const handleClosePresentationDoc = () => {
    setViewUploadPrstnDoc(false);
  }
  const handleOpenPresentationDoc1 = () => {
    setViewUploadMoreDoc(true);
    handleClosePresentationDoc();
  }
  const handleClosePresentationDoc1 = () => {
    setViewUploadMoreDoc(false);
  }
  const handlefile = (e) => {
    var name = e?.target?.files[0]?.name?.split("");

    var validate = name?.reverse().join("").split(".")[0];
    if (validate === "xslx" || validate === "piz" || validate === "kpa") {
      alert("please select image file !");
      e.target.value = null;
    }
    else {
      setuploadfile(e.target.files[0])
    }
  }


  const handleViewClick = (path) => {
    window.open(path, '_blank');
  };

  function uploadfiles() {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", uploadfile);
    data.append("ID", projectName);
    data.append("SubType", GetDocName);
    data.append("name", GetDocName);
    data.append("Type", "PRESENTATION_DOCUMENT");

    var config = {
      method: "post",
      url: CREATE_DOCUMENT,

      data: data,
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        if (response.data.isSuccess === true) {

          window.location.reload();
        }
        else {
          setHeading("Submission Failed!");
          setErrMsg("Faield to create file");
          handalOpenErr();
          return;
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function uploadfiles1() {

    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", uploadfile);
    data.append("ID", projectName);
    data.append("SubType", GetDocName);
    data.append("name", GetDocName);
    data.append("Type", "EXTRA_DOCUMENT");

    var config = {
      method: "post",
      url: CREATE_DOCUMENT,

      data: data,
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        if (response.data.isSuccess === true) {

          window.location.reload();
        }
        else {
          setHeading("Submission Failed!");
          setErrMsg("failed to upload file");
          handalOpenErr();
          return;
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const [showMultiDoc1, setShowMultiDoc1] = useState(false);
  const handlecDoc1 = () => {

    if (showMultiDoc1 === true) {
      // setShowMultiDoc1(false);
      // setDoc1(projectDetails.Category)
    }
    else {
      setShowMultiDoc1(true);
    }
  }
  const deletePdfFile = (id) => {
    setLoading(true);
    axios.post(DELETE_MERGED_PDF_DOCUMENT, {
      "ROWPOS": id,

    }).then((response) => {
      setLoading(false);
      setMergDltPop(false);
      if (response.data.isSuccess === true) {
        setHeading1("Success");
        setsuccessMsg("Document Deleted successfully!");
        handalOpen1();
        //window.location.reload(); 
      }
      else {
        setHeading("Error!");
        setErrMsg("Somthing Went Wrong");
        handalOpenErr();
        return;
      }

    });
  }
  const addCmpnyDoc = () => {
    setLoading(true);

    axios.post(ASSIGN_COMPANY_DOC, {

      "Type": "COMPANY_DOCUMENT",

      "Project_ID": projectName,

      "lst_subType": comDocName,

      "lst_documentID": comDocId

    }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setHeading1("Success");
        setsuccessMsg("Document add successfully!");
        handalOpen1();
        //window.location.reload(); 
      }
      else {
        setHeading("Error!");
        setErrMsg("Somthing Went Wrong");
        handalOpenErr();
        return;
      }

    });

  }
  const exportToPDFPopUp = () => {
    setIsOpenPrint(true);
  }
  const exportToPDF = () => {
    const doc = new jsPDF('p', 'pt', 'a3');
    doc.setTextColor(255, 0, 0);
    doc.html(document.querySelector("#content"), {
      callback: function (pdf) {
        window.open(doc.output('bloburl'))
        // pdf.save("mtPDf.pdf");
      }
    }, "#000");

  }
  const costcolumns = [
    {
      field: "eDate",
      headerName: "Created At",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params?.row?.eDate?.split("T")[0]}</p>
        );
      }
    },

    {
      field: "head",
      headerName: "Particular Type",
      width: 180,
      renderCell: (params) => {
        if (params.row.head === '' || params.row.head === null) {
          return (<span className='text-white'>--NA--</span>);
        }
        else {
          return (<span className='text-white'>{params.row.head}</span>);
        }
      }
    },
    { field: "name", headerName: "Particular Name", width: 180 },
    { field: "length", headerName: "Length", width: 70 },
    { field: "width", headerName: "Width", width: 70 },
    { field: "height", headerName: "Height", width: 70 },
    { field: "area", headerName: "Area", width: 70 },
    { field: "qty", headerName: "Qty", width: 70 },
    { field: "unit", headerName: "Unit", width: 70 },
    { field: "days", headerName: "Days", width: 70 },
    { field: "rate", headerName: "Rate", width: 70 },
    { field: "amt", headerName: "Amt", width: 70 },
    {
      field: "remark",
      headerName: "Remarks",
      width: 230,
      renderCell: (params) => {
        if (params.row.remark === '' || params.row.remark === null) {
          return (<span className='text-white'>--NA--</span>);
        }
        else {
          return (<span className='text-white'>{params.row.remark}</span>);
        }
      }
    },
  ];


  function formatDate(date) {

    var year = date.split("-")[0];
    var month = date.split("-")[1];
    var day = date.split("-")[2];
    return `${day}-${month}-${year}`;
  }



  const vendorcolumns = [
    {
      field: "edate",
      headerName: "Created At",
      width: 110,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const edate = params.row.edate;
        // Ensure edate is available and split it correctly
        const formattedDate = edate ? formatDate(edate.split("T")[0]) : '--NA--';

        return (
          <p className='text-white mt-2 f-14'>{formattedDate}</p>
        );
      }
    },

    {
      field: "creationTime",
      headerName: "Creation Time",
      width: 110,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const edate = params.row.edate;
        // Ensure edate is available and split it correctly
        const formattedTime = edate ? formatTime(edate.split("T")[1]) : '--NA--';

        return (
          <p className='text-white mt-2 f-14'>{formattedTime}</p>
        );
      }
    },

    {
      field: "scope_Of_Work",
      headerName: "Scope Of Work",
      width: 180,
      renderCell: (params) => {
        const scopeOfWork = params.row.scope_Of_Work;
        return (
          <span className='text-white'>{scopeOfWork || '--NA--'}</span>
        );
      }
    },

    {
      field: "mobile",
      headerName: "Mobile No.",
      width: 110,
      renderCell: (params) => {
        const mobile = params.row.mobile;
        return (
          <span className='text-white'>{mobile || '--NA--'}</span>
        );
      }
    },

    { field: "vendorName", headerName: "Company Name", width: 250 },

    {
      field: "contactName",
      headerName: "Contact Person",
      width: 180,
      renderCell: (params) => {
        const contactName = params.row.contactName;
        return (
          <span className='text-white'>{contactName || '--NA--'}</span>
        );
      }
    },

    {
      field: "contactMobile",
      headerName: "Contact Number",
      width: 110,
      renderCell: (params) => {
        const contactMobile = params.row.contactMobile;
        return (
          <span className='text-white'>{contactMobile || '--NA--'}</span>
        );
      }
    },

    {
      field: "address",
      headerName: "Address",
      width: 180,
      renderCell: (params) => {
        const address = params.row.address;
        return (
          <span className='text-white'>{address || '--NA--'}</span>
        );
      }
    },

    // Uncomment if needed
    // {
    //   field: "gstNumber",
    //   headerName: "GST Number",
    //   width: 105,
    //   renderCell: (params) => {
    //     const gstNumber = params.row.gstNumber;
    //     return (
    //       <span className='text-white'>{gstNumber || '--NA--'}</span>
    //     );
    //   }
    // },
  ];




  const StckAsstcolumns = [
    {
      field: "edate",
      headerName: "Created At",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params?.row?.edate?.split("T")[0]}</p>
        );
      }
    },



    { field: "particular_Type", headerName: "Particular Type", width: 180 },
    { field: "particular_Name", headerName: "Particular Name", width: 180 },
    { field: "new", headerName: "New", width: 90 },
    { field: "descriptions", headerName: "Descriptions", width: 200 },
    { field: "existing", headerName: "Existing", width: 150 },
    { field: "total", headerName: "Total", width: 90 },
    { field: "rate", headerName: "Rate", width: 90 },
    { field: "amount", headerName: "Amount", width: 90 },
    { field: "event_Referance", headerName: "Event Referance", width: 120 },
    {
      field: "purchase_Date",
      headerName: "Purchase Date",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params?.row?.purchase_Date?.split("T")[0]}</p>
        );
      }
    },

  ];

  const productionRiderCol = []

  const jobcolumns = [
    {
      field: "eDate",
      headerName: "Created At",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params?.row?.eDate?.split("T")[0]}</p>
        );
      }
    },
    {
      field: "head",
      headerName: "Particular Type",
      width: 180,
      renderCell: (params) => {
        if (params.row.head === '' || params.row.head === null) {
          return (<span className='text-white'>--NA--</span>);
        }
        else {
          return (<span className='text-white'>{params.row.head}</span>);
        }
      }
    },
    { field: "name", headerName: "Particular Name", width: 240 },
    { field: "length", headerName: "Size", width: 90 },
    { field: "qty", headerName: "Qty", width: 90 },
    { field: "rate", headerName: "Rate", width: 90 },
    { field: "amt", headerName: "Amt", width: 100 },
    {
      field: "remark",
      headerName: "Remarks",
      width: 500,
      renderCell: (params) => {
        if (params.row.remark === '' || params.row.remark === null) {
          return (<span className='text-white'>--NA--</span>);
        }
        else {
          return (<span className='text-white'>{params.row.remark}</span>);
        }
      }
    },
  ];


  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(":");
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert '0' to '12'
    return `${formattedHours}:${minutes} ${ampm}`;
  }

  const handleExcelUpload = (type) => {
    setSelectedExcelType(type);
    setEnableOprt(false);
    // Optionally fetch data or handle additional logic
  };


  const openBOQ = () => {
    //document.getElementById("BOQDiv").focus();
    let isBOQ = false;
    let BOQDOC = {};
    data[0]?.lst_documents?.filter((data, i) => {
      if (data.type === "BOQ_DOCUMENT") {
        isBOQ = true;
        BOQDOC = data;
      }
    }
    )
    isBOQ ?
      hadldleOpenEligibilityDoc(BOQDOC.path, BOQDOC.path.split(".")[BOQDOC.path.split(".").length - 1]) :
      alert("No BOQ Found For " + data[0].projectName);
  }
  const setexcelUopload = (type) => {

    if (type == 'PROD_RDRR') {
      getProdRider();

    }
    if (type == 'PUCH_ORD') {
      getPurchaseOrder();

    }

    setSelectedExcelType(type);
    inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    inputRefTwo.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }
  const unRejectTenderPopUp = () => {
    setIsOpen(true);
  }
  const handalClosePopUp = () => {
    setIsOpen(false);
  }
  const unRejectTender = () => {
    setLoading(true);
    axios.post(UNREJECT_PROJECT, {
      "ROWPOs": projectName
    }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        window.location.reload();
      }
      else {
        alert("Somthing went wrong");
      }

    });

  }
  var tokectDetails = props;

  const componentRef = useRef();

  //SEND TO PREVIOUE PAGE
  const handleGoToPrv = (e) => {
    navigate(-1);
  }
  return (
    <div className='row'>
      {
        isLoading ? <div className='loaderDiv'>
        </div> : null
      }
      {
        props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ?
          <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
            {<SideBar ref={componentRef} />}
          </div> : null
      }
      {props.tokectDetails?.logintype === ALLOWED_USERTYPE.OPERATIONS ?
        <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
          {<SideBar ref={componentRef} />}
        </div> : null
      }

      <div className={`${props.tokectDetails?.logintype !== ALLOWED_USERTYPE.OPERATIONS
        ? 'col-md-12 col-12 col-sm-12 '
        : 'col-md-10 col-10 col-sm-10'} myview`}>
        <div className='px-4 '>
          <span className="cursor-pointer"><a className="mt-5" href="#" onClick={e => { handleGoToPrv(e) }}>Go Back</a></span>
          <ProjectInfo project={data[0]}  {...tokectDetails} />
          {data[0]?.state === TENDER_STATE.REJECTED ? <div className='text-center'><span className='h5 colorSec'>Reason for Reject : </span><span className='h6 text-white'>{data[0]?.remarks}</span></div> : null}
          <center>
            {
              <div className='details-li d-inline-block fw-bold pb-3'>
                <>
                  {/* <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "COSTING" || selectedExcelType === "BOQ" || selectedExcelType === "COST" ? "activeCls" : null}`} onClick={() => { setEnableOprt(true); setexcelUopload("COSTING"); }}>COSTING</button> */}
                  {/* <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "JOB_SHEET" ? "activeCls" : null}`} onClick={() => { setexcelUopload("JOB_SHEET"); setEnableOprt(false); }}>JOB SHEET</button> */}
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "VENDOR" ? "activeCls" : null}`} onClick={() => { setexcelUopload("VENDOR"); getallVendors(); setEnableOprt(false); }}>VENDOR CONTACT SHEET</button>
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "PROD_RDRR" ? "activeCls" : null}`} onClick={() => { setexcelUopload("PROD_RDRR"); setEnableOprt(false); }}>PRODUCTION RIDER</button>
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "PUCH_ORD" ? "activeCls" : null}`} onClick={() => { setexcelUopload("PUCH_ORD"); setEnableOprt(false); }}>PURCHASE ORDER</button>
                  {/* <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "STCK_ASST" ? "activeCls" : null}`} onClick={() => { setexcelUopload("STCK_ASST"); setEnableOprt(false); getStockByPro(); }}>STOCK / ASSEST</button> */}
                  {/* <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "PRO_CLSN" ? "activeCls" : null}`} onClick={() => { setexcelUopload("PRO_CLSN"); setEnableOprt(false); }}>PROJECT CLOSING</button> */}
                </>
              </div>

            }
            <div ref={inputRef}>
              {
                enableOprt ?
                  <div id='BOQDiv'>
                    <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "JOB_SHEET" ? "activeCls" : null}`} onClick={() => { openBOQ(); setEnableOprt(false); }} >BOQ DOCUMENT</button>
                    <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "COST" ? "activeCls" : null}`} onClick={() => { setexcelUopload("COST"); }} >INTERNAL COST SHEET</button>
                  </div> :
                  null
              }
            </div>
            <div>
              {
                selectedExcelType === "PUCH_ORD" ?
                  <div ref={inputRefTwo} id="costingDiv">
                    <ExcelUpload refreshData={getPurchaseOrder} title={"upload purchase order"} dwnPath={purchaseOrder} ProjectId={projectName} reloadFun={reloadFun} for={selectedExcelType} setLoading={setLoading} />
                    {PurchaseOrder?.length > 0 && (selectedExcelType === "PUCH_ORD") ?
                      <div className='table-responsive mt-3 emgncy'>
                        <table className="table table-bordered border-secondary tblHeader" id="rspsblSheet" ref={tableRef}>
                          <thead className='tblHeader'>
                            <tr>
                              <th scope="col text-white">S.No.</th>
                              <th scope="col text-white" className="d-none">Responsive</th>
                              <th scope="col text-white">Name</th>
                              <th scope="col text-white">Create Date</th>
                              <th scope="col text-white">Create Time</th>
                              <th scope="col text-white">Created By</th>

                              <th scope="col text-white">View  </th>
                            </tr>
                          </thead>
                          <tbody className='tblBdy'>
                            {
                              PurchaseOrder?.map((data, i) => {
                                return (<tr key={i}>
                                  <th scope="row">{i + 1}</th>
                                  <td className="d-none" >{data.responsive}</td>
                                  <td>{data.name}</td>
                                  <td>{data.edate.split("T")[0]}</td>
                                  <td>{formatTime(data.edate.split("T")[1])}</td>
                                  <td>{data.euser}</td>
                                  <td><button
                                    className='btn btn-success'
                                    onClick={() => handleViewClick(data.path)}
                                  >
                                    View
                                  </button></td>


                                </tr>);
                              }
                              )
                            }

                          </tbody>
                        </table>
                      </div>
                      :
                      ""
                    }
                  </div> : ""
              }
              {
                selectedExcelType === "VENDOR" ?
                  <div ref={inputRefTwo} id="costingDiv">
                    <ExcelUpload title={"upload Vendor Contact Sheet"} dwnPath={vendorSheetdefault} ProjectId={projectName} reloadFun={reloadFun} for={selectedExcelType} setLoading={setLoading} />
                    <DataGrids cardsList={vendor} columns={vendorcolumns} components={{
                      Toolbar: CustomToolbar,
                    }} />
                  </div> : ""
              }
              {/* {
                selectedExcelType === "JOB_SHEET" ?
                  <div ref={inputRefTwo} id="costingDiv">
                    <ExcelUpload title={"upload Job Sheet"} dwnPath={jobSheetdefault} ProjectId={projectName} reloadFun={reloadFun} for={selectedExcelType} setLoading={setLoading} />
                    <DataGrids cardsList={brandSheet} columns={jobcolumns} components={{
                      Toolbar: CustomToolbar,
                    }} />
                    <div className="totalcostprc text-white">
                      <p style={{ marginTop: "-5%" }}>
                        Total Amount :
                        <span style={{ fontWeight: "800" }}>
                          {" "}
                          &#x20b9; &nbsp; &nbsp;{countTotalbrand}
                        </span>
                      </p>
                    </div>
                  </div> : ""
              } */}

              {
                selectedExcelType === "PROD_RDRR" ?
                  <div ref={inputRefTwo} id="costingDiv">
                    <ExcelUpload refreshdata={getProdRider} title={"upload Production Rider Sheet"} dwnPath={jobSheetdefault} ProjectId={projectName} reloadFun={reloadFun} for={selectedExcelType} setLoading={setLoading} onUploadSuccess={(type) => handleExcelUpload(type)} />
                    {ProductRiderData?.length > 0 && (selectedExcelType === "PROD_RDRR") ?
                      <div className='table-responsive mt-3 emgncy'>
                        <table className="table table-bordered border-secondary tblHeader" id="rspsblSheet" ref={tableRef}>
                          <thead className='tblHeader'>
                            <tr>
                              <th scope="col text-white">S.No.</th>
                              <th scope="col text-white" className={selectedExcelType !== "RESPONSIBILITY_SHEET" ? "d-none" : ""}>Responsive</th>
                              <th scope="col text-white">Name</th>
                              <th scope="col text-white">Create Date</th>
                              <th scope="col text-white">Create Time</th>
                              <th scope="col text-white">Created By</th>

                              <th scope="col text-white">View  </th>
                            </tr>
                          </thead>
                          <tbody className='tblBdy'>
                            {
                              ProductRiderData?.map((data, i) => {
                                return (<tr key={i}>
                                  <th scope="row">{i + 1}</th>
                                  <td className={selectedExcelType !== "RESPONSIBILITY_SHEET" ? "d-none" : ""}>{data.responsive}</td>
                                  <td>{data.name}</td>
                                  <td>{data.edate.split("T")[0]}</td>
                                  <td>{formatTime(data.edate.split("T")[1])}</td>
                                  <td>{data.euser}</td>
                                  <button
                                    className='btn btn-success'
                                    onClick={() => handleViewClick(data.path)}
                                  >
                                    View
                                  </button>
                                  {/* //                        <td>{data.width}</td>
    //                        <td>{data.height}</td>
    //                        <td>{data.area}</td>
    //                        <td>{data.qty}</td>
    //                        <td>{data.unit}</td>
    //                        <td>{data.days}</td> */}
                                  {/* //                        <td>{data.rate}</td>
    //                        <td>{data.amt}</td>
    //                        <td>{data.remark}</td> */}
                                </tr>);
                              }
                              )
                            }

                          </tbody>
                        </table>
                      </div>
                      :
                      ""
                    }
                  </div> : ""
              }
              {
                selectedExcelType === "STCK_ASST" ?
                  <div ref={inputRefTwo} id="costingDiv">
                    <ExcelUpload title={"upload Stock or Assests Sheet"} dwnPath={assestSheetdefault} ProjectId={projectName} reloadFun={reloadFun} for={selectedExcelType} setLoading={setLoading} />
                    <DataGrids cardsList={stockAsset} columns={StckAsstcolumns} components={{
                      Toolbar: CustomToolbar,
                    }} />
                  </div> : ""
              }
            </div>
          </center>
        </div>
        <ErrorAlertBox isOpen={isOpenErr} setIsOpen={setIsOpenErr} heading={heading} errMsg={errMsg} />
        <SuccessAlertBox href={urlVal} isOpen1={isOpen1} setIsOpen1={setIsOpen1} heading1={heading1} successMsg={successMsg} />      </div>
    </div>)


}
export default ProjectDetailsOPT;