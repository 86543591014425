import React, { useEffect, useState } from "react";
import "./form.css";
import { useParams, useNavigate } from "react-router-dom";
import {
  CREATE_EMPLOYEE, UPDATE_EMPLOYEE, GET_EMPLOYEEBYID,
  CREATE_DOCUMENT, GET_DOCUMENTBYID, UPDATE_DOCUMETS,
  CREATE_HEADS, GET_HEADBYID, UPDATE_HEADS,
  GET_PROJECT, CREATE_ELEMENT, GET_ALL_PROJECTS,
  CREATE_CLIENT, GET_CLIENTBYID, UPDATE_CLIENT,
  CREATE_VENDOR, GET_VENDOR_BY_ID, UPDATE_VENDOR,
  GET_ALL_UMO, GET_ELEMENT_TYPE, cREATE_ELEMENT_TYPE, CREATE_PRO_CAT,
} from "../../../utiles/AllApis";
import axios from "axios";
import SuccessAlertBox from "../../../common/AlertBox/SuccessAlertBox";
import ErrorAlertBox from "../../../common/AlertBox/ErrorAlertBox";
import { onlynumerical } from "../../../GenericFunction/GenericFunction";
import { CabinSharp } from "@mui/icons-material";
import { Button } from "reactstrap";
const MasterFrom = (props) => {
  const navigate = useNavigate();
  const { masterName } = useParams();
  let URL = "";
  if (window.location.href.split("?").length === 1) {
    URL = window.location.href;
  }
  else {
    if (masterName === "client_master" || masterName === "cat_master") {
      URL = window.location.href.split('?')[1] === "FormProject" ? '/projectList/form' : `/manageMaster/${masterName}`;
    }
    else {
      URL = `/manageMaster/${masterName}`;
    }
  }
  const [isLoading, setLoading] = useState(false);
  const [isMarried, setIsMarried] = useState(true);
  const [isUnMarried, setIsUnMarried] = useState(false);
  const [errEmail, setErrorEmail] = useState("");
  const [errMobile, setErrorMobile] = useState("");
  const [heading, setHeading] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const [showDocFromByTypeForCmpny, setShowDocFromByTypeForCmpny] = useState(false);
  const [showDocFromByTypeForWork, setShowDocFromByTypeForWork] = useState(false);
  const [Empupdatebtn, setEmpupdatebtn] = useState(false)
  const [docuploadbtn, setdocuploadbtn] = useState(false)
  const [btnupdateclient, setbtnupdateclient] = useState(false)
  const [btnupdatevendor, setbtnupdatevendor] = useState(false)
  const [btnupdatehead, setbtnupdatehead] = useState(false)
  const [isdisabled, setisdisabled] = useState(false)
  const [successMsg, setsuccessMsg] = useState("");
  const [employeeShow, setEmployeeShow] = useState(false);
  const [departmentShow, setDepartmentShow] = useState(false);
  const [documentShow, setDocumentShow] = useState(false);
  const [headListShow, setHeadShow] = useState(false);
  const [elementListShow, setElementShow] = useState(false);
  const [catShow, setCatShow] = useState(false);
  const [typeListShow, setTypeListShow] = useState(false);
  const [vendorshow, setvendorshow] = useState(false)
  const [docs, submitDoc] = useState(null);
  const [docname, setdocname] = useState("");
  const [empAadhar, setempAadhar] = useState("");
  const [empPan, setEmpPan] = useState("");
  const [projectname, setprojectname] = useState("")
  const [documentname, setdocumentname] = useState("")
  const [UMOList, setUMOList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [documentType, setdocumentType] = useState("");
  const [proID, setProId] = useState("");
  const [personal, setpersonal] = useState(true)
  const [employement, setemployement] = useState(false);
  const [account, setaccount] = useState(false);
  const [eventVal, setEventVal] = useState(false);
  const [fyear, setFYear] = useState(false);
  const [formName, setFormName] = useState(
    masterName
      .split("_")[0]
      .trim()
      .replace(/^\w/, (c) => c.toUpperCase())
  );
  const [FinancialyearList, setFinancialyearList] = useState([
    { "value": "1990-1991" },
    { "value": "1991-1992" },
    { "value": "1992-1993" },
    { "value": "1993-1994" },
    { "value": "1993-1994" },
    { "value": "1993-1994" },
    { "value": "1993-1994" },
    { "value": "1993-1994" },
    { "value": "1993-1994" },
    { "value": "1993-1994" },
    { "value": "1993-1994" },
    { "value": "1994-1995" },
    { "value": "1995-1996" },
    { "value": "1996-1997" },
    { "value": "1997-1998" },
    { "value": "1998-1999" },
    { "value": "1999-2000" },
    { "value": "2001-2002" },
    { "value": "2002-2003" },
    { "value": "2003-2004" },
    { "value": "2004-2005" },
    { "value": "2005-2006" },
    { "value": "2006-2007" },
    { "value": "2007-2008" },
    { "value": "2008-2009" },
    { "value": "2009-2010" },
    { "value": "2010-2011" },
    { "value": "2011-2012" },
    { "value": "2012-2013" },
    { "value": "2013-2014" },
    { "value": "2014-2015" },
    { "value": "2015-2016" }
  ]);
  const [empDetails, setEmpDetails] = useState({
    "AsPerPf": true,
    "BiometricCode": "",
    "Department": "",
    "EDOG": "1990-01-01",
    "EDOI": "1990-01-01",
    "EDOJGRP": "1990-01-01",
    "EDOL": "1990-01-01",
    "EDOP": "1990-01-01",
    "FirstName": "",
    "HiringDate": "1990-01-01",
    "LastName": "",
    "Mail": "",
    "password": "",
    "MiddleName": "",
    "Phone": "",
    "bMarried": false,
    "bankac": "",
    "bankacname": "",
    "bankhname": "",
    "cadd1": "",
    "cadd2": "",
    "ccity": "",
    "cphone": "",
    "cpin": "",
    "cstate": "",
    "currRate": "",
    "currency": "",
    "dblot": false,
    "depend": "",
    "dependents": 0,
    "dtDOB": "1990-01-01",
    "ecategory": "",
    "edepartment": "",
    "egrade": "",
    "elevel": "",
    "elocation": "",
    "employer_percentage": "",
    "eshift": "",
    "eslPcnt": 0,
    "eslempPcnt": 0,
    "eslno": "",
    "ifsccode": "",
    "nameOfWeeksDay": "",
    "nationality": "",
    "nigthAllow": 0,
    "otrate": "",
    "otttype": "",
    "padd1": "",
    "padd2": "",
    "paymentMods": "",
    "pcity": "",
    "pfno": "",
    "pfprcnt": "",
    "pphone": "",
    "ppin": "",
    "pstate": "",
    "religon": "",
    "retirement": "",
    "salarytype": "",
    "strDesignation": "",
    "strEmergContactName": "",
    "strInitial": "",
    "strLeaveReason": "",
    "strLocation": "",
    "strQualification": "",
    "timesheet": "",
    "userType": "",
    "emgcyTel": "",
    "contractEmp": 0
  });


  const handledocs = (e, type) => {
    var name = e.target.files[0].name.split("");

    var validate = name.reverse().join("").split(".")[0];
    if (type === "EMP_AADAHR" || type === "EMP_PAN") {
      if (e.target.files[0].name.split(".")[e.target.files[0].name.split(".").length - 1] !== "jpg" && e.target.files[0].name.split(".")[e.target.files[0].name.split(".").length - 1] !== "jpeg" && e.target.files[0].name.split(".")[e.target.files[0].name.split(".").length - 1] !== "png") {
        alert("Only Image files Allow");
        e.target.value = null;
      }
      if (type === "EMP_AADAHR") {
        setempAadhar(e.target.files[0]);
      }
      else if (type === "EMP_PAN") {
        setEmpPan(e.target.files[0]);
      }

    }
    else {
      if (e.target.files[0].name.split(".")[e.target.files[0].name.split(".").length - 1] !== "jpg" && e.target.files[0].name.split(".")[e.target.files[0].name.split(".").length - 1] !== "jpeg" && e.target.files[0].name.split(".")[e.target.files[0].name.split(".").length - 1] !== "png" && e.target.files[0].name.split(".")[e.target.files[0].name.split(".").length - 1] !== "pdf") {
        alert("Only Image and PDF files Allow");
        e.target.value = null;
      }
    }
    submitDoc(e.target.files[0]);
  };

  const [doc, setDocMaster] = useState({
    Name: "",
    TypeID: documentType,
  });
  const [dpt, setDptMaster] = useState({
    salutation: "",
    address: "",
    mobile: "",
    clientName: "",
    contactName: "",
    contactMobile: "",
    gstNumber: "",
    control: "",
    email: "",
    designation: ""
  });
  const [vendor, setvendorMaster] = useState({
    vendorName: "",
    address: "",
    mobile: "",
    contactName: "",
    contactMobile: "",
    gstNumber: "",
    email: "",
    control: ""
  })
  const [head, setHeadMaster] = useState({
    Name: "",
    TypeID: "EXPENSE_HEAD",
  });
  const [category, setCategoryMaster] = useState({
    Name: "",
    TypeID: "PRO_CAT",
  });
  const [element, setElementMaster] = useState({
    Name: "",
    TypeID: "ELEMENT",
  });
  const [cat, setCatMaster] = useState({
    Name: "",
  });
  //for element type
  var queryBCD = window.location.href.split("=")[1];
  const [elementType, setelementType] = useState({
    Name: "",
    Description: "",
    RUOM: "",
    BCD: queryBCD,
    itemType: ""
  });
  const getElementTypeVal = (e) => {

    setelementType({ ...elementType, [e.target.name]: e.target.value })
  }
  const [isOpen, setIsOpen] = useState(false);
  const handalOpen = () => {
    setIsOpen(true);
  };
  const [isOpen1, setIsOpen1] = useState(false);
  const handalOpen1 = () => {
    setIsOpen1(true);
  };
  useEffect(() => {

    if (window.location.href.split("&").length > 1) {
      setProId(window.location.href.split("&")[1].split('?')[1]);
    }
    let urlId = "";
    if (window.location.href.split("=").length - 1 === 2) {
      urlId = window.location.href.split("=")[window.location.href.split("=").length - 1];
    }
    setLoading(true);
    axios.get(GET_ALL_PROJECTS).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        var ProWithotWorkOrder = response.data.data.filter((data => {
          if (data.lst_InternalDocuments.length === 0 && (data.state !== "DRAFT" || data.state !== "REJECTED  ")) {
            return data;
          }
        }))
        setProjectList(ProWithotWorkOrder);
      }
      else {
        if (urlId !== "") {
          setHeading("Error!");
          setErrMsg(`Opps.. SomeThing went Wrong`);
          handalOpen();
        }
      }
    });


    if (masterName === "employee_master") {
      setEmployeeShow(true);
      var thisrowpos = window.location.href.split("=")[1];

      setLoading(true);
      axios.post(GET_EMPLOYEEBYID, { "ROWPOS": thisrowpos }).then((response) => {
        setLoading(false);
        ;
        if (response.data.isSuccess === true) {
          setEmpupdatebtn(true);
          setEmpDetails(response.data.data[0]);
          if (response.data.data[0].bMarried) {
            setIsMarried(response.data.data[0].bMarried);
            setIsUnMarried(false);
          }
          else {
            setIsMarried(response.data.data[0].bMarried);
            setIsUnMarried(true);
          }

          setisdisabled(true);
        }
        else {
          if (urlId !== "") {
            setHeading("Error!");
            setErrMsg(`Opps.. SomeThing went Wrong`);
            handalOpen();
          }
          setEmpupdatebtn(false);
        }
      })


      //get employe by id  call to get specific emp data
    }
    else if (masterName === "client_master") {
      setDepartmentShow(true);
      var thisrowpos = window.location.href.split("=")[1];
      setDptMaster({ ...dpt, "rowpos": thisrowpos });
      setLoading(true);
      axios.post(GET_CLIENTBYID, { "ROWPOS": thisrowpos }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setbtnupdateclient(true);
          //setDetails(response.data.data);
          setDptMaster(response.data.data);
        }
        else {
          if (urlId !== "") {
            setHeading("Error!");
            setErrMsg(`Opps.. SomeThing went Wrong`);
            handalOpen();
          }

        }
      })
    } else if (masterName === "document_master") {
      setDocumentShow(true);
      var thisrowpos = window.location.href.split("=")[1]
      setLoading(true);
      axios.post(GET_DOCUMENTBYID, { "ROWPOS": thisrowpos }).then((response) => {
        setLoading(false);
        if (response.data.data !== null) {
          setdocuploadbtn(true)
          setdocname(response.data.data[0].name)
        }
        else {
          if (urlId !== "") {
            setHeading("Error!");
            setErrMsg(`Opps.. SomeThing went Wrong`);
            handalOpen();
          }

        }

      })
    }
    else if (masterName === "vendor_master") {
      setvendorshow(true);

      var thisrowpos = window.location.href.split("=")[1];
      setvendorMaster({ ...vendor, "rowpos": thisrowpos });
      setLoading(true);
      axios.post(GET_VENDOR_BY_ID, { "ROWPOS": thisrowpos }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          //setDetails(response.data.data);
          setvendorMaster(response.data.data);
          setbtnupdatevendor(true);
          setisdisabled(true);
        }
        else {
          if (urlId !== "") {
            setHeading("Error!");
            setErrMsg(`Opps.. SomeThing went Wrong`);
            handalOpen();
          }

        }

      })
    }

    else if (masterName === "head_master") {
      setHeadShow(true);
      var thisrowpos = window.location.href.split("=")[1];
      setLoading(true);
      axios.post(GET_HEADBYID, { "ROWPOS": thisrowpos }).then((response) => {
        setLoading(false);
        if (response.data.data !== null) {
          setbtnupdatehead(true)
          setisdisabled(true)
          document.getElementById("headsName").value = response.data.data.name;
        }
        else {
          if (urlId !== "") {
            setHeading("Error!");
            setErrMsg(`Opps.. SomeThing went Wrong`);
            handalOpen();
          }

        }

      })
    }
    else if (masterName === "element_master") {
      setElementShow(true);
      var thisrowpos = window.location.href.split("=")[1];
      setLoading(true);
      axios.post(GET_HEADBYID, { "ROWPOS": thisrowpos }).then((response) => {
        setLoading(false);
        if (response.data.data !== null) {
          setbtnupdatehead(true)
          setisdisabled(true)
          document.getElementById("headsName").value = response.data.data.name;
        }
        else {
          if (urlId !== "") {
            setHeading("Error!");
            setErrMsg(`Opps.. SomeThing went Wrong`);
            handalOpen();
          }

        }

      })
    }
    else if (masterName === "type_master") {
      setTypeListShow(true);
      var thisrowpos = window.location.href.split("=")[1];
      setLoading(true);
      axios.get(GET_ALL_UMO).then((response) => {

        setLoading(false);
        if (response.data.data !== null) {
          setbtnupdatehead(true)
          setisdisabled(true)
          setUMOList(response.data.data);
        }
        else {
          if (urlId !== "") {
            setHeading("Error!");
            setErrMsg(`Opps.. SomeThing went Wrong`);
            handalOpen();
          }

        }

      })
    }
    else if (masterName === "category_master") {
      setCatShow(true);
      var thisrowpos = window.location.href.split("=")[1];
      setLoading(true);
      axios.post(GET_HEADBYID, { "ROWPOS": thisrowpos }).then((response) => {
        setLoading(false);
        if (response.data.data !== null) {
          setbtnupdatehead(true)
          setisdisabled(true)
          document.getElementById("headsName").value = response.data.data.name;
        }
        else {
          if (urlId !== "") {
            setHeading("Error!");
            setErrMsg(`Opps.. SomeThing went Wrong`);
            handalOpen();
          }

        }

      })
    }
  }, []);

  const onSubmitHandeling = () => {
    if (masterName === "employee_master") {
      if (
        empDetails.strInitial === "" ||
        empDetails.firstName === "" ||
        empDetails.lastName === "" ||
        empDetails.EmployeeEmail === "" ||
        empDetails.EmployeeMobile === "" ||
        empDetails.department === "" ||
        empDetails.HiringDate === "" ||
        empDetails.strDesignation === "" ||
        empDetails.dtDOB === "" || empDetails.strQualification == "" ||
        empDetails.location === ""
      ) {
        setHeading("Submission Failed!");
        setErrMsg("Fields Cannot Empty");
        handalOpen();
        return;
      }
      else {
        if (errEmail !== "") {
          setHeading("Submission Failed!");
          setErrMsg("Enter valid Email ID");
          handalOpen();
          return;
        } else if (empDetails?.EmployeeMobile?.length < 10) {
          setHeading("Submission Failed!");
          setErrMsg("Enter valid Phone Number");
          handalOpen();
          return;
        }
        setLoading(false);
        axios.post(CREATE_EMPLOYEE, empDetails).then((response) => {
          setLoading(false);
          console.log(response)

          if (response.data.isSuccess === true) {

            if (empAadhar !== "") {
              udloadEmpDoc(response.data.data.rowpos, empAadhar, "EMP_AADHAR");
            }
            if (empPan !== "") {
              udloadEmpDoc(response.data.data.rowpos, empPan, "EMP_PAN");
            }
            setsuccessMsg("Employee Add Successfully.");
            setHeading1("Success");
            handalOpen1();
          } else {
            setHeading("Creation Failed!");
            setErrMsg(response.data.message);
            handalOpen();
          }
        });
      }
    }

    else if (masterName === "client_master") {
      if (dpt.clientName === "" || dpt.address === "") {
        setHeading("Submission Failed!");
        setErrMsg("Fields Cannot Empty");
        handalOpen();
        return;
      }
      if (dpt.mobile !== "") {
        if (dpt.mobile.length < 9) {
          setHeading("Submission Failed!");
          setErrMsg("Mobile No cannot less then 10 digit");
          handalOpen();
          return;
        }
      }
      if (dpt.contactMobile !== "") {
        if (dpt.contactMobile.length < 9) {
          setHeading("Submission Failed!");
          setErrMsg("Contact No cannot less then 10 digit");
          handalOpen();
          return;
        }
      }
      if (dpt.salutation !== "") {
        console.log(dpt.salutation)
      }
      if (errEmail !== "") {
        setHeading("Submission Failed!");
        setErrMsg("Invalid Email format");
        handalOpen();
        return;
      }
      setLoading(true);
      axios.post(CREATE_CLIENT, dpt).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setHeading1("Success");
          setsuccessMsg("client Add Successfully");
          handalOpen1();

        } else {
          setHeading("Creation Failed!");
          setErrMsg(response.data.message);
          handalOpen();
        }
      });

    }

    else if (masterName === "vendor_master") {

      if (vendor.vendorname === "" || vendor.address === "") {
        setHeading("Submission Failed!");
        setErrMsg("Fields Cannot Empty");
        handalOpen();
        return;
      }
      if (vendor.mobile !== "") {
        if (vendor.mobile.length < 9) {
          setHeading("Submission Failed!");
          setErrMsg("Mobile No cannot less then 10 digit");
          handalOpen();
          return;
        }
      }
      if (vendor.contactMobile !== "") {
        if (vendor.contactMobile.length < 9) {
          setHeading("Submission Failed!");
          setErrMsg("Contact No cannot less then 10 digit");
          handalOpen();
          return;
        }
      }
      if (errEmail !== "") {
        setHeading("Submission Failed!");
        setErrMsg("Invalid Email format");
        handalOpen();
        return;
      }
      setLoading(true);
      axios.post(CREATE_VENDOR, vendor).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setsuccessMsg("Vendor Add Successfully.");
          setHeading1("Success");
          handalOpen1();
        } else {
          setHeading("Creation Failed!");
          setErrMsg(response.data.message);
          handalOpen();
        }
      });

    }

    else if (masterName === "document_master") {

      if (documentType === "") {
        setHeading("Submission Failed!");
        setErrMsg("Please Select Document Type");
        handalOpen();
        return;
      }
      else {
        if (documentType === "COMPANY_DOCUMENT") {
          if (docname === "" || docs === "") {
            setHeading("Submission Failed!");
            setErrMsg("All Fields are Mandatory");
            handalOpen();
            return;
          }
        }
        else if (documentType === "WORKORDER_DOCUMENT") {
          if (docname === "" || docs === "" || projectname === "") {
            setHeading("Submission Failed!");
            setErrMsg("All Fields are Mandatory");
            handalOpen();
            return;
          }
        }
        var FormData = require("form-data");
        var data = new FormData();
        data.append("File", docs);
        data.append("ID", projectname);
        data.append("SubType", docname);
        data.append("name", docname);
        data.append("Type", documentType);
        data.append("EventVal", eventVal);
        data.append("Fyear", fyear);

        var config = {
          method: "post",
          url: CREATE_DOCUMENT,

          data: data,
        };
        setLoading(true);
        axios(config)
          .then(function (response) {
            setLoading(false);
            if (response.data.isSuccess === true) {
              setHeading1("Success")
              setsuccessMsg(response.data.message);
              handalOpen1();
              document.getElementById('updoc').value = "";
              document.getElementById('updoc').value = "";
              setdocname("");
              setprojectname("");
              setdocumentname("");
              setdocumentType("");

            } else {

              setHeading("Creation Failed!");
              setErrMsg(`Opps.. SomeThing went Wrong`);
              handalOpen();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      //    }  );
    }
    else if (masterName === "head_master") {
      if (head.Name === "") {
        setHeading("Submission Failed!");
        setErrMsg("Fields Cannot Empty");
        handalOpen();
        return;
      } else {
        setLoading(true);
        axios.post(CREATE_HEADS, head).then((response) => {
          setLoading(false);
          if (response.data.isSuccess === true) {
            setHeading1("Success");
            setsuccessMsg("Head Add Successfully");
            handalOpen1();
            setHeadMaster({ ...head, "Name": "" });
          } else {
            setHeading("Creation Failed!");
            setErrMsg(`Opps.. SomeThing went Wrong`);
            handalOpen();
          }
        });
      }
    }
    else if (masterName === "element_master") {
      if (element.Name === "") {
        setHeading("Submission Failed!");
        setErrMsg("Fields Cannot Empty");
        handalOpen();
        return;
      } else {
        setLoading(true);
        axios.post(CREATE_ELEMENT, element).then((response) => {
          setLoading(false);
          if (response.data.isSuccess === true) {
            setHeading1("Success");
            setsuccessMsg("Element Created Successfully");
            handalOpen1();
            setHeadMaster({ ...head, "Name": "" });
          } else {
            setHeading("Creation Failed!");
            setErrMsg(`Opps.. SomeThing went Wrong`);
            handalOpen();
          }
        });
      }
    }
    else if (masterName === "type_master") {
      if (elementType.Name === "" || elementType.Description === "" || elementType.RUOM === "" || elementType.BCD === "" || elementType.itemType === "") {
        setHeading("Submission Failed!");
        setErrMsg("Fields Cannot Empty");
        handalOpen();
        return;
      } else {
        setLoading(true);
        ;
        axios.post(cREATE_ELEMENT_TYPE, elementType).then((response) => {
          setLoading(false);
          if (response.data.isSuccess === true) {
            setHeading1("Success");
            setsuccessMsg("Element Type Created Successfully");
            handalOpen1();
          } else {
            setHeading("Creation Failed!");
            setErrMsg(`Opps.. SomeThing went Wrong`);
            handalOpen();
          }
        });
      }
    }
    else if (masterName === "category_master") {
      if (cat.Name === "") {
        setHeading("Submission Failed!");
        setErrMsg("Fields Cannot Empty");
        handalOpen();
        return;
      } else {
        setLoading(true);
        axios.post(CREATE_PRO_CAT, cat).then((response) => {
          setLoading(false);
          if (response.data.isSuccess === true) {
            setHeading1("Success");
            setsuccessMsg("Category Created Successfully");
            handalOpen1();
            setCatMaster({ ...cat, "Name": "" });
          } else {
            setHeading("Creation Failed!");
            setErrMsg(`Opps.. SomeThing went Wrong`);
            handalOpen();
          }
        });
      }
    }
  };
  const udloadEmpDoc = (rowpos, Document, typ) => {
    var FormData = require("form-data");
    var data = new FormData();
    let SubType = `${rowpos}_${typ}`
    data.append("File", Document);
    data.append("ID", rowpos);
    data.append("SubType", SubType);
    data.append("name", SubType);
    data.append("Type", typ);
    var config = {
      method: "post",
      url: CREATE_DOCUMENT,

      data: data,
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        if (response.data.isSuccess === true) {

        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const UpdateUdloadEmpDoc = (rowpos, Document, typ) => {
    var FormData = require("form-data");
    var data = new FormData();
    let SubType = `${rowpos}_${typ}`
    data.append("File", Document);
    data.append("ID", rowpos);
    data.append("SubType", SubType);
    data.append("name", SubType);
    data.append("Type", typ);
    var config = {
      method: "post",
      url: UPDATE_DOCUMETS,

      data: data,
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        if (response.data.isSuccess === true) {

        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const onupdateHandeling = () => {
    URL = `/manageMaster/${window.location.href.split("=")[1]}`;
    var thisrowpos = window.location.href.split("=")[1]
    if (document.getElementById("firstName").value === "" || document.getElementById("lastName").value === "" ||
      document.getElementById("empEmail").value === "" || document.getElementById("empMobile").value === "" ||
      document.getElementById("dprtName").value === "" || document.getElementById("HiringDate").value === ""
      || document.getElementById("strDesignation").value === "" || document.getElementById("strInitial").value === ""
      || document.getElementById("strLocation").value === "" || document.getElementById("dtDOB").value === ""
      || document.getElementById("strQualification").value === "") {
      setHeading("Submission Failed!");
      setErrMsg(`Fields Cannot empty!!`);
      handalOpen();
    }
    else {
      setLoading(true);
      let maritalStatus;
      if (document.getElementById("bMarried").value === "Married") {
        maritalStatus = true;
      }
      else {
        maritalStatus = false;
      }
      axios.post(UPDATE_EMPLOYEE,
        empDetails
      ).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          if (empAadhar !== "") {
            UpdateUdloadEmpDoc(response.data.data.rowpos, empAadhar, "EMP_AADHAR");
          }
          if (empPan !== "") {
            UpdateUdloadEmpDoc(response.data.data.rowpos, empPan, "EMP_PAN");
          }
          setsuccessMsg("Employee Details Updated Successfully.");
          setHeading1("Success");
          handalOpen1();
        }
        else {
          setHeading("Updation Failed!");
          setErrMsg(`Opps.. SomeThing went Wrong`);
          handalOpen();
        }
      })
    }
  }
  const handleupdatedoc = () => {
  }
  const updateclientbtn = () => {
    URL = `/manageMaster/${window.location.href.split("=")[1]}`;
    var thisrowpos = window.location.href.split("=")[1];
    if (document.getElementById("ClientName").value === "" ||
      document.getElementById("ClientAddress").value === "") {
      setHeading("Submission Failed!");
      setErrMsg("Fields Cannot Empty");
      handalOpen();
      return;
    }
    if (document.getElementById("ClientPhnumber").value !== "") {
      if (document.getElementById("ClientPhnumber").value.length < 9) {
        setHeading("Error!");
        setErrMsg(`Mobile no. must be 10 digit`);
        handalOpen();
        return;
      }
    }
    if (document.getElementById("ClientContactnumber").value !== "") {
      if (document.getElementById("ClientContactnumber").value.length < 9) {
        setHeading("Error!");
        setErrMsg(`Client Contact no. must be 10 digit`);
        handalOpen();
        return;
      }
    }
    setLoading(true);


    axios.post(UPDATE_CLIENT, dpt
      // {
      // "ROWPOS": thisrowpos,
      // "ClientName": document.getElementById("ClientName").value,
      // "Address":  document.getElementById("ClientAddress").value,
      // "Mobile": document.getElementById("ClientPhnumber").value,
      // "ContactName": document.getElementById("ClientContactname").value,
      // "ContactMobile":  document.getElementById("ClientContactnumber").value,
      // "GSTNumber":  document.getElementById("ClientGstnumber").value,
      // "email":  document.getElementById("ClientEmail").value

      // }
    ).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setsuccessMsg("Client Updated Successfully.");
        setHeading1("Success");
        handalOpen1();
      }
      else {
        setHeading("Updation Failed!");
        setErrMsg(`Opps.. SomeThing went Wrong`);
        handalOpen();

      }

    })

  }

  const updatevendorbtn = () => {
    URL = `/manageMaster/${window.location.href.split("=")[1]}`;
    var thisrowpos = window.location.href.split("=")[1];
    if (document.getElementById("venName").value === "" ||
      document.getElementById("venaddress").value === "") {
      setHeading("Submission Failed!");
      setErrMsg("Fields Cannot Empty");
      handalOpen();
      return;
    }
    if (document.getElementById("venphnumber").value !== "") {
      if (document.getElementById("venphnumber").value.length < 9) {
        setHeading("Error!");
        setErrMsg(`Mobile no. must be 10 digit`);
        handalOpen();
        return;
      }
    }
    if (document.getElementById("vencontactnum").value !== "") {
      if (document.getElementById("vencontactnum").value.length < 9) {
        setHeading("Error!");
        setErrMsg(`Client Contact no. must be 10 digit`);
        handalOpen();
        return;
      }
    }

    setLoading(true);
    axios.post(UPDATE_VENDOR, vendor
      //   {
      //     "ROWPOS": thisrowpos,
      //     "VendorName": document.getElementById("venName").value,
      //     "Address":  document.getElementById("venaddress").value,
      //     "Mobile": document.getElementById("venphnumber").value,
      //     "ContactName": document.getElementById("venconName").value,
      //     "ContactMobile":  document.getElementById("vencontactnum").value,
      //     "GSTNumber":  document.getElementById("vengstnum").value,
      //     "email":  document.getElementById("vendorChange").value
      // }
    ).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setsuccessMsg("Vendor Updated Successfully.");
        setHeading1("Success");
        handalOpen1();
      }
      else {
        setHeading("Updation Failed!");
        setErrMsg(`Opps.. SomeThing went Wrong`);
        handalOpen();
      }
    })

  }
  const updateheadbtn = () => {
    URL = `/manageMaster/${window.location.href.split("=")[1]}`;
    var thisrowpos = window.location.href.split("=")[1];
    if (head.Name === "") {
      setHeading("Submission Failed!");
      setErrMsg("Fields Cannot Empty");
      handalOpen();
      return;
    }
    else {
      setLoading(true);
      axios.post(UPDATE_HEADS,
        {
          "ROWPOS": thisrowpos,
          "Name": document.getElementById("headsName").value,
          "TypeID": "EXPENSE_HEAD"
        }).then((response) => {
          setLoading(false);
          if (response.data.isSuccess === true) {
            setsuccessMsg("Head Updated Successfully.");
            setHeading1("Success");
            handalOpen1();
          }
          else {
            setHeading("Updation Failed!");
            setErrMsg(`Opps.. SomeThing went Wrong`);
            handalOpen();

          }
        })
    }

  }
  const updateCategorybtn = () => {
    URL = `/manageMaster/${window.location.href.split("=")[1]}`;
    var thisrowpos = window.location.href.split("=")[1];
    if (head.Name === "") {
      setHeading("Submission Failed!");
      setErrMsg("Fields Cannot Empty");
      handalOpen();
      return;
    }
    else {
      setLoading(true);
      axios.post(UPDATE_HEADS,
        {
          "ROWPOS": thisrowpos,
          "Name": document.getElementById("headsName").value,
          "TypeID": "EXPENSE_HEAD"
        }).then((response) => {
          setLoading(false);
          if (response.data.isSuccess === true) {
            setsuccessMsg("Head Updated Successfully.");
            setHeading1("Success");
            handalOpen1();
          }
          else {
            setHeading("Updation Failed!");
            setErrMsg(`Opps.. SomeThing went Wrong`);
            handalOpen();

          }
        })
    }

  }


  const deptChange = (e) => {
    if (e.target.id === "clientEmail") {
      let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (e.target.value.match(emailValidate)) {
        setErrorEmail("");
      } else {
        setErrorEmail("* Please enter valid Email ID");
        //e.target.value="";
      }
    }
    setDptMaster({ ...dpt, [e.target.name]: e.target.value });
  };
  const vendorChange = (e) => {
    if (e.target.id === "vendorEmail") {
      let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (e.target.value.match(emailValidate)) {
        setErrorEmail("");
      } else {
        setErrorEmail("* Please enter valid Email ID");
        // e.target.value="";
      }
    }
    setvendorMaster({ ...vendor, [e.target.name]: e.target.value });
  };

  const headsChange = (e) => {
    setHeadMaster({ ...head, [e.target.name]: e.target.value });
  };
  //Get Element value
  const elementChange = (e) => {
    setElementMaster({ ...element, [e.target.name]: e.target.value });
  };
  //--------for get cat values
  const catChange = (e) => {
    setCatMaster({ ...cat, [e.target.name]: e.target.value });
  };
  const empChange = (e) => {

    if (e.target.id === "empEmail") {
      let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (e.target.value.match(emailValidate)) {
        setErrorEmail("");
      } else {
        setErrorEmail("* Please enter valid Email ID");
      }
    }
    if (e.target.name === "bMarried") {
      setIsMarried(true);
      setIsUnMarried(false);
      if (e.target.value === "Married") {
        e.target.value = true;
      }
      else if (e.target.value === "Unmarried") {
        setIsMarried(false);
        setIsUnMarried(true);
        e.target.value = false;
      }
    }
    setEmpDetails({ ...empDetails, [e.target.name]: e.target.value });
  };
  const onChangeDocType = (DocTypeVal) => {
    setdocumentType(DocTypeVal);
    if (DocTypeVal === "WORKORDER_DOCUMENT" || DocTypeVal === "COMPLITION_CERTIFICATE") {
      setShowDocFromByTypeForCmpny(false);
      setShowDocFromByTypeForWork(true);
    }
    else if (DocTypeVal === "COMPANY_DOCUMENT") {
      setShowDocFromByTypeForCmpny(true);
      setShowDocFromByTypeForWork(false);
    }
    else {
      setShowDocFromByTypeForCmpny(false);
      setShowDocFromByTypeForWork(false);
    }

  }



  // form page 

  const handlepersonal = () => {
    setpersonal(true)
    setemployement(false)
    setaccount(false)
  }
  const handleemployement = () => {
    setpersonal(false)
    setemployement(true)
    setaccount(false)
  }
  const handleaccount = () => {
    setpersonal(false)
    setemployement(false)
    setaccount(true)
  }
  return (
    <div className="ligthBg formbg">
      {
        isLoading ? <div className='loaderDiv'>
        </div> : null
      }
      <div className="">
        <div className="row">
          <div className="col-sm-3 col-md-3 col-12"></div>
          <div
            className="formBg"
          >
            <h4 className="py-3 text-center theamColor">Add New {formName}</h4>
            {documentShow ? (
              <>
                <div >
                  <center><h4 className="theamColor mt-3 mb-5">UPLOAD DOCUMENT</h4>
                    <div className="my-3 w-25">
                      <label className="form-label h6 mb-3">SELECT DOCUMENT TYPE<span className='required'>*</span></label> <br />
                      <select
                        className="form-control mt-3"
                        aria-label="Default select example"
                        id="dprtcategory"
                        name="docutyp"
                        value={documentType}
                        onChange={(e) => {
                          onChangeDocType(e.target.value);
                        }}
                      >
                        <option value="">--Select Document Type--</option>
                        <option value="WORKORDER_DOCUMENT">Work Order</option>
                        <option value="COMPLITION_CERTIFICATE">Completion Certificate</option>
                        <option value="COMPANY_DOCUMENT">Company Document</option>
                      </select>
                    </div></center>
                  <br /><br />
                  <div className="px-7">
                    {
                      showDocFromByTypeForCmpny ?
                        <div className="row">
                          <div className="col-md-4 col-sm-4 col-4">
                            <div className="my-3 w-75">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label h6"
                              >
                                Document Name<span className='required'>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control mt-3"
                                id="docName"
                                name="Name"
                                placeholder="Enter Document Name Here..."
                                value={docname}
                                onChange={(e) => {
                                  setdocname(e.target.value);
                                }}
                              />
                            </div>

                          </div>
                          <div className="col-md-4 col-sm-4 col-4">
                            <div className="my-3 w-75">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label h6"
                              >
                                Upload DOCUMENTS<span className='required'>*</span>
                              </label>
                              <input
                                type="file"
                                id="updoc"
                                className="form-control mt-3"
                                onChange={(e) => handledocs(e)}
                                placeholder="Enter Document Name Here.."
                              />
                            </div>
                          </div>
                        </div> : null
                    }
                    {
                      showDocFromByTypeForWork ?
                        <>
                          <div className="row">
                            <div className="col-md-4 col-sm-4 col-4">
                              <div className="my-3 w-75">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label h6"
                                >
                                  Document Name<span className='required'>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="docName"
                                  name="Name"
                                  placeholder="Enter Document Name Here.."
                                  value={docname}
                                  onChange={(e) => {
                                    setdocname(e.target.value);
                                  }}
                                />
                              </div>

                            </div>
                            <div className="col-md-4 col-sm-4 col-4">
                              <div className="my-3 w-75">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label h6"
                                >
                                  Upload DOCUMENTS<span className='required'>*</span>
                                </label>
                                <input
                                  type="file"
                                  id="updoc"
                                  className="form-control"
                                  onChange={(e) => handledocs(e)}
                                  placeholder="Enter Document Name Here..."
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-4">
                              <div className="my-3 w-75">
                                <label className="form-label h6">Project Name<span className='required'>*</span></label> <br />
                                <select
                                  className="form-control mt-3"
                                  aria-label="Default select example"
                                  id="dprtName"
                                  name="Department"
                                  value={projectname}
                                  onChange={(e) => {
                                    setprojectname(e.target.value);
                                  }}

                                >
                                  <option value="">--Select Project--</option>
                                  {projectList?.map((option, index) => (
                                    <option key={index} value={option.rowpos}>
                                      {option.projectName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-4">
                              <div className="my-3 w-75">
                                <label className="form-label h6">Select Category<span className='required'>*</span></label> <br />
                                <select
                                  className="form-control mt-3"
                                  aria-label="Default select example"

                                  id="dprtcategory"
                                  name="Department"
                                  value={documentname}
                                  onChange={(e) => {
                                    setdocumentname(e.target.value);
                                  }}

                                >
                                  <option value="">---Select Category--</option>
                                  {projectList?.map((option, index) => (
                                    <option key={index} value={option.category}>
                                      {option.category}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-4">
                              <div className="my-3 w-75">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label h6"
                                >
                                  Event value
                                </label>
                                <input
                                  type="text"
                                  id="eventVal"
                                  className="form-control mt-3"
                                  onChange={(e) => setEventVal(e.target.value)}
                                  placeholder="Enter Event Value Here..."
                                  onKeyPress={onlynumerical}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-4">
                              <div className="my-3 w-75">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label h6"
                                >
                                  Financial Year
                                </label>
                                <select
                                  className="form-control mt-3"
                                  aria-label="Default select example"

                                  id="dprtName"
                                  name="Department"
                                  //  value={projectname}
                                  onChange={(e) => {
                                    setFYear(e.target.value);
                                  }}

                                >
                                  <option value="">--Select Financial Year--</option>
                                  {FinancialyearList?.map((option, index) => (
                                    <option key={index} value={option.value}>
                                      {option.value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                        </> : null
                    }
                  </div>

                </div>
                <br /><br />

                {
                  docuploadbtn ?
                    <center><button className="btn btnSuccess mx-1" onClick={handleupdatedoc}>update</button></center>
                    :
                    <center>
                      <center>
                        <button
                          className="btn btnSuccess mx-1"
                          onClick={onSubmitHandeling}
                          disabled={isdisabled}
                        >
                          Submit
                        </button>
                      </center>
                    </center>
                }
              </>
            ) : null}

            {
              vendorshow ?
                (
                  <div >
                    <center><h4 className="theamColor mt-3 mb-5">VENDOR FORM</h4></center>
                    <br /><br />

                    <div className="employee_Form">
                      <div className="d-flexed" style={{ justifyContent: "space-between", width: "80%", margin: "auto" }}>

                        <div className="mb-3">
                          <label className="form-label h6">Vendor Name<span className='required'>*</span> </label>
                          <input
                            type="text"
                            className="form-control"
                            id="venName"
                            name="vendorName"
                            value={vendor.vendorName}
                            placeholder="Enter Vendor Name Here ..."
                            onChange={vendorChange}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label h6"> Address<span className='required'>*</span> </label>
                          <input
                            type="text"
                            className="form-control"
                            id="venaddress"
                            value={vendor.address}
                            name="address"
                            placeholder="Enter Vendor Address Here ..."
                            onChange={vendorChange}
                          />
                        </div>


                      </div>

                      <div className="d-flexed" style={{ justifyContent: "space-between", width: "80%", margin: "auto" }}>
                        <div className="mb-3">
                          <label className="form-label h6">Phone Number </label>
                          <input
                            type="tel"
                            className="form-control"
                            id="venphnumber"
                            name="mobile"
                            maxLength="10"
                            value={vendor.mobile}
                            placeholder="Enter Vendor Number Here..."
                            onChange={vendorChange}
                            onKeyPress={onlynumerical}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label h6">Contact Name </label>
                          <input
                            type="text"
                            className="form-control"
                            id="venconName"
                            value={vendor.contactName}
                            name="contactName"
                            placeholder="Enter Vendor Contact Name Here..."
                            onChange={vendorChange}
                          />
                        </div>


                      </div>
                      <div className="d-flexed" style={{ justifyContent: "space-between", width: "80%", margin: "auto" }}>
                        <div className="mb-3">
                          <label className="form-label h6">Contact Number </label>
                          <input
                            type="tel"
                            className="form-control"
                            id="vencontactnum"
                            value={vendor.contactMobile}
                            maxLength="10"
                            name="contactMobile"
                            placeholder="Enter Contact Number Here..."
                            onChange={vendorChange}
                            onKeyPress={onlynumerical}
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label h6">GST Number</label>
                          <input
                            type="text"
                            className="form-control"
                            id="vengstnum"
                            value={vendor.gstNumber}
                            maxLength="15"
                            name="gstNumber"
                            placeholder="Enter GST Number Here..."
                            onChange={vendorChange}
                          />
                        </div>
                      </div>
                      <div className="d-flexed" style={{ justifyContent: "space-between", width: "80%", margin: "auto" }}>
                        <div className="mb-3">
                          <label className="form-label h6">Email Address</label>
                          <input
                            type="email"
                            className="form-control"
                            id="vendorEmail"
                            value={vendor.email}
                            name="email"
                            placeholder="Enter Email Address Here..."
                            onChange={vendorChange}
                          // onKeyPress={onlynumerical}
                          />    <span className="text-danger py-1 fw-bold">{errEmail}</span>
                        </div>
                      </div>
                    </div>


                    <br />
                    {
                      btnupdatevendor ?
                        <center><button className="btn btnSuccess mx-1" onClick={updatevendorbtn}> UPDATE</button></center>

                        :
                        <center>
                          <center>
                            <button
                              className="btn btnSuccess mx-1"
                              onClick={onSubmitHandeling}
                              disabled={isdisabled}
                            >
                              Submit
                            </button>
                          </center>
                        </center>
                    }

                  </div>
                ) : null
            }
            {departmentShow ? (
              <div >

                <center><h4 className="theamColor mt-3 mb-5">CLIENT FORM</h4></center>
                <br /><br />
                <div className="employee_Form">
                  <div className="d-flexed" style={{ justifyContent: "space-between", width: "80%", margin: "auto" }}>
                    <div className="mb-3 flex-input">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label h6"
                      >
                        salutation<span className='required'>*</span>
                      </label>
                      <select
                        className="form-select"

                        id="salutation"
                        value={dpt.salutation}
                        name="salutation"
                        onChange={
                          deptChange
                        }
                      >
                        <option value=""> --Select Salutation--</option>
                        <option value="MR.">MR.</option>
                        <option value="MS.">MS.</option>
                        <option value="MRS.">MRS.</option>
                      </select>
                    </div>

                    <div className="mb-3">
                      <label className="form-label h6">Contact Name </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ClientContactname"
                        value={dpt.contactName}
                        name="contactName"
                        placeholder="Enter contact Name Here..."
                        onChange={deptChange}
                      />
                    </div>

                  </div>
                  <div className="d-flexed" style={{ justifyContent: "space-between", width: "80%", margin: "auto" }}>
                    <div className="mb-3">
                      <label className="form-label h6"> Address <span className='required'>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        id="ClientAddress"
                        name="address"
                        value={dpt.address}
                        placeholder="Enter Client Address Here ..."
                        onChange={deptChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label h6" >Phone Number </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="ClientPhnumber"
                        maxLength="10"
                        name="mobile"
                        value={dpt.mobile}
                        placeholder="Enter Client Number Here..."
                        onChange={deptChange}
                        onKeyPress={onlynumerical}
                      />
                    </div>

                  </div>
                  <div className="d-flexed" style={{ justifyContent: "space-between", width: "80%", margin: "auto" }}>

                    <div className="mb-3">
                      <label className="form-label h6">Client Name<span className='required'>*</span> </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ClientName"
                        name="clientName"
                        placeholder="Enter Client Name Here ..."
                        onChange={deptChange}
                        value={dpt.clientName}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label h6">Contact Number </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="ClientContactnumber"
                        maxLength="10"
                        value={dpt.contactMobile}
                        name="contactMobile"
                        placeholder="Enter Contact Number Here..."
                        onChange={deptChange}
                        onKeyPress={onlynumerical}
                      />
                    </div>


                  </div>
                  <div className="d-flexed" style={{ justifyContent: "space-between", width: "80%", margin: "auto" }}>
                    <div className="mb-3">
                      <label className="form-label h6">Designation </label>
                      <input
                        type="text"
                        className="form-control"
                        id="clientDesignation"
                        name="designation"
                        value={dpt.designation}
                        placeholder="Enter Designaition Number Here..."
                        onChange={deptChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label h6">GST Number </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ClientGstnumber"
                        name="gstNumber"
                        value={dpt.gstNumber}
                        placeholder="Enter GST Number Here..."
                        onChange={deptChange}
                      />
                    </div>

                  </div>

                  <div className="d-flexed" style={{ justifyContent: "space-between", width: "80%", margin: "auto" }}>

                    <div className="mb-3">
                      <label className="form-label h6">Email Address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="clientEmail"
                        value={dpt.email}
                        name="email"
                        placeholder="Enter Email Address Here..."
                        onChange={deptChange}
                      // onKeyPress={onlynumerical}
                      />    <span className="text-danger py-1 fw-bold">{errEmail}</span>
                    </div>
                  </div>
                </div>
                <br />
                {
                  btnupdateclient ?

                    <center>  <button className="btn btnSuccess mx-1" onClick={updateclientbtn}> UPDATE </button></center>

                    :
                    <center>
                      <center>
                        <button
                          className="btn btnSuccess mx-1"
                          onClick={onSubmitHandeling}
                          disabled={isdisabled}
                        >
                          Submit
                        </button>
                      </center>
                    </center>
                }

              </div>

            ) : null}
            {headListShow ? (
              <>
                <center><h4 className="theamColor mt-3 mb-5">CREATE HEAD</h4></center>

                <div className="mb-3" style={{ width: "40%", margin: "auto" }}>
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                    Heads Name
                  </label>
                  <input
                    type="text"
                    className="form-control mt-3"
                    id="headsName"
                    name="Name"
                    placeholder="Enter Heads Name Here..."
                    onChange={headsChange}
                  />

                  <br />
                  {
                    btnupdatehead ?
                      <center><button
                        className="btn btnSuccess mx-1"
                        onClick={updateheadbtn}>UPDATE</button></center>
                      :
                      <center>
                        <center>
                          <button
                            className="btn btnSuccess mx-1"
                            onClick={onSubmitHandeling}
                            disabled={isdisabled}
                          >
                            Submit
                          </button>
                        </center>
                      </center>
                  }


                </div>
              </>
            ) : null}
            {employeeShow ? (
              <>

                <div style={{ width: "100%", display: "flex", justifyContent: "space-evenly", marginTop: "-20px" }}>
                  <Button style={{ width: "100%" }} className="btn-sec" onClick={handlepersonal}>Personal</Button>
                  <Button style={{ width: "100%" }} className="btn-sec" onClick={handleemployement}>Employeement</Button>
                  <Button style={{ width: "100%" }} className="btn-sec" onClick={handleaccount}>Account</Button>
                </div>

                <center><h4 className="theamColor mt-3 mb-5">EMPLOYEE FORM</h4></center>
                {
                  personal ?
                    <>

                      <div className="employee_Form mt-2">
                        <div className="d-flexed" style={{ justifyContent: "space-between", width: "80%", margin: "auto" }}>
                          <div className="mb-3 flex-input">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              salutation<span className='required'>*</span>
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="strInitial"
                              value={empDetails.strInitial}
                              name="strInitial"
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Salutation--</option>
                              <option value="MR.">MR.</option>
                              <option value="MS.">MS.</option>
                              <option value="MRS.">MRS.</option>
                            </select>
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              First Name<span className='required'>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control "
                              id="firstName"
                              name="firstName"
                              value={empDetails.firstName}
                              placeholder="Enter Employee Name Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="d-flexed" style={{ justifyContent: "space-between", margin: "auto", width: "80%" }}>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              Last Name<span className='required'>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="lastName"
                              name="lastName"
                              value={empDetails.lastName}
                              placeholder="Enter Employee Name Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              Employee Email<span className='required'>*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="empEmail"
                              name="mail"
                              value={empDetails.mail}
                              placeholder="Enter Employee Email Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                            <span className="text-danger py-1 fw-bold">{errEmail}</span>
                          </div>
                        </div>
                        <div className="d-flexed" style={{ justifyContent: "space-between", margin: "auto", width: "80%" }}>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              Department Name<span className='required'>*</span>
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="dprtName"
                              name="department"
                              value={empDetails.department}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Department Name--</option>
                              <option value="accounts"> ACCOUNTS</option>
                              <option value="HR"> HR</option>
                              <option value="creative"> CREATIVE</option>
                              <option value="PROJECTS"> PROJECTS</option>
                              <option value="production"> PRODUCTIONS/OPERATIONS</option>
                              <option value="tenders"> TENDERS</option>
                            </select>
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              Password<span className='required'>*</span>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              maxLength="10"
                              id="password"
                              value={empDetails.password}
                              name="password"
                              placeholder="Enter Employee Password No. Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                            <span className="text-danger py-1 fw-bold">{errMobile}</span>
                          </div>
                        </div>

                        <div className="d-flexed" style={{ justifyContent: "space-between", margin: "auto", width: "80%" }}>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              Hiring Date<span className='required'>*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="hiringDate"
                              id="HiringDate"
                              value={empDetails?.hiringDate?.split("T")[0]}
                              max="9999-12-31"
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              Aadhar Card
                            </label>
                            <input
                              className="form-control"
                              onChange={e => { handledocs(e, "EMP_AADAHR") }}
                              type="file" name="aadharCard" id="aadharCard" />
                          </div>


                        </div>
                        <div className="d-flexed" style={{ justifyContent: "space-between", width: "80%", margin: "auto" }}>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              Pan Card
                            </label>
                            <input placeholder="Upload Pan Card..."
                              className="form-control"
                              onChange={e => { handledocs(e, "EMP_PAN") }}
                              type="file" name="aadharCard" id="aadharCard" />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              Location<span className='required'>*</span>
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="strLocation"
                              name="strLocation"
                              value={empDetails.strLocation}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Office Location--</option>
                              <option value="Noida">Noida</option>
                            </select>

                          </div>



                        </div>
                        <div className="d-flexed" style={{ justifyContent: "space-between", width: "80%", margin: "auto" }}>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              Date Of Birth<span className='required'>*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              value={empDetails.dtDOB.split("T")[0]}
                              id="dtDOB"
                              name="dtDOB"
                              max="9999-12-31"
                              // defaultValue={"01-01-0001"}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6" style={{ width: "38%" }}
                            >
                              Marital status
                            </label>
                            <div className="form-check">
                              <input className="form-check-input " type="radio" name="bMarried" id="bMarried" value="Married" onChange={(e) => {
                                empChange(e);
                              }} checked={isMarried} />
                              <label className="form-check-label mx-2 text-white" htmlFor="exampleRadios1">
                                Married
                              </label>
                            </div>
                            <div className="form-check">
                              <input className="form-check-input" type="radio" name="bMarried" id="bMarried2" value="Unmarried" onChange={(e) => {
                                empChange(e);
                              }} checked={isUnMarried} />
                              <label className="form-check-label text-white" htmlFor="exampleRadios2">
                                Unmarried
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="d-flexed" style={{ justifyContent: "space-between", width: "80%", margin: "auto" }}>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              Designation<span className='required'>*</span>
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="strDesignation"
                              name="strDesignation"
                              value={empDetails.strDesignation}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Designation--</option>
                              <option value="Owner/Director">Owner/Director</option>
                              <option value="additional_director">Additional Director</option>
                              <option value="HOD">Head Of Department</option>
                              <option value="General_Manager">General Manager</option>
                              <option value="Assistant_Manager">Assistant Manager</option>
                              <option value="Manager">Manager</option>
                              <option value="Team">Team</option>
                              <option value="Intern">Intern</option>
                            </select>
                          </div>

                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              Qualification<span className='required'>*</span>
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="strQualification"
                              name="strQualification"
                              value={empDetails.strQualification}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Qualification--</option>
                              <option value="PHD">PHD</option>
                              <option value="Masters">Masters</option>
                              <option value="Bachelors">Bachelors</option>
                              <option value="Intermediate">intermediate</option>
                              <option value="High School">High School</option>
                            </select>
                          </div>
                        </div>
                        <div className="d-flexed" style={{ justifyContent: "space-between", width: "80%", margin: "auto" }}>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              USER TYPE<span className='required'>*</span>
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="userType"
                              value={empDetails.userType}
                              name="userType"
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select UserType--</option>
                              <option value="ADMIN">ADMIN</option>
                              <option value="ACCOUNTS-LEAD">ACCOUNTS-LEAD</option>
                              <option value="ACCOUNTS">ACCOUNTS</option>
                              <option value="PROJECTS-LEAD">PROJECTS-LEAD</option>
                              <option value="PROJECTS">PROJECTS</option>
                              <option value="OPERATIONS-LEAD">OPERATION/PRODUCTION-LEAD</option>
                              <option value="OPERATIONS">OPERATION/PRODUCTION</option>
                              <option value="CREATIVE-LEAD">CREATIVE-LEAD</option>
                              <option value="CREATIVE">CREATIVE</option>
                              <option value="TENDERS-LEAD">TENDERS-LEAD</option>
                              <option value="TENDERS">TENDERS</option>
                            </select>
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label h6"
                            >
                              Mobile No.<span className='required'>*</span>
                            </label>
                            <input
                              type="tel"
                              className="form-control"
                              maxLength="10"
                              id="empMobile"
                              value={empDetails.phone}
                              name="phone"
                              placeholder="Enter Employee Mobile No. Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                              onKeyPress={onlynumerical}
                            />
                            <span className="text-danger py-1 fw-bold">{errMobile}</span>
                          </div>
                        </div>

                      </div>
                      {
                        Empupdatebtn ?
                          <center>
                            <button
                              className="btn btnSuccess mx-1"
                              onClick={onupdateHandeling}
                            >
                              update
                            </button>
                          </center>
                          : <center>
                            <button
                              className="btn btnSuccess mx-1"
                              onClick={onSubmitHandeling}
                              disabled={isdisabled}
                            >
                              Submit
                            </button>
                          </center>
                      }
                    </> : ""
                }
                {
                  employement ?
                    <>
                      <div className='d-flex hrEmployeeform'
                        style={{ justifyContent: "space-between", width: "85%", margin: "auto" }}>
                        <div className='col-md-5 col-sm-5 col-5'>
                          <h4 style={{ color: "#fff" }}> Dates</h4>
                          <hr className='myhr' />
                          <p><span className='text-white'>JOINING GROUP :</span><input type="text" /></p>
                          <p><span className='text-white'>CONTACT / JOINING :</span><input type="text" /></p>
                          <p><span className='text-white'>PROBATION :</span><input type="text" /></p>
                          <p><span className='text-white'>CONFIRMATION :</span><input type="text" /></p>
                          <p><span className='text-white'>CONTRACT LEAVING :</span><input type="text" /></p>
                          <p><span className='text-white'>REASON FOR LEAVING :</span><input type="text" /></p>
                          <p><span className='text-white'>NEXT INCREMENT :</span><input type="text" /></p>
                        </div>

                        <div className='col-md-5 col-sm-5 col-5'>
                          <h4 style={{ color: "#fff" }}> Status</h4>
                          <hr className='myhr' />
                          <p><span className='text-white'>GRADE:</span><input type="text" /></p>
                          <p><span className='text-white'>DEPARTMENT:</span><input type="text" /></p>
                          <p><span className='text-white'>DESIGNATION:</span><input type="text" /></p>
                          <p><span className='text-white'>LOCATION:</span><input type="text" /></p>
                          <p><span className='text-white'>CATEGORY:</span><input type="text" /></p>
                          <p><span className='text-white'>SHIFT:</span><input type="text" /></p>
                          <p><span className='text-white'>TIMIMG SHEET:</span><input type="text" /></p>

                        </div>
                      </div>



                    </>
                    : ""
                }
                {
                  account ?
                    <>

                      <div className='d-flex hrEmployeeform' style={{ justifyContent: "space-between", width: "85%", margin: "auto" }}>
                        <div className='col-md-5 col-sm-5 col-5'>
                          <h4 style={{ color: "#fff" }}> Dates</h4>
                          <hr className='myhr' />
                          <p><span className='text-white'>JOINING GROUP :</span><input type="text" /></p>
                          <p><span className='text-white'>CONTACT / JOINING :</span><input type="text" /></p>
                          <p><span className='text-white'>PROBATION :</span><input type="text" /></p>
                          <p><span className='text-white'>CONFIRMATION :</span><input type="text" /></p>
                          <p><span className='text-white'>CONTRACT LEAVING :</span><input type="text" /></p>
                          <p><span className='text-white'>REASON FOR LEAVING :</span><input type="text" /></p>
                          <p><span className='text-white'>NEXT INCREMENT :</span><input type="text" /></p>
                        </div>

                        <div className='col-md-5 col-sm-5 col-5'>
                          <h4 style={{ color: "#fff" }}> Status</h4>
                          <hr className='myhr' />
                          <p><span className='text-white'>GRADE:</span><input type="text" /></p>
                          <p><span className='text-white'>DEPARTMENT:</span><input type="text" /></p>
                          <p><span className='text-white'>DESIGNATION:</span><input type="text" /></p>
                          <p><span className='text-white'>LOCATION:</span><input type="text" /></p>
                          <p><span className='text-white'>CATEGORY:</span><input type="text" /></p>
                          <p><span className='text-white'>SHIFT:</span><input type="text" /></p>
                          <p><span className='text-white'>TIMIMG SHEET:</span><input type="text" /></p>

                        </div>
                      </div>
                    </>
                    : ""
                }

              </>
            ) : null}
            {/* for element masterbtnCls    */}
            {elementListShow ? (
              <>
                <center><h4 className="theamColor mt-3 mb-5">CREATE ELEMENT</h4></center>
                <div className="mb-3" style={{ width: "40%", margin: "auto" }}>
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                    ELEMENT Name
                  </label>
                  <input
                    type="text"
                    className="form-control mt-3"
                    id="elementName"
                    name="Name"
                    placeholder="Enter Element Name Here..."
                    onChange={elementChange}
                  />
                  <br />
                  {
                    btnupdatehead ?
                      <center><button
                        className="btn btnSuccess mx-1"
                        onClick={updateheadbtn}>UPDATE</button></center>
                      :
                      <center>
                        <center>
                          <button
                            className="btn btnSuccess mx-1"
                            onClick={onSubmitHandeling}
                            disabled={isdisabled}
                          >
                            Submit
                          </button>
                        </center>
                      </center>
                  }


                </div>
              </>
            ) : null}
            {typeListShow ? (
              <>
                <center><h4 className="theamColor mt-3 mb-5">CREATE ELEMENT TYPE</h4></center>
                <br /><br />
                <div >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4 col-sm-4 col-4">
                        <div className="my-3 w-75">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label h6"
                          >
                            NAME<span className='required'>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mt-3"
                            id="docName"
                            name="Name"
                            placeholder="Enter Element Type Name Here..."
                            //  value={elementType.Name}
                            onChange={(e) => {
                              getElementTypeVal(e);
                            }}
                          />
                        </div>

                      </div>
                      <div className="col-md-4 col-sm-4 col-4">
                        <div className="my-3 w-75">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label h6"
                          >
                            DESCRIPTION<span className='required'>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mt-3"
                            id="docName"
                            name="Description"
                            placeholder="Enter Element Type Name Here..."
                            //  value={elementType.Description}
                            onChange={(e) => {
                              getElementTypeVal(e);
                            }}
                          />
                        </div>

                      </div>
                      <div className="col-md-4 col-sm-4 col-4">
                        <div className="my-3 w-75">
                          <label className="form-label h6">UOM<span className='required'>*</span></label> <br />
                          <select
                            className="form-control mt-3"
                            aria-label="Default select example"

                            id="dprtName"
                            name="RUOM"
                            //  value={elementType.RUOM}
                            onChange={(e) => {
                              getElementTypeVal(e);
                            }}

                          >
                            <option>--Select UOM--</option>
                            {UMOList?.map((option, index) => (
                              <option key={index} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-4 col-sm-4 col-4">
                  <div className="my-3 w-75">
                 <label
                   htmlFor="exampleFormControlInput1"
                   className="form-label h6"
                 >
                  Size<span className='required'>*</span>
                 </label>
                 <input
                   type="text"
                   className="form-control"
                   id="docName"
                   name="itemType"
                   placeholder="Enter Element Type Name here..."
                   value={elementType.itemType}
                   onChange={(e) => {
                    getElementTypeVal(e.target.value);
                   }}
                 />
                 </div>
                
                    </div> */}
                      <div className="col-md-4 col-sm-4 col-4">
                        <div className="my-3 w-75">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label h6"
                          >
                            Item Type
                          </label>
                          <select
                            className="form-select mt-3"
                            aria-label="Default select example"
                            id="strQualification"
                            name="itemType"
                            onChange={(e) => {
                              getElementTypeVal(e);
                            }}
                          >
                            <option value=""> --Select Item Type--</option>
                            <option value="CALCULATE">Calculate</option>
                            <option value="GENERATE">Generate</option>
                          </select>
                        </div>
                      </div>
                    </div>

                  </div>
                  <br />
                  <center>
                    <button
                      className="btn btnSuccess mx-1"
                      onClick={onSubmitHandeling}
                    >
                      Submit
                    </button>
                  </center>
                </div>
              </>
            ) : null}

            {/* for category masterbtnCls    */}
            {catShow ? (
              <>
                <center>
                  <h4 className="theamColor mt-3 mb-5">CREATE CATEGORY</h4></center>
                <div className="mb-3" style={{ width: "40%", margin: "auto" }}>
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label h6"
                  >
                    Category Name
                  </label>
                  <input
                    type="text"
                    className="form-control mt-3"
                    id="elementName"
                    name="Name"
                    placeholder="Enter Element Name Here..."
                    onChange={catChange}
                  />
                  <br />
                  {
                    btnupdatehead ?
                      <center><button
                        className="btn btnSuccess mx-1"
                        onClick={updateheadbtn}>UPDATE</button></center>
                      :
                      <center>
                        <center>
                          <button
                            className="btn btnSuccess mx-1"
                            onClick={onSubmitHandeling}
                            disabled={isdisabled}
                          >
                            Submit
                          </button>
                        </center>
                      </center>
                  }


                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <ErrorAlertBox
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        heading={heading}
        errMsg={errMsg}
      />
      <SuccessAlertBox proId={proID} isOpen1={isOpen1} href={URL} setIsOpen1={setIsOpen1} heading1={heading1} successMsg={successMsg} />
    </div>
  );
};
export default MasterFrom;