import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import EmpSearch from '../../../../common/EmpSearch/EmpSearch';
import axios from 'axios';
import {VscError} from 'react-icons/vsc';
import './emergency.css';
import {GET_EMERGENCY_BY_ID,ALLOWED_USERTYPE,GET_EMERGENCY_CONTACT,CREATE_EMERGENCY_CONTACT,UPDATE_EMERGENCY_CONTACT,
  GET_EMERGENCY_DETAILS_BY_MCODE,DELETE_EMERGENCY_DETAILS}from '../../../../utiles/AllApis';
import ErrorAlertBox from '../../../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../../../common/AlertBox/SuccessAlertBox';
import {Modal,ModalBody} from 'reactstrap';
function Emengncy(props) {
  const [isLoading, setLoading] = useState(false);
  const [emergencydata, setemergencydata] = useState([])
  const [uType,setUType]=useState(props.tokectDetails.logintype);
   const [userID,setUserID]=useState(props.tokectDetails.mcode);
  const [selecedUserID,setSelectedUserID]=useState(uType===ALLOWED_USERTYPE.ADMIN?"":props.tokectDetails.mcode);
  const [seletedeData,setseletedeData]=useState({});
  const [isadd, setIsadd] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
const [isOpen, setIsOpen] = useState(false);
const [isOpencomfirm, setIsOpencomfirm] = useState(false);
const [isEdit, setIsEdit] = useState(false);
const[successMsg, setsuccessMsg]=useState("");
const[rowpos, setRowpos]=useState("");
const [male, setMale] = useState(false);
const [femail, setFemale] = useState(false);
const [nominee, setNominee] = useState(false);
const[heading, setHeading]=useState("");
const[errMsg, setErrMsg]=useState("");
const[heading1, setHeading1]=useState("");
const handalOpenErr=()=>{setIsOpen(true);}
const handalOpen1=()=>{setIsOpen1(true);}
const handalCloseconfim=()=>{setIsOpencomfirm(false);}

  const [relativeDetails,setRelativeDetails]=useState(
    {
      "EmployeeID": selecedUserID,
      "strEmergContactName": "",
      "dtEmergContactDOB": "",
      "strEmergContactGender": "",
      "strRelationship": "",
      "nominee":false,
    });
  useEffect(() => {
     
    setRelativeDetails({...relativeDetails,"EmployeeID":selecedUserID})
    getDetails();
  },[selecedUserID])
  const addDetails=()=>
  {
    setIsadd(true);  
    setRelativeDetails({});
    setMale(false);setIsEdit(false);
    setFemale(false);
    setNominee(false); 
  }
  //------------get all relative details--------
  function getDetails()
  {
    if(uType===ALLOWED_USERTYPE.ADMIN)
    {
        getAllEmgncyDtls(selecedUserID);
    }
    else {
      getAllEmgncyDtls(userID);
    }
  }
  //---------------get all Relative/emergency details--------------
  const getAllEmgncyDtls=(mcode)=>
  {
    //-----------gey all details------------
  if(mcode=="")
  {
    setLoading(true);
    axios.get(GET_EMERGENCY_CONTACT).then((response) => {
      setLoading(false);
      if(response.data.isSuccess===true)
      {
        setemergencydata(response.data.data);
      }
      else
      {
        setemergencydata([]);
      }
      
    })
  }
      //-----------gey details by userMCode------------
  else
  {
    setLoading(true);
    axios.post(GET_EMERGENCY_DETAILS_BY_MCODE,{
      "EmployeeID": mcode
  }).then((response) => {
      setLoading(false);
      if(response.data.isSuccess===true)
      {
        setemergencydata(response.data.data);
      }
      else
      {
        setemergencydata([]);
      }
    })
  }
  }
    //---------------get relatives details--------------
    const onHandleChange=(e)=>
    {
      if(e.target.name==="nominee")
      {
        e.target.value=e.target.checked;
        if(e.target.checked)
        {
          setNominee(true);
        }
        else{
          setNominee(false);

        }
      }
      if(e.target.name==="strEmergContactGender")
      {
        if(e.target.value==="male")
        {
          setMale(true);
        }
        else{
          setFemale(true);
        }
      }
      setRelativeDetails({...relativeDetails,[e.target.name]:e.target.value}); 
    }
  //---------add Emergency relative details---------------
  const handlesubmit = () => {
    if(relativeDetails?.strEmergContactName==""||relativeDetails?.dtEmergContactDOB==""||relativeDetails?.strEmergContactGender==""||relativeDetails?.strRelationship=="")
    {
      setHeading("Failed"); 
      setErrMsg("Mandatory fields cannot empty");
      handalOpenErr();
    }
    else{
      if(new Date(relativeDetails.dtEmergContactDOB)>new Date())
      {
      setHeading("Failed"); 
      setErrMsg("D.O.B. cannot greater then Today's date.");
      handalOpenErr();
      return;
      }
      setLoading(true);
      axios.post(CREATE_EMERGENCY_CONTACT,{
        "EmployeeID": selecedUserID,
        "strEmergContactName": relativeDetails.strEmergContactName,
        "dtEmergContactDOB": relativeDetails.dtEmergContactDOB,
        "strEmergContactGender": relativeDetails.strEmergContactGender,
        "strRelationship": relativeDetails.strRelationship,
        "nominee":relativeDetails.nominee,
      }).then((response) => {
        setLoading(false);
      if(response.data.isSuccess===true)
      {
      setRelativeDetails({});
        setHeading1("Success"); 
        setsuccessMsg("Contact added successfully");
        handalOpen1();
        setIsadd(false);
        getDetails();
        setMale(false);
        setFemale(false);
        setNominee(false);
      }
      else{
        setHeading("Failed"); 
        setErrMsg(response.data.message);
        handalOpenErr();
      }
      })
    }
  }

    //---------update Emergency relative details---------------
    const handleUpdate = () => {
      setRelativeDetails(
        {
          "EmployeeID": selecedUserID,
          "strEmergContactName": "",
          "dtEmergContactDOB": "",
          "strEmergContactGender": "",
          "strRelationship": "",
          "nominee":false,
        }
      )
      setIsEdit(false);
      setMale(false);
      setFemale(false);
      setNominee(false);
      if(relativeDetails?.strEmergContactName==""||relativeDetails?.dtEmergContactDOB==""||relativeDetails?.strEmergContactGender==""||relativeDetails?.strRelationship=="")
      {
        setHeading("Failed"); 
        setErrMsg("Mandatory fields cannot empty");
        handalOpenErr();
      }
      else{
        if(new Date(relativeDetails.dtEmergContactDOB)>new Date())
        {
        setHeading("Failed"); 
        setErrMsg("D.O.B. cannot greater then Today's date.");
        handalOpenErr();
        return;
        }
        setLoading(true);
        axios.post(UPDATE_EMERGENCY_CONTACT,{
          "EmployeeID": selecedUserID,
      "strEmergContactName": relativeDetails.strEmergContactName,
      "dtEmergContactDOB": relativeDetails.dtEmergContactDOB,
      "strEmergContactGender": relativeDetails.strEmergContactGender,
      "strRelationship": relativeDetails.strRelationship,
      "nominee":relativeDetails.nominee,
      "ROWPOS":rowpos
        }).then((response) => {
          setLoading(false);
        if(response.data.isSuccess===true)
        {
          setHeading1("Success"); 
          setsuccessMsg("Record update successfully..");
          handalOpen1();
          setIsadd(false);
          getDetails();
      setRelativeDetails({});
        }
        else{
          setHeading("Failed"); 
          setErrMsg(response.data.message);
          handalOpenErr();
        }
        })
      }
    }
  
  //-------------Edit emergency details------------------
  const handelEdit  = (id) => {
   // setLoading(true);
   setRowpos(id);
    setIsEdit(true);
    setIsadd (true);
    axios.post(GET_EMERGENCY_BY_ID,{"ROWPOS":id}).then((response) => {
      //setLoading(false);
    if(response.data.isSuccess===true)
    {
     setRelativeDetails(response.data.data[0]);
     if(response.data.data[0].strEmergContactGender==="male")
     {
      setMale(true); 
      setFemale(false); 
     }
     else{
      setFemale(true); 
      setMale(false); 

     }
     setNominee(response.data.data[0].nominee);
    }
    })
  }
    //-------------Delete emergency details------------------
    const handelDelete  = (id) => {
      handalCloseconfim();
   setRowpos(id);
      setLoading(true);
      axios.post(DELETE_EMERGENCY_DETAILS,{"ROWPOS":id}).then((response) => {
        setLoading(false);
      if(response.data.isSuccess===true)
      {

        getDetails();
      }
      else{
        setHeading("Failed"); 
        setErrMsg(response.data.message);
        handalOpenErr();
      }
    })
  }
  return (
    <>
     {
    isLoading?  <div className='loaderDiv'>
    </div>:null
  }
      <div className='emp-aboutdetailmaincontent'>
      <div className={uType===ALLOWED_USERTYPE.ADMIN?'mnusTopMrgn':''}>
        <h5 className='text-white fw-bold mt-2 text-center'>Emergency Details</h5>
        <hr className='myhr text-white' />
       </div>
       {
        uType===ALLOWED_USERTYPE.ADMIN?
       <EmpSearch uType={uType}setIsadd={setIsadd} isadd={isadd}setUserID={setSelectedUserID} isEdit={isEdit}empData={seletedeData}/>
        :
        <div className='row'>
            <div className='col-md-2 col-lg-2 col-sm-2 col-2 mt-1'>
        {
          isadd ?<button className='btn btnSuccess'onClick={e=>{setIsadd(false);}}>View List</button>
          :
          <button className='btn btnSuccess'onClick={e=>{addDetails()}}>Add Details</button>
        }
     </div> 
          </div>
       }
       {
        isadd?
        <div className={uType===ALLOWED_USERTYPE.ADMIN?'mt-5':'mnusTopMrgn'}>
                <h5 className='SecoHead text-center'>Relation Details</h5>
                <hr className='myhr text-white' />
        <div className='emp_profile_form'>
          <div className='row'> 
            <div className='col-md-3 col-sm-3 col-3 mt-3'>
            <label
                    htmlFor="strEmergContactName"
                    className="form-label fw-normal h6 text-capitalize"
                  >
                  Name<span className='required'>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control w-75 mt-2"
                    id="strEmergContactName"
                    name="strEmergContactName"
                     value={relativeDetails?.strEmergContactName}
                    placeholder="Enter Employee Name Here..."
                    onChange={(e) => onHandleChange(e)}
                  />
            </div>
            <div className='col-md-3 col-sm-3 col-3 mt-3'>
            <label
                    htmlFor="dtEmergContactDOB"
                    className="form-label fw-normal h6 text-capitalize"
                  >
                    Date Of Birth<span className='required'>*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control w-75 mt-2"
                    value={relativeDetails?.dtEmergContactDOB?.split("T")[0]}
                    id="dtEmergContactDOB"
                    name="dtEmergContactDOB"
                    max="9999-12-31"
                    onChange={(e) => onHandleChange(e)}
                  />
            </div>

            <div className='col-md-3 col-sm-3 col-3 mt-3'>
            <label
                    htmlFor="strRelationship"
                    className="form-label fw-normal h6 text-capitalize"
                  >
                    Relationship<span className='required'>*</span>
                  </label>
                      <select class="form-select mt-2 w-75"  value={relativeDetails?.strRelationship}
                      aria-label="Default select example" name="strRelationship" id="strRelationship"onChange={e=>{onHandleChange(e)}}>
<option value="">--Select Relation--</option>
<option value="Father">Father</option>
<option value="Mother">Mother</option>
<option value="Sister">Sister</option>
<option value="Brother">Brother</option>
<option value="Wife">Wife</option>
<option value="Son">Son</option>
</select>
          </div>
          <div className='col-md-2 col-sm-2 col-2 mt-3'>
            <label
                    htmlFor="strEmergContactGender"
                    className="form-label fw-normal h6 text-capitalize"style={{width:"38%"}}
                  >
                  Gender<span className='required'>*</span>
                  </label>
                  <div className="form-check ">
  <input className="form-check-input " type="radio" name="strEmergContactGender" id="strEmergContactGender" value="male"  
      onChange={(e) => onHandleChange(e)} 
      checked={male}
 />
  <label className="form-check-label  text-white fw-normal h6 text-capitalize" htmlFor="exampleRadios1">
    Male
  </label>
</div>
<div className="form-check">
  <input className="form-check-input" type="radio" name="strEmergContactGender" id="strEmergContactGender2" value="female"  
 onChange={(e) => onHandleChange(e)}
 checked={femail}
/>
  <label className="form-check-label text-white fw-normal h6 text-capitalize" htmlFor="exampleRadios2">
    Female
  </label>
</div>
            </div>
         <div className='col-md-1 col-sm-1 col-1 mt-3'>
            <label
                    htmlFor="nominee"
                    className="form-label fw-normal h6 text-capitalize d-block">
                    Nominee
                  </label>
                  <input className="form-check-input" type="checkbox" name="nominee"value="" id="nominee"
                   onChange={(e) => onHandleChange(e)} checked={nominee}/>
              </div>
          <div className='col-md-12 col-sm-12 col-12 mt-4 text-center'>
            {
              isEdit?
          <button className='btn btnSuccess' onClick={handleUpdate} ><span>Update</span></button>:
          <button className='btn btnSuccess' onClick={handlesubmit} ><span>Submit</span></button>
            }
          </div>
          </div>
         </div>
        </div>
        :
         emergencydata.length>0?
         <div className={`${uType===ALLOWED_USERTYPE.ADMIN?"emgncy":"emgncyFull"} table-responsive mt-3`}>
         <table className="table table-bordered border-secondary ">
                <thead className='tblHeader'>
                    <tr>
                       <th scope="col text-white">#</th>
                       <th scope="col text-white">Created At</th>
                       <th scope="col text-white">Employee Name</th>
                       <th scope="col text-white">Relative Name</th>
                       <th scope="col text-white">D.O.B.</th>
                       <th scope="col text-white">Sex</th>
                       <th scope="col text-white">Relationship</th>
                       <th scope="col text-white">Nonimee</th>
                       <th scope="col text-white"className={uType!==ALLOWED_USERTYPE.ADMIN?"d-none":""}>Edit</th>
                       <th scope="col text-white"className={uType!==ALLOWED_USERTYPE.ADMIN?"d-none":""}>Delete</th>
                    
                    
                   </tr>
                </thead>
                <tbody className='tblBdy'>
                    {
                          emergencydata.map((data, i) => 
                          {
                            return(    <tr key={i}>
                                <th scope="row">{i+1}</th>
                                <td>{data.edate.slice(0,10)}</td>
                                <td>{data.employeeName}</td>
                                <td>{data.strEmergContactName}</td>
                                <td>{data.dtEmergContactDOB.slice(0,10)}</td>
                                <td>{data.strEmergContactGender}</td>
                                <td>{data.strRelationship}</td>
                                <td>{data.nominee?"Yes":"No"}</td>
                                <td className={uType!==ALLOWED_USERTYPE.ADMIN?"d-none":""}><button className='btn btnEdit'onClick={e=>{handelEdit(data.strEmergRowpos)}}>Edit</button></td>
                                <td className={uType!==ALLOWED_USERTYPE.ADMIN?"d-none":""}><button className='btn btnDelete' onClick={e=>{setRowpos(data.strEmergRowpos);setIsOpencomfirm(true);}}>Delete</button></td>
                            </tr>);
                          }
                        )
                    }
                
                </tbody>
                        </table>
         </div>
     
        :
          <p className='text-white text-center mt-5'>No Data Found!!</p>
        }
       
      </div>
      <Modal className="fade show"isOpen={isOpencomfirm} >
  <ModalBody  className='text-center tracking-in-expand-fwd-bottom'>
<p className=' text-center text-danger fw-bold'><VscError className='fw-bolder mx-2 my-2 h2'/>Are you sure you want to Delete</p>
<div>   <Button
     className='btnSuccess mx-2'
     onClick={()=>{handelDelete(rowpos)}}
    >
      Yes
    </Button>
    <Button
     className='btnDelete mx-2'
     onClick={handalCloseconfim}
    >
      No
    </Button></div>
  </ModalBody>

  </Modal>
         <ErrorAlertBox isOpen={isOpen} setIsOpen={setIsOpen} heading={heading} errMsg={errMsg} />
      <SuccessAlertBox
        isOpen1={isOpen1}
        setIsOpen1={setIsOpen1}
        heading1={heading1}
        successMsg={successMsg}
      /> 
    </>
  )
}

export default Emengncy;