import React, { useState, useEffect } from 'react'
import { DataGrid,GridToolbarContainer, GridToolbarExport  } from '@mui/x-data-grid'
import { Button } from "@mui/material";
import { CSVLink } from "react-csv";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport className="exBtn"/>
    </GridToolbarContainer>
  );
}
const columns = [
     {
      field: "edate",
      headerName: "Created At",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
  
        return (
          <p className='text-white mt-2 f-14'>{ params.row.edate.replace("T"," ")}</p>
        );
      }
    },
      { field: 'projectName', headerName: 'Project Name', width:120},
      { field: 'category', headerName: 'Category',width:120 },
      { field: 'ownerName', headerName: 'Project Lead',width:120},
      { field: 'clientName', headerName: 'Client Name',width:120},
      { field: 'vendor', headerName: 'Vendor Name',width:120},
      { field: 'projectType', headerName: 'Project Type',width:120},
      {
        field: "ldateOfSubmissionBid",
        headerName: "Last Date of Submission",
        width: 120,
        disableClickEventBubbling: true,
        renderCell: (params) => {
    
          return (
            <p className='text-white mt-2 f-14'>{params?.row?.openingDate?.split("T")[0]}</p>
          );
        }
      },
      { field: 'evaluation', headerName: 'Evaluation',width:120},
     
      { field: 'estimatedProjectCost', headerName: 'Estimated Project Cost',width:120},
      { field: 'emd', headerName: 'EMD',width:120},
      { field: 'tenderDocumentFee', headerName: 'Tender Document Fee',width:120},
      { field: 'processOfSubmissionBid', headerName: 'Process Of Submission Bid',width:20},
      { field: 'ldateOfSubmissionBid', headerName: 'Last Date of Pre Bid Queries',width:120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
  
        return (
          <p className='text-white mt-2 f-14'>{ params?.row?.ldateOfSubmissionBid?.split("T")[0]}</p>
        );
      }},
      { field: 'technicalBidOpenDate', headerName: 'Date of Opening Technical Bid',width:120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
  
        return (
          <p className='text-white mt-2 f-14'>{params?.row?.technicalBidOpenDate?.split("T")[0]}</p>
        );
      }},
      { field: 'presentationDate', headerName: 'Presentation Date',width:120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
  
        return (
          <p className='text-white mt-2 f-14'>{params?.row?.presentationDate?.split("T")[0]}</p>
        );
      }},
      { field: 'preBidMeetingDate', headerName: 'Date Of Pre-bid Meeting',width:20,
      disableClickEventBubbling: true,
      renderCell: (params) => {
  
        return (
          <p className='text-white mt-2 f-14'>{params?.row?.preBidMeetingDate?.split("T")[0]}</p>
        );
      }},
       { field: 'evaluationIssue', headerName: 'Eligibility Issues',width:120},
      { field: 'scoringIssue', headerName: 'Scoring Issues',width:20},
    ]
const ProjectTable=(props)=> {
  return (
    <>
  <div className="px-5 mb-2">
     <div className='tracking-in-expand-fwd-bottom tbanew ' >
      <DataGrid
        rows={props.tblData}
        columns={props.Col}
        pageSize={10}
        getRowId={(row) => row.id}
        components={{
          Toolbar: CustomToolbar,
        }}
        
      />
    </div>
    </div>
    </>
  )
}

export default ProjectTable;