import React,{useEffect, useState} from 'react';
import {
    CardGroup,
    Card,
    // CardImg,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    CardImg,

} from 'reactstrap';
import ButtonEdit from '../../common/Buttons/ButtonEdit';
import ButtonDelete from '../../common/Buttons/ButtonDelete';
import pdfImg from '../../assets/pdf.png';
const DocumentCard=(props)=>
{
   
  const[redirectionForm,setRedirectionForm]=useState(`${props.formRedirection}?id=${props.rowpos}`) 
    return(

<CardGroup className='crdGrp tracking-in-expand-fwd-bottom m-0'>
  <Card className='crdBody'>
    {props?.path?.split(".")[props?.path?.split(".").length-1]==="pdf"?
     <iframe className='cursor-pointer' src={props.path} width="100%" height="300px" >
    </iframe>
    :  <a href={props.path} target="blank"><CardImg
    alt={props.name}
    src={props.path}
    top
    width="100%"
  /></a>}
  
    <CardBody className='myshadowcard'>
      {/* <CardTitle tag="h5">
      {props.id}
      </CardTitle> */}
      <a href={props.path} target="_blank">
      <CardSubtitle
        className="mb-2 text-muted mytitle"
        tag="h6"
      >
       {props.name}
      </CardSubtitle>
      </a>
      {/* <CardText className='text-start'>
      <a href="#"> {props.val}</a>
      </CardText> */}
      {/* <ButtonSuccess btnTitle="Add"/> */}
      {/* <ButtonEdit style={{background:"blue"}} btnTitle="Edit"href={redirectionForm}/> */}
      <ButtonDelete  btnTitle="Delete"usehtmlFor="doc"rowpos={props.rowpos}onDelete={props.onDelete}/>
    </CardBody>
  </Card>
</CardGroup>

    )
}
export default DocumentCard;