import React, { useState, useEffect } from 'react'
import { Chart as ChartJS,BarElement} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { GET_PROJECT } from '../../../utiles/AllApis';

ChartJS.register(
  BarElement,
  
);

const LineChart = () => {
  const [chart, setChart] = useState([])
  const [bar,setbar] = useState(false)
  



  useEffect(() => {

    axios.get(GET_PROJECT).then((response) => {
      
      console.log(response.data.data.length)
    })
   
    const Fetchpost = async ()=>{
      const res =await axios.get("https://jsonplaceholder.typicode.com/posts")
      console.log(res.data)
      setChart(res.data)
      

    }
    Fetchpost()

  
     
  
     
  },[])


  
  var data = {
    labels: chart?.map(x => x.userId),
    datasets: [{
      label: `${chart?.length} events Available`,
      data: chart?.map(x => x.id),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }]
  };

  var options = {
    maintainAspectRatio: false,
    scales: {
    },
    legend: {
      labels: {
        fontSize: 25,
      },
    },
  }


 const showbar = ()=>{
  setbar(true)
 }
 const showline =()=>{
  setbar(false)
 }


  return (
  <>

   <div style={{display:"flex",justifyContent:"space-between"}}>
       <div  className='barchart' onClick={showbar}><ion-icon name="bar-chart-outline"></ion-icon></div>
       <div className='barchart'  onClick={showline}><ion-icon name="trending-up-outline"></ion-icon></div>
   </div>

    {
      bar ?

      <div>
      <Line
        data={data}
        height={400}
        options={options}

      />
    </div> 
    : 
    <div>
    <Bar
      data={data}
      height={400}
      options={options}

    />
  </div>
    }

   
  </>
  )
}

export default LineChart
