import React, { useEffect, useState } from 'react'
import { Container, Button, Link } from 'react-floating-action-button';
import { BsPlusLg } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
 const FloatingBtn = (props) => {
     const navigate =useNavigate();
     const tooltip=`Add ${props.usehtmlFor}`;
     const href=`${props.href}?bcd=${props.QuerPara}`;
    return (
        <div style={{right:'8vw'}}>
        <Container >
            <Button
                tooltip={tooltip}
                icon="fas fa-plus"
                rotate={false}
                onClick={() => {navigate(href)}} > <BsPlusLg/>
                </Button>
               
        </Container>
        </div>
    )
}
export default FloatingBtn;