import React, { useEffect, useState } from 'react';
import { GET_EMP_DATAILS_BY_MCODE } from '../../../../utiles/AllApis';
import axios from 'axios';

function EmpDoc(props) {
    const [docs, setdocs] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [uType,setUType]=useState(props.tokectDetails.logintype);
    const [userID,setUserID]=useState(props.tokectDetails.mcode);
  
    useEffect(()=>{
        setLoading(true);
        axios.post( GET_EMP_DATAILS_BY_MCODE,{"EmployeeID":userID}).then((response) =>{
            setLoading(false);
       if(response.data.isSuccess===true)
       {
        setdocs(response.data.data[0].employee_docs);
       }
       
        })

    },[])

    return (
        <>
         {
    isLoading?  <div className='loaderDiv'>
    </div>:null
  }
            <h5 className='text-white fw-bold mt-2 text-center'>Documents</h5>
        <hr className='myhr text-white' />
                <div className='row'>
                    {
                        docs?.map((item,i) => (
                            <>
                               <div key={i} className='col-md-6 col-sm-6 col-6' >
                               <a href={item.path} target="blank" >
                                    {item.media_Type==="EMP_PAN"?<p style={{color:"#fff"}}>Pan Card</p>:<p style={{color:"#fff"}}>Aadhar Card</p>}
                                    <img src={item.path} alt={item.media_Type} className="empDoc w-100 h-50"/></a>
                                </div>
                            </>
                        ))
                    }

                </div>

        
        </>
    )
}

export default EmpDoc