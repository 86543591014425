import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import DataGrids from "../../../common/table/DataGrid";
import ExcelUpload from '../../../common/excelUpload/Main'
import Multiselect from 'multiselect-react-dropdown';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Link, useParams } from "react-router-dom";
import axios, { CancelToken, isCancel } from 'axios';
import {
  Modal,
  ModalBody,
} from 'reactstrap';
import Getcookie from '../../../modules/login/Getcookie';
import SideBar from '../../../common/SideBar/SideBar';
import HrSideBar from "../../../common/SideBar/HrSideBar";
// import pdfImg from '../../../assets/pdf.png'
import { VscError } from 'react-icons/vsc';
import {
  GET_TRAVEL_EXPENSE, GET_IMPREST_EXPENSE,
  BRANDSHEET_EXCEL, GET_Employee, TENDER_STATE, DELETE_IMPREST, DELETE_TRAVEL, GET_COSTSHEET_BY_ID, GET_BRANDSHEET_BY_ID,
  COSTSHEET_EXCEL, UPDATE_PROJECT,
  CREATE_EVENTVENDOR, SUBMIT_ANS,
  GET_ALLVENDOR, CREATE_TEAM,
  GET_EVENTBYID, GET_ALLDAILYUPDATE,
  GET_EVENTNAMEBYID, GET_DAILYUPDATE, DAILY_UPDATE_BY_PROJECT_ID,
  GET_PROJECTBYID, DAILY_UPDATE_BY_EMPLOYEE_ID, GET_DOCUMENT, CREATE_DOCUMENT,
  GET_VENDOR, GET_VENDOR_BY_PROJECT_BY_ID, DELETE_INDENT_PR, ALLOWED_USERTYPE,
  GET_TEAMS_BY_PROJECT_ID, GET_EMP_DATAILS_BY_MCODE, GET_ELEMENT_TYPE, GET_ALL_ELEMENTS, CREATE_INDENT_PR, GET_INDENT_PR
} from "../../../utiles/AllApis";
// import Uploadexcel from '../../components/Uploadexcel';
import Costsheetdefault from "../../../assets/Internal_costing_Format.xlsx";

import travelExpenseSheet from "../../../assets/static/Conveyance_Sheet.xlsx";
import imprestExpenseSheet from "../../../assets/static/Imprest_Sheet.xlsx";
import { Button } from "@mui/material";
import { ArrowBackIos } from "@material-ui/icons";
import ElementCard from "../../manageMaster/ElementCard";
import ErrorAlertBox from "../../../common/AlertBox/ErrorAlertBox";
import SuccessAlertBox from "../../../common/AlertBox/SuccessAlertBox";
import FloatingBtn from "../../../common/Buttons/FloatingBtn";
// import AllVendor from "../itemMaster/AllVendor";


function TravelExpense(props) {
  const { projectName } = useParams();
  const [ViewDoc, setviewDoc] = useState([]);
  const [costData, setCostData] = useState([]);
  const [BrandData, setBrandData] = useState([]);
  const [selectedExcelType, setSelectedExcelType] = useState("IMPRESST_SHEET");
  const [ProductRiderData, setProductRiderData] = useState([]);
  const [costsheet, setcostsheet] = useState([]);
  const [brandsheet, setbrandsheet] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [viewUploadMoreDoc, setViewUploadMoreDoc] = useState(false);
  const [cost, setcost] = useState(true);
  const [production, setproduction] = useState(false);
  const [query, setQuery] = useState("");
  const [collapsShow, setCollapsShow] = useState(false);
  const [brand, setbrand] = useState(false);
  const [isElementSelected, setIsElementSelected] = useState(false);
  let vendorNameList = [];
  const cancelFileUpload = useRef(null);
  const [dltRowpos, setDltRowpos] = useState("");
  const [searchDropdown, setSearchDropDown] = useState("");
  const [TyprDocVal, setTyprDocVal] = useState(false);
  const [totalcost, settotalcost] = useState("0");
  const [totalbrand, settotaalbrand] = useState("0");
  const [empname, setempname] = useState("");
  const [dropdown, setdropdown] = useState([]);
  const defaultDate = `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`
  const [allocateDate, setallocateDate] = useState(defaultDate);
  const [dataTableForDailyUpdatesForProject, setdDataTableForDailyUpdatesForProject] = useState([]);
  const [countTotalcost, setcountTotalcost] = useState("");
  const [countTotalbrand, setcountTotalbrand] = useState("");
  const [DailyUpdatesForEmp, setDailyUpdatesForEmp] = useState([]);
  const [uploadedPrecentage, setUploadedPrecentage] = useState(0);
  const [selectedDocType, setSelectedDocType] = useState("");
  const [costfile, setcostfile] = useState(null);
  const [brandfile, setbrandfile] = useState(null);
  const [cmpntDocs, setCmpntDocs] = useState();
  const [internalDocs, setInternalDocs] = useState();
  const [GetDocName, setGetDocName] = useState("");
  const [ElementsArr, setElementsArr] = useState([])
  const [Category, setCategory] = useState("")
  const [selectedElement, setselectedElement] = useState("")
  const [SelectedCategory, setSelectedCategory] = useState("")
  const [ElemntCategory, setElemntCategory] = useState([]);
  const [isSubmitFile, setIsSubmitFile] = useState(false);
  const [TotalRider, setTotalRider] = useState("")
  const [cardclass, setcardclass] = useState(null)
  const [cardTypeclass, setcardTypeclass] = useState(null);
  const navigate = useNavigate();
  const [isdisable, setisdisable] = useState(true)
  const [showallocate, setshowallocate] = useState(false);
  const [costhover, setcosthover] = useState(
    "foocus tracking-in-expand-fwd-bottom"
  );
  const [brandhover, setbrandhover] = useState(
    "focus tracking-in-expand-fwd-bottom boxes"
  );
  const [Productionhover, setproductionhover] = useState(
    "focus tracking-in-expand-fwd-bottom boxes"
  );
  const [isOpenDeletePopUp, setIsOpenDeletePopUp] = useState(false);
  const handalCloseDelete = () => {
    setIsOpenDeletePopUp(false);
  };
  const handalOpenDelete = (rowpos) => {
    setIsOpenDeletePopUp(true);
    setDltRowpos(rowpos);
  };
  const cancelUpload = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current("user has cacel the file upload");
    }
  }
  const columns = [

    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'department', headerName: 'Department', width: 150 },
    {
      field: "hiringDate",
      headerName: "Hiring Date",
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params.row.hiringDate.split("T")[0]}</p>
        );
      }
    },
    { field: 'mail', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Mobile No.', width: 150 },

  ]

  const optionsProduct = [
  ];
  const [selectOptionsProduct, setSelectOptionsProduct] = useState([]);
  const [checked, setChecked] = useState([]);
  const [vList, setVList] = useState("");
  const [thisuser, setthisuser] = useState([]);
  const [popupcell, setpopupcell] = useState(false);
  const [brandpopupcell, setbrandpopupcell] = useState(false);
  const [eventname, seteventname] = useState([])
  const [viewDailyUpdatesByProject, setViewDailyUpdatesByProject] = useState(false);
  const [popupdoc, setpopupDoc] = useState(false)
  const [vendordrop, setvendordrop] = useState([])
  const [eventVendor, seteventVendor] = useState([]);
  const [percent, setpercent] = useState("")
  const [showEmpDailyUpdatesPops, setShowEmpDailyUpdatesPopup] = useState(false);
  const [showMulticategory, setshowMulticategory] = useState(false);
  const [projectDetails, setProjetcDetails] = useState({
    vendor: "",
    vdr: ""
  });
  const [viewTeams, setViewTeams] = useState(false);
  const [viewVendorsTeam, setViewVendorsTeams] = useState(false);
  const [dataTable, setDataTable] = useState();
  const [dataTableForVendor, setDataTableForVendor] = useState([]);
  const [viewCompyDoc, setViewCompyDoc] = useState(false);
  const [viewInternalDoc, setViewInternalDoc] = useState(false);
  const [viewUploadPrstnDoc, setViewUploadPrstnDoc] = useState(false);
  const [uploadfile, setuploadfile] = useState("");
  const [empDoc, setEmpDoc] = useState();
  const [docList, setDocList] = useState([]);
  const [oselectedOtionsProduct, setoselectedOtionsProduct] = useState([]);

  const [allDailyUpdates, setAllDailyUpdates] = useState([]);
  const [userType, setUSerType] = useState("");
  const [vendorListName, setVendoeNameList] = useState("");
  const [viewDocList, setViewDocList] = useState(false);
  const handleOpenDocList = () => { setViewDocList(true) }
  const handleCloseDocList = () => { setViewDocList(false) }

  // -------------------
  const [Length, setLength] = useState(1)
  const [Width, setWidth] = useState(1)
  const [Height, setHeight] = useState(1)
  const [Amount, setAmount] = useState("")
  const [Quantity, setQuantity] = useState(1)
  const [Area, setArea] = useState("")
  const [Remark, setRemark] = useState("")
  const [Delivered, setDelivered] = useState("")
  const [recAccept, setRecAccept] = useState("U");
  const [recWork, setRecWork] = useState("U");
  const [securityDpst, setSecurityDpst] = useState("U");
  const [EMDReleased, setEMDReleased] = useState("U");
  const [openDataPopup, setOpenDataPopup] = useState(false);
  const [openDataPopup1, setOpenDataPopup1] = useState(false);
  const [openDataPopup2, setOpenDataPopup2] = useState(false);
  const [openDataPopup3, setOpenDataPopup3] = useState(false);
  const [dates, setDates] = useState({
    "recAcceptDate": "1990-01-01",
    "recWorkDate": "1990-01-01",
    "securityDpstDate": "1990-01-01",
    "EMDReleasedDate": "1990-01-01",
  });
  const handalOpen1 = () => { setIsOpen1(true); }
  const handalOpen = () => { setIsOpen(true); }
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);
  const [heading, setHeading] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  // ------------------- Update Q&A  -----------------
  const updateRecAccept = (val) => {
    setOpenDataPopup(val);
    if (val) {
      setRecAccept("Y");
    }
    else {
      setRecAccept("N");
    }

  }
  const updateWorkOrder = (val) => {
    setOpenDataPopup1(val);
    if (val) {
      setRecWork("Y");
    }
    else {
      setRecWork("N");
    }
  }
  const updateSecDpst = (val) => {
    setOpenDataPopup2(val);
    if (val) {
      setSecurityDpst("Y");
    }
    else {
      setSecurityDpst("N");
    }
  }
  const updateEMDReleased = (val) => {
    setOpenDataPopup3(val);
    if (val) {
      setEMDReleased("Y");
    }
    else {
      setEMDReleased("N");
    }
  }
  const onDateChange = (e) => {
    setDates({ ...dates, [e.target.name]: e.target.value });
  }
  const taledata = [];


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2
  };

  var ary = [];



  useEffect(() => {
    setArea(Length * Width * Height)
    setAmount(Area * Quantity)

  }, [Length, Width, Height, Quantity, Area])



  var arr = [];

  // setSelectedCategory(item)
  // console.log(itemType);
  // // if(itemType==="GENERATE")
  // // {

  // // }
  // }




  const checkedItems = checked?.length
    ? checked.reduce((total, item) => {
      return total + ", " + item

    })
    : "";

  const checkedCategory = checked?.length
    ? checked.reduce((total, item) => {
      return total + ", " + item

    })
    : "";
  useEffect(() => {


  }, [checkedItems])

  useEffect(() => {
    getTravelExpense()
    getImprestExpense()
    // ---------------------------
    const handleChangeSearchBox = (e) => {

      setSelectOptionsProduct({ ...selectOptionsProduct, e })
    }


    // --------------------------------------
    setUSerType(Getcookie("UserType"));

    // getTeamMembers();








  }, []);


  useEffect(() => {
    var config = {
      method: 'get',
      url: GET_TRAVEL_EXPENSE,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    setLoading(true);
    axios(config)
      .then(function (response) {


        console.log(response)
        setLoading(false);
        if (response.data.isSuccess === true) {
          if (response.data.data.length > 0) {

            setBrandData(response.data.data);
          }
        }
        else {
          setBrandData([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  //-----------GET COST SHEET BY PROJECT ID--------
  const getImprestExpense = () => {
    var config = {
      method: 'get',
      url: GET_IMPREST_EXPENSE,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    setLoading(true);
    axios(config)
      .then(function (response) {


        console.log(response)
        setLoading(false);
        if (response.data.isSuccess === true) {
          if (response.data.data.length > 0) {
            setCostData(response.data.data);

          }

        }
        else {
          setCostData([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }







  const getTravelExpense = () => {
    var config = {
      method: 'get',
      url: GET_TRAVEL_EXPENSE,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    setLoading(true);
    axios(config)
      .then(function (response) {


        console.log(response)
        setLoading(false);
        if (response.data.isSuccess === true) {
          if (response.data.data.length > 0) {

            setBrandData(response.data.data);
          }
        }
        else {
          setBrandData([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //----------get brandsheet by id---------



  const handlebrand = () => {
    setcost(false);
    setproduction(false);
    setbrand(true);
    setbrandhover("foocus tracking-in-expand-fwd-bottom");
    setcosthover("focus tracking-in-expand-fwd-top boxes");
    setproductionhover("focus tracking-in-expand-fwd-top boxes");
    setSelectedExcelType("TRAVEL_EXPENSE");

  };


  const getTeamMembers = () => {
    axios.post(GET_TEAMS_BY_PROJECT_ID, { "Project_ID": projectName }).then((response) => {

      if (response.data.isSuccess === true) {
        response?.data?.data?.map((data, i) => {

          axios.post(GET_EMP_DATAILS_BY_MCODE, { "EmployeeID": data.emP_MCODE }).then((response) => {

            if (response.data.isSuccess === true) {
              taledata.push(response?.data?.data[0]);

            }
          });
        }
        )

      }

    });
  }
  const handlecost = () => {
    setcost(true);
    setproduction(false);
    setbrand(false);
    setcosthover("foocus tracking-in-expand-fwd-bottom");
    setbrandhover("focus tracking-in-expand-fwd-top boxes");
    setproductionhover("focus tracking-in-expand-fwd-top boxes");
    setSelectedExcelType("IMPRESST_SHEET");
  };



  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert '0' to '12'
    return `${formattedHours}:${minutes} ${ampm}`;
  }





  const Imprestcolumns = [
    {
      field: "rowpos",
      headerName: "ID",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <p className='text-white mt-2 f-14'>{params?.row?.rowpos}</p>
        );
      }
    },
    { field: "f_NAME", headerName: "NAME", width: 180 },
    {
      field: "date",
      headerName: "DATE",
      width: 180,
      renderCell: (params) => {
        return (<span className='text-white'>{params.row.date?.split("T")[0]}</span>);
      }
    },
    {
      field: "edate",
      headerName: "Created Date",
      width: 180,
      renderCell: (params) => {
        return (<span className='text-white'>{params.row.edate?.split("T")[0]}</span>);
      }
    },
    {
      field: "createdTime",
      headerName: "Created Time",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const time = params?.row?.edate?.split("T")[1];
        const formattedTime = time ? formatTime(time) : "--:--";
        return (
          <p className='text-white mt-2 f-14'>{formattedTime}</p>
        );
      }
    },
    { field: "particular", headerName: "PARTICULAR", width: 180 },
    { field: "received", headerName: "RECEIVED", width: 180 },
    { field: "expense", headerName: "EXPENSE", width: 180 },
    { field: "remark", headerName: "REMARK", width: 180 },
    {
      field: "",
      headerName: "DELETE",
      width: 180,
      renderCell: (params) => {
        const handleDelete = () => {
          axios.request({
            method: 'POST',
            url: DELETE_IMPREST,
            data: { rowpos: params.row.rowpos }
          }).then(response => {
            if (response.status === 200) {

              window.location.reload()
            } else {
              // Handle error
              console.error('Error deleting the record');
            }
          }).catch(error => {
            console.error('Error deleting the record', error);
          });
        };


        return (
          <button onClick={handleDelete} className="btn btn-danger">
            Delete
          </button>
        );
      }
    },
  ];



  const brandcolumns = [
    {
      field: "rowpos",
      headerName: "ID",
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params?.row?.rowpos}</p>
        );
      }
    },

    { field: "f_NAME", headerName: "NAME", width: 80 },
    {
      field: "date",
      headerName: "DATE",
      width: 100,
      renderCell: (params) => {

        return (<span className='text-white'>{params.row.date?.split("T")[0]}</span>);

      }
    },
    {
      field: "edate",
      headerName: "Created Date",
      width: 180,
      renderCell: (params) => {
        return (<span className='text-white'>{params.row.edate?.split("T")[0]}</span>);
      }
    },
    {
      field: "createdTime",
      headerName: "Created Time",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const time = params?.row?.edate?.split("T")[1];
        const formattedTime = time ? formatTime(time) : "--:--";
        return (
          <p className='text-white mt-2 f-14'>{formattedTime}</p>
        );
      }
    },
    { field: "days", headerName: "DAYS", width: 80 },
    { field: "from", headerName: "FROM ", width: 120 },
    { field: "to", headerName: "TO", width: 120 },
    { field: "kilometers", headerName: "KILOMETERS", width: 80 },
    { field: "mode_of_Transport", headerName: "MODE OF TRANSPORT", width: 120 },
    { field: "rate_per_kms", headerName: "RATE PER KILOMETERS", width: 80 },

    {
      field: "remarks",
      headerName: "REMARKS",
      width: 300,
      renderCell: (params) => {
        if (params.row.remarks === '' || params.row.remarks === null) {
          return (<span className='text-white'>--NA--</span>);
        }
        else {
          return (<span className='text-white'>{params.row.remarks}</span>);
        }
      }
    },
    {
      field: "",
      headerName: "DELETE",
      width: 180,
      renderCell: (params) => {
        const handleDelete = () => {
          axios.request({
            method: 'POST',
            url: DELETE_TRAVEL,
            data: { rowpos: params.row.rowpos }
          }).then(response => {
            if (response.status === 200) {

              window.location.reload()
            } else {
              // Handle error
              console.error('Error deleting the record');
            }
          }).catch(error => {
            console.error('Error deleting the record', error);
          });
        };


        return (
          <button onClick={handleDelete} className="btn btn-danger">
            Delete
          </button>
        );
      }
    },

  ];



  return (
    <div className='row'>
      <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
        <HrSideBar />
      </div>
      {/* {
            props?.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN?
           :null
          } */}
      {/* {
            props?.tokectDetails?.logintype === ALLOWED_USERTYPE.TEAM?
            <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
            <HrSideBar/>
            </div>:null
          } */}
      {/* {
            props?.tokectDetails?.logintype === ALLOWED_USERTYPE.PRODUCTION?
            <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
            <HrSideBar/>
            </div>:null
          } */}
      <div className={`${props.tokectDetails?.logintype === ALLOWED_USERTYPE.OPERATIONS
        ? 'col-md-12 col-12 col-sm-12 '
        : 'col-md-10 col-10 col-sm-10'} myview`}>

        <div className='p-4'>


          <div className="row mb-5">

            <div className="col-md-2 col-2  col-sm-4"></div>
            <div className="col-md-4 col-4 col-sm-4">
              <div
                className={costhover}
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={handlecost}
              >
                <div className="totalbg text-center m-auto p-0 h3">Imprest Sheet</div>
              </div>

            </div>
            <div className="col-md-4 col-sm-4 col-4">
              <div
                className={brandhover}
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={handlebrand}
              >
                <div className="totalbg text-center m-auto p-0 h3">Conveyance Sheet</div>

              </div>
            </div>
          </div>
          {cost ? (
            <>
              <ExcelUpload refreshData={getImprestExpense} title={"Upload Imprest Expense Sheet"} dwnPath={imprestExpenseSheet} ProjectId={projectName} for={selectedExcelType} setLoading={setLoading} />
              <DataGrids cardsList={costData} columns={Imprestcolumns} />
              <div className="totalcostprc text-white">
                <p style={{ marginTop: "-5%" }}>
                  Total Amount :
                  <span style={{ fontWeight: "800" }}>
                    {" "}
                    &#x20b9; &nbsp; &nbsp;
                  </span>
                </p>
              </div>

              <div>
                <FloatingBtn
                  href={`/expense/form1`}
                  usehtmlFor={` IMPREST `}
                />
              </div>
              <br />

              <br />
              <div
                className="d-flexed tracking-in-expand-fwd-top"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "auto",
                  width: "70%",
                }}
              >

              </div>
            </>
          ) : null}


          {brand ? (
            <>
              <ExcelUpload refreshData={getTravelExpense} title={"Upload Conveyance Expense Sheet"} dwnPath={travelExpenseSheet} ProjectId={projectName} for={selectedExcelType} setLoading={setLoading} />
              <DataGrids cardsList={BrandData} columns={brandcolumns} />
              <div className="totalcostprc text-white">
                <p style={{ marginTop: "-5%" }}>
                  Total Amount :
                  <span style={{ fontWeight: "800" }}>
                    {" "}
                    &#x20b9; &nbsp; &nbsp;
                  </span>
                </p>
              </div>

              <div>
                <FloatingBtn
                  href={`/expense/form1`}
                  usehtmlFor={` CONVEYANCE `}
                />
              </div>
              <br />

              <br />
              <div
                className="d-flexed tracking-in-expand-fwd-top"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "auto",
                  width: "70%",
                }}
              >

              </div>
            </>
          ) : null}









        </div>
      </div>

      <ErrorAlertBox isOpen={isOpen} setIsOpen={setIsOpen} heading={heading} errMsg={errMsg} />
      <SuccessAlertBox href={window.location.href} isOpen1={isOpen1} setIsOpen1={setIsOpen1} heading1={heading1} successMsg={successMsg} />
    </div>
  );
}
export default TravelExpense;