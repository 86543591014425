import React, { useEffect, useState } from 'react'
import SideBar from '../common/SideBar/SideBar';
import {
    CardGroup,
    Card,
    // CardImg,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    CardImg,

} from 'reactstrap';
import axios from 'axios';
import { GET_ALLTODO,Delete_TODO } from '../utiles/AllApis';
import {
  Input,Button,Modal,ModalBody
} from 'reactstrap';
const Todos = () => {
    const [alltodo,setalltodo] = useState([]);
    const [dltTodoId,setDltTodoId] = useState("");
    const [isOpenDeleteCom,setIsOpenDeleteCom] = useState(false);
    useEffect(()=>{
      getAllTODOS();
      },[]);
      const getAllTODOS=()=>
      {
        var config = {
          method: 'get',
          url: GET_ALLTODO,
          headers: {"Content-Type":"application/json; charset=utf-8" }
        };
        axios(config)
        .then(function (response) {
          setalltodo(response.data.data)
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      const deleteTodo =()=>{
        var data = JSON.stringify({
          "ROWPOS": dltTodoId
        });
        
        var config = {
          method: 'post',
          url: Delete_TODO,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        axios(config)
        .then(function (response) {
          if(response.data.isSuccess === true){
            setIsOpenDeleteCom(false);
            getAllTODOS();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    return (
        <>
            <div className="container-fluid">
                {/* {
  isLoading?  <div className='loaderDiv'>
  </div>:null
} */}
                <div className="row ">
                    <div className="col-md-2 col-12 col-sm-12 mainSideBar px-0">
                        <SideBar />
                    </div>
                    <div className="col-md-10 col-12 col-sm-12 py-3 myview">
                        <div className="row">
                            <center><h3 className="theamColor mailHeading h3">TODOS</h3></center>
                            <br /><br />
                            {
                              alltodo?.length>0?
          alltodo?.map((item,index)=>(
              <>
               <div className='col-md-3 col-sm-3 col-6 text-center my-2 py-1 ' key={index}>
                                <CardGroup className='h-75 tracking-in-expand-fwd-top cursor-pointer'style={{maxHeight:"75%",overflowY: "scroll"}}>
                                    <Card className='crdBody'>
                                        <CardBody className='myshadowcard'>
                                            <div className='' >
                                                <CardSubtitle
                                                    className="mb-2 mytitle"
                                                    tag="h5"
                                                >
                                                   
                                                </CardSubtitle>
                                                <CardText className='text-start'>
                                                    <span className='' style={{color:"#fff"}}>Subject:</span> {item.subject} 
                                                </CardText>
                                                <CardText className='text-start'>
                                                    <span className=' ' style={{color:"#fff"}}>Remarks:</span> {item.remarks} 
                                                </CardText>
                                                <CardText className='text-start'>
                                                    <span className=' ' style={{color:"#fff"}}>Date:</span> {item.edate?.slice(0,10)}
                                                </CardText>
                                    <br />
                                            </div>
                                            <div className='mt-2'>
                                           <button className='btn btn-danger' style={{position:"absolute",bottom:"10px",right:"10px"}} onClick={e=>{setIsOpenDeleteCom(true);setDltTodoId(item.rowpos)}} >Delete</button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </CardGroup>
                            </div>
              </>
          )):<p className='my-5'>No Todo List Found!!</p>
        }

<Modal className="fade show"isOpen={isOpenDeleteCom} >
  <ModalBody  className='text-center tracking-in-expand-fwd-bottom'>
  <h4 className=' text-center  fw-bold  successColor'>Confirmation</h4>   
<p className=' text-center text-danger fw-bold'>Are you sure You want to Delete</p>
<div>   <Button
     className='btnSuccess mx-2'
     onClick={deleteTodo}
    >
      Yes
    </Button>
    <Button
     className='btnDelete mx-2'
     onClick={()=>{setIsOpenDeleteCom(false)}}
    >
      No
    </Button></div>
  </ModalBody>

  </Modal>               

                        </div>
                    </div>

                </div>
            </div>



        </>
    )
}

export default Todos