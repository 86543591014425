import React, { useState } from "react";
import { useEffect } from "react";
import "../Dashboard/dashboard.css";
import { DataGrid } from "@mui/x-data-grid";
import FloatingBtn from "../../common/Buttons/FloatingBtn";
import Multiselect from 'multiselect-react-dropdown';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import {
  BRANDSHEET_EXCEL,
  COSTSHEET_EXCEL,
  CREATE_EVENTVENDOR,
  GET_ALLVENDOR,
  GET_EVENTBYID,GET_ALLDAILYUPDATE,
  GET_EVENTNAMEBYID,GET_DAILYUPDATE,DAILY_UPDATE_BY_PROJECT_ID,
  GET_PROJECTBYID,DAILY_UPDATE_BY_EMPLOYEE_ID,GET_DOCUMENT,CREATE_DOCUMENT,
  GET_VENDOR,GET_VENDOR_BY_PROJECT_BY_ID,
  GET_TEAMS_BY_PROJECT_ID,GET_EMP_DATAILS_BY_MCODE
} from "../../utiles/AllApis";
// import Costsheetdefault from "../../assets/sAMPLE_COST.xlsx";
// import Brandsheetdefault from "../../assets/sAMPLE_BRAND.xlsx";
import { Button } from "@mui/material";
import { ArrowBackIos } from "@material-ui/icons";
import AllVendor from "../itemMaster/AllVendor";
function Gridboxevent() {
  const { projectName } = useParams();
  const [ViewDoc,setviewDoc] = useState([]);
  const [costsheet, setcostsheet] = useState([]);
  const [brandsheet, setbrandsheet] = useState([]);
  const [viewUploadMoreDoc,setViewUploadMoreDoc]=useState(false);
  const [cost, setcost] = useState(true);
  const [brand, setbrand] = useState(false);
  const [totalcost, settotalcost] = useState("0");
  const [totalbrand, settotaalbrand] = useState("0");
const [dataTableForDailyUpdatesForProject,setdDataTableForDailyUpdatesForProject]=useState([]);
  const [countTotalcost, setcountTotalcost] = useState("");
  const [countTotalbrand, setcountTotalbrand] = useState("");
const [DailyUpdatesForEmp,setDailyUpdatesForEmp]=useState([]);
  const [costfile, setcostfile] = useState(null);
  const [brandfile, setbrandfile] = useState(null);
  const [cmpntDocs,setCmpntDocs]=useState();
  const [internalDocs,setInternalDocs]=useState();
  const [GetDocName,setGetDocName]=useState("");
  const [costhover, setcosthover] = useState(
    "foocus tracking-in-expand-fwd-bottom"
  );
  const [brandhover, setbrandhover] = useState(
    "focus tracking-in-expand-fwd-bottom"
  );

  const [thisuser, setthisuser] = useState([]);
  const [popupcell, setpopupcell] = useState(false);
  const [brandpopupcell,setbrandpopupcell] =useState(false);
  const [eventname,seteventname] =useState([])
const [viewDailyUpdatesByProject,setViewDailyUpdatesByProject]=useState(false);
  const [popupdoc,setpopupDoc] = useState(false)
  const [vendordrop, setvendordrop] = useState([])
  const [eventVendor,seteventVendor] =useState([]);
  const [percent,setpercent] =useState("")
const [showEmpDailyUpdatesPops,setShowEmpDailyUpdatesPopup]=useState(false);
  const [showMulticategory, setshowMulticategory] = useState(false);
  const [projectDetails, setProjetcDetails] = useState({
    vendor: "",
    vdr:""
  });
  const  [viewTeams,setViewTeams]=useState(false);
  const  [viewVendorsTeam,setViewVendorsTeams]=useState(false);
 const [dataTable,setDataTable]=useState();
 const [dataTableForVendor,setDataTableForVendor]=useState([]);
 const [viewCompyDoc,setViewCompyDoc]=useState(false);
const [viewInternalDoc,setViewInternalDoc]=useState(false);
const [viewUploadPrstnDoc,setViewUploadPrstnDoc]=useState(false);
const [uploadfile,setuploadfile] = useState("");
const [empDoc,setEmpDoc]=useState();
const [allDailyUpdates,setAllDailyUpdates]=useState([]);
const taledata=[];
var taledataVendor=[];
const columnsForTeams = [

  { field: 'firstName', headerName: 'First Name', width: 150   },
  { field: 'lastName', headerName: 'Last Name', width: 150   },
  { field: 'department', headerName: 'Department', width: 150 },
  {
    field: "hiringDate",
    headerName: "Hiring Date",
    width: 150,
    disableClickEventBubbling: true,
    renderCell: (params) => {

      return (
        <p className='text-white mt-2 f-14'>{params?.row?.hiringDate?.split("T")[0]}</p>
      );
    }
  },
  { field: 'mail', headerName: 'Email', width: 200 },
  { field: 'phone', headerName: 'Mobile No.', width: 150 },
  {
    field: "View Daily Updates",
    headerName: "View Daily Updates",
    width: 150,
    disableClickEventBubbling: true,
    renderCell: (params) => {
return(<button className="btn btn-success" onClick={e=>{viewDailyUpdatesOfEmp(params.row.rowpos)}}>View</button>)
     
    }
  },
]
const columnForDUpdatesOFEmp=[
  {
    field: "edate",
    headerName: "Created At",
    width: 120,
    disableClickEventBubbling: true,
    renderCell: (params) => {

      return (
        <p className='text-white mt-2 f-14'>{ params?.row?.edate?.split("T")[0]}</p>
      );
    }
  },
      { field: 'employeeName', headerName: 'Employee Name' ,width: 190,},
      { field: 'projectName', headerName: 'Event Name',width: 230,},
      { field: 'subject', headerName: 'Subject' ,width: 250,},
      { field: 'remarks', headerName: 'Remarks',width: 250,},
      {
        field: "path",
        headerName: "View Documents",
        sortable: false,
        width: 230,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <Button  variant="contained" style={{background:"rgb(15, 83, 61)",color:"#b8e3d7"}} color="primary" onClick={e=>{viewDailyDocFoeEmp(params.row.dailyUpdate_docs)}}>view</Button>
          );
        }
      }
];
const columnsForCmpnyDoc = [

  { field: 'name', headerName: 'Document Name', width: 500   },
  { field: 'path', headerName: 'Documets', width: 500   },


]
const handlefile =(e)=>{
  var name = e?.target?.files[0]?.name?.split("");

  var validate = name?.reverse().join("").split(".")[0];
  if (validate === "xslx"|| validate === "piz" || validate === "kpa") {
    alert("please select image file !");
    e.target.value = null;
  }
  else{
    console.log(e.target.files[0])
    setuploadfile(e.target.files[0])
  }
}
function uploadfiles(){

  var FormData = require("form-data");
  var data = new FormData();
  data.append("File", uploadfile);
  data.append("ID", projectName);
  data.append("SubType", "UPDATE");
  data.append("name", "document");
  data.append("Type", "PRESENTATION_DOCUMENT");
 
  var config = {
    method: "post",
    url: CREATE_DOCUMENT,

    data: data,
  };

  axios(config)
    .then(function (response) {
       
     if(response.data.isSuccess===true)
     {
      window.location.reload();
     }
     else{
      alert("File Upload Fails");
     }
  
    })
    .catch(function (error) {
      console.log(error);
    });
}
function uploadfiles1(){

  var FormData = require("form-data");
  var data = new FormData();
  data.append("File", uploadfile);
  data.append("ID", projectName);
  data.append("SubType", GetDocName);
  data.append("name", GetDocName);
  data.append("Type", "EXTRA_DOCUMENT");
 
  var config = {
    method: "post",
    url: CREATE_DOCUMENT,

    data: data,
  };

  axios(config)
    .then(function (response) {
       
     if(response.data.isSuccess===true)
     {
      window.location.reload();
     }
     else{
      alert("File Upload Fails");
     }
  
    })
    .catch(function (error) {
      console.log(error);
    });
}
const handlecrossDocPopo=()=>
{
  setpopupDoc(false);
}
const viewDailyUpdatesOfEmp=(empID)=>
{
  axios.post(GET_DAILYUPDATE,
    {"Project_ID":projectName ,
    "EMP_MCODE": empID
   }
    ).then((response) => {
     
    if(response.data.isSuccess===true)
    {
      setDailyUpdatesForEmp(response.data.data);
    }
    
  })

  setShowEmpDailyUpdatesPopup(true);
}
const viewDailyDocFoeEmp=(dailyDocID)=>
{
  const docVal= dailyDocID.map((data,i)=>
  {
  return(<img src={data.path} key={i}></img>)
  })
  setviewDoc(docVal);
  setpopupDoc(true);

}

  const handlecostALLvendor =(params)=>{
    window.location.href = "#"
    setthisuser(params);
    console.log(params.row)
    setpopupcell(true)
    setbrandpopupcell(false)
  }
const handleAllbrand =(params)=>{
  window.location.href = "#"
  setthisuser(params);
  console.log(params.row)
  setbrandpopupcell(true)
  setpopupcell(false)
}

  useEffect(() => {
     
    axios.post(GET_ALLDAILYUPDATE,{ "ROWPOS": projectName}).then((response)=>{
       
          if(response.data.isSuccess===true)
          {           
            const resDate= response.data.data.filter((data,i)=>
            {
              if(data.project_ID.includes(projectName))
              {
                return data;
              }
            })
            // taledataVendor=response?.data?.data;
            taledataVendor=resDate;
          }
        
        })
    getTeamMembers();
    let s = 0;
    let b = 0;

    var data = JSON.stringify({
      Project_ID: projectName,
    });

    var config = {
      method: "post",
      url: GET_EVENTBYID,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data);
        console.log(response?.data?.data?.costSheet);
        console.log(response?.data?.data?._brandSheet)
        console.log(response?.data?.data?._brandSheet?.length)
        
        settotaalbrand(response?.data?.data?._brandSheet?.length);
        settotalcost(response?.data?.data?.costSheet?.length);
        setbrandsheet(response?.data?.data?._brandSheet);
        setcostsheet(response?.data?.data?.costSheet);
        var sums = response?.data?.data?.costSheet;

        sums?.map((sum, index) => {
          console.log(JSON.stringify(sum.internal_Amt));

          var sa = JSON.parse(JSON.stringify(sum.internal_Amt));
          s = sa + s;
        });

        setcountTotalcost(s);

        var Bsum = response?.data?.data?._brandSheet;
        console.log(Bsum);
        Bsum?.map((brand, index) => {
          console.log(JSON.stringify(brand.rateAmount));

          var BD = JSON.parse(JSON.stringify(brand.rateAmount));
          b = BD + b;
        });
        setcountTotalbrand(b);

       
      })
      .catch(function (error) {
        console.log(error);
      });

    axios.get(GET_VENDOR).then((response) => {
      setvendordrop(response?.data?.data)
    })

    axios.post(GET_EVENTBYID,{ "Project_ID": projectName}).then((response) => {
  
      seteventname(response.data.data)
  
    })
    
axios.post(GET_VENDOR_BY_PROJECT_BY_ID,{ "ROWPOS": projectName}).then((response)=>{

  if(response.data.isSuccess===true)
  {
   
    taledataVendor=response?.data?.data;

    setDataTableForVendor(response.data.data);
  }

})

axios.post(GET_DOCUMENT,{"Project_ID": projectName}).then((response) => {
   
  var array1=[];
  if(response.data.isSuccess===true)
  {
    
   const resData= response.data.data.filter((data,i)=>
    {
      if(data.type.includes("COMPANY_DOCUMENT"))
      {
        return data;
      }

    }
    )
    resData.map((data,i)=>
       {
         array1.push(data.name);
       })
      setCmpntDocs( <Multiselect
      id="cmpDoc"
       options={array1}
       displayValue="name"
        isObject={false}
       showCheckbox={true}
   
     /> );
}
 
});
axios.post(GET_DOCUMENT,{"Project_ID": projectName}).then((response) => {
  var array1=[];
  if(response.data.isSuccess===true)
  {
    
   const resData= response.data.data.filter((data,i)=>
    {
      if(data.type.includes("WORKORDER_DOCUMENT"))
      {
        return data;
      }

    }
    )
    resData.map((data,i)=>
    {
      array1.push(data.name);
    })
    setInternalDocs(   <Multiselect
    options={array1}
    displayValue="key"

     isObject={false}
    showCheckbox={true}
  /> );
}
 
});
  }, []);
  const handlebrand = () => {
    setcost(false);
    setbrandhover("foocus tracking-in-expand-fwd-bottom");
    setcosthover("focus tracking-in-expand-fwd-top");
  };

  const handlecost = () => {
    setcost(true);
    setcosthover("foocus tracking-in-expand-fwd-bottom");
    setbrandhover("focus tracking-in-expand-fwd-top");
  };

  const selectedcost = (event) => {
    var name = event?.target?.files[0]?.name?.split("");

    var validate = name?.reverse().join("").split(".")[0];
    console.log(validate);
    if (validate !== "xslx") {
      alert("please select xlsx file !");
      event.target.value = null;
    }
    setcostfile(event.target.files[0]);
  };

  const selectedbrand = (event) => {
    var name = event?.target?.files[0]?.name?.split("");

    var validate = name?.reverse().join("").split(".")[0];
    console.log(validate);
    if (validate !== "xslx") {
      alert("please select xlsx file !");
      event.target.value = null;
    }
    setbrandfile(event.target.files[0]);
  };

  const btnuploadcost = () => {
 
    if (costfile === null) {
      alert("please select  excel file ");
      return;
    }
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", costfile);
    data.append("projectID", projectName);

    var config = {
      method: "post",
      url: COSTSHEET_EXCEL,
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.isSuccess);
        if (response.data.isSuccess === true) {
          alert("cost factor sheet uploaded Successfully ! ");
          window.location.reload();
        } else {
          alert(response.data.message); 
          document.getElementById("fileCost").value="";
          
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const btnuploadbrand = () => {
    if (brandfile === null) {
      alert("please select  excel file ");
      return;
    }
    var FormData = require("form-data");

    var data = new FormData();
    data.append("File", brandfile);
    data.append("projectID", projectName);

    var config = {
      method: "post",
      url: BRANDSHEET_EXCEL,
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.message);
        alert(response.data.message);
        brandfile(null)

      })
      .catch(function (error) {
        console.log(error);
      });
  };

 
  const handlecategory = () => {
    if (showMulticategory === true) {
      setshowMulticategory(false);
      // setcategories(projectDetails.Category)
    } else {
      setshowMulticategory(true);
    }
  };

  const onHandleChange2 = (e) => {
    if (e.target.checked) {
      setProjetcDetails({
        ...projectDetails,
        [e.target.name]: `${e.target.value}`,
      });
      setProjetcDetails({
        ...projectDetails,
        [e.target.id]: `${e?.target?.options[e?.target?.selectedIndex]?.text.trim()}`,
      });
      //e?.target?.options[e?.target?.selectedIndex]?.text.trim()
    } else {
      setProjetcDetails({ ...projectDetails, [e.target.name]: e.target.value });
      setProjetcDetails({
        ...projectDetails,
        [e.target.id]: `${e?.target?.options[e?.target?.selectedIndex]?.text.trim()}`,
      });
    }
  };
  const handleAllocatEmp = () => {
    if(projectDetails.vendor === ""  ){
      alert("fields cannot be empty")
   
    }
    else if(percent > 100 ){
    alert("Percentage should not be greater than 100 ")
    }
    else{
      var data = JSON.stringify({
        "Project_ID": thisuser.row.project_ID,
        "FactorID": thisuser.row.rowpos,
        "Type": "COSTSHEET",
        "VENDOR_MCODE": projectDetails.vendor,
        "Allocation": percent,
        "Eversion": "1.0.0.8"
      });
   
      var config = {
        method: 'post',
        url: CREATE_EVENTVENDOR,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
  
      axios(config)
        .then(function (response) {
    
          console.log(response.data);
          if(response.data.isSuccess === true){
            // alert(response.data.message);
          setpercent("");
    window.location.reload();
          }
          else{
            alert("something went wrong !")
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  const handleOpenTeams=()=>
  {
   setViewTeams(true);
   setViewVendorsTeams(false);
   setViewDailyUpdatesByProject(false);
   handleCloseCmpyDoc();
   handleCloseInternalDoc();
   handleClosePresentationDoc();
  }
  const handleCloseTeams=()=>
  {
   setViewTeams(false);
  }
  const handleAllocatEmpbrand =()=>{

    if(projectDetails.vendor === ""  ){
      alert("fields cannot be empty")
   
    }
    else if(percent > 100 ){
      alert("Percentage should not be greater than 100 ")
      }
      
   else{
    var data = JSON.stringify({
      "Project_ID": thisuser.row.project_ID,
      "FactorID": thisuser.row.rowpos,
      "Type": "BRANDSHEET",
      "VENDOR_MCODE": projectDetails.vendor,
      "Allocation": percent,
     
    });
 
    var config = {
      method: 'post',
      url: CREATE_EVENTVENDOR,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if(response.data.isSuccess === true){
          alert(response.data.message)
        setpercent("")
        

        }
        else{
          alert("something went wrong !")
        }
      })
      .catch(function (error) {
        console.log(error);
      });
   }
    
    
  }
  const getTeamMembers=()=>
  {
    axios.post(GET_TEAMS_BY_PROJECT_ID,{"Project_ID": projectName}).then((response) => {
      if(response.data.isSuccess===true)
      {
  response?.data?.data?.map((data,i)=>
      {
    
  axios.post( GET_EMP_DATAILS_BY_MCODE,{"EmployeeID":data.emP_MCODE}).then((response) => {
  if(response.data.isSuccess===true)
  {
    taledata.push(response?.data?.data[0]);
  }
  // settabledata(...taledata,response?.data?.data);
     });
   
    
      
      }
     )
     setEmpDoc
     (  <DataGrid
      rows={taledata}
       columns={columnsForTeams}
       getRowId={(row) => row.rowpos}
       pageSize={10}
       onSelectionModelChange={(ids) => {
       }}
       />)
    }
     
    });
  }
const handlecrossEmpDailyUpdates=()=>
{
  setShowEmpDailyUpdatesPopup(false);
}
  
const handleOpenVendors=()=>
{
  setViewVendorsTeams(true);
  setViewTeams(false);
  setViewDailyUpdatesByProject(false);
  handleCloseCmpyDoc();
  handleCloseInternalDoc();
  handleClosePresentationDoc();
}
const handleCloseVendors=()=>
{
  setViewVendorsTeams(false);
}
const handleOpendailyUpdates=()=>
{
   
  setViewVendorsTeams(false);
  setViewTeams(false);
  setViewDailyUpdatesByProject(true);
  handleCloseCmpyDoc();
  handleCloseInternalDoc();
  handleClosePresentationDoc();
  axios.post(GET_ALLDAILYUPDATE,).then((response) => {
     
    if(response.data.isSuccess===true)
    {
      
     const resData= response.data.data.filter((data,i)=>
      {
        if(data.project_ID.includes(projectName))
        {
          return data;
        }
 
      }
      )
       
      setAllDailyUpdates(resData);
;  }
   
  });
}
const handleClosedailyUpdates=()=>
{
  setViewDailyUpdatesByProject(false);
}
const handleOpenCmpyDoc=()=>
{
 setViewCompyDoc(true);
 handleCloseInternalDoc();
 handleClosePresentationDoc();
 handleCloseVendors();
 handleCloseTeams();
 handleClosedailyUpdates();
 axios.post(GET_DOCUMENT,{"Project_ID": projectName}).then((response) => {
    
   var array1=[];
   if(response.data.isSuccess===true)
   {
     
    const resData= response.data.data.filter((data,i)=>
     {
       if(data.type.includes("COMPANY_DOCUMENT"))
       {
         return data;
       }

     }
     )
     resData.map((data,i)=>
        {
          array1.push(data.name);
        })
       setCmpntDocs( <Multiselect
       id="cmpDoc"
        options={array1}
        displayValue="name"
         isObject={false}
        showCheckbox={true}
    
      /> );
 }
  
 });
 
}
const handleCloseCmpyDoc=()=>
{
 setViewCompyDoc(false);
}
const handleOpenInternalDoc=()=>
{
 setViewInternalDoc(true);
 handleCloseCmpyDoc();
 handleClosePresentationDoc();
 handleCloseVendors();
 handleCloseTeams();
 handleClosedailyUpdates();
 axios.post(GET_DOCUMENT,{"Project_ID": projectName}).then((response) => {
    
   if(response.data.isSuccess===true)
   {
     
    const resData= response.data.data.filter((data,i)=>
     {
       if(data.type.includes("WORKORDER_DOCUMENT"))
       {
         return data;
       }

     }
     )
     setInternalDocs(resData);
 }
  
 });
 
}
const handleCloseInternalDoc=()=>
{
 setViewInternalDoc(false);
}
const handleOpenPresentationDoc=()=>
{
 setViewUploadPrstnDoc(true);
 handleClosePresentationDoc1(); 
}
const handleClosePresentationDoc=()=>
{
 setViewUploadPrstnDoc(false);
}
const handleOpenPresentationDoc1=()=>
{
 setViewUploadMoreDoc(true);
handleClosePresentationDoc();
}
const handleClosePresentationDoc1=()=>
{
 setViewUploadMoreDoc(false);
}
const costcolumns = [
  { field: "name", headerName: "NAME" },
  { field: "discription", headerName: " DESCRIPTON", width: 150 },
  { field: "head", headerName: "HEAD", width: 130 },
  { field: "internal_Amt", headerName: "internal_Amt", width: 130 },
  // { field: "internal_Rate", headerName: "internal_Rate", width: 130 },
  { field: "location", headerName: "location", width: 110 },
  { field: "qty", headerName: "QUANTITY", width: 110 },
  { field: "size", headerName: "SIZE", width: 110 },
  { field: "unit", headerName: "UNIT", width: 110 },
  { field: "remark", headerName: "REMARK", width: 110 },
  {
    field: "ALLOCATE VENDOR",
    headerName: "ALLOCATE",
    sortable: false,
    width: 130,
    disableClickEventBubbling: true,
    renderCell: () => {
      return (
        <button className="btn btn-success">
          Allocate Vendor
        </button>
      );
    },
  },
  // {
  //   field: "edit",
  //   headerName: "Edit",
  //   sortable: false,
  //   width: 130,
  //   disableClickEventBubbling: true,
  //   renderCell: () => {
  //     return (
  //       <Button variant="contained" color="primary">
  //         EDIT
  //       </Button>
  //     );
  //   },
  // },
];

const brandcolumns = [
  { field: "area", headerName: "area" },
  { field: "description", headerName: " DESCRIPTON", width: 120 },
  { field: "grouping", headerName: "grouping", width: 110 },
  { field: "height", headerName: "height", width: 110 },
  { field: "width", headerName: "width", width: 110 },
  { field: "location", headerName: "location", width: 110 },
  { field: "quantity", headerName: "quantity", width: 110 },
  { field: "rateAmount", headerName: "rateAmount", width: 110 },
  { field: "rateType", headerName: "rateType", width: 110 },
  { field: "remarks", headerName: "remarks", width: 110 },
  { field: "status", headerName: "status", width: 110 },
  { field: "totalAmount", headerName: "totalAmount", width: 110 },
  { field: "unit", headerName: "unit", width: 110 },
  {
    field: "ALLOCATE VENDOR",
    headerName: "ALLOCATE",
    sortable: false,
    width: 130,
    disableClickEventBubbling: true,
    renderCell: () => {
      return (
        <button className="btn btn-success">
          Allocate Vendor
        </button>
      );
    },
  },
  // {
  //   field: "edit",
  //   headerName: "Edit",
  //   sortable: false,
  //   width: 130,
  //   disableClickEventBubbling: true,
  //   renderCell: () => {
  //     return (
  //       <Button variant="contained" color="primary">
  //         EDIT
  //       </Button>
  //     );
  //   },
  // },
];

const column = [
  {
    field: "edate",
    headerName: "Created At",
    width: 360,
    disableClickEventBubbling: true,
    renderCell: (params) => {

      return (
        <p className='text-white mt-2 f-14'>{params?.row?.edate?.split("T")[0]}</p>
      );
    }
  },
  { field: "vendorName", headerName: "VENDOR NAME", width: 360 },
  { field: "allocation", headerName: "allocation", width: 360 },
  // { field: "vendorID", headerName: "vendorID", width: 200 },
 
  // {
  //   field: "edit",
  //   headerName: "Edit",
  //   sortable: false,
  //   width: 130,
  //   disableClickEventBubbling: true,
  //   renderCell: () => {
  //     return (
  //       <Button variant="contained" color="primary">
  //         delete
  //       </Button>
  //     );
  //   },
  // },
]
 const columnForDailyUpdatesForProject=[
  {
    field: "edate",
    headerName: "Created At",
    width: 120,
    disableClickEventBubbling: true,
    renderCell: (params) => {

      return (
        <p className='text-white mt-2 f-14'>{params?.row?.edate?.split("T")[0]}</p>
      );
    }
  },
      { field: 'employeeName', headerName: 'Employee Name' ,width: 190,},
      { field: 'projectName', headerName: 'Event Name',width: 230,},
      { field: 'subject', headerName: 'Subject' ,width: 250,},
      { field: 'remarks', headerName: 'Remarks',width: 250,},
      {
        field: "path",
        headerName: "View Documents",
        sortable: false,
        width: 230,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <Button  variant="contained" style={{background:"rgb(15, 83, 61)",color:"#b8e3d7"}} color="primary" onClick={e=>{viewDailyDocFoeEmp(params.row.dailyUpdate_docs)}}>view</Button>
          );
        }
      }
 ];
 const addCmpnyDoc=()=>
{
//  
//   document.getElementById("cmpDoc_input").value=document.getElementsByClassName("chip").textContent;
// console.log(document.getElementsByClassName("chip").innerHTML);
//   if(document.getElementById("cmpDoc_input").value===""||document.getElementById("cmpDoc_input").value==="undefined")
// {
//   alert("Please Select any Dooument");

// }
// else
// {
//   alert("Add Successfully");
//   window.location.reload();
// }
alert("Add Successfully");
window.location.reload();
}
  return (
 <>
<br /><br/>
 
<div className='px-4'>
<center> <h3 className='tracking-in-expand-fwd-bottom' style={{fontWeight:"900",textTransform:"uppercase",letterSpacing:"4px",color:"rgba(230, 55, 55, 0.884)"}}>{eventname.projectName}</h3></center>
                <Link to={`/projectList/updateform?id=${projectName}`}>
                <div  style={{color:"#fff",float:"right",background:"rgb(9, 99, 88)",padding:"5px 10px",fontSize:"14px",borderRadius:"6px"}}><ion-icon name="create"></ion-icon>EDIT</div>
                </Link>
     <div className=" py-4 Project_details row">
     <div className='col-md-6 col-sm-6 col-6'>
              <p><span className='tracking-in-expand-fwd-top'>CLIENT NAME :</span>{eventname.clientName===""?"-----":eventname.clientName}</p>
                <p><span className='tracking-in-expand-fwd-bottom'>CATEGORY :</span>{eventname.category===""?"-----":eventname.category}</p>
                <p><span className='tracking-in-expand-fwd-bottom'>Owner Name :</span>{eventname.emd===""?"-----":eventname.ownerName}</p>
                <p><span className='tracking-in-expand-fwd-top'>PROJECT TYPE :</span>{eventname.projectType===""?"-----":eventname.projectType}</p> 
                <p><span className='tracking-in-expand-fwd-top'>ESTIMATED PROJECT COST :</span>{eventname.estimatedProjectCost===""?"-----": `Approx ${eventname.estimatedProjectCost} Crore for 1 years(As mentioned in Corrigendum)`}</p>  
                <p><span className='tracking-in-expand-fwd-bottom'>EARNEST MONEY DEPOSIT(EMD) :</span>{eventname.emd===""?"-----":`${eventname.emd}/-`}</p>
                <p><span className='tracking-in-expand-fwd-top'>TENDER DOCUMENT FEE :</span>{eventname.tenderDocumentFee===""?"-----":`${eventname.tenderDocumentFee}/-`}</p> 
                <p><span className='tracking-in-expand-fwd-top'>PROCESS OF SUBMISSION OF BID :</span>{eventname.processOfSubmissionBid===""?"-----":eventname.processOfSubmissionBid}</p> 
</div>
<div className='col-md-6 col-sm-6 col-6'>
<p><span className='tracking-in-expand-fwd-bottom'>DATE OF PRE-BID MEETING:</span>{eventname.preBidMeetingDate===""?"-----":new Date(eventname.preBidMeetingDate).toLocaleString()}</p>
              <p><span className='tracking-in-expand-fwd-bottom'>LAST DATE OF PRE-BID QUERIES :</span>{eventname.lDateOfPreBidQueries===""?"-----":new Date(eventname.lDateOfPreBidQueries).toLocaleString()}</p>
              <p><span className='tracking-in-expand-fwd-bottom'>LAST DATE OF SUBMISSION BIDS :</span>{eventname.ldateOfSubmissionBid===""?"-----":new Date(eventname.ldateOfSubmissionBid).toLocaleString()}</p>
              <p><span className='tracking-in-expand-fwd-bottom'>DATE OF OPENING TECHNICAL BIDS :</span>{eventname.technicalBidOpenDate===""?"-----":new Date(eventname.technicalBidOpenDate).toLocaleString()}</p>
              <p><span className='tracking-in-expand-fwd-bottom'>PRESENTATION DATE :</span>{eventname.presentationDate===""?"-----":new Date(eventname.presentationDate).toLocaleString()}</p>
              <p><span className='tracking-in-expand-fwd-bottom'>EVALUATION :</span>{eventname.evaluation===""?"-----":eventname.evaluation}</p>
              <p><span className='tracking-in-expand-fwd-top'>Interested :</span>{eventname.interested?.toString()=== 'true'?"✔"  :"❌"}</p>
              <p><span className='tracking-in-expand-fwd-bottom'>Remarks :</span>{eventname.remarks}</p>
              <p><span className='tracking-in-expand-fwd-top'>WON :</span>{eventname.won?.toString()=== 'true'?  "✔" :"❌"}</p> 
            </div>
            </div>
            <div className='px-3 text-center'>
              <div className='row'>
              <div className='col-md-6'>
              <h4 className='theamColor'>Choose Company Documents</h4>
              <table className="table hAuto">
                <thead>
                 <tr>
                    <th scope="col">Document Name</th>
                    {/* <th scope="col">Documents</th> */}
                   </tr>
                </thead>
                <tbody>
                   <tr>
                     <td scope="row">
                     {cmpntDocs}
                </td>
                      <td>
                      <button className='btn btn-success'onClick={e=>{addCmpnyDoc(e)}}>Add</button>
                      </td>
  
                    </tr>
                 </tbody>
               </table>
                </div>
                
              <div className='col-md-6'>
              <h4 className='theamColor'>Choose Work Order & Completions Certificate</h4>
              <table className="table hAuto">
                <thead>
                 <tr>
                    <th scope="col">Document Name</th>
                    {/* <th scope="col">Documents</th> */}
                   </tr>
                </thead>
                <tbody>
                <tr>
                     <td scope="row">
                      
                {internalDocs}
                </td>
                      <td>
                      <button className='btn btn-success'>Add</button>
                      </td>
  
                    </tr>
                
                 </tbody>
               </table>
                </div>
                <div className='col-md-4'>
                <button className=' btn btn-success tracking-in-expand-fwd-top' onClick={e=>{handleOpenPresentationDoc()}}>upload Technical Presentation Documents</button>  
                </div> 
                <div className='col-md-3'>
                <button className=' btn btn-success tracking-in-expand-fwd-top' onClick={e=>{handleOpenPresentationDoc1()}}>Upload More Documents</button>  
                </div>
                {/* <div className='col-md-2'>
                <button className=' btn btn-success tracking-in-expand-fwd-top' onClick={e=>{handleOpenCmpyDoc()}}>view Compony Documents</button>  
                </div>
                <div className='col-md-2'>
                <button className=' btn btn-success tracking-in-expand-fwd-top' onClick={e=>{handleOpenInternalDoc()}}>view Internal Documents</button>  
                </div>
                <div className='col-md-2'>
                <button className=' btn btn-success tracking-in-expand-fwd-top' onClick={e=>{handleOpenPresentationDoc()}}>upload Presentation Documents</button>  
                </div> */}
                <div className="col-md-3 col-sm-3 col-3">
              <button className=' btn btn-success tracking-in-expand-fwd-top'onClick={e=>{handleOpenTeams(e)}}>View Employees List</button>  
              </div>
              <div className="col-md-4 col-sm-4 col-4 my-2" >
              <button className=' btn btn-success tracking-in-expand-fwd-top'onClick={e=>{handleOpendailyUpdates(e)}}>View Daily Updates</button>  
              </div>
              <div className="col-md-3 col-sm-3 col-3 my-2">
              <button className=' btn btn-success tracking-in-expand-fwd-top 'onClick={e=>{handleOpenVendors(e)}}>View Vendors List</button>  
              </div>
              </div>
            </div>
      
                         {/*show company Documents */}
            {
              viewCompyDoc?
             
              <div style={{ height: "100vh", width: "100%" }}>
              <p className="float-end cursor-pointer p-4" onClick={(e) => handleCloseCmpyDoc()}>
                ❌
              </p>
          
              <div className="formbg">
                <center>
                  {" "}
                  <h3
                    className="tracking-in-expand-fwd-bottom"
                    style={{
                      fontWeight: "900",
                      textTransform: "uppercase",
                      letterSpacing: "4px",
                      color: "rgb(9, 82, 85)",
                      marginTop:"-60px"
                    }}
                  >
                  Company Documents List
                  </h3>
                </center>
                <br /><br />
          
                <div style={{ width: "80%", margin: "auto" }}>
                <div className="table">
          
                <DataGrid
              rows={cmpntDocs}
              columns={columnsForCmpnyDoc}
              getRowId={(row) => row.rowpos}
              pageSize={10}
              // checkboxSelection
              onSelectionModelChange={(ids) => {
              }}
              /> 
                </div>
                </div>
              </div>
            </div>
                        :null
            }
               {/*show Internal Documents */}
               {
              viewInternalDoc?
              <div style={{ height: "100vh", width: "100%" }}>
              <p className="float-end cursor-pointer p-4" onClick={(e) => handleCloseInternalDoc()}>
                ❌
              </p>
          
              <div className="formbg">
                <center>
                  {" "}
                  <h3
                    className="tracking-in-expand-fwd-bottom"
                    style={{
                      fontWeight: "900",
                      textTransform: "uppercase",
                      letterSpacing: "4px",
                      color: "rgb(9, 82, 85)",
                      marginTop:"-60px"
                    }}
                  >
                   Internal Documents List
                  </h3>
                </center>
                <br /><br />
          
                <div style={{ width: "80%", margin: "auto" }}>
                <div className="table">
                <DataGrid
              rows={internalDocs}
              columns={columnsForCmpnyDoc}
              getRowId={(row) => row.rowpos}
              pageSize={10}
              // checkboxSelection
              onSelectionModelChange={(ids) => {
              }}
              /> 
                </div>
                </div>
              </div>
            </div>
              
              :null
            }
               {/*show uploade presentation Documents */}
               {
                viewUploadPrstnDoc?  
                <div className="row py-5">
                <div className="col-md-12 col-sm-12 col-12">
              <h3
                style={{
                  color: "#0f533d",
                  textTransform: "uppercase",
                  fontWeight: "900",
                  textAlign:"center",
                   
                  // marginTop: "-30px",
                }}
                className="mb-2"
              >
                {" "}
                <span style={{paddingLeft:"8%"}}> UPLOAD PRESENTATION DOCUMENT{" "}</span>
               
                <p className="float-end px-4 cursor-pointer "onClick={e=>{handleClosePresentationDoc()}} >❌</p>
              </h3>
                </div>
              
                <div className="col-md-4 col-sm-4 col-4"></div>
                <div className="col-md-4 col-sm-4 col-4 py-3 formClass" >
                <div className="my-5">
                  <label className="form-label">DOCUMENT NAME</label> <br />
                <input type="text" placeholder='Enter Document Name..' className="w-100"/>
                </div>
                <div className="my-5">
                  <label className="form-label">UPLOAD DOCUMENT</label> <br />
                  <input type="file"
                     onChange={(e) => handlefile(e)}
                    className="form-control w-100" />
   
                </div>
                {/* <div className="my-5">
                  <label className="form-label">SELECT CATEGORY</label> <br />
                  <select
                    className="w-100"
                    aria-label="Default select example"
                    style={{ width: "200px" }}
                    // value={empname}
                    id="docCategory"
                    // onChange={(e) => setempname(e.target.value)}
                  >
                    <option>select employee</option>
                   {dropdown?.map((emp, i) => (
                      <option key={i} value={emp.employeeID}>{emp.firstName}{emp.lastName}</option>
                    ))} 
                  </select>
                </div> */}
       
                <div className="my-5">
                  <button
                    style={{
                      borderRadius: 5,
                      backgroundColor: "green",
                      padding: "6px 20px",
                      fontSize: "14px",
                      border: "transparent",
                      color: "#fff",
                      marginTop: "20px",
                    }}
                    onClick={e=>{uploadfiles()}}
                  >
                    SUBMIT
                  </button>
                </div>
              {/* <div className='table animatingBottom' style={{width:"80%"}}>
            {dataTable}
            
            </div> */}
                </div>
              </div>:null
               }
                          {
                viewUploadMoreDoc?  
                <div className="row py-5">
                <div className="col-md-12 col-sm-12 col-12">
              <h3
                style={{
                  color: "#0f533d",
                  textTransform: "uppercase",
                  fontWeight: "900",
                  textAlign:"center",
                   
                  // marginTop: "-30px",
                }}
                className="mb-2"
              >
                {" "}
                <span style={{paddingLeft:"8%"}}> Upload More Document{" "}</span>
               
                <p className="float-end px-4 cursor-pointer "onClick={e=>{handleClosePresentationDoc1()}} >❌</p>
              </h3>
                </div>
              
                <div className="col-md-4 col-sm-4 col-4"></div>
                <div className="col-md-4 col-sm-4 col-4 py-3 formClass" >
                <div className="my-5">
                  <label className="form-label">DOCUMENT NAME</label> <br />
                <input type="text" placeholder='Enter Document Name..'onChange={e=>{setGetDocName(e.target.value)}} className="w-100"/>
                </div>
                <div className="my-5">
                  <label className="form-label">UPLOAD DOCUMENT</label> <br />
                  <input type="file"
                     onChange={(e) => handlefile(e)}
                    className="form-control w-100" />
   
                </div>
                <div className="my-5">
                  <button
                    style={{
                      borderRadius: 5,
                      backgroundColor: "green",
                      padding: "6px 20px",
                      fontSize: "14px",
                      border: "transparent",
                      color: "#fff",
                      marginTop: "20px",
                    }}
                    onClick={e=>{uploadfiles1()}}
                  >
                    SUBMIT
                  </button>
                </div>
                </div>
              </div>:null
               }
            {/*Show Team member List */}
            {
              viewTeams? 
                <div style={{ height: "100vh", width: "100%" }}>
    <p className="float-end cursor-pointer p-4" onClick={(e) => handleCloseTeams()}>
      ❌
    </p>

    <div className="formbg">
      <center>
        {" "}
        <h3
          className="tracking-in-expand-fwd-bottom"
          style={{
            fontWeight: "900",
            textTransform: "uppercase",
            letterSpacing: "4px",
            color: "rgb(9, 82, 85)",
            marginTop:"-60px"
          }}
        >
        Team Member List
        </h3>
      </center>
      <br /><br />

      <div style={{ width: "80%", margin: "auto" }}>
      <div className="table">
{empDoc}
    
      </div>
      </div>
    </div>
  </div>
              :null
             }
{/*show vendor List Data */}
             {
              viewVendorsTeam? <div style={{ height: "100vh", width: "100%" }}>
              <p className="float-end cursor-pointer p-4" onClick={(e) => handleCloseVendors()}>
                ❌
              </p>
          
              <div className="formbg">
                <center>
                  {" "}
                  <h3
                    className="tracking-in-expand-fwd-bottom"
                    style={{
                      fontWeight: "900",
                      textTransform: "uppercase",
                      letterSpacing: "4px",
                      color: "rgb(9, 82, 85)",
                      marginTop:"-60px"
                    }}
                  >
                 Vendors List
                  </h3>
                </center>
                <br /><br />
          
                <div style={{ width: "80%", margin: "auto" }}>
                <div className="table">
                <DataGrid
      rows={dataTableForVendor}
       columns={column}
       getRowId={(row) => row.rowpos}
       pageSize={10}
       onSelectionModelChange={(ids) => {
       }}
       />
                </div>
                </div>
              </div>
            </div>
                        :null
             }
             {/*show DailyUpdates By Project List */}
             {
              viewDailyUpdatesByProject?
              <div style={{ height: "100vh", width: "100%" }}>
              <p className="float-end cursor-pointer p-4" onClick={(e) => handleClosedailyUpdates()}>
                ❌
              </p>
          
              <div className="formbg">
                <center>
                  {" "}
                  <h3
                    className="tracking-in-expand-fwd-bottom"
                    style={{
                      fontWeight: "900",
                      textTransform: "uppercase",
                      letterSpacing: "4px",
                      color: "rgb(9, 82, 85)",
                      marginTop:"-60px"
                    }}
                  >
                 Daily Updates
                  </h3>
                </center>
                <br /><br />
          
                <div style={{ width: "80%", margin: "auto" }}>
                <div className="table">
                <DataGrid
            rows={allDailyUpdates}
             columns={columnForDailyUpdatesForProject}
             getRowId={(row) => row.rowpos}
             pageSize={10}
             onSelectionModelChange={(ids) => {
             }}
             />
                </div>
                </div>
              </div>
            </div>:null
             }
{/*show emp daily updates popus */}
{showEmpDailyUpdatesPops?
    <div className="popupbox" style={{position:"fixed",top:"-1%"}}>
    <div className='popup' style={{width:"50%",position:"fixed"}}>
   <p style={{float:"right",cursor:"pointer"}} onClick={handlecrossEmpDailyUpdates}>❌</p>
<h3 className="text-danger">Daily Updates</h3>
<DataGrid
  className='animatingTop'
    rows={DailyUpdatesForEmp}
    columns={columnForDUpdatesOFEmp}
    pageSize={10}
    getRowId={(row) => row.rowpos}
   
  />
  </div>
  </div>:null
}
{/*DocShow popup*/}
{
        popupdoc ?

          <div className='popup' style={{width:"50%",position:"fixed"}}>
         <p style={{float:"right",cursor:"pointer"}} onClick={handlecrossDocPopo}>❌</p>
        {
          ViewDoc
        }
        </div>
        :
        ""

      }
{
  popupcell ? 
  
  (
    <div style={{ height: "100vh", width: "100%" }}>
      <p className="float-end cursor-pointer p-4"onClick={(e) => setpopupcell(false)}>
        ❌
      </p>
  
      <div className="formbg">
        <center>
          {" "}
          <h3
            className="tracking-in-expand-fwd-bottom"
            style={{
              fontWeight: "900",
              textTransform: "uppercase",
              letterSpacing: "4px",
              color: "rgb(9, 82, 85)",
              marginTop:"-60px"
            }}
          >
            ALLOCATE VENDOR
          </h3>
        </center>
        <br /><br />
  
        <div style={{ width: "80%", margin: "auto" }}>
          <div
            className="d-flex"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="mb-3">
              <label>EVENT NAME</label>
              <br />
            
              <select style={{marginTop:"12px",color:"green",fontWeight:"800"}}>
              <option  value={eventname.project_ID} >{eventname.projectName} </option>
               
              </select>
              
            </div>
            <div className="mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label"
              >
                SELECT VENDOR
              </label>
  
              <input
                className="form-select"
                aria-label="Default select example"
                id="vdr"
                name="Category"
                value={projectDetails.vendor}
                onClick={handlecategory}
              />
              {showMulticategory ? (
                <div>
                  <ul className="list-group multiSelectBox">
                    {vendordrop?.map((vendor, i) => (
                      <li className="list-group-item"key={i}>
                        <input
                          type="checkbox"
                          name="vendor"
                          className="mx-2"
                          onChange={(e) => onHandleChange2(e)}
                          value={vendor.mcode}
                        />
                        {vendor.vendorName}{" "}
                      </li>
                    ))
  
                    }
  
                  </ul>
                </div>
              ) : null}
            </div>
            <div className="mb-3">
              <label>ALLOCATE PERCENTAGE</label>
              <br />
              <br />
              <input type="number"
              placeholder="enter percentage "
              value={percent}
              onChange={(e)=>setpercent(e.target.value)}
              />
            </div>
  
          </div>
          <center><Button onClick={handleAllocatEmpbrand} style={{background:"green",color:"#fff",padding:"5px 20px"}} >SUBMIT</Button></center>
          <br />
        <div className="table">
        {/* <DataGrid
  className='animatingTop'
    rows={eventVendor}
    columns={column}
    pageSize={10}
    getRowId={(row) => row.rowpos}
   
  /> */}
        </div>
        </div>
      </div>
    </div>
  ) 
  
  :""
}
{
brandpopupcell ? 

(
  <div style={{ height: "100vh", width: "100%" }}>
    <p className="float-end cursor-pointer p-4" onClick={(e) => setbrandpopupcell(false)}>
      ❌
    </p>

    <div className="formbg">
      <center>
        {" "}
        <h3
          className="tracking-in-expand-fwd-bottom mb-3 h3"
          style={{
            fontWeight: "900",
            textTransform: "uppercase",
            letterSpacing: "4px",
            color: "rgb(9, 82, 85)",
            marginTop:"-60px"
          }}
        >
          ALLOCATE VENDOR
        </h3>
      </center>

      <div className="row">
        <div className="col-md-4 col-sm-4 col-4">

        </div>
        <div className="col-md-4 col-sm-4 col-4 formClass">
        <div className="my-5">
            <label>EVENT NAME</label>
            <br />
          
            <select style={{marginTop:"12px",color:"green",fontWeight:"800"}} className="w-75">
            <option  value={eventname.project_ID} >{eventname.projectName} </option>
             
            </select>
            
          </div>
          <div className="my-5">
            <label
              htmlFor="exampleFormControlInput1"
              className="form-label"
            >
              SELECT VENDOR
            </label>

            <input
              className="form-select w-75"
              aria-label="Default select example"
              id="vdr"
              name="Category"
              value={projectDetails.vendor}
              onClick={handlecategory}
            />
            {showMulticategory ? (
              <div>
                <ul className="list-group multiSelectBox">
                  {vendordrop?.map((vendor, i) => (
                    <li className="list-group-item"key={i}>
                      <input
                        type="checkbox"
                        name="vendor"
                        className="mx-2"
                        onChange={(e) => onHandleChange2(e)}
                        value={vendor.mcode}
                      />
                      {vendor.vendorName}{" "}
                    </li>
                  ))

                  }

                </ul>
              </div>
            ) : null}
          </div>
          <div className="my-5">
            <label>ALLOCATE PERCENTAGE</label>
            <br />
            <br />
            <input type="number"
            placeholder="enter percentage "
            className="w-75"
            value={percent}
            onChange={(e)=>setpercent(e.target.value)}
            />
          </div>
          <center className="my-3"><Button onClick={handleAllocatEmp} style={{background:"green",color:"#fff",padding:"5px 20px"}} >SUBMIT</Button></center>
        </div>
        </div>
  
    </div>
  </div>
)

:""
}

<br /><br /><br/>

      <div className="responsivebox">
        <div
          className={costhover}
          style={{ display: "flex", justifyContent: "space-between" }}
          onClick={handlecost}
        >
          <div className="totalbg">TOTAL COST FACTOR</div>
          <div>{totalcost}</div>
        </div>

        <div
          className={brandhover}
          style={{ display: "flex", justifyContent: "space-between" }}
          onClick={handlebrand}
        >
          <div className="totalbg">TOTAL BRANDING FACTOR</div>
          <div>{totalbrand}</div>
        </div>
      </div>
      <br />

      {cost ? (
        <>
          <div
            className="table tracking-in-expand-fwd-bottom"
            style={{ width: "88%", margin: "auto" }}
          >
            <DataGrid
              className=""
              rows={costsheet}
              columns={costcolumns}
              pageSize={10}
              getRowId={(row) => row.location}
              onCellClick={handleAllbrand}
            ></DataGrid>

            <div className="totalcostprc">
              <p>
                Total Amount :
                <span style={{ fontWeight: "800" }}>
                  {" "}
                  &#x20b9; &nbsp; &nbsp;{countTotalcost}
                </span>
              </p>
            </div>
          </div>
          {/* <div>
            <FloatingBtn
              href={`/ItemList/form/${projectName}`}
              usehtmlFor={`COST  `}
            />
          </div> */}
          <br />
          <br />
          {/* <div
            className="d-flexed  tracking-in-expand-fwd-top"
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "auto",
              width: "70%",
            }}
          >
            <a href={Costsheetdefault} className="downloadbtn" download>
              {" "}
              <ion-icon name="download-outline"></ion-icon>&nbsp;&nbsp; download
              Default Cost 
            </a>
            <input
              type="file"
              id="fileCost"
              onChange={(e) => selectedcost(e)}
              style={{ padding: "10px", color: "rgb(9, 82, 85)" }}
            />
            <Button
              style={{ background: "green", color: "#fff", height: "35px" }}
              onClick={btnuploadcost}
            >
              UPLOAD COST{" "}
            </Button>
          </div> */}
        </>
      ) : (
        <>
          <div
            className="table tracking-in-expand-fwd-top"
            style={{ width: "88%", margin: "auto", border: "none" }}
          >
            <DataGrid
              className=""
              rows={brandsheet}
              columns={brandcolumns}
              pageSize={10}
              getRowId={(row) => row.rowpos}
              onCellClick={handlecostALLvendor}
              
            ></DataGrid>
            <div className="totalcostprc">
              <p style={{ color: "#111" }}>
                Total Amount :
                <span style={{ fontWeight: "800" }}>
                  {" "}
                  &#x20b9; &nbsp; &nbsp;{countTotalbrand}
                </span>
              </p>
            </div>
          </div>

          {/* <div>
            <FloatingBtn
              href={`/ItemList/form/${projectName}`}
              usehtmlFor={` BRAND `}
            />
          </div> */}
          <br />

          <br />
          {/* <div
            className="d-flexed tracking-in-expand-fwd-top"
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "auto",
              width: "70%",
            }}
          >
            <a href={Brandsheetdefault} className="downloadbtn" download>
              {" "}
              <ion-icon name="download-outline"></ion-icon>&nbsp;&nbsp; download
              Default Branding
            </a>
            <input
              type="file"
              onChange={(e) => selectedbrand(e)}
              style={{ padding: "10px", color: "rgb(9, 82, 85)" }}
            />
            <Button
              style={{ background: "green", color: "#fff", height: "35px" }}
              onClick={btnuploadbrand}
            >
              UPLOAD Brand{" "}
            </Button>
          </div> */}
        </>
      )}

      <br />
      </div>
      </>
      
  
  );
}

export default Gridboxevent;
