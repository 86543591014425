import React, { useEffect, useState } from 'react';
import './monthalyData.css';
import { GET_ALL_COL_NAME, GET_LEAVE_MASTER, GET_MONTHLY_DATA, ALLOWED_USERTYPE, GET_MONTHLY_DATA_BY_ID, ADD_MONTHALY_SALARY, UPDATE_MONTHALY_SALARY } from '../../../../utiles/AllApis';
import axios from 'axios';
import EmpSearch from '../../../../common/EmpSearch/EmpSearch';
import { onlynumerical } from "../../../../GenericFunction/GenericFunction";
const MonthalyData = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [leaveType, setLeaveType] = useState([]);
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [empData, setEmpData] = useState([]);
  const [uType, setUType] = useState(props.tokectDetails.logintype);
  const [userID, setUserID] = useState(props.tokectDetails.mcode);
  const [isEdit, setIsEdit] = useState(false);
  const [seletedeData, setseletedeData] = useState({});
  const [isadd, setIsadd] = useState(false);
  const [viewCreateSalary, setViewCreateSalary] = useState(false);
  const [heading, setHeading] = useState("");
  const [dynamicClo, setDynamicClo] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handalOpenErr = () => { setIsOpen(true); }
  const [allColumns, setAllColumns] = useState([]);
  const handalOpen1 = () => { setIsOpen1(true); }
  const [successMsg, setsuccessMsg] = useState("");
  const [rowpos, setRowpos] = useState("");
  const [selecedUserID, setSelectedUserID] = useState(uType === ALLOWED_USERTYPE.ADMIN ? "" : props.tokectDetails.mcode);
  const addDetails = () => {
    setIsadd(true);
    setEmpData({});
    setIsEdit(false);
  }
  const [MnthlySlryData, setMnthlySlryData] = useState({
    "EmployeeID": selecedUserID,
    "ColName": dynamicClo
  });
  useEffect(() => {
    setMonth(new Date().toLocaleString('default', { month: 'long' }));
    setYear(new Date().getFullYear());
    getDetails();
    getAllLeaveType();
    GetAllColName();
    setMnthlySlryData({ ...MnthlySlryData, "EmployeeID": selecedUserID });
    setDynamicClo({});
  }, [selecedUserID]);

  function getDetails() {
    if (selecedUserID === "") {
      getAllMnthlyData();
    }
    else {
      getAllMnthlyDatabYiD(selecedUserID);
    }
  }
  const onHandleChangeNew = (e) => {
    setDynamicClo({ ...dynamicClo, [e.target.name]: e.target.value });
  }
  //--------------get all columns -----------
  const GetAllColName = () => {
    setLoading(true);
    axios.get(GET_ALL_COL_NAME).then((response) => {
      setLoading(false);

      if (response.data.isSuccess === true) {
        setAllColumns(response.data.data);
      }
      else {
        setAllColumns([]);
      }

    })
  }
  //-----------get all employee's data-------------
  const getAllMnthlyData = () => {
    setLoading(true);
    axios.get(GET_MONTHLY_DATA).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        debugger;
        setEmpData(response.data.data);
      }
      else {
        setEmpData([]);
      }

    })
  }
  //-----------get employee's data BY MCODE-------------
  const getAllMnthlyDatabYiD = (id) => {
    setLoading(true);
    axios.post(GET_MONTHLY_DATA_BY_ID, { "EmployeeID": id }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setEmpData(response.data.data);
        setMnthlySlryData(response.data.data[0]);
        setDynamicClo(response.data.data[0].colName)
      }
      else {
        setEmpData([]);
        setMnthlySlryData({
          "EmployeeID": selecedUserID,
          "ColName": dynamicClo
        });

      }
    })
  }
  //---------add Monthaly salary details---------------
  const handlesubmit = () => {
    setLoading(true);
    axios.post(ADD_MONTHALY_SALARY, {
      "EmployeeID": selecedUserID,
      "ColName": dynamicClo
    }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setMnthlySlryData({});
        setHeading1("Success");
        setsuccessMsg("Salary added successfully");
        handalOpen1();
        setIsadd(false);
        getDetails();
        setViewCreateSalary(true);

      }
      else {
        setHeading("Failed");
        setErrMsg(response.data.message);
        handalOpenErr();
      }
    })
  }
  //---------UPDATE Monthaly salary details---------------
  const handleUpdate = (id) => {
    setLoading(true);
    axios.post(UPDATE_MONTHALY_SALARY,
      {
        "EmployeeID": selecedUserID,
        "ColName": dynamicClo,
        "ROWPOS": id
      }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setMnthlySlryData({});
          setHeading1("Success");
          setsuccessMsg("Salary updates successfully");
          handalOpen1();
          setIsadd(false);
          getDetails();
          setViewCreateSalary(true);
        }
        else {
          setHeading("Failed");
          setErrMsg(response.data.message);
          handalOpenErr();
        }
      })
  }
  //---------get All leave type------------
  function getAllLeaveType() {
    axios.get(GET_LEAVE_MASTER).then((response) => {
      if (response.data.isSuccess === true) {
        setLeaveType(response.data.data);
      }
      else {
        setLeaveType([]);
      }
    })
  }
  //--------rediretion link in generate salary
  const openGnrtSlry = () => {
    window.location.href = `/employeeprofile/generateSalary/${selecedUserID}`;
  }
  return (
    <>
      {
        isLoading ? <div className='loaderDiv'>
        </div> : null
      }
      <div className='emp-aboutdetailmaincontent'>
        <div className={uType === ALLOWED_USERTYPE.ADMIN ? 'mnusTopMrgn' : ''}>
          <h5 className='text-white fw-bold mt-2 text-center'> Salary Details for the month of {month}-{year}</h5>
          <hr className='myhr text-white' />
        </div>
        {
          uType === ALLOWED_USERTYPE.ADMIN ?
            <EmpSearch uType={uType} setIsadd={setIsadd} isadd={isadd} setUserID={setSelectedUserID} isEdit={isEdit} empData={seletedeData} isView={false} />
            :
            <div className='row'>
              <div className='col-md-2 col-lg-2 col-sm-2 col-2 mt-1'>
                {
                  isadd ? <button className='btn btnSuccess' onClick={e => { setIsadd(false); }}>View List</button>
                    :
                    <button className='btn btnSuccess' onClick={e => { addDetails() }}>Add Details</button>
                }
              </div>
            </div>
        }
        <div className='row'>
          <div className='col-md-12 col-sm-12 col-12 mt-2'>
            {
              empData.length > 0 ?
                <table className="table table-bordered border-secondary ">

                  <thead className='tblHeader'>
                    <tr>
                      <th scope="col text-white">Ecode</th>
                      <th scope="col text-white">Name</th>
                      <th scope="col text-white">Designation</th>
                      <th scope="col text-white">Department</th>
                      <th scope="col text-white">category</th>
                      <th scope="col text-white">off day</th>
                      {empData.filter((key, i) => i === 0).map((data, i) => {
                        return (
                          Object.keys(data?.colName).map((c, i) =>
                            <td>{c}</td>
                          )
                        );
                      })
                      }
                    </tr>
                  </thead>

                  <tbody className='tblBdy'>
                    {
                      empData.map((data, i) => {
                        return (<><tr key={i}>
                          <th scope="row">{data.employeeID}</th>
                          <td>{data.employeeName}</td>
                          <td>{data.strDesignation}</td>
                          <td>{data.department}</td>
                          <td>{data.ecategory}</td>
                          <td>{data.weekOfDay}</td>
                          {Object.keys(data?.colName).map((c, i) =>
                            <td>{data?.colName[c]}</td>
                          )}
                        </tr>
                        </>
                        );
                      }
                      )
                    }
                  </tbody>
                </table>
                : <div className='text-center'>
                  <h5 className='text-danger text-center mt-2'>
                    ------ No Data Found ------
                  </h5>
                </div>
            }
          </div>
        </div>
        {
          selecedUserID !== "" ?
            <div className={uType === ALLOWED_USERTYPE.ADMIN ? 'mt-2' : 'mnusTopMrgn'}>
              <h5 className='SecoHead text-center'>Leave Details</h5>
              <hr className='myhr text-white' />
              <div className='emp_profile_form'>
                <div className='row'>
                  {
                    leaveType?.map((data, i) => {
                      return (
                        <> <div className='col-md-2 col-sm-2 col-2 mt-3'>
                          <label
                            htmlFor={data.lcode}
                            className="form-label fw-normal h6 text-capitalize"
                          >
                            {data.description}
                            <span className='required'>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control w-100 mt-2"
                            id={data.lcode}
                            name={data.lcode}
                            onKeyPress={onlynumerical}
                            value={Object.keys(dynamicClo).filter((key) => key === data.lcode).map((c, i) =>
                              dynamicClo[c]
                            )}
                            placeholder={`Enter ${data.description} Here...`}
                            onChange={(e) => onHandleChangeNew(e)}
                          />
                        </div>
                        </>)
                    })
                  }
                </div>

                <h5 className='SecoHead text-center mt-3'>Salary Structure</h5>
                <hr className='myhr text-white' />
                <div className='row'>
                  {
                    allColumns?.filter((c, i) => c.type === "D").map((data, i) => {
                      return (<div className='col-md-3 col-sm-3 col-4 my-2'>
                        <label
                          htmlFor={data?.printName}
                          className="form-label fw-normal h6 text-capitalize"
                        >
                          {data?.salaryHead}<span className='required'>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control w-75 mt-2"
                          id={data?.printName}
                          name={data?.printName}
                          disabled={data?.fix ? true : data?.val ? false : data?.type === "R" || data?.type === "P" ? true : false}
                          value={Object.keys(dynamicClo).filter((key) => key === data?.printName).map((c, i) =>
                            dynamicClo[c]
                          )}
                          placeholder={`Enter ${data?.printName} of employee Here...`}
                          onChange={(e) => onHandleChangeNew(e)}
                          onKeyPress={onlynumerical}
                        />
                      </div>);
                    })
                  }
                  {empData.length > 0 ?
                    <>
                      <div className="col-md-2 col-sm-2 col-2 mt-5 text-center">
                        <button className='btn btnSuccess'
                          onClick={e => { handleUpdate(empData[0].rowpos) }}
                        ><span>Update</span></button>
                      </div>
                      <div className="col-md-2 col-sm-2 col-2 mt-5 text-center">
                        <button className='btn btnSuccess'
                          onClick={openGnrtSlry}
                        ><span>Generate Salary</span></button>
                      </div>
                    </>
                    :
                    <div className="col-md-2 col-sm-2 col-2 mt-5 text-center">
                      <button className='btn btnSuccess'
                        onClick={handlesubmit}
                      ><span>Submit</span></button>
                    </div>

                  }

                </div>
              </div></div>
            : ""}
      </div>
    </>
  )
}

export default MonthalyData