import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import EmpSearch from '../../../../common/EmpSearch/EmpSearch';
import axios from 'axios';
import { VscError } from 'react-icons/vsc';
import './empChanges.css';
import {
  ALLOWED_USERTYPE, GET_ALL_EMP_CHANGES, CREATE_EMP_CHANGES, UPDATE_EMP_CHANGES,
  GET_EMP_CHANGES_BY_MCODE, DELETE_EMP_CHANGES, GET_ALL_COL_NAME
} from '../../../../utiles/AllApis';
import ErrorAlertBox from '../../../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../../../common/AlertBox/SuccessAlertBox';
import { Modal, ModalBody } from 'reactstrap';
import { onlynumerical } from "../../../../GenericFunction/GenericFunction";
function SalaryChanges(props) {
  const [isLoading, setLoading] = useState(false);
  const [empChangedata, setChangesdata] = useState([])
  const [allColumns, setAllColumns] = useState([])
  const [uType, setUType] = useState(props.tokectDetails.logintype);
  const [userID, setUserID] = useState(props.tokectDetails.mcode);
  const [selecedUserID, setSelectedUserID] = useState(uType === ALLOWED_USERTYPE.ADMIN ? "" : props.tokectDetails.mcode);
  const [seletedeData, setseletedeData] = useState({});
  const [isadd, setIsadd] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpencomfirm, setIsOpencomfirm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [rowpos, setRowpos] = useState("");
  const [dynamicClo, setDynamicClo] = useState({});
  const [male, setMale] = useState(false);
  const [femail, setFemale] = useState(false);
  const [nominee, setNominee] = useState(false);
  const [heading, setHeading] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const handalOpenErr = () => { setIsOpen(true); }
  const handalOpen1 = () => { setIsOpen1(true); }
  const handalCloseconfim = () => { setIsOpencomfirm(false); }

  const [empChangesDetails, setEmpChangesDetails] = useState(
    {
      "EmployeeID": selecedUserID,
      "chgdate": "",
      "wef": "",
      "elocation": "",
      "eGrade": "",
      "eLevel": "",
      "edepartment": "",
      "eDesignation": "",
      "remarks": "",
      "basic": "",
      "hra": "",
      "sa": "",
      "ta": ""
    });
  useEffect(() => {

    GetAllColName();
    setEmpChangesDetails({ ...empChangesDetails, "EmployeeID": selecedUserID })
    getDetails();
  }, [selecedUserID])
  const addDetails = () => {
    setIsadd(true);
    setEmpChangesDetails({});
    setMale(false); setIsEdit(false);
    setFemale(false);
    setNominee(false);
  }
  //--------------get all columns -----------
  const GetAllColName = () => {
    setLoading(true);
    axios.get(GET_ALL_COL_NAME).then((response) => {
      setLoading(false);

      if (response.data.isSuccess === true) {
        setAllColumns(response.data.data);
      }
      else {
        setAllColumns([]);
      }

    })
  }
  //------------get all relative details--------
  function getDetails() {
    if (uType === ALLOWED_USERTYPE.ADMIN) {
      getAllEmgncyDtls(selecedUserID);
    }
    else {
      getAllEmgncyDtls(userID);
    }
  }
  //---------------get all Relative/emergency details--------------
  const getAllEmgncyDtls = (mcode) => {
    //-----------gey all details------------
    if (mcode == "") {
      setLoading(true);
      axios.get(GET_ALL_EMP_CHANGES).then((response) => {
        setLoading(false);

        if (response.data.isSuccess === true) {
          setChangesdata(response.data.data);
        }
        else {
          setChangesdata([]);
        }

      })
    }
    //-----------gey details by userMCode------------
    else {
      setLoading(true);
      axios.post(GET_EMP_CHANGES_BY_MCODE, {
        "EmployeeID": mcode
      }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setChangesdata(response.data.data);
        }
        else {
          setChangesdata([]);
        }
      })
    }
  }
  //---------------get relatives details--------------
  const onHandleChange = (e) => {
    if (e.target.name === "nominee") {
      e.target.value = e.target.checked;
      if (e.target.checked) {
        setNominee(true);
      }
      else {
        setNominee(false);

      }
    }
    if (e.target.name === "strEmergContactGender") {
      if (e.target.value === "male") {
        setMale(true);
      }
      else {
        setFemale(true);
      }
    }
    setEmpChangesDetails({ ...empChangesDetails, [e.target.name]: e.target.value });
  }
  //---------------get relatives details--------------
  const onHandleChangeNew = (e) => {
    setDynamicClo({ ...dynamicClo, [e.target.name]: e.target.value });
  }
  //---------add Emergency relative details---------------
  const handlesubmit = () => {
    if (empChangesDetails?.chgdate == "" || empChangesDetails?.wef == "" || empChangesDetails?.elocation == "" || empChangesDetails?.eGrade == ""
      || empChangesDetails?.eLevel == "" || empChangesDetails?.edepartment == "" || empChangesDetails?.eDesignation == "") {
      setHeading("Failed");
      setErrMsg("Mandatory fields cannot empty");
      handalOpenErr();
    }
    else {
      if (new Date(empChangesDetails.chgdate) > new Date(empChangesDetails.wef)) {
        setHeading("Failed");
        setErrMsg("Effective date cannot greater then upto valid date.");
        handalOpenErr();
        return;
      }
      setLoading(true);
      axios.post(CREATE_EMP_CHANGES, {
        "EmployeeID": selecedUserID,
        "chgdate": empChangesDetails.chgdate,
        "wef": empChangesDetails.wef,
        "elocation": empChangesDetails.elocation,
        "eGrade": empChangesDetails.eGrade,
        "eLevel": empChangesDetails.eLevel,
        "edepartment": empChangesDetails.edepartment,
        "eDesignation": empChangesDetails.eDesignation,
        "remarks": empChangesDetails.remarks,
        "ColName": dynamicClo

      }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setEmpChangesDetails({});
          setHeading1("Success");
          setsuccessMsg("Contact added successfully");
          handalOpen1();
          setIsadd(false);
          getDetails();
          setMale(false);
          setFemale(false);
          setNominee(false);
        }
        else {
          setHeading("Failed");
          setErrMsg(response.data.message);
          handalOpenErr();
        }
      })
    }
  }

  //---------update Emergency relative details---------------
  const handleUpdate = () => {
    setEmpChangesDetails(
      {
        "EmployeeID": selecedUserID,
        "chgdate": "",
        "wef": "",
        "elocation": "",
        "eGrade": "",
        "eLevel": "",
        "edepartment": "",
        "eDesignation": "",
        "remarks": "",
        //  "basic":"",
        //  "hra":"",
        //  "sa":"",
        //  "ta":""
      }
    )
    setIsEdit(false);
    setDynamicClo("");
    if (empChangesDetails?.chgdate == "" || empChangesDetails?.wef == "" || empChangesDetails?.elocation == "" || empChangesDetails?.eGrade == ""
      || empChangesDetails?.eLevel == "" || empChangesDetails?.edepartment == "" || empChangesDetails?.eDesignation == "" || empChangesDetails?.basic == ""
      || empChangesDetails?.hra == "" || empChangesDetails?.sa == "" || empChangesDetails?.ta == "") {
      setHeading("Failed");
      setErrMsg("Mandatory fields cannot empty");
      handalOpenErr();
    }
    else {
      if (new Date(empChangesDetails.chgdate) > new Date(empChangesDetails.wef)) {
        setHeading("Failed");
        setErrMsg("Effective date cannot greater then upto valid date.");
        handalOpenErr();
        return;
      }
      setLoading(true);
      axios.post(UPDATE_EMP_CHANGES, {
        "EmployeeID": selecedUserID,
        "chgdate": empChangesDetails.chgdate,
        "wef": empChangesDetails.wef,
        "elocation": empChangesDetails.elocation,
        "eGrade": empChangesDetails.eGrade,
        "eLevel": empChangesDetails.eLevel,
        "edepartment": empChangesDetails.edepartment,
        "eDesignation": empChangesDetails.eDesignation,
        "remarks": empChangesDetails.remarks,
        "ColName": dynamicClo,
        //  "basic":empChangesDetails.basic,
        //  "hra":empChangesDetails.hra,
        //  "sa":empChangesDetails.sa,
        //  "ta":empChangesDetails.ta,
        "ROWPOS": rowpos
      }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess == true) {
          setHeading1("Success");
          setsuccessMsg("Record update successfully..");
          handalOpen1();
          setIsadd(false);
          getDetails();
          setEmpChangesDetails({});
        }
        else {
          setHeading("Failed");
          setErrMsg(response.data.message);
          handalOpenErr();
        }
      })
    }
  }

  //-------------Edit emergency details------------------
  const handelEdit = (id, chgdate, wef, elocation, eGrade, eLevel, edepartment, eDesignation, remarks, ColName) => {
    // setLoading(true);

    setRowpos(id);
    setIsEdit(true);
    setIsadd(true);
    setEmpChangesDetails({
      "chgdate": chgdate,
      "wef": wef,
      "elocation": elocation,
      "eGrade": eGrade,
      "eLevel": eLevel,
      "edepartment": edepartment,
      "eDesignation": eDesignation,
      "remarks": remarks,
    })
    setDynamicClo(ColName);
  }
  //-------------Delete emergency details------------------
  const handelDelete = (id) => {
    handalCloseconfim();
    setRowpos(id);
    setLoading(true);
    axios.post(DELETE_EMP_CHANGES, { "ROWPOS": id }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {

        getDetails();
      }
      else {
        setHeading("Failed");
        setErrMsg(response.data.message);
        handalOpenErr();
      }
    })
  }
  return (
    <>
      {
        isLoading ? <div className='loaderDiv'>
        </div> : null
      }
      <div className='emp-aboutdetailmaincontent'>
        <div className={uType === ALLOWED_USERTYPE.ADMIN ? 'mnusTopMrgn' : ''}>
          <h5 className='text-white fw-bold mt-2 text-center'>Employee Changes</h5>
          <hr className='myhr text-white' />
        </div>
        {
          uType === ALLOWED_USERTYPE.ADMIN ?
            <EmpSearch uType={uType} setIsadd={setIsadd} isadd={isadd} setUserID={setSelectedUserID} isEdit={isEdit} empData={seletedeData} />
            :
            <div className='row'>
              <div className='col-md-2 col-lg-2 col-sm-2 col-2 mt-1'>
                {
                  isadd ? <button className='btn btnSuccess' onClick={e => { setIsadd(false); }}>View List</button>
                    :
                    <button className='btn btnSuccess' onClick={e => { addDetails() }}>Add Details</button>
                }
              </div>
            </div>
        }
        {
          isadd ?
            <div className={uType === ALLOWED_USERTYPE.ADMIN ? 'mt-5' : 'mnusTopMrgn'}>
              <h5 className='SecoHead text-center'>Add Details</h5>
              <hr className='myhr text-white' />
              <div className='emp_profile_form'>
                <div className='row'>
                  <div className='col-md-3 col-sm-3 col-3 mt-3'>
                    <label
                      htmlFor="chgdate"
                      className="form-label fw-normal h6 text-capitalize"
                    >
                      Effective Form<span className='required'>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control w-75 mt-2"
                      value={empChangesDetails?.chgdate?.split("T")[0]}
                      id="chgdate"
                      name="chgdate"
                      max="9999-12-31"
                      onChange={(e) => onHandleChange(e)}
                    />
                  </div>
                  <div className='col-md-3 col-sm-3 col-3 mt-3'>
                    <label
                      htmlFor="wef"
                      className="form-label fw-normal h6 text-capitalize"
                    >
                      Valid Upto<span className='required'>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control w-75 mt-2"
                      value={empChangesDetails?.wef?.split("T")[0]}
                      id="wef"
                      name="wef"
                      max="9999-12-31"
                      onChange={(e) => onHandleChange(e)}
                    />
                  </div>
                  <div className='col-md-3 col-sm-3 col-3 mt-3'>
                    <label
                      htmlFor="elocation"
                      className="form-label fw-normal h6 text-capitalize"
                    >
                      Location<span className='required'>*</span>
                    </label>
                    <select className="form-select mt-2 w-75" value={empChangesDetails?.elocation}
                      aria-label="Default select example" name="elocation" id="elocation" onChange={e => { onHandleChange(e) }}>
                      <option value="">--Select Location--</option>
                      <option value="Noida">Noida</option>
                    </select>
                  </div>
                  <div className='col-md-3 col-sm-3 col-3 mt-3'>
                    <label
                      htmlFor="eGrade"
                      className="form-label fw-normal h6 text-capitalize"
                    >
                      Grade<span className='required'>*</span>
                    </label>
                    <select className="form-select mt-2 w-75" value={empChangesDetails?.eGrade}
                      aria-label="Default select example" name="eGrade" id="eGrade" onChange={e => { onHandleChange(e) }}>
                      <option value="">--Select Grade--</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                    </select>
                  </div>
                  <div className='col-md-3 col-sm-3 col-3 mt-3'>
                    <label
                      htmlFor="eLevel"
                      className="form-label fw-normal h6 text-capitalize"
                    >
                      Level<span className='required'>*</span>
                    </label>
                    <select className="form-select mt-2 w-75" value={empChangesDetails?.eLevel}
                      aria-label="Default select example" name="eLevel" id="eLevel" onChange={e => { onHandleChange(e) }}>
                      <option value="">--Select Level--</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                  <div className='col-md-3 col-sm-3 col-3 mt-3'>
                    <label
                      htmlFor="edepartment"
                      className="form-label fw-normal h6 text-capitalize"
                    >
                      Department<span className='required'>*</span>
                    </label>
                    <select className="form-select mt-2 w-75" value={empChangesDetails?.edepartment}
                      aria-label="Default select example" name="edepartment" id="edepartment" onChange={e => { onHandleChange(e) }}>
                      <option value=""> --Select Department Name--</option>
                      <option value="tenders"> TENDERS</option>
                      <option value="accounts"> ACCOUNTS</option>
                      <option value="creative"> CREATIVE</option>
                      <option value="production"> PRODUCTIONS</option>
                      <option value="HR"> HR</option>
                    </select>
                  </div>
                  <div className='col-md-3 col-sm-3 col-3 mt-3'>
                    <label
                      htmlFor="eDesignation"
                      className="form-label fw-normal h6 text-capitalize"
                    >
                      Designation<span className='required'>*</span>
                    </label>
                    <select className="form-select mt-2 w-75" value={empChangesDetails?.eDesignation}
                      aria-label="Default select example" name="eDesignation" id="eDesignation" onChange={e => { onHandleChange(e) }}>
                      <option value=""> --Select Designation--</option>
                      <option value="Owner/Director">Owner/Director</option>
                      <option value="additional_director">additional director</option>
                      <option value="Manager">Manager</option>
                      <option value="Team">Team</option>
                      <option value="Intern">Intern</option>
                    </select>
                  </div>
                  <div className='col-md-3 col-sm-3 col-3 mt-3'>
                    <label
                      htmlFor="remarks"
                      className="form-label fw-normal h6 text-capitalize"
                    >
                      Remarks
                    </label>
                    <textarea className="form-control w-75" placeholder="Add Remarks..." id="remarks" name="remarks" style={{ height: "130px" }} value={empChangesDetails?.remarks}
                      onChange={e => { onHandleChange(e) }}></textarea>
                  </div>
                  <div className='row gridBorder mt-3'>
                    {
                      allColumns?.filter((c) => c.type !== "D").map((data, i) => {
                        return (<div className='col-md-3 col-sm-3 col-4 my-2'>
                          <label
                            htmlFor={data?.printName}
                            className="form-label fw-normal h6 text-capitalize"
                          >
                            {data?.salaryHead}<span className='required'>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control w-75 mt-2"
                            id={data?.printName}
                            name={data?.printName}
                            disabled={!data?.formulaBased && data?.fix ? false : data?.type === "R" || data?.type === "P" ? true : false}
                            value={Object.keys(dynamicClo).filter((key) => key === data?.printName).map((c, i) =>
                              dynamicClo[c]
                            )}
                            placeholder={`Enter ${data?.printName} of employee Here...`}
                            onChange={(e) => onHandleChangeNew(e)}
                            onKeyPress={onlynumerical}
                          />
                        </div>);
                      })
                    }
                  </div>
                  <div className='col-md-12 col-sm-12 col-12 mt-1 text-center'>
                    {
                      isEdit ?
                        <button className='btn btnSuccess' onClick={handleUpdate} ><span>Update</span></button> :
                        <button className='btn btnSuccess' onClick={handlesubmit} ><span>Submit</span></button>
                    }
                  </div>
                </div>
              </div>
            </div>
            :
            empChangedata?.length > 0 ?
              <div className={`${uType === ALLOWED_USERTYPE.ADMIN ? "emgncy" : "emgncyFull"} table-responsive mt-3`}>
                <table className="table table-bordered border-secondary ">
                  <thead className='tblHeader'>
                    <tr>
                      <th scope="col text-white">#</th>
                      <th scope="col text-white">Created At</th>
                      <th scope="col text-white">Employee Name</th>
                      <th scope="col text-white">Designation</th>
                      <th scope="col text-white">Effective from</th>
                      <th scope="col text-white">Valid upto</th>
                      <th scope="col text-white">Location</th>
                      <th scope="col text-white">Grade</th>
                      <th scope="col text-white">Level</th>
                      <th scope="col text-white">Department</th>
                      <th scope="col text-white">Remarks</th>
                      <th scope="col text-white" className={uType !== ALLOWED_USERTYPE.ADMIN ? "d-none" : ""}>Edit</th>
                      <th scope="col text-white" className={uType !== ALLOWED_USERTYPE.ADMIN ? "d-none" : ""}>Delete</th>
                    </tr>
                  </thead>
                  <tbody className='tblBdy'>
                    {
                      empChangedata?.map((data, i) => {
                        return (<tr key={i}>
                          <th scope="row">{i + 1}</th>
                          <td>{data.edate.slice(0, 10)}</td>
                          <td>{data.name}</td>
                          <td>{data.eDesignation}</td>
                          <td>{data.chgdate.slice(0, 10)}</td>
                          <td>{data.wef.slice(0, 10)}</td>
                          <td>{data.elocation}</td>
                          <td>{data.eGrade}</td>
                          <td>{data.eLevel}</td>
                          <td>{data.edepartment}</td>
                          <td>{data.remarks}</td>
                          <td className={uType !== ALLOWED_USERTYPE.ADMIN ? "d-none" : ""}><button className='btn btnEdit' onClick={e => { handelEdit(data.rowpos, data.chgdate, data.wef, data.elocation, data.eGrade, data.eLevel, data.edepartment, data.eDesignation, data.remarks, data.colName) }}>Edit</button></td>
                          <td className={uType !== ALLOWED_USERTYPE.ADMIN ? "d-none" : ""}><button className='btn btnDelete' onClick={e => { setRowpos(data.rowpos); setIsOpencomfirm(true); }}>Delete</button></td>
                        </tr>);
                      }
                      )
                    }

                  </tbody>
                </table>
              </div>

              :
              <p className='text-white text-center mt-5'>No Data Found!!</p>
        }

      </div>
      <Modal className="fade show" isOpen={isOpencomfirm} >
        <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
          <p className=' text-center text-danger fw-bold'><VscError className='fw-bolder mx-2 my-2 h2' />Are you sure you want to Delete</p>
          <div>   <Button
            className='btnSuccess mx-2'
            onClick={() => { handelDelete(rowpos) }}
          >
            Yes
          </Button>
            <Button
              className='btnDelete mx-2'
              onClick={handalCloseconfim}
            >
              No
            </Button></div>
        </ModalBody>

      </Modal>
      <ErrorAlertBox isOpen={isOpen} setIsOpen={setIsOpen} heading={heading} errMsg={errMsg} />
      <SuccessAlertBox
        isOpen1={isOpen1}
        setIsOpen1={setIsOpen1}
        heading1={heading1}
        successMsg={successMsg}
      />
    </>
  )
}

export default SalaryChanges;