import React, { useState, useEffect } from "react";
// import "../src/modules/Dashboard/dashboard.css";
import axios from "axios";
function Dash({name}) {
  const [all,setall] = useState(true)
  const [approvedbtn,setapprovedbtn] =useState(false)
  const [Unapprovedbtn,setUnapprovedbtn] =useState(false)
  const [reject,setreject] =useState(false)
  const [data, setData] = useState([]); 
  const [allcolor,setallcolor]= useState("afterclicktopbuttons animatingBottom");
  const [apprColor,setapprColor]= useState("topbuttons animatingTop");
  const [unApprColor,setunApprColor]= useState("topbuttons animatingBottom");
  const [trashcolor,settrashcolor]= useState("topbuttons trashhover animatingTop");
  const [loading,setloading]=useState(false);
  useEffect(() => {

  }, []);
  const allbutton = (e) =>{
    setallcolor("afterclicktopbuttons")
    setapprColor("topbuttons")
    setunApprColor("topbuttons")
    settrashcolor("topbuttons")
    setall(true)
    setUnapprovedbtn(false)
    setreject(false)
    setapprovedbtn(false)
   }
   
    
  const approvedbutton = (e) =>{
    setallcolor("topbuttons")
    setapprColor("afterclicktopbuttons")
    setunApprColor("topbuttons")
    settrashcolor("topbuttons")
    setapprovedbtn(true)
    setall(false)
    setUnapprovedbtn(false)
    setreject(false)
  

   }
   
    
  const unapprovedbutton = (e) =>{
    setallcolor("topbuttons")
    setunApprColor("afterclicktopbuttons")
    setapprColor("topbuttons")
    settrashcolor("topbuttons")
    setall(false)
    setUnapprovedbtn(true)
    setreject(false)
    setapprovedbtn(false)
   }
   
    
  const trashbutton = (e) =>{
    setallcolor("topbuttons")
    settrashcolor("afterclicktopbuttons")
    setapprColor("topbuttons")
    setunApprColor("topbuttons")
    setall(false)
    setUnapprovedbtn(false)
    setreject(true)
    setapprovedbtn(false)
   }
   
   const handleclient = () =>{
    settrashcolor("topbuttons")
   }


  return (
    <>
  
  
      <div className="main">
     
        <div className="gridbox ">
          <button className={allcolor} onClick={allbutton}>All</button>
          <span>|</span>
          <button className={apprColor} onClick={approvedbutton}> Approved</button>
          <span>|</span>
          <button className={unApprColor} onClick={unapprovedbutton}>UnApproved</button>
          <span>|</span>
          <button className={trashcolor} onClick={trashbutton}>Get Masters &nbsp;&nbsp; </button>
          <br />
         
        </div>
        <div style={{position:"absolute",right:"130px"}}>
          <button className={trashcolor} style={{width:"300px",marginTop:"2px"}} onClick={handleclient} >client</button><br />
          <button className={trashcolor} style={{width:"300px",marginTop:"2px"}} onClick={handleclient} >document</button><br />
          <button className={trashcolor} style={{width:"300px",marginTop:"2px"}} onClick={handleclient} >Employee</button><br />
          <button className={trashcolor} style={{width:"300px",marginTop:"2px"}} onClick={handleclient} >vendors</button><br />
          <button className={trashcolor} style={{width:"300px",marginTop:"2px"}} onClick={handleclient} >Heads</button>
          </div>
        <br />

        
      
      </div>
    </>
  );
}

export default Dash;
