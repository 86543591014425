import { Button } from '@material-ui/core';
// import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import axios from 'axios';
// import Getcookie from "../modules/login/Getcookie";
 import {CREATE_SUPPORT_TICKET,GET_ALL_SUPPORT_TICKET,ALLOWED_USERTYPE}from '../../../../utiles/AllApis';
import ErrorAlertBox from '../../../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../../../common/AlertBox/SuccessAlertBox';
import DataGrids from '../../../../common/table/DataGrid';

function Help(props) {
  const [uType,setUType]=useState(props.tokectDetails.logintype);
  const [userID,setUserID]=useState(props.tokectDetails.mcode);
  const [isLoading, setLoading] = useState(false);
  const[heading, setHeading]=useState("");
  const[errMsg, setErrMsg]=useState("");
const[heading1, setHeading1]=useState("");
const[successMsg, setsuccessMsg]=useState("");
const handalOpen=()=>{setIsOpen(true);}
const [isOpen,setIsOpen]=useState(false);
const [isOpen1,setIsOpen1]=useState(false);
const [allTickets, setAllTickets] = useState([]);
const handalOpen1=()=>{setIsOpen1(true);}

const urlVal=`/employeeprofile/help`;
   const [helpData, setHelpdata] = useState(
    {
      "Subject":"",
      "Message":"",
      "Mcode":userID
    }
   )
   //----function to get values from field-----
   const onhandleChange=(e)=>
   {
    setHelpdata({...helpData,[e.target.name]:e.target.value});
   }
     //----function to Submit help field-----
     const handlesubmit=()=>
     {

      if(helpData.Subject===""||helpData.Message==="")
      {
        setHeading("Submission Failed!");
        setErrMsg("Fields Cannot Empty");
        handalOpen();
        return;
      }
      setLoading(true);
            axios.post(CREATE_SUPPORT_TICKET,helpData).then((response) => {
              setLoading(false);
              if(response.data.isSuccess===true)
              {
                setHeading1("Success"); 
                setsuccessMsg("Message send successfully");
                  handalOpen1();
              }
              
            })
     }
  const columnsForTickets = [
    {
      field: "eDate",
      headerName: "Created At",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
  
        return (
          <p className='text-white mt-2 f-14'>{params?.row?.eDate?.split("T")[0]}</p>
        );
      }
    },
    { field: 'username', headerName: 'User Name', 
    width: 180 
  },
    { field: 'subject', headerName: 'Subject', width: 270
   },
    { field: 'message', headerName: 'Query',width: 550
   },
  ]
  useEffect(() => {

    if(uType===ALLOWED_USERTYPE.ADMIN)
    {
      setLoading(true);
      axios.get(GET_ALL_SUPPORT_TICKET).then((response) => {
        setLoading(false);
        if(response.data.isSuccess===true)
        {
          setAllTickets(response.data.data);
        }
        
      })
    }
  }, []);

  return (
    <>
   {
    isLoading?  <div className='loaderDiv'>
    </div>:null
  } 
          <div className={uType===ALLOWED_USERTYPE.ADMIN?'mnusTopMrgn':''}>
        <h5 className='text-white fw-bold mt-2 text-center'>Help</h5>
        <hr className='myhr text-white' />
       </div>
       {/* <h5 className='SecoHead text-center'>Write your Query</h5> */}
      <div className='emp-aboutdetailmaincontent px-2'>
      <div className='row text-center'>
      <div className='col-sm-4 col-md-4 col-4 text-center'>
      <div className="mb-2 text-center">
  <label for="exampleFormControlInput1" className="form-label ">Subject <span className='required'>*</span></label>
  <input type="email" className="form-control my-3 w-75" name="Subject" placeholder="Enter Subject" onChange={e=>{onhandleChange(e)}}/>
</div>
        </div>
        <div className='col-sm-6 col-md-6 col-6 text-center'>
        <div className="mb-2 text-center">
  <label for="exampleFormControlTextarea1" className="form-label">Message <span className='required'>*</span></label>
  <textarea className="form-control my-3 w-100" name="Message" placeholder="Enter Your Query here.." onChange={e=>{onhandleChange(e)}}></textarea>
</div>
</div>
<div className='col-sm-2 col-md-2 col-2 text-center'>
<div className='my-5'>
            <button className='btn btnSuccess' 
           onClick={handlesubmit}
           ><span>Submit</span></button>
            </div>
</div>
        {

         uType===ALLOWED_USERTYPE.ADMIN?
         allTickets.length>0?
         <div className='emgncy'>
         <table className="table table-bordered border-secondary ">
                <thead className='tblHeader'>
                    <tr>
                       <th scope="col text-white">#</th>
                       <th scope="col text-white">Created At</th>
                       <th scope="col text-white">Employee Name</th>
                       <th scope="col text-white">Subject</th>
                       <th scope="col text-white">Query</th>
                   </tr>
                </thead>
                <tbody className='tblBdy'>
                    {
                          allTickets.map((data, i) => 
                          {
                            return(    <tr key={i}>
                                <th scope="row">{i+1}</th>
                                <td>{data.eDate.slice(0,10)}</td>
                                <td>{data.username}</td>
                                <td>{data.subject}</td>
                                <td>{data.message.slice(0,10)}</td>
                            </tr>);
                          }
                        )
                    }
                </tbody>
          </table>
          </div>
         :<p style={{padding:"40px"}} className="text-danger">No Data Found!!</p>:null
        }
        </div>
      </div>
      <ErrorAlertBox isOpen={isOpen} setIsOpen={setIsOpen}heading={heading}errMsg={errMsg}/>
<SuccessAlertBox href={urlVal} isOpen1={isOpen1} setIsOpen1={setIsOpen1}heading1={heading1}successMsg={successMsg}/>
    </>
  )
}

export default Help;