import { Button,
    Modal,
    ModalFooter,
    ModalBody,
    ModalHeader,} from 'reactstrap';
import {TiTick} from 'react-icons/ti';
import './AlertBoc.css';
    const SuccessAlertProduct=(props)=>
    { 
return(
    <>
    <Modal className=" fade show"isOpen={props.isOpen1} >
      <ModalHeader className='brdBtm0 text-center pb-0'
        // close={
        // <button className="close" onClick={handalClose}>×</button>}
      >
      <h4 className=' text-center  fw-bold  successColor'><TiTick className='successColor fw-bolder mx-2 h2'/>{props.heading1}</h4>   
      </ModalHeader>
      <ModalBody className='text-center pt-1'>
    <p className='h6'>{props.successMsg}</p>
  <div>   <Button
         className='btnSuccess'
          onClick={props.getProductionRider}
        >
          Ok
        </Button></div>
      </ModalBody>
  
      </Modal>
    </>
)
    }
    export default SuccessAlertProduct;
