import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import "./attendance.css"
import 'react-calendar/dist/Calendar.css';
import {
  GET_Employee, ADD_ATTENDANCE, GET_ATTENDANCE_BY_DATE, ALLOWED_USERTYPE,
  ADD_IN_OUT_TIME, GET_EMP_DATAILS_BY_MCODE, GET_ATTENDANCE_OF_USER_BY_DATE
} from '../../../../utiles/AllApis';
import axios from 'axios';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { VscError } from 'react-icons/vsc';
import { TiTick } from 'react-icons/ti';
import ErrorAlertBox from '../../../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../../../common/AlertBox/SuccessAlertBox';
function Attendance(props) {
  const date = new Date();
  date.setDate(date.getDate() - 1)
  const [isTodayDateSelected, setIsTodayDateSelected] = useState(true);
  const [value, onChange] = useState(new Date());
  const [isLoading, setLoading] = useState(false);
  const [attendanceList, setAttendanceList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenErr, setIsOpenErr] = useState(false);
  const [empList, setEmpList] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [IsEmpCome, setIsEmpCome] = useState(false);
  const [empDetails, setEmpDetails] = useState();
  const [empSStatus, setEmpStatus] = useState({});
  const [timeIn, setTimeIn] = useState("");
  const handalClosePopUp = () => { setIsOpen(false); }
  const [heading, setHeading] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const handalOpenErr = () => { setIsOpenErr(true); }
  const handalOpen1 = () => { setIsOpen1(true); }
  const [isOpen1, setIsOpen1] = useState(false);
  const [timeOut, setTimeOut] = useState("");
  const [uType, setUType] = useState(props.tokectDetails.logintype);
  const [userID, setUserID] = useState(props.tokectDetails.mcode);
  const [sDate, setSDate] = useState(`${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`);
  let list = [];
  const OnOutBtn = () => { addInOut("Out"); }
  //State for attendance
  useEffect(() => {

    //call is usertype is ADMIN
    if (uType === ALLOWED_USERTYPE.ADMIN) {
      getAttByDate(new Date());

      //api call for get all employees 
      setLoading(true);
      axios.get(GET_Employee).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setEmpList(response.data.data);
        }
      });
    }
    //api calls for usertype is not admin
    else {
      //api call for get employee details
      setLoading(true);
      axios.post(GET_EMP_DATAILS_BY_MCODE, { "EmployeeID": userID }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setEmpDetails(response.data.data[0]);
        }
      });
      //api call for get user attendace by date
      getUserAttByDate(new Date(), userID);
    }
  }, []);

  //-------GET USER ATTE BY DATE-------------
  const getUserAttByDate = (day, userID) => {
    setLoading(true);
    axios.post(GET_ATTENDANCE_OF_USER_BY_DATE, { "DateOfAttendance": day, "Ecode": userID }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        if (response.data.data[0].inTime !== "0" && (response.data.data[0].outTime === "0" || response.data.data[0].outTime === "")) {
          setIsEmpCome(true);
        }
        else if (response.data.data[0].inTime !== "0" && response.data.data[0].outTime !== "0") {
          setIsEmpCome(false);
        }
        setEmpStatus(response.data.data[0]);
      }
      else {
        setEmpStatus({
          "half": 0,
          "inTime": "0",
          "outTime": "0",
          "lcode": ""
        });
      }
    });
  }
  //Add attendance FUNCTION for ADMIN
  const addAttendance = (props) => {
    list = document.querySelectorAll('input[type="radio"]:checked');
    let element;
    if (list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        element = {
          "Ecode": list[i].name,
          "DateOfAttendance": `${value.getFullYear()}/${value.getMonth() + 1}/${value.getDate()}`,
          "Euser": ALLOWED_USERTYPE.ADMIN,
          "OutTime": 0,
          "InTime": 0,
          "Lcode": list[i].value
        }
        attendance.push(element);
      }

      setLoading(true);
      axios.post(ADD_ATTENDANCE, { "AttendanceChild": attendance }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          resetList();
          setHeading1("Success");
          setsuccessMsg("Add successfully..");
          handalOpen1();
          getAttByDate(value);
        }
        else {
          setHeading("Failed");
          setErrMsg(response.data.message);
          handalOpenErr();
        }
      });
    }
    else {
      setHeading("Failed");
      setErrMsg("Blank attendence cannot submit");
      handalOpenErr();
    }

  }
  //------reset list------------
  const resetList = () => {
    list = document.querySelectorAll('input[type="radio"]');
    for (let i = 0; i < list.length; i++) {
      list[i].checked = false;
    }
  }
  // const fixDate=(e)=>
  // {
  //    
  //   var date=`${e.getDate()+1}/${e.getMonth()+1}/${e.getFullYear()}`;
  //   getAttByDate(new Date(date))
  // }
  //get attendance by date
  const getAttByDate = (e) => {
    resetList();
    setSDate(`${e.getDate() + 1}/${e.getMonth() + 1}/${e.getFullYear()}`);

    if (`${e.getDate()}/${e.getMonth() + 1}/${e.getFullYear()}` !== `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`) {
      setIsTodayDateSelected(false);
    }
    else {
      setIsTodayDateSelected(true);
    }
    if (uType === ALLOWED_USERTYPE.ADMIN) {
      setLoading(true);
      axios.post(GET_ATTENDANCE_BY_DATE, {

        "DateOfAttendance": new Date(e).toISOString().split('T')[0]
      }).then((response) => {
        setLoading(false);
        var a = [{
          "company": "",
          "dateOfAttendance": "",
          "eSystem": "",
          "ecode": "",
          "edate": "",
          "euser": "",
          "half": "",
          "inTime": "",
          "lcode": "",
          "name": "",
          "outTime": "",
          "rowpos": ""
        }]

        if (response.data.isSuccess === true) {
          setAttendanceList(response.data.data);
        }
        else {
          setAttendanceList(a[0]);
        }

      });
    }
    else {
      //api call for get user attendace by date
      getUserAttByDate(new Date(e), userID)
    }

  }
  //get values in -out time
  const handleAttendance = (e) => {
    setIsEmpCome(e.target.checked);
    if (e.target.checked) {
      if (empSStatus?.outTime === "" || empSStatus?.outTime === "0" || empSStatus?.outTime === undefined) {
        setTimeIn(`${new Date().getHours()}:${new Date().getMinutes()}`);
        addInOut("In");
      }
      else {
        setHeading("Failed");
        setErrMsg("Cannot Update Time..");
        handalOpenErr();
      }

    }
    else {
      setTimeIn("");
      setIsOpen(true);
    }

  }
  //add intime out time attendance
  const addInOut = (val) => {

    console.log(userID)
    var InTym = "";
    var outTym = "";
    if (val === "In") {
      InTym = `${new Date().getHours()}:${new Date().getMinutes()}`;
    }
    else {
      outTym = `${new Date().getHours()}:${new Date().getMinutes()}`;
    }
    setIsOpen(false);
    setLoading(true);
    axios.post(ADD_IN_OUT_TIME, {
      "Ecode": userID,
      "DateOfAttendance": `${value.getFullYear()}/${value.getMonth() + 1}/${value.getDate()}`,
      "InTime": InTym,
      "OutTime": outTym,
      "Euser": empDetails.firstName
    }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        getUserAttByDate(new Date(), userID);
      }
    });
  }
  return (
    <>
      {
        isLoading ? <div className='loaderDiv'>
        </div> : null
      }
      <div className={uType === ALLOWED_USERTYPE.ADMIN ? 'mnusTopMrgn' : ''}>
        <h5 className='text-white fw-bold mt-2 text-center'>Attendance</h5>
        <hr className='myhr text-white' />
      </div>
      <div className='row px-3 adttdnc'>
        <div className={uType === ALLOWED_USERTYPE.ADMIN ? 'col-md-4 col-sm-6 col-6 box' : 'col-md-6 col-sm-6 col-6 box'}>
          <Calendar onChange={onChange} onClickDay={e => { getAttByDate(e) }} value={value} />
        </div>

        {
          uType === ALLOWED_USERTYPE.ADMIN ?
            <>
              <div className='col-md-4 col-sm-6 col-6 box'>
                <div className='main_card_box atteListBox'>
                  <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }} ><h4 className='mt-3 mx-2'>Attendance List</h4></div>
                  <div className='attList'>
                    <hr className='myhr' />
                    {
                      attendanceList?.map((attList, i) => {
                        return (<div key={i}>
                          <div className='task_ass' style={{ display: "block" }}>
                            {/* <span>✅</span>  task_ass_head*/}
                            <h5 className='atteList-Name SecoHead my-2 text-start'>{attList.name}</h5>
                            <p className='date my-2 h6 text-white'><span className='text-white'>Date : </span>{attList?.dateOfAttendance?.split("T")[0]}</p>
                            {/* <span>Deepali Pandey<i>(project manager)</i></span> */}
                            <h6 className={`${attList.half === "1" ? "text-warning" : attList.lcode === "WRK" ? "text-success" : "text-danger"} my-2 h6 atteListTxt`}> {attList.half === "1" ? "Half Day" : attList.lcode === "" ? `--NA-- ( ${attList.inTime === '' ? '00:00' : attList.inTime} )` : attList.lcode === "WRK" ? `Present` : `Absent`}</h6>{/*( ${attList.inTime===''?'00:00':attList.inTime} )*/}
                          </div>
                          <hr className='myhr' />
                        </div>)
                      })
                    }

                  </div>
                </div>
              </div>
              <div className='col-md-4 col-sm-6 col-6 box'>
                <h5 className='mt-3 mx-2 text-white text-center'>Add Attendance</h5>
                <div className='main_card_box userList'>
                  {/*atteListBox <div  style={{ display: "flex", justifyContent: "space-between", width: "80%"}} ><h4 className='mt-3 mx-2'>Add Attendance</h4>
                      </div>*/}
                  <div className="Attendance_form px-1 w-100">
                    <table className="table tblBorder table-dark" >
                      <thead>
                        <tr>
                          <th scope="col">Employee Name</th>
                          <th scope="col">Present</th>
                          <th scope="col">Absent</th>
                          <th scope="col">Half Day</th>

                        </tr>
                      </thead>
                      <tbody className='attList' >
                        {
                          empList?.map((emp, i) => {
                            return (
                              <tr>
                                <td >{emp.firstName} {emp.lastName}</td>
                                <td>  <div className="form-check">
                                  <input className="form-check-input" type="radio" name={emp.employeeID} value="WRK" />
                                </div></td>
                                <td>  <div className="form-check">
                                  <input className="form-check-input" type="radio" name={emp.employeeID} value="LOP" />
                                </div></td>
                                <td>  <div className="form-check">
                                  <input className="form-check-input" type="radio" name={emp.employeeID} value="1" />
                                </div></td>
                              </tr>
                            )
                          })
                        }

                      </tbody>
                    </table>

                  </div>
                </div>
                <center className="mt-2">
                  <button className="btn btnSuccess" onClick={addAttendance} >submit</button>
                </center>
              </div> </> :

            <div className='col-md-6 col-sm-6 col-6'>
              {
                isTodayDateSelected ?
                  <div className="form-check form-switch text-center">
                    <input className="form-check-input togCheck" type="checkbox" id="flexSwitchCheckChecked" onChange={e => { handleAttendance(e) }} checked={IsEmpCome} />
                    <label className="form-check-label" style={{ color: "#00a859a3" }} htmlFor="flexSwitchCheckChecked">MARK YOURSELF PRESENT</label>
                  </div> : null
              }
              <div className='main_card_box atteListBox mt-3 text-center'>
                <div className='task_ass py-3 text-center' style={{ display: "block" }}>
                  <h4 className='text-white my-2 h3 text-center'><span className="userNameAtt">{empDetails?.firstName} {empDetails?.lastName}</span> your Attendance details are:</h4>
                  <p className='date my-3 h5 text-center'><span className="userNameAttSub">Date :</span><span className="userNameAttSubText">{sDate}</span> </p>
                  <p className='date my-3 h5 text-center'><span className="userNameAttSub">Status : </span><span className="userNameAttSubText">{empSStatus?.half === "1" ? "Half Day" : empSStatus?.lcode === "" ? "--NA--" : "WRK" ? "Present" : "ABSENT"}</span> </p>
                  <p className='date my-3 h5 text-center'><span className="userNameAttSub">In-Time :</span><span className="userNameAttSubText">{empSStatus?.inTime === "" || empSStatus?.inTime === "0" ? "00:00" : empSStatus?.inTime}</span>  </p>
                  <p className='date my-3 h5 text-center'><span className="userNameAttSub">Out-Time :</span><span className="userNameAttSubText">{empSStatus?.outTime === "" || empSStatus?.outTime === "0" ? "00:00" : empSStatus?.outTime}</span>  </p>
                </div>
              </div>

            </div>
        }
      </div>
      <Modal className="fade show" isOpen={isOpen} >
        <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
          <h4 className=' text-center  fw-bold  successColor'>Confirmation</h4>
          <p className=' text-center text-danger fw-bold'>Are you sure that you want to mark out yourself.</p>
          <div>   <Button
            className='btnSuccess mx-2'
            onClick={() => { OnOutBtn() }}
          >
            Yes
          </Button>
            <Button
              className='btnDelete mx-2'
              onClick={handalClosePopUp}
            >
              No
            </Button></div>
        </ModalBody>

      </Modal>
      <ErrorAlertBox isOpen={isOpenErr} setIsOpen={setIsOpenErr} heading={heading} errMsg={errMsg} />
      <SuccessAlertBox
        isOpen1={isOpen1}
        setIsOpen1={setIsOpen1}
        heading1={heading1}
        successMsg={successMsg}
      />
    </>
  )
}
export default Attendance;