import React, { useEffect, useState } from "react";
import SideBar from "../../common/SideBar/HrSideBar";
import "../Dashboard/dashboard.css";
import { useNavigate } from "react-router-dom";
import TopBar from "./TopBar";
import Main from "./MainPage/Main";
const LandingHRPage = (props) => {
  const [isLoading, setLoading] = useState(false);
  var url=window.location.href.split('/')[window.location.href.split('/').length-1];
  
  const navigate = useNavigate();
  return (
    <div className="container-fluid">
      {isLoading ? <div className="loaderDiv"></div> : null}
      <div className="row ">
        <div className="col-md-2 col-12 col-sm-12 mainSideBar px-0">
          <SideBar/>
        </div>
        <div className="col-md-10 col-12 col-sm-12 pt-3 headerBgColor myview ">
         {/* <TopBar/> */}
         <Main page={url} tokectDetails={props.tokectDetails}/>
        </div>
      </div>
    </div>
  );
};
export default LandingHRPage;
