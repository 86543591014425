import React from 'react';
//import Dailyupdates from '../../../Employeemanagement/EmployeeDailyupdate';
function Training(props) {
  return (
    <>
         <div className='emp-aboutdetailmaincontent'>
         <h5 className='text-white fw-bold mt-2 text-center'>Daily Updates</h5>
        <hr className='myhr text-white' />
                {/* <Dailyupdates tokectDetails={props.tokectDetails}/> */}
            </div>
    </>
  )
}

export default Training;