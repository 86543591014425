import React, { useEffect } from 'react'
import Slider from "react-slick";



function Carousel(props) {

  const settings = {
    dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 2
  };

  useEffect(()=>{
console.log(props.carouseldata)
  },[])


  return (
   <>
     <div className='sliderBox' style={{margin:"auto",paddingLeft:"10px",cursor:"grab"}} >
     <Slider {...settings}>
     {
              props.carouseldata.map((item,i)=>(
                <div key={i} >
      
                <h6 style={{width:"200px",height:"300px",boxShadow:"0 0 10px 0 rgba(0,0,0,0.9)"}}>
                
                <img   src={item}  />
                  </h6>
      
            
              </div>
              ))
            }
     
       

         
        </Slider>
     </div>
   </>
  )
}

export default Carousel;