import React, { useEffect, useState,memo } from 'react';
import './empSearch.css';
import axios from 'axios';
import { GET_Employee, GET_SEARCH_EMP_DATAILS, GET_EMP_DATAILS_BY_MCODE } from '../../utiles/AllApis';

const Empsearch = (props) => {
  const [searchType, setSearchType] = useState("empCode");
  const [isLoading, setLoading] = useState(false);
  const [empList, setEmpList] = useState([]);
  const [empDetails, setEmpDetails] = useState({
    "strDesignation": "--NA--",
    "strLocation": "--NA--"
  });
  const handleChange = (e) => {
    setSearchType(e.target.id);
  }
  useEffect(() => {
    //setEmpDetails(props.empData!==undefined?props.empData:{});
    getAllEmpList();
  }, [searchType]);

  //--------------Get All Employees List---------------
  const getAllEmpList = () => {
    // setLoading(true);
    axios.get(GET_Employee).then((response) => {
      console.log(response)
      //setLoading(false);
      if (response.data.isSuccess === true) {
        setEmpList(response.data.data);

      }

    })
  }
  //--------------Get Employee BY MCODE---------------
  const getMultiEmpByCode = (code) => {
    // setLoading(true);
    axios.post(GET_SEARCH_EMP_DATAILS, { "EmployeeID": code }).then((response) => {
      console.log(response)
      //setLoading(false);
      if (response.data.isSuccess === true) {
        setEmpList(response.data.data);
      }
      else {
        setEmpList([]);
        setEmpDetails({});
        props.setIsadd(false);
      }
    })
  }
  //--------------Get Employee BY MCODE---------------
  const getEmpByCode = (code) => {
    setLoading(true);
    axios.post(GET_EMP_DATAILS_BY_MCODE, { "EmployeeID": code }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        debugger;
        setEmpDetails(response.data.data[0]);
        if (props?.setSelectEmpDetails !== undefined) {
           
          props?.setSelectEmpDetails(response.data.data[0]);
        }
      }
    })
  }
  //----------------------Get user details--------------
  const onHandelSearchCode = (e) => {
    if (e.target.name === "empNameList") {
      props.setUserID(e.target.value);
      getEmpByCode(e.target.value);
      if (e.target.value === "") {
        setEmpDetails({});
        props.setIsadd(false);
      }
    }
    else {
      getMultiEmpByCode(e.target.value);
    }
  }
  return (
    <>
      {
        isLoading ? <div className='loaderDiv'>
        </div> : null
      }
      <div className='row'>
        {/* <div className='col-md-2 col-lg-2 col-sm-2 col-2'>
               </div> */}
        <div className='col-md-2 col-lg-2 col-sm-4 col-3'>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="searchType" id="empCode" onClick={e => { handleChange(e) }} checked={searchType === "empCode" ? true : false} />
            <label className="form-check-label text-white text-capitalize fw-light" htmlFor="empCode">
              Employee Code
            </label>
          </div>
        </div>
        <div className='col-md-2 col-lg-2 col-sm-4 col-3'>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="searchType" id="empName" onClick={e => { handleChange(e) }} checked={searchType === "empName" ? true : false} />
            <label className="form-check-label text-white text-capitalize fw-light" htmlFor="empName">
              Employee Name
            </label>
          </div>
        </div>
        {
          searchType === "empCode" ?
            <div className='col-md-3 col-lg-3 col-sm-6 col-6'>
              <div className="mb-3">
                <label for="exampleInputEmail1" class="form-label text-capitalize fw-normal h6">Employee Code</label>
                <input type="text" className="form-control mt-2 w-75" id="empMcode" name="empMcode" aria-describedby="emailHelp" onChange={e => { onHandelSearchCode(e) }} />
              </div>
            </div> :
            ""

        }

        <div className='col-md-3 col-lg-3 col-sm-6 col-6'>
          <div className="mb-3">
            <label for="exampleInputEmail1" class="form-label text-capitalize fw-normal h6">Employee Name</label>

            <select class="form-select mt-2 w-75" aria-label="Default select example" name="empNameList" onChange={e => { onHandelSearchCode(e) }}>
              <option value={""}>--Select Name--</option>
              {
                empList?.map((data, i) => {
                  return (<option value={data.employeeID}>{data.firstName} {data.lastName}</option>);
                })
              }
            </select>
          </div>
        </div>
        {/*!props.isEdit&&*/}
        {
          empDetails.employeeID === undefined || empDetails.employeeID === ""|| props.isView===false?
            null :
            <div className='col-md-2 col-lg-2 col-sm-2 col-2 mt-3'>
              {
                props.isadd ? <button className='btn btnSuccess' onClick={e => { props.setIsadd(false); }}>View List</button>
                  :
                  <button className='btn btnSuccess' onClick={e => { props.setIsadd(true); }}>Add Details</button>
              }
            </div>
        }
        <div className='row'>
          <div className='col-md-4 col-lg-4 col-sm-4 col-6 text-start'>
            <label for="exampleInputEmail1" class="form-label text-capitalize fw-bold h6">Location : </label>
            <span className='text-white'>{empDetails.strLocation}</span>
          </div>
          <div className='col-md-4 col-lg-4 col-sm-4 col-6 text-start'>
            <label for="exampleInputEmail1" class="form-label text-capitalize fw-bold h6">Designation : </label>
            <span className='text-white'>{empDetails.strDesignation}</span>
          </div>
        </div>
        {/* <hr className='myhr text-white' /> */}
      </div>
    </>
  )
}
export default memo(Empsearch);