import {useState,useEffect} from 'react';
import {
    Pagination,
  PaginationItem,
  PaginationLink,


}from 'reactstrap';
const CardPagination=(props)=>
{

 const [counter,setCounter]=useState(1);
 //const [noOfBtn,setNoOfBtn]=useState(Math.ceil(props.cardLength/props.showParPage));


  useEffect(()=>
  {
    const val=props?.showParPage * counter;
    props?.paginationChange(val-props?.showParPage,val);

  },[counter]);
  const onPaginationBtnClick=(val)=>
  {
    if(val==="pre")
    {
      if(counter===1)
      {
        setCounter(1);
        return;
      }
      setCounter(counter-1);
    }
    else
    {
      if(counter===Math.ceil(props?.cardLength/props?.showParPage))
      {
        setCounter(Math.ceil(props?.cardLength/props?.showParPage));
        return;
      }
      setCounter(counter+1);
    }
  }
    return(

        <Pagination
  aria-label="Page navigation example"
  size="sm"
  className='pagination'
>
  <PaginationItem>
    <PaginationLink
      first
      href="#"
      onClick={()=>setCounter(1)}
    />
  </PaginationItem>
  <PaginationItem>
    <PaginationLink
      href="#"
      previous
       onClick={()=>{onPaginationBtnClick("pre")}}
    >pre</PaginationLink>
  </PaginationItem>
  {
      new Array(Math.ceil(props?.cardLength/props?.showParPage)).fill("").map((el,i)=>
      {
        return(  <PaginationItem key={i} className={`${counter===i+1 ? "active" :null}`}>
          <PaginationLink href="#"onClick={()=>setCounter(i+1)}>
            {i+1}
          </PaginationLink>
        </PaginationItem>)
      })
    }

  <PaginationItem>
    <PaginationLink
      href="#"
      next
      onClick={()=>{onPaginationBtnClick("next")}}
    >next</PaginationLink>
  </PaginationItem>
  <PaginationItem>
    <PaginationLink
      href="#"
      last
      onClick={()=>setCounter(Math.ceil(props?.cardLength/props?.showParPage))}
    />
  </PaginationItem>
</Pagination>
    )
}
export default CardPagination;