import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {ALLOWED_USERTYPE, GET_PROJECT_BY_MCODE,GET_PROJECTBYID } from '../../../../utiles/AllApis'

import AdminProjects from './AdminProjects';
function EmpProject(props) {
    const [isLoading, setLoading] = useState(false);
    const [view, setview] = useState(true)
    const [projects, setprojects] = useState([])
    const [selectedproject,setselectedproject] = useState("")
    const [Thisprojectdetails,setThisprojectdetails] =useState([]);
    const [adminprojects,setadminprojects]  =useState(false)

    useEffect(() => {
        if(props.tokectDetails.logintype===ALLOWED_USERTYPE.ADMIN){
        setadminprojects(true);
        }
        else{
            setLoading(true);
            axios.post(GET_PROJECT_BY_MCODE, {
                "EMP_MCODE": props.tokectDetails.mcode
            }).then((response) => {
                setLoading(false);
            if(response.data.isSuccess===true)
            {
                setprojects(response.data.data);
            }
               
            })
        }
    }, [])
    const handlethisproject = (proId) => {
              
        var config = {
            method: 'post',
            url: GET_PROJECTBYID,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : {"ROWPOS": proId}
          };
          const ProjectArray=[];
          setLoading(true);
          axios(config)
          .then(function (response) {
          
            setLoading(false);
             
            if(response.data.isSuccess===true)
            {
                setThisprojectdetails(response.data.data[0]);
            }
           
          })
          .catch(function (error) {
            console.log(error);
          });
          setview(false);
    }
    const handlebacktoproject = () => {
        setview(true);
    }

    return (
        <>
            <div className='emp-aboutdetailmaincontent'>
            {
    isLoading?  <div className='loaderDiv'>
    </div>:null
  }
                {
                    view ?
                        <>
                            <div className='em_docs_grid' >
                                {
                                    projects?.map((item, i) => (
                                        <>
                                           <Link key={i} to={`/employeeprofile/projects/id=${item.rowpos}`}>
                                           <div className='emp_docs_box' onClick={e=>{handlethisproject(item.rowpos)}} >
                                            {item.won === false ? <h5 style={{color:"red"}}>{item.projectName}</h5> :<h5 style={{color:"green"}}>{item.projectName}</h5>}
                                                <hr className='myhr' />
                                                <div className='card_middle_projectscard'>
                                                <p>Client Name : <span>{item.clientName}</span></p>
                                                <p>Project Type :<span>{item.projectType}</span></p>
                                                </div>
                                                <hr className='myhr' />
                                                <i style={{float:"right",fontSize:"12px"}}>{item.openingDate?.slice(0,10)}</i>
                                            </div>
                                           </Link>
                                        </>
                                    ))
                                }
                            </div>
                        </>
                        :
                        <>

                            <center><h6 className='emp_color '>{selectedproject}</h6></center>
                            <span className='backtoprojecticon' onClick={handlebacktoproject} ><ion-icon name="arrow-back"></ion-icon></span>
                            <hr className='myhr' />
                            <br />
                            <div className='project_emp_details'>

                                <div className=" py-4  Project_details row" >
                                    <div className='col-md-12 col-sm-12 col-12'>
                                        <p><span className='tracking-in-expand-fwd-top'>CLIENT NAME :</span>{Thisprojectdetails.clientName}</p>
                                        <p><span className='tracking-in-expand-fwd-bottom'>CATEGORY :</span>{Thisprojectdetails.category}</p>
                                        <p><span className='tracking-in-expand-fwd-bottom'>Owner Name :</span>{Thisprojectdetails.ownerName}</p>
                                        <p><span className='tracking-in-expand-fwd-top'>PROJECT TYPE :</span>{Thisprojectdetails.projectType}</p>
                                        <p><span className='tracking-in-expand-fwd-top'>ESTIMATED PROJECT COST :</span>{Thisprojectdetails.emd}</p>
                                        <p><span className='tracking-in-expand-fwd-bottom'>EARNEST MONEY DEPOSIT(EMD) :</span>{Thisprojectdetails.category}</p>
                                        <p><span className='tracking-in-expand-fwd-top'>TENDER DOCUMENT FEE :</span>{Thisprojectdetails.tenderDocumentFee}</p>
                                        <p><span className='tracking-in-expand-fwd-top'>PROCESS OF SUBMISSION OF BID :</span>{Thisprojectdetails.processOfSubmissionBid}</p>
                                        <p><span className='tracking-in-expand-fwd-bottom'>DATE OF PRE-BID MEETING:</span>{new Date(Thisprojectdetails.preBidMeetingDate).toLocaleString()}</p>
                                        <p><span className='tracking-in-expand-fwd-bottom'>LAST DATE OF PRE-BID QUERIES :</span>{new Date(Thisprojectdetails.lDateOfPreBidQueries).toLocaleString()}</p>
                                        <p><span className='tracking-in-expand-fwd-bottom'>LAST DATE OF SUBMISSION BIDS :</span>{new Date(Thisprojectdetails.ldateOfSubmissionBid).toLocaleString()}</p>
                                        <p><span className='tracking-in-expand-fwd-bottom'>DATE OF OPENING TECHNICAL BIDS :</span>{new Date(Thisprojectdetails.technicalBidOpenDate).toLocaleString()}</p>
                                        <p><span className='tracking-in-expand-fwd-bottom'>PRESENTATION DATE :</span>{new Date(Thisprojectdetails.presentationDate).toLocaleString()}</p>
                                        <p><span className='tracking-in-expand-fwd-bottom'>EVALUATION :</span>{Thisprojectdetails.evaluation}</p>
                                        {Thisprojectdetails.remarks!==""?  <p><span className='tracking-in-expand-fwd-top'>PROJECT FILING STATUS :</span>{Thisprojectdetails.interested}</p>:null}
                                        {Thisprojectdetails.remarks!==""?<p><span className='tracking-in-expand-fwd-bottom'>Remarks :</span>{Thisprojectdetails.remarks}</p>:null}
                                    </div>


                                </div>
                            </div>
                        </>

                }
                {
                    adminprojects ? <AdminProjects /> :""
                }
            </div>
        </>
    )
}

export default EmpProject;