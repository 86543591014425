import React,{useEffect, useState} from 'react';
import ProjectCards from './ProjectCards';
import './project.css';
import SuccessAlertBox from '../../common/AlertBox/SuccessAlertBox';
import {
    Input,Button,Modal,ModalBody
} from 'reactstrap';
import axios from 'axios';
import { GrSearch } from "react-icons/gr";
import FloatingBtn from '../../common/Buttons/FloatingBtn';
import Pagination from '../manageMaster/common/cardPagination';
import {GET_NEW_ARRVAL_PRO,GET_ALL_PROJECTS,DELETE_PROJECT,GET_allprojects,GET_fillingprojects,GET_rejectedProjects
    ,GET_runningprojects,GET_completeprojects,GET_DraftProjects,ALLOWED_USERTYPE} from '../../utiles/AllApis';
import SideBar from '../../common/SideBar/SideBar';
import {VscError} from 'react-icons/vsc';
import ErrorAlertBox from '../../common/AlertBox/ErrorAlertBox';
import { useNavigate } from 'react-router-dom';
import DataGrids from '../../common/table/DataGrid';
import {MdFormatLineSpacing,MdOutlineStorage} from 'react-icons/md';
const ProjectsList=(props)=>
{
    const [isTblView, setIsTblView] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const[filter,setfilter] = useState("");
    const [cardLength,setCardLength]=useState(0);
    const [showParPage,setShowParPage]=useState(10);
    const [heading, setHeading] = useState("");
    const navigate = useNavigate();
    const [docList,setDocList]=useState([]);
    const [isOpenError, setIsOpenError] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [cards,setCards]=useState();
    const [newProject,setnewProject]=useState([]);
    const [allCards,setAllCards]=useState([]);
    const [newCards,setNewCards]=useState([]);
    const [filingCards,setFilingCard]=useState([]);
    const [runningCards,setRunningCards]=useState([]);
    const [completeCards,setCompleteCards]=useState([]);
    const [newArrCards,setNewArrCards]=useState([]);
    const [rejectCards,setRejectCards]=useState([]);
    const [draftCards,setDraftCards]=useState([]);
    const [colorCls, setColorCls] = useState("masterbtnCls");
    const [cardsArray,setCardsArray]=useState([]);
    const[deleteId, setDeleteID]=useState("");
    const [isOpen1,setIsOpen1]=useState(false);
    const handalOpen1=()=>{setIsOpen1(true);}
    const [ispage,setIspage]=useState(true);
    const[searctVal, setSearctVal]=useState();
    const [isOpen,setIsOpen]=useState(false);
    const [proType,setProType]=useState("");
    const[heading1, setHeading1]=useState("");
const[successMsg, setsuccessMsg]=useState("");
    const handalOpen=()=>{setIsOpen(true);}
    const handalClose=()=>{setIsOpen(false);}
    const [pagination,setPagination]=useState(
        {
            start:0,
            end:showParPage
        }
    );

    const projectList=[
        {
            "MenuName": "New Arrival",
            "href":`newArrival`
        },
        {
         "MenuName": "New Tenders",
         "href":`new`
     },
     {
         "MenuName": "Filing",
          "href":"filing"
     },
     {
         "MenuName": "Running",
        "href":"running"
     },
      {
         "MenuName": "Completed",
           "href":"completed"
     }   
     ,
     {
        "MenuName": "Rejected",
          "href":"rejected"
    } ,
    {
        "MenuName": "Draft",
        "href":"draft"
    }
 ]
 const projectListOperation=[
    {
     "MenuName": "New Tenders",
     "href":`new`
 },
 {
     "MenuName": "Running",
    "href":"running"
 },
  {
     "MenuName": "Completed",
       "href":"completed"
 }   

]
const projectListProjects=[
    {
     "MenuName": "New Tenders",
     "href":`new`
 },
 {
    "MenuName": "Filing",
     "href":"filing"
},
 {
     "MenuName": "Running",
    "href":"running"
 },
  {
     "MenuName": "Completed",
       "href":"completed"
 },
 {
    "MenuName": "Rejected",
      "href":"rejected"
}  

]
 //column for grid view for New Project
 
const columnsForNewPro = [
    {
        field: "edate",
        headerName: "Created At",
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => {
    
          return (
            <p className='text-white mt-2 f-14'>{params?.row?.edate?.split("T")[0]}</p>
          );
        }
      },
    { field: 'rowpos', headerName: 'Project Id'},
      { field: 'projectName', headerName: 'Project Name', width: 150},
      { field: 'clientName', headerName: 'Client Name', width: 130},
      { field: 'category', headerName: 'Category', width: 140},
      { field: 'ownerName', headerName: 'Owner Name', width: 130 },
      { field: 'projectType', headerName: 'Project Type', width: 130},
      {
        field: "ldateOfSubmissionBid",
        headerName: "Last date Of Submission Bid",
        width: 100,
        disableClickEventBubbling: true,
        renderCell: 
        (params) => {
          return (
           <p className='text-white mt-2 f-14'>{params?.row?.ldateOfSubmissionBid?.split("T")[0]}</p> 
          );
        }
      },
      {
        field: "vIEW",
        headerName: "Eligibility Document",
        sortable: false,
        width: 105,
        disableClickEventBubbling: true,
        renderCell: (params) => { 
            if(params.row.eligibilitY_DOC===""||params.row.eligibilitY_DOC===null)
            {
                return(
                    <p className='text-white'>--Not Found--</p>
              )
            }
            else{
          return (
            <Button  variant="contained btnSuccessClr" ><a className='text-white' href={params.row.eligibilitY_DOC} target="_blank">view</a></Button>
          ); 
            }
        }
      },
         {
        field: "VIEW",
        headerName: "Project Document",
        width: 105,
        renderCell: (params) => {
            if(params.row.projecT_DOC===""||params.row.projecT_DOC===null)
            {
                return(
                    <p className='text-white'>--Not Found--</p>
              )
            }
            else{
                return (
                    <Button  variant="contained btnSuccessClr" style={{background:"rgb(15, 83, 61)",color:"#b8e3d7"}}><a className='text-white' href={params.row.projecT_DOC}target="_blank">view</a></Button>
                  );
            }
        }
      }
    ]
 const deleteProject=()=>
 {
    handalClose();
    setLoading(true);
    axios.post(DELETE_PROJECT,{ "ROWPOS": deleteId}).then((response) => {
       setLoading(false);
        if(response.data.IsSuccess===true)
        {
            setHeading1("Success");
           setsuccessMsg("Project Deleted Successfully.");
            handalOpen1();
        }
        else{
           setHeading("Error")
           setErrMsg(`Opps..Fail to delete`);
           setIsOpenError(true);
        }
        });
 }
 const onDelete=(id)=>
 {
  setDeleteID(id);
  handalOpen();
 }
    const paginationChange=(start,end)=>
    {
        setIspage(false)
        setPagination({start:start,end:end});
    }
    const searchFilterData=(e)=>
    {
         
        setSearctVal(e.target.value);
        // getAllProjectForSearch();
    }
    useEffect(()=>
    {
        setCardLength(0);
        var a = window.location.href;
        setProType(window.location.href.split("=")[window.location.href.split("=").length-1] );
        if(window.location.href.split("=")[window.location.href.split("=").length-1] ==="new"||window.location.href.split("=")[window.location.href.split("=").length-1] ==="new#")
        {
            axios.get(GET_allprojects).then((response) => {
               
                setLoading(false);
              if(response.data.isSuccess===true)
              {
               
                setNewCards(response.data.data);
                     setCardLength(response.data.data.length);
                }
                else
                {
                    // setNewCards(<p className='my-5'>No Projects Found!!</p>)
                }
               });
        }

       else if(window.location.href.split("=")[window.location.href.split("=").length-1] ==="filing"||window.location.href.split("=")[window.location.href.split("=").length-1] ==="filing#")
        {
            axios.get(GET_fillingprojects).then((response) => {
                setLoading(false);
              if(response.data.isSuccess===true)
              {
                setFilingCard(response.data.data);
                     setCardLength(response.data.data.length);
                }
                else
                {
                    
                    // setFilingCard(<p className='my-5'>No Projects Found!!</p>)
                }
               });
        }
        else if(window.location.href.split("=")[window.location.href.split("=").length-1] ==="running"||window.location.href.split("=")[window.location.href.split("=").length-1] ==="running#")
        {
            axios.get(GET_runningprojects).then((response) => {
                 
                setLoading(false);
              if(response.data.isSuccess===true)
              {
                // var dataRunning= response.data.data.filter((data=>{
                //     if(data.lst_InternalDocuments.length===0)
                //     {
                //         return data; 
                //     }
                //     }))
                setRunningCards(response.data.data);
                setCardLength(response.data.data.length);
                     
                }
                else
                {
                    setCardLength(0);
                }
               });
        }
        else if(window.location.href.split("=")[window.location.href.split("=").length-1] ==="completed"||window.location.href.split("=")[window.location.href.split("=").length-1] ==="completed#")
        {
            axios.get(GET_completeprojects).then((response) => {
                setLoading(false);
              if(response.data.isSuccess===true)
              {
                var dataCom= response.data.data.filter((data=>{

                    if(data.lst_InternalDocuments.length>0 || data.lost===true)
                    {
                        return data; 
                    }
                }))
                setCompleteCards(dataCom);
                     setCardLength(dataCom.length);
              }
                else
                {
                    // setCompleteCards(<p className='my-5'>No Projects Found!!</p>)
                }
               });
        }
        else if(window.location.href.split("=")[window.location.href.split("=").length-1] ==="newArrival"||window.location.href.split("=")[window.location.href.split("=").length-1] ==="newArrival#")
        {
            axios.get(GET_NEW_ARRVAL_PRO).then((response) => {
                setLoading(false);
              if(response.data.isSuccess===true)
              {
                var dataCom= response.data.data;
                setNewArrCards(dataCom);
                     setCardLength(dataCom.length);
              }
                else
                {
                    // setCompleteCards(<p className='my-5'>No Projects Found!!</p>)
                }
               });
        }
        else if(window.location.href.split("=")[window.location.href.split("=").length-1] ==="rejected"||window.location.href.split("=")[window.location.href.split("=").length-1] ==="rejected#")
        {
            axios.get(GET_rejectedProjects).then((response) => {
                 
                setLoading(false);
              if(response.data.isSuccess===true)
              {
                setRejectCards(response.data.data);
                setCardLength(response.data.data.length);
              }
                else
                {
                    // setCompleteCards(<p className='my-5'>No Projects Found!!</p>)
                }
               });
        }
        else if(window.location.href.split("=")[window.location.href.split("=").length-1] ==="draft"||window.location.href.split("=")[window.location.href.split("=").length-1] ==="draft#")
        {
            axios.get(GET_DraftProjects).then((response) => {
                setLoading(false);
              if(response.data.isSuccess===true)
              {
                setDraftCards(response.data.data);
                setCardLength(response.data.data.length);
              }
                else
                {
                    // setCompleteCards(<p className='my-5'>No Projects Found!!</p>)
                }
               });
        }
    },[]);
    const getAllProjectForSearch=()=>
    {
        // setLoading(true);
axios.get(GET_ALL_PROJECTS).then((response) => {
    // setLoading(false);
  if(response.data.isSuccess===true)
  {
    { 
        setFilingCard(response.data.data);
        setCardLength(response?.data?.data.length);
           }  
        } 
    else
    {
        setCards(<p className='my-5'>No Projects Found!!</p>)
    }
   });
    }
    const filterProject=(val)=>
    {
      
    window.location.replace(`/projectList?type=${val}`);
    setCardLength(0);
 if(val==="new")
 {
    setLoading(true);
    axios.get(GET_allprojects).then((response) => {
        setLoading(false);
      if(response.data.isSuccess===true)
      {
        setCardLength(response?.data?.data?.length);
        setNewCards(response.data.data);
            } 
        else
        {
            // setNewCards(<p className='my-5'>No Projects Found!!</p>)
            setCardLength(0);
        }
       });
 }
 else  if(val==="filing")
 {
    setLoading(true);
    axios.get(GET_fillingprojects).then((response) => {
        setLoading(false);
      if(response.data.isSuccess===true)
      {
         
        setFilingCard(response.data.data);
                 setCardLength(response?.data?.data.length);
            } 
        else
        {
            // setFilingCard(<p className='my-5'>No Projects Found!!</p>)
            setCardLength(0);
        }
       }); 
 }
 else  if(val==="running")
 {
    setLoading(true);
    axios.get(GET_runningprojects).then((response) => {
        setLoading(false);
      if(response.data.isSuccess===true)
      {
        // var dataRunning= response.data.data.filter((data=>{
        //     if(data.lst_InternalDocuments.length===0)
        //     {
        //         return data; 
        //     }
        // }))
          setRunningCards(response.data.data);
                    setCardLength(response.data.data.length);  
            } 
        else
        {
            setCardLength(0);
            // setRunningCards(<p className='my-5'>No Projects Found!!</p>)
        }
       }); 
 }
 else  if(val==="completed")
 {
    setLoading(true);
    axios.get(GET_completeprojects).then((response) => {
           setLoading(false);
      if(response.data.isSuccess===true)
      {
        var dataCom= response.data.data.filter((data=>{
            if(data.lst_InternalDocuments.length>0 || data.lost===true)
            {
                return data; 
            }
        }))
        setCompleteCards(dataCom);
        setCardLength(response?.data?.data.length);
            } 
        else
        {
            setCardLength(0);
            // setCompleteCards(<p className='my-5'>No Projects Found!!</p>)
        }
       });
 }
 else  if(val==="newArrival")
 {
    setLoading(true);
    axios.get(GET_NEW_ARRVAL_PRO).then((response) => {
           setLoading(false);
      if(response.data.isSuccess===true)
      {
        var dataCom= response.data.data
        newArrCards(dataCom);
        setCardLength(response?.data?.data.length);
            } 
        else
        {
            setCardLength(0);
            // setCompleteCards(<p className='my-5'>No Projects Found!!</p>)
        }
       });
 }
 else  if(val==="rejected")
 {

    setLoading(true);
    axios.get(GET_rejectedProjects).then((response) => {
           setLoading(false);
      if(response.data.isSuccess===true)
      {
        setRejectCards(response.data.data);
        setCardLength(response.data.data.length);
            } 
        else
        {
            setCardLength(0);
            setCards(<p className='my-5'>No Projects Found!!</p>)
        }
       });
 }
    }
    return(
              <>   {
                isLoading?  <div className='loaderDiv'>
                </div>:null
              }
                    <div className='container-fluid'>
   <div className='row '>
   {
              props?.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN?
              <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
                 <SideBar/>
              </div>:null
              }
          
    <div className={`${props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN
        ?'col-md-10 col-10 col-sm-10 '
        :'col-md-12 col-12 col-sm-12'} myview`}>
    <div className='row px-2' >
         {/*for Admin type */}
        {
   props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN?
    
        projectList.map((data,i)=>
        {
            return(<div key={i} className={`col-md-2 col-sm-2 col-3 text-center my-2 py-1 newShdBtn ${proType===data.href||proType===`${data.href}#`?'activeCls':''}`}  ><button id={data.href} className={"btn text-white"} onClick={e=>{filterProject(data.href)}}>{data.MenuName} </button></div>)
        }):
        ""
        }
        {/*for operation type */}
        {
   props.tokectDetails?.logintype === ALLOWED_USERTYPE.OPERATIONS?
   projectListOperation.map((data,i)=>
   {
       return(<><div key={i} className={`col-md-1 col-sm-1 col-1`}></div>
       <div key={i} className={`col-md-2 col-sm-2 col-3 text-center my-2 py-1 newShdBtn ${proType===data.href||proType===`${data.href}#`?'activeCls':''}`}  ><button id={data.href} className={"btn text-white"} onClick={e=>{filterProject(data.href)}}>{data.MenuName} </button></div></>)
   }):
        ""
        }
  {/*for Projects type */}
   {
   props.tokectDetails?.logintype === ALLOWED_USERTYPE.PROJECTS?
    <><div  className={`col-md-1 col-sm-1 col-1`}></div>
    {
          projectListProjects.map((data,i)=>
          {
              return(<>
              <div key={i} className={`col-md-2 col-sm-2 col-3 text-center my-2 py-1 newShdBtn ${proType===data.href||proType===`${data.href}#`?'activeCls':''}`}  ><button id={data.href} className={"btn text-white"} onClick={e=>{filterProject(data.href)}}>{data.MenuName} </button></div></>)
          })
    }
        </>
 :
        ""
        }
        </div>
                  
 <div className='mx-4 my-1
 '>
     <div className='row'>
     <h4 className='py-3 text-center theamColor mailHeading heading h4 text-capitalize'>{(proType.at(-1))==="#"?proType.substring(0, proType.length - 1):proType} Projects</h4>
     
<div className='row'>
<div className='col-md-8'>
{
            isTblView?
            <MdFormatLineSpacing onClick={e=>{setIsTblView(false)}}className="h4 mt-2 text-white"/>
            :
            <MdOutlineStorage onClick={e=>{setIsTblView(true)}} className="h4 mt-2 text-white"/>
        }
</div>
<div className='col-md-4 col-11 col-sm-11 my-2 px-3'>
    {
        isTblView?null:
        <>
                <GrSearch className='searchIconNew'/>
    <Input
      id="seaarch"
      name="search"
      placeholder="Search Here...."
      type="text" 
    //   value={filter}
    //   onChange={searchcard}
      onChange={searchFilterData}
    />
        </>
    }

    </div>
</div>
    {
    proType==="new"||proType==="new#"?
    isTblView?  
    <>  <DataGrids cardsList={newCards} columns={columnsForNewPro}/>
  </> :
        <div className="row">{
            newCards?.length>0?
            newCards?.filter((data=>
            {
              
            if(!searctVal)
            {
                return data;
            }
            else if(data?.projectName?.toLowerCase().includes(searctVal.toLowerCase()))
            {
                return data; 
            }
            })) 
               ?.map((data,i)=>
        {
            return( <div key={i}className='col-sm-4 col-md-3 col-12'>
            <ProjectCards  onDelete={onDelete}rowpos={data.rowpos}  img={data.logo} projectName={data.projectName}projectDetails={data.category} created={data.edate}ldateOfSubmissionBid={data.ldateOfSubmissionBid}/>
            </div>)
        }):
            <p className='my-5'>No Projects Found!!</p>
        
    }    

</div>:null
    }    {
        proType==="filing"||proType==="filing#"?
        isTblView?  
        <>  <DataGrids cardsList={filingCards} columns={columnsForNewPro}/>
      </> :
            <div className="row">{
                filingCards?.length>0?
                filingCards?.filter((data=>
                {
                  
                if(!searctVal)
                {
                    return data;
                }
                else if(data?.projectName?.toLowerCase().includes(searctVal.toLowerCase()))
                {
                    return data; 
                }
                })) 
                    
                    
                   ?.slice(pagination.start,pagination.end)?.map((data,i)=>
            {
                return( <div key={i}className='col-sm-4 col-md-3 col-12'>
                <ProjectCards  onDelete={onDelete}rowpos={data.rowpos}   img={data.logo}    projectName={data.projectName}projectDetails={data.category} created={data.edate}ldateOfSubmissionBid={data.ldateOfSubmissionBid}/>
                </div>)
            }):
            <p className='my-5'>No Projects Found!!</p>
        }    
    
    </div>:null
        }
     {
    proType==="running"||proType==="running#"?
    isTblView?  
    <>  <DataGrids cardsList={runningCards} columns={columnsForNewPro}/>
  </> :
        <div className="row">{
            runningCards?.length>0?
            runningCards?.filter((data=>
            {
              
            if(!searctVal)
            {
                return data;
            }
            else if(data?.projectName?.toLowerCase().includes(searctVal.toLowerCase()))
            {
                return data; 
            }
            })) 
                  
               ?.map((data,i)=>
        {
            return( <div key={data.rowpos} className='col-sm-4 col-md-3 col-12'>
            <ProjectCards tokectDetails={props.tokectDetails} onDelete={onDelete} page={i} rowpos={data.rowpos} img={data.logo} projectName={data.projectName}projectDetails={data.category} created={data.edate}/>
            </div>)
        }):
        <p className='my-5'>No Projects Found!!</p>
    }    

</div>:null
    }
        {
    proType==="completed"||proType==="completed#"?
    isTblView?  
    <>
    <DataGrids cardsList={completeCards} columns={columnsForNewPro}/>
  </> :
        <div className="row">
          {
            completeCards?.length>0?
            completeCards?.filter((data=>{
                if(data.lst_InternalDocuments.length>0 || data.lost===true)
                {
                    return data; 
                }
        }))?.filter((data=>
                    {
                      
                    if(!searctVal)
                    {
                        return data;
                    }
                    else if(data?.projectName?.toLowerCase().includes(searctVal.toLowerCase()))
                    {
                        return data; 
                    }
                    }))
               ?.map((data,i)=>
        {
            return( <div key={i}className='col-sm-4 col-md-3 col-12'>
               
            <ProjectCards i={data.lst_InternalDocuments.length}  onDelete={onDelete}rowpos={data.rowpos}   img={data.logo}    projectName={data.projectName}projectDetails={data.category} created={data.edate} Lost={data.lost}/>
            </div>)
        }):
        <p className='my-5'>No Projects Found!!</p>
    }    

</div>:null
    }  

{
    proType==="newArrival"||proType==="newArrival#"?
    isTblView?  
    <>
    <DataGrids cardsList={newArrCards} columns={columnsForNewPro}/>
  </> :
        <div className="row">
          {
            newArrCards?.length>0?
            newArrCards?.filter((data=>
                    {
                      
                    if(!searctVal)
                    {
                        return data;
                    }
                    else if(data?.projectName?.toLowerCase().includes(searctVal.toLowerCase()))
                    {
                        return data; 
                    }
                    }))
               ?.map((data,i)=>
        {
            return( <div key={i}className='col-sm-4 col-md-3 col-12'>
               
            <ProjectCards i={data.lst_InternalDocuments.length}  onDelete={onDelete}rowpos={data.rowpos}   img={data.logo}    projectName={data.projectName}projectDetails={data.category} created={data.edate} Lost={data.lost}/>
            </div>)
        }):
        <p className='my-5'>No Projects Found!!</p>
    }    

</div>:null
    }  
            {
    proType==="rejected"||proType==="rejected#"?
    isTblView?  
    <>  <DataGrids cardsList={rejectCards} columns={columnsForNewPro}/>
  </> :
        <div className="row">
          {
            
            rejectCards?.length>0?
            rejectCards?.filter((data=>
                    {
                      
                    if(!searctVal)
                    {
                        return data;
                    }
                    else if(data?.projectName?.toLowerCase().includes(searctVal.toLowerCase()))
                    {
                        return data; 
                    }
                    }))
               ?.map((data,i)=>
        {
            return( <div key={i}className='col-sm-4 col-md-3 col-12'>
               
            <ProjectCards i={data.lst_InternalDocuments.length}  onDelete={onDelete}rowpos={data.rowpos}   img={data.logo}    projectName={data.projectName}projectDetails={data.category} created={data.edate}/>
            </div>)
        }):
        <p className='my-5'>No Projects Found!!</p>
    }    

</div>:null
    }   

{
    proType==="draft"||proType==="draft#"?
    isTblView?  
    <>  <DataGrids cardsList={draftCards} columns={columnsForNewPro}/>
  </> :
        <div className="row">
          {
            draftCards?.length>0?
            draftCards?.filter((data=>
                    {
                    if(!searctVal)
                    {
                        return data;
                    }
                    else if(data?.projectName?.toLowerCase().includes(searctVal.toLowerCase()))
                    {
                        return data; 
                    }
                    }))
               ?.map((data,i)=>
        {
            return( <div key={i}className='col-sm-4 col-md-3 col-12'>
            <ProjectCards page={"DRAFT"}i={data.lst_InternalDocuments.length}  onDelete={onDelete}rowpos={data.rowpos}   img={data.logo}    projectName={data.projectName}projectDetails={data.category} created={data.edate}/>
            </div>)
        }):
        <p className='my-5'>No Projects Found!!</p>
    }    

</div>:null
    }
   <div className='my-3'> 
   <FloatingBtn href='/projectList/form' usehtmlFor="Add Project"/>
    </div> 
     </div>
     <Modal className="fade show"isOpen={isOpen} >
  <ModalBody  className='text-center tracking-in-expand-fwd-bottom'>
<p className=' text-center text-danger fw-bold'><VscError className='fw-bolder mx-2 my-2 h2'/>Are you sure you want to Delete</p>
<div>   <Button
     className='btnSuccess mx-2'
     onClick={()=>{deleteProject()}}
    >
      Yes
    </Button>
    <Button
     className='btnDelete mx-2'
     onClick={handalClose}
    >
      No
    </Button></div>
  </ModalBody>

  </Modal>
   {/*Error Box*/}
   <ErrorAlertBox
        isOpen={isOpenError}
        setIsOpen={setIsOpenError}
        heading={heading}
        errMsg={errMsg}
      />
      <SuccessAlertBox href={window.location.href} isOpen1={isOpen1} setIsOpen1={setIsOpen1}heading1={heading1}successMsg={successMsg}/>
     </div>
 </div>
 </div>
    </div></>  
 
      
    )
}
export default ProjectsList;