import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ErrorAlertBox from "../../common/AlertBox/ErrorAlertBox";
import SuccessAlertBox from "../../common/AlertBox/SuccessAlertBox";
import LandingPage from "../../common/SideBar/SideBar";
import { CREATE_DAILYUPDATE, CREATE_DOCUMENT, GET_ALLDAILYUPDATE, GET_DAILYUPDATE, GET_Employee, GET_EVENT, GET_PROJECT } from "../../utiles/AllApis";

function Dailyupdate() {
  const [isLoading, setLoading] = useState(false);
  const [projectdrop, setprojectdrop] = useState([]);
  const [projectname, setprojectname] = useState("");
  const [SelectedRows,setSelectedRows] =useState([])
const [uploadfile,setuploadfile] = useState("")
const [remarks,setremarks] = useState("")
const [subject,setsubject] = useState("")
const [ViewDoc,setviewDoc] = useState([]);
const [tabledata,settabledata] = useState([]);
  const [popup,setpopup] = useState(false)
  const [empname,setempname] =useState("")
  const [empList,setEmpList]=useState([]);
  const [isOpen1, setIsOpen1] = useState(false);
  const [heading, setHeading] = useState("");
const [errMsg, setErrMsg] = useState("");
const [heading1, setHeading1] = useState("");
const [successMsg, setsuccessMsg] = useState("");
const [isOpenError, setIsOpenError] = useState(false);
  const handalOpen1 = () => {
    setIsOpen1(true);
  };
const columns = [
  {
    field: "edate",
    headerName: "Created At",
    width: 120,
    disableClickEventBubbling: true,
    renderCell: (params) => {

      return (
        <p className='text-white mt-2 f-14'>{params?.row?.edate?.split("T")[0]}</p>
      );
    }
  },
      { field: 'employeeName', headerName: 'Employee Name' ,width: 190,},
      { field: 'projectName', headerName: 'Event Name',width: 230,},
      { field: 'subject', headerName: 'Subject' ,width: 250,},
      { field: 'remarks', headerName: 'Remarks',width: 250,},
      {
        field: "path",
        headerName: "View Documents",
        sortable: false,
        width: 230,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <Button  variant="contained" className="btn btnSuccess" onClick={e=>{edit(params.row.dailyUpdate_docs)}}>view</Button>
          );
        }
      }

]
const edit =(data)=>{
  const docVal= data.map((data,i)=>
  {
  return(<div className="col-md-12 col-sm-12 col-12"key={i}><img className="w-100 h-100" src={data.path}></img></div>)
  })
  setviewDoc(docVal);
  setpopup(true);
}

  useEffect(() => {
    setLoading(true);
    axios.get(GET_EVENT).then((response) => {
      setLoading(false);
     if(response.data.isSuccess===true)
     {

      setprojectdrop(response.data.data);
     }

    });
    setLoading(true);
    axios.get(GET_Employee).then((response) => {
      setLoading(false);
      if(response.data.isSuccess===true){
       
      setEmpList(response.data.data);
      }
    });
   
    
    var config = {
      method: 'post',
      url: GET_ALLDAILYUPDATE,
      headers: { 
        'Content-Type': 'application/json'
      },
   
    };
    setLoading(true);
    axios(config)
    .then(function (response) {
      setLoading(false);
      if(response.data.isSuccess===true)
      {
          
        settabledata(response.data.data);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }, []);



  const handlecross =()=>{
    setpopup(false)
  }

  const handlefile =(e)=>{
  
    var validate = e?.target?.files[0].name.split(".")[e?.target?.files[0].name.split(".").length-1];
    var FileExt = validate.toUpperCase();
    if (FileExt !== "PNG" && FileExt !== "JPG" && FileExt!== "JPEG") {
      setHeading("Error")
      setErrMsg(`please select only image file !`);
      setIsOpenError(true);
      e.target.value = null;
    }
    else{
      setuploadfile(e.target.files[0])
    }
  }
function uploadfiles(id,docId){
 
  var FormData = require("form-data");
  var data = new FormData();
  data.append("File", uploadfile);
  data.append("ID", id);
  data.append("docID", docId);
  data.append("SubType", "UPDATE");
  data.append("name", "document");
  data.append("Type", "DAILY_UPDATE_DOCUMENT");

  var config = {
    method: "post",
    url: CREATE_DOCUMENT,

    data: data,
  };
  setLoading(true);
   
  axios(config)
    .then(function (response) {
      setLoading(false);
     if(response.data.isSuccess===false)
     {
      setHeading("Error");
      setErrMsg(`File Upload Fails`);
      setIsOpenError(true);
     }
    })
    .catch(function (error) {
      console.log(error);
    });
}
  const handlecreate = ()=>{
    if(projectname,uploadfile,subject,remarks,empname === ""){
      setHeading("Error");
      setErrMsg(`Fields Can not be empty`);
      setIsOpenError(true);
    }
    else{
      createDailyUpdates();
    }
  }
  const createDailyUpdates=()=>
  {
    var dataDailtUpdate = JSON.stringify({
      "EMP_MCODE": empname,
      "Project_ID":projectname,
      "Subject":subject,
      "Remarks":remarks,
    });
    var config = {
      method: 'post',
      url: CREATE_DAILYUPDATE,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : dataDailtUpdate
    };
    setLoading(true);
    axios(config)
    .then(function (response) {
      setLoading(false);  
      if(response.data.isSuccess===true)
      {
         
        uploadfiles(response.data.data.project_ID,response.data.data.rowpos);
            setsuccessMsg(response.data.message);
           setHeading1("Success");
           handalOpen1()
        window.location.reload();
      }
      else{
        setHeading("Error")
        setErrMsg(`Opps.. Fail to Create`);
        setIsOpenError(true);
      document.getElementById("EventName").value="";
      document.getElementById("EmpName").value="";
      document.getElementById("DailyFile").value="";
      document.getElementById("DailyEDate").value="";
      document.getElementById("DailySub").value="";
      document.getElementById("DailyRemarks").value="";
      }
    
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  // var tbl = document.getElementsByClassName('MuiDataGrid-row')
  // for (var i = 0; i < tbl.length; i++) {
  //   tbl[i].style.color = 'red'
  //   }


  return (
    <>
      <div className="container-fluid">
      {
    isLoading?  <div className='loaderDiv'>
    </div>:null
  }
        <div className="row ">
          <div className="col-md-2 col-12 col-sm-12 mainSideBar px-0">
            <LandingPage />
          </div>
          <div className="col-md-10 col-12 col-sm-12 py-3 myview">
            <div className="row">
              <center><h3 className="theamColor mailHeading h3">DAILY UPDATE</h3></center>
              <br /><br />

              <div className="employee_Form" >
              <div className='d-flexed space' style={{ width: "80%", margin: "auto",justifyContent:"space-between" }}>
                <div className="mb-3 animatingTop">
                  <label className="form-label">Event name<span className='required'>*</span></label>
                  <br />
                  <select
                style={{ width: "100%",height:"40px",color:"#111" }}
                    value={projectname}
                    id="EventName"
                    onChange={(e) => setprojectname(e.target.value)}
                  >
                    <option>select event</option>
                    {projectdrop?.map((option, i) => (
                      <option key={i} value={option.rowpos}>
                        {option.projectName}
                      </option>
                    ))}
                  </select>

                </div>
                <div className="mb-3 animatingTop">
                  <label className="form-label">Employee name<span className='required'>*</span></label>
                  <br />
                  <select
                  id="EmpName"
                  style={{ width: "100%",height:"40px",color:"#111" }}
                    value={empname}
                    onChange={(e) => setempname(e.target.value)}
                  >
                    <option>select Employee</option>
                    {empList?.map((option, i) => (
                      <option key={i} value={option.employeeID}> {option.firstName}  {option.lastName} </option>
                    ))}
                  </select>

                </div>

                
                
              </div>

              <div className='d-flexed space' style={{ width: "80%", margin: "auto",justifyContent:"space-between" }}>
              <div className="mb-3 animatingTop">
                  <label className="form-label">upload file<span className='required'>*</span></label>
                  <input type="file"
                    id="DailyFile"
                     onChange={(e) => handlefile(e)}
                    className="form-control " />

                </div>
                <div className="mb-3 animatingTop">
                  <label className="form-label">DATE<span className='required'>*</span></label>
                  <input type="date" 
                    max="9999-12-31"
                  id="DailyEDate"
                    className="form-control " />
                </div>

              </div>
              <div className='d-flexed space' style={{ width: "80%", margin: "auto",justifyContent:"space-between" }}>
           
              <div className=" mb-3 animatingBottom">
                 
                  <div className=" mb-3 form-outline full-Width" >
                    <label className="form-label" >
                      SUBJECT<span className='required'>*</span>
                    </label>
                    <textarea

                      className="form-control"
                      id="DailySub"
                      placeholder="subject..."
                      rows="2"
                      value={subject}
                      onChange={(e)=>setsubject(e.target.value)}
                    ></textarea>
                  </div>
                </div>

                <div className="mb-3 animatingBottom" >
                  <div className=" mb-3 form-outline full-Width" >
                    <label className="form-label" >
                      REMARKS<span className='required'>*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="DailyRemarks"
                      placeholder="remarks..."
                      rows="2"
                      value={remarks}
                      onChange={(e)=>setremarks(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              </div>
              <center>
                <button
                className="btn btnSuccess"
                  // style={{ color: "#fff", padding: "5px 40px", background: "#0f533d", border: "#0f533d", fontWeight: "600" }}
                onClick={e=>{handlecreate()}}
               >SUBMIT</button>
              </center>
           <div className="px-5 my-4">
           <div className='tracking-in-expand-fwd-bottom tbanew' > <DataGrid
          rows={tabledata}
          columns={columns}
          getRowId={(row) => row?.rowpos}
          pageSize={10}
            // checkboxSelection
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = tabledata.filter((row) =>
                selectedIDs.has(row?.rowpos),
              );
              setSelectedRows(selectedRows);
              // setfirstname(selectedRows.mcode)
            }}
            {...tabledata}
          /></div>
           </div>
      {
        popup ?

        <div className="popupbox" style={{position:"fixed",top:"-1%"}}>
          <div className='popup' style={{position:"fixed"}}>
            <div className="row">
              <div className="col-sm-11 col-md-11 col-11">
              </div>
              <div className="col-sm-1 col-md-1 col-1">
              <p style={{float:"right",cursor:"pointer"}} onClick={handlecross}>❌</p>
              </div>
        {
          ViewDoc
        }
        </div>
        </div>
        </div>
        :

        ""

      }
            </div>
          </div>
          
        </div>
      </div>
      <SuccessAlertBox isOpen1={isOpen1} setIsOpen1={setIsOpen1}heading1={heading1}successMsg={successMsg}/>
{/*Error Box*/}
<ErrorAlertBox
      isOpen={isOpenError}
      setIsOpen={setIsOpenError}
      heading={heading}
      errMsg={errMsg}
    />
      
    </>
  );
}

export default Dailyupdate;
