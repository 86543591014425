import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarQuickFilter, GridLinkOperator, } from '@mui/x-data-grid'
import "./datagrid.css"

import XLSX from 'sheetjs-style';
const DataGrids = (props) => {
  const handleExport = () => {
    exportToExcel(props.cardsList, props.columns);
  };

  const exportToExcel = (rows, columns) => {

    const filteredColumns = columns.filter(column => column.field !== 'vIEW');


    const headers = filteredColumns.map(column => ({
      v: column.headerName.toUpperCase(),
      s: { font: { bold: true } }
    }));

    const data = [
      headers.map(header => header.v),
      ...rows.map(row =>
        filteredColumns.map(column => {
          if (column.field === 'dtDOB' || column.field === 'edate') {
            const date = new Date(row[column.field]);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
          }
          return row[column.field];
        })
      )
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Apply bold styling to headers
    headers.forEach((header, index) => {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: index }); // Get cell reference (e.g., A1, B1)
      worksheet[cellRef].s = header.s; // Apply the bold style
    });

    // Adjust column width (optional)
    worksheet['!cols'] = headers.map(() => ({ wch: 20 })); // Adjust all columns to width 20

    XLSX.writeFile(workbook, 'Employee_Master_Sheet.xlsx');
  };





  return (
    <>
      <div className='tablebox mt-3 text-center'>
        <button
          className='newSuccBtn px-2 my-2'
          onClick={handleExport}
        >
          Export Excel
        </button>
        <DataGrid
          className='text-center'
          rows={props?.cardsList}
          columns={props?.columns}
          pageSize={10}
          getRowId={(row) => row?.rowpos}
        />
      </div>
    </>
  )
}
export default DataGrids;