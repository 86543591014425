import { Button,
    Modal,
    ModalFooter,
    ModalBody,
    ModalHeader,} from 'reactstrap';
import {VscError} from 'react-icons/vsc';
import './AlertBoc.css';
    const ErrorAlertBox=(props)=>
    { 
        const handalClose=()=>{props.setIsOpen(false);}
return(
    <>
    <Modal className=" fade show"isOpen={props.isOpen} >
      <ModalHeader className='brdBtm0 text-center '
        // close={
        // <button className="close" onClick={handalClose}>×</button>}
      >
      <span className=' text-center text-danger fw-bold'><VscError className='fw-bolder mx-2 h2'/>{props.heading}</span>   
      </ModalHeader>
      <ModalBody className='text-center'>
    <p className='text-dark'>
      {
        typeof(props.errMsg)==="object"?
        props.errMsg.map((data,i)=>
        {
          return(<>
           {data}
          <br/>
          </>)
        })
        :
       props.errMsg
      }

      </p>
  <div>   <Button
         className='btnDelete'
          onClick={handalClose}
        >
          Ok
        </Button></div>
      </ModalBody>
  
      </Modal>
    </>
)
    }
    export default ErrorAlertBox;
