import React, { useEffect, useState } from 'react';
import './login.css';
import {
  FormGroup,
  Label,
  Input, ModalBody, Modal,
  ModalHeader, ModalFooter, Button

} from 'reactstrap';
import axios from 'axios';
import { LOGIN_API, FORGET_PASSWORD, ALLOWED_USERTYPE, GET_EMP_DATAILS_BY_MCODE, ENCRYPTION_KEY } from '../../utiles/AllApis';
import { useNavigate } from 'react-router-dom';
import Setcookie from "./Setcookie"
import ErrorAlertBox from '../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../common/AlertBox/SuccessAlertBox';
// import logo from '../../assets/showcrafLogo.png'
import logo from '../../assets/showcraftLogo.png'
import logoall from '../../assets/ALLTHEWORLDSSTAGE.png'
import PNG from '../../assets/loginwebbackground.jpg'
import HeaderDemo from './Header';
import Getcookie from "../../modules/login/Getcookie";
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import Removecookie from './Removecookie';
// import Mobilebg  from '../../assets/loginwebbg.png'

var CryptoJS = require("crypto-js");
axios.defaults.withCredentials = true;
const Login = (Props) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const [heading, setHeading] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [isOpenErr, setIsOpenErr] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [loginscreen, setloginscreen] = useState(true);
  const handalOpen1 = () => { setIsOpen1(true); }
  const handalOpen = () => { setIsOpenErr(true); }
  const [loginDataVal, setLoginDataVal] = useState({
    "email": "",
    "password": ""
  })
  const [forgetDataVal, setForgetDataVal] = useState({
    "password": "",
    "email": "",
    "reEnterPass": ""
  })
  const handalClose = () => {
    setIsOpen(false);
  }

  const chechEmailValidation = (e) => {
    var keyCode = e.charCode;

    if ((keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122) || (keyCode === 64) || (keyCode >= 48 && keyCode <= 57) || (keyCode === 46)) {
    }
    else {
      e.preventDefault();
    }
  }
  const chechForBlankSpace = (e) => {
    var keyCode = e.charCode;

    if (keyCode !== 32) {
    }
    else {
      e.preventDefault();
    }
  }
  const getLoginData = (e) => {
    setLoginDataVal({
      ...loginDataVal, [e.target.name]: e.target.value
    })
  }
  const getForgetData = (e) => {
    setForgetDataVal({
      ...forgetDataVal, [e.target.name]: e.target.value
    })
  }
  const submitLoginDetaile = () => {
    if (loginDataVal.email === "" || loginDataVal.password === "") {
      alert("fields cannot empty");
    }
    else {

      //  withCredentials: true,
      setLoading(true);
      axios.post(LOGIN_API,
        {
          "userName": loginDataVal.email,

          "password": loginDataVal.password,

          //"logintype":Props.userDetails.UserType

        }
        , { withCredentials: true }, { credentials: 'include' }
      ).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          var a = response.cookie;
          var rawCookie = response.headers["set-cookie"] ?? '';
          response.data.data.rememberMe = document.querySelector('#rememberme').checked;
          const res = response.data.data;
          Props.setUser(response.data.data);
          var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(res), ENCRYPTION_KEY).toString();
          Setcookie('ERPUser', ciphertext);

          if(response.data.isSuccess===true){
            navigate("/dashboard");
          }
          // if (response.data.data.logintype === ALLOWED_USERTYPE.OPERATIONS) {
          //   navigate("/oprtn_Project");
          // }
          // else if (response.data.data.logintype === ALLOWED_USERTYPE.ADMIN) {
          //   navigate("/dashboard");
          // }
          // else if (response.data.data.logintype === ALLOWED_USERTYPE.PROJECTS) {
          //   navigate("/ProjectDashboard");
          // }
          // else /*if(response.data.data.logintype===ALLOWED_USERTYPE.PROJECTS)*/ {
          //   navigate("/employeeprofile/dashboard");
          // }
        }
        else {
          alert("Invalid Email id or Password");
        }
      });
    }

  }
  useEffect(() => {
    document.querySelector('.headerBgColor').style.display = 'none';
  }, [])


  const forgetPassFunction = () => {
    if (forgetDataVal.email == "" || forgetDataVal.password == "" || forgetDataVal.reEnterPass == "") {
      alert("Field cannot blank");
    }
    else {
      if (forgetDataVal.password !== forgetDataVal.reEnterPass) {
        alert("Password does not match");
        return;
      }
      setLoading(true);
      axios.post(FORGET_PASSWORD, forgetDataVal)
        .then((response) => {
          setLoading(false);
          if (response.data.isSuccess === true) {
            setHeading1("Success");
            setsuccessMsg("Password updated Successfully");
            setForgetDataVal({ "email": "", "password": "", "reEnterPass": "" });
            handalOpen1();
            handalClose();
          }
          else {
            setHeading("Submission Failed!");
            setErrMsg(response.data.message);
            handalOpen();
            setForgetDataVal({ "email": "" });
          }
        });
    }
  }

  const handleback = () => {
    setloginscreen(true);

  }
  return (
    <>
      <div>
        {
          isLoading ? <div className='loaderDiv'>
          </div> : null
        }
        {/* <video autoPlay muted loop style={{ width: '100%' }}>
                    <source src={vedio} type="video/mp4" />
                </video> */}
        <img src={PNG} alt="showcraftbackground Images" className='loginbgimg' style={{ width: '100%', height: '100%' }} />
        {
          loginscreen ?
            <>
              <HeaderDemo button={setloginscreen} setUserDetails={Props.setUserDetails} userDetails={Props.userDetails} />
              {/* <center> <span className='loginText'>ALL THE WORLD'S A STAGE</span></center> */}
              <center> <div className='logo-second'>
                <img className="W-30" style={{ width: '55%', height: '100%' }} src={logoall} />
              </div>
              </center>
              <center> <div className='logo-First'>
                <img className="w-40" style={{ width: '80%' }} src={logo} />

              </div>
              </center>
            </>

            :
            <div className="content">
              <span className="cursor-pointer"><a className="mt-4" href="#" style={{ color: "white", top: "0%", position: "absolute" }} onClick={handleback}>BACK</a></span>
              <center> <h1 className='loginBottomText' id="wecolmetopgun">WELCOME TOPGUNS</h1></center>
              <center>
                <div className='loginback'>
                  <div className='row d-flex' >
                    <div className='col-md-6 col-12 col-sm-12 loginpageLeft pt-4'>


                      <div className=''>
                        <img className="w-75" src={logo} />

                      </div>
                    </div>

                    <div className='col-md-6 col-12 col-sm-12 loginpageRight'>
                      <div className='loginformDiv'>
                        {/* <h3>Welcome To <span >Top Guns</span></h3> */}
                        <span> <PersonIcon name="apps-outline" style={{ color: "#393186", transform: "translate(-167px, 31px)", height: '40px', width: '47px', borderBottomLeftRadius: '10px', borderTopLeftRadius: '10px', border: '2px solid rgb(57, 49, 134)' }}></PersonIcon></span>
                        < Input
                          id="exampleEmail"
                          name="email"
                          placeholder="Enter your User Name.."
                          type="email"

                          onKeyPress={chechEmailValidation}
                          onChange={getLoginData}

                        />


                        <span> <LockIcon name="apps-outline" style={{ color: "#393186", transform: "translate(-167px, 31px)", height: '40px', width: '47px', borderBottomLeftRadius: '10px', borderTopLeftRadius: '10px', border: '2px solid rgb(57, 49, 134)' }}></LockIcon></span>
                        <Input
                          id="examplePassword"
                          name="password"
                          placeholder="Enter your password.."
                          type="password"
                          onChange={getLoginData}
                          onKeyPress={chechForBlankSpace}
                          style={{ width: "270px" }}
                        />

                        <br />
                        <input type="submit" className='loginBtn' id="loginbtnw" value="LOGIN" onClick={submitLoginDetaile} />
                        <br />

                        <div >
                          <input type="checkbox" id="rememberme" style={{ width: "20px", height: "20px", boxShadow: "0 0 0px 0 rgba(0,0,0,0.9)", transform: "translate(-200px, 14px)", }} className="check-input boxinput" value="" name="remember me" />
                        </div>
                        <div className='login-ask'>
                          <div>
                            <p style={{ color: "000", fontStyle: "italic" }} className='remembertext' > remember me </p>
                          </div>
                          <div>
                            <p onClick={e => { setIsOpen(true); }} style={{ color: "000", fontStyle: "italic" }} className='forgottext' >forgot password ?</p>
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </center>

            </div>
        }

      </div>


      <Modal className=" fade show" isOpen={isOpen} >
        <ModalHeader className='brdBtm0 text-center'
          close={
            <span className=' h4 text-center text-danger fw-bold'>Forgot Password<button className="close croseBtn" onClick={handalClose}>×</button></span>}>

        </ModalHeader>
        <ModalBody className='text-center'>
          <div className="mb-4">
            <label htmlFor="exampleInputname" className=" fw-bold float-left">Enter Email :</label>
            <input type="text" className="form-control" placeholder="Enter user Email" name="email" onChange={getForgetData} value={forgetDataVal.email} />
          </div>
          <div className="mb-4">

            <label htmlFor="exampleInputname" className="float-left fw-bold">Enter New Password : </label>
            <input type="password" className="form-control" placeholder="Enter New Password" name="password" onChange={getForgetData} value={forgetDataVal.password} />
          </div>
          <div className="mb-4 ">
            <label htmlFor="exampleInputname" className="float-left fw-bold">Re-Enter New Password :</label>
            <input type="password" className="form-control" placeholder="Re-Enter New Password" name="reEnterPass" onChange={getForgetData} value={forgetDataVal.reEnterPass} />
          </div>
          <div>   <Button
            className='btnDelete'
            onClick={forgetPassFunction}
          >
            Submit
          </Button></div>
        </ModalBody>

      </Modal>

      <ErrorAlertBox isOpen={isOpenErr} setIsOpen={setIsOpenErr} heading={heading} errMsg={errMsg} />
      <SuccessAlertBox isOpen1={isOpen1} setIsOpen1={setIsOpen1} heading1={heading1} successMsg={successMsg} />

    </>

  )
}
export default Login;