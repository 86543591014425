import React,{useState} from 'react';
import {
    CardGroup,
    Card,
    // CardImg,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,

} from 'reactstrap';
// import ButtonEdit from '../../common/Buttons/ButtonEdit';
// import ButtonDelete from '../../common/Buttons/ButtonDelete';
const ElementCard=(props)=>
{
  const[redirectionForm,setRedirectionForm]=useState(`${props.formRedirection}?id=${props.rowpos}`) 
  const handleOpen=()=>{props.getElementType(props.id)}
    return(
<CardGroup className='crdGrp tracking-in-expand-fwd-top'>
  <Card className='crdBody'>
  <div className='' onClick={e=>{handleOpen(e)}}>
    <CardBody className='myshadowcard'>
      {/* <CardTitle tag="h5">
      {props.id}
      </CardTitle> */}
      <CardSubtitle
        className="mb-2"
        tag="h6" style={{color:"white"}}
      >
       {props.name}
      </CardSubtitle>
      <CardText className='text-start'>
      <a href="#"> {props.val}</a>
      </CardText>
      {/* <ButtonEdit btnTitle="Edit" href={redirectionForm}/>
      <ButtonDelete btnTitle="Delete"usehtmlFor="head"rowpos={props.rowpos}onDelete={props.onDelete}/> */}
    </CardBody>
    </div>
  </Card>
</CardGroup>
    )
}
export default ElementCard;