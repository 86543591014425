import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Slider from 'react-slick';
import TopSection from './Components/TopSection';

import './dashboard.css';
import axios from 'axios';
import {
  CREATE_SUPPORT_TICKET,
  GET_ALL_SUPPORT_TICKET,
  ALLOWED_USERTYPE,
} from '../../../../utiles/AllApis';
  // Dummy data for the bar graph
  const currentYearData = [65, 59, 80, 81, 56, 55, 40,0, 0, 0, 0, 0];
  const lastYearData = [28, 48, 40, 19, 86, 27, 90, 80, 10, 56, 29, 71];
  const barGraphLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August', 'September','October','November','December'];

  const sliderSettings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
function Dashboard(props) {
  const [isLoading, setLoading] = useState(false);

  const urlVal = `/employeeprofile/help`;

  return (
    <>
      {isLoading ? <div className="loaderDiv"></div> : null}
     <TopSection/>
      <div className="">
        <div className="row">
          {/* First Column */}
          <div className="col-md-4">
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Card 1</h5>
                <p className="card-text">This is the content of card 1</p>
                <p className="card-text">This is the content of card 1</p>
                <p className="card-text">This is the content of card 1</p>
                <p className="card-text">This is the content of card 1</p>
                <p className="card-text">This is the content of card 1</p>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Card 2</h5>
                <p className="card-text">This is the content of card 2</p>
                <p className="card-text">This is the content of card 2</p>
                <p className="card-text">This is the content of card 2</p>
                <p className="card-text">This is the content of card 2</p>
                <p className="card-text">This is the content of card 2</p>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Card 3</h5>
                <p className="card-text">This is the content of card 3</p>
                <p className="card-text">This is the content of card 3</p>
                <p className="card-text">This is the content of card 3</p>
                <p className="card-text">This is the content of card 3</p>
                <p className="card-text">This is the content of card 3</p>
              </div>
            </div>
          </div>

          {/* Second Column */}
          <div className="col-md-4">
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Card 4</h5>
                <p className="card-text">This is the content of card 4</p>
                <p className="card-text">This is the content of card 4</p>
                <p className="card-text">This is the content of card 4</p>
                <p className="card-text">This is the content of card 4</p>
                <p className="card-text">This is the content of card 4</p>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Card 5</h5>
                <p className="card-text">This is the content of card 5</p>
                <p className="card-text">This is the content of card 5</p>
                <p className="card-text">This is the content of card 5</p>
                <p className="card-text">This is the content of card 5</p>
                <p className="card-text">This is the content of card 5</p>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Card 6</h5>
                <p className="card-text">This is the content of card 6</p>
                <p className="card-text">This is the content of card 6</p>
                <p className="card-text">This is the content of card 6</p>
                <p className="card-text">This is the content of card 6</p>
                <p className="card-text">This is the content of card 6</p>
              </div>
            </div>
          </div>
            {/* Third Column */}
            <div className="col-md-4">
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Card 1</h5>
                <p className="card-text">This is the content of card 1</p>
                <p className="card-text">This is the content of card 1</p>
                <p className="card-text">This is the content of card 1</p>
                <p className="card-text">This is the content of card 1</p>
                <p className="card-text">This is the content of card 1</p>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Card 2</h5>
                <p className="card-text">This is the content of card 2</p>
                <p className="card-text">This is the content of card 2</p>
                <p className="card-text">This is the content of card 2</p>
                <p className="card-text">This is the content of card 2</p>
                <p className="card-text">This is the content of card 2</p>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Card 3</h5>
                <p className="card-text">This is the content of card 3</p>
                <p className="card-text">This is the content of card 3</p>
                <p className="card-text">This is the content of card 3</p>
                <p className="card-text">This is the content of card 3</p>
                <p className="card-text">This is the content of card 3</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bar Graph Slider Section */}
      <div className="bar-graph-slider">
        <Slider {...sliderSettings}>
          <div>
            {/* Current Year Bar Graph */}
            <Bar
              data={{
                labels: barGraphLabels,
                datasets: [
                  {
                    label: 'Current Year',
                    backgroundColor: '#c92b2f',
                    borderColor: '#c92b2f',
                    borderWidth: 1,
                    data: currentYearData,
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </div>
          <div>
            {/* Last Year Bar Graph */}
            <Bar
              data={{
                labels: barGraphLabels,
                datasets: [
                  {
                    label: 'Last Year',
                    backgroundColor: '#00a859',
                    borderColor: '#00a859',
                    borderWidth: 1,
                    data: lastYearData,
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </div>
        </Slider>
      </div>
      {/* <ErrorAlertBox isOpen={isOpen} setIsOpen={setIsOpen} heading={heading} errMsg={errMsg} />
      <SuccessAlertBox
        href={urlVal}
        isOpen1={isOpen1}
        setIsOpen1={setIsOpen1}
        heading1={heading1}
        successMsg={successMsg}
      /> */}
    </>
  );
}

export default Dashboard;
