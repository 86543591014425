import React,{useState} from 'react';
import {
    CardGroup,
    Card,
    // CardImg,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,

} from 'reactstrap';
import ButtonEdit from '../../common/Buttons/ButtonEdit';
import ButtonDelete from '../../common/Buttons/ButtonDelete';
const HeadCard=(props)=>
{
  const[redirectionForm,setRedirectionForm]=useState(`${props.formRedirection}?id=${props.rowpos}`) 
    return(

<CardGroup className='crdGrp tracking-in-expand-fwd-top'>
  <Card className='crdBody'>
    {/* <CardImg
      alt={props.alt}
      src="https://picsum.photos/318/180"
      top
      width="100%"
    /> */}
    <CardBody className='myshadowcard'>
      <CardTitle tag="h6">
      {props.id}
      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted mytitle"
        tag="h5"
      >
       {props.name}
      </CardSubtitle>
      <hr className="cardLine m-2"/>
      <CardText className='text-start'>
      <a href="#"> {props.val}</a>
      </CardText>
   
    
      {/* <ButtonSuccess btnTitle="Add"/> */}
      <ButtonEdit btnTitle="Edit" href={redirectionForm}/>
      <ButtonDelete btnTitle="Delete"usehtmlFor="head"rowpos={props.rowpos}onDelete={props.onDelete}/>
    </CardBody>
  </Card>
</CardGroup>
    )
}
export default HeadCard;