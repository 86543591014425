import React, { useState, useEffect } from 'react';
import './generateSallary.css';
import { GET_EMP_CHANGES_BY_MCODE, ALLOWED_USERTYPE, GET_OLD_SRY_BY_ID, GET_RMBSMT_ID, GET_PERK_ID, GET_DynamicSlary_ID, GENERATE_SLY } from '../../../../utiles/AllApis';
import EmpSearch from '../../../../common/EmpSearch/EmpSearch';
import axios from 'axios';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
export default function GenerateSalary(props) {
  const [isLoading, setLoading] = useState(false);
  const [uType, setUType] = useState(props.tokectDetails.logintype);
  const [selecedUserID, setSelectedUserID] = useState(uType === ALLOWED_USERTYPE.ADMIN ? "" : props.tokectDetails.mcode);
  const [empSlry, setEmpSlry] = useState([]);
  const [empRimbsmet, setEmpRimbsmet] = useState([]);
  const [empPerk, setEmpPerk] = useState([]);
  const [dynamic, setDynamic] = useState([]);
  const [changesdata, setChangesdata] = useState([]);
  const [changeTotal, setChangeTotal] = useState(0);
  const [spaybleTotal, setPaybleTotal] = useState(0);
  const [rowpos, setRowpos] = useState(0);
  const [proSlary, setProSlary] = useState(false);
  useEffect(() => {
    getSlryDetails();
    getRmbsmetDetails();
    getPerkDetails();
    getDynamicData();
    getDynamichangeData();
  }, []);
  //----------------GET dynamic DETAILS--------------
  const getDynamicData = () => {
    setLoading(true);
    axios.post(GET_DynamicSlary_ID, { "EmployeeID": props.userselected }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setDynamic(response.data.data);
        setPaybleTotal(response.data.data[0].payBleSlry);
        setRowpos(response.data.data[0].rowpos);
        setProSlary(response.data.data[0].isSlryPro);
      }
      else {
        setDynamic([]);
        setPaybleTotal(0);
        setRowpos("");

      }

    })
  }
  //----------------GET dynamic change  DETAILS--------------
  const getDynamichangeData = () => {
    setLoading(true);
    axios.post(GET_EMP_CHANGES_BY_MCODE, {
      "EmployeeID": props.userselected
    }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setChangesdata(response.data.data);
        setChangeTotal(response.data.data[0].total);
      }
      else {
        setChangesdata([]);
        setChangeTotal(0);
      }
    })
  }
  //----------------GET SALARY DETAILS--------------
  const getSlryDetails = () => {
    setLoading(true);
    axios.post(GET_OLD_SRY_BY_ID, { "EmployeeID": props.userselected }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setEmpSlry(response.data.data);
      }
      else {
        setEmpSlry([]);
      }

    })
  }
  //----------------GET REIMBURSMENT DETAILS--------------
  const getRmbsmetDetails = () => {
    setLoading(true);
    axios.post(GET_RMBSMT_ID, { "EmployeeID": props.userselected }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setEmpRimbsmet(response.data.data);
      }
      else {
        setEmpRimbsmet([]);
      }

    })
  }
  //----------------GET PERK DETAILS--------------
  const getPerkDetails = () => {
    setLoading(true);
    axios.post(GET_PERK_ID, { "EmployeeID": props.userselected }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setEmpPerk(response.data.data);
      }
      else {
        setEmpPerk([]);
      }

    })
  }
  const onHandleGenerateSly = () => {
    setLoading(true);
    axios.post(GENERATE_SLY, { "EmployeeID": props.userselected, "PayBleSlry": spaybleTotal, "ROWPOS": rowpos }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setEmpPerk(response.data.data);
        getSlryDetails();
        getDynamicData();
      }
      else {
        setEmpPerk([]);
      }
    })
  }
  return (
    <>
      {
        isLoading ? <div className='loaderDiv'>
        </div> : null
      }
      <div className='emp-aboutdetailmaincontent'>
        <div className={uType === ALLOWED_USERTYPE.ADMIN ? 'mnusTopMrgn' : ''}>
          <h5 className='text-white fw-bold mt-2 text-center'>Generate Salary</h5>
          <hr className='myhr text-white' />
        </div>
        <ArrowBackSharpIcon className="text-white cursor-pointer" onClick={e=>window.location.replace("/employeeprofile/mnthalyData")}>
                        Go Back
                      </ArrowBackSharpIcon>
        {

          empSlry.length > 0 ?
            <div className={`${uType === ALLOWED_USERTYPE.ADMIN ? "tblSly" : "tblSlyFull"} table-responsive mt-3`}>
              <table className="table table-bordered border-secondary ">
                <thead className='tblHeader'>
                  <tr>
                    {empSlry.filter((key, i) => i === 0).map((data, i) => {
                      return (
                        Object.keys(data?.colName).map((c, i) =>
                          <td>{c}</td>
                        )
                      );
                    })
                    }
                  </tr>
                </thead>
                <tbody className='tblBdy'>
                  {
                    empSlry.map((data, i) => {
                      return (<><tr key={i}>
                        {Object.keys(data?.colName).map((c, i) =>
                          <td>{data?.colName[c]}</td>
                        )}
                      </tr>
                      </>);
                    }
                    )
                  }
                </tbody>
              </table>
            </div>
            :
            <p className='text-white text-center mt-5'>----No Data Found----</p>
        }
        <h5 className='text-white fw-bold mt-2 text-center'>Reimbursment</h5>
        <hr className='myhr text-white' />
        {

          empRimbsmet.length > 0 ?
            <div className={`${uType === ALLOWED_USERTYPE.ADMIN ? "tblSly" : "tblSlyFull"} table-responsive mt-3`}>
              <table className="table table-bordered border-secondary ">
                <thead className='tblHeader'>
                  <tr>
                    {empSlry.filter((key, i) => i === 0).map((data, i) => {
                      return (
                        Object.keys(data?.colName).map((c, i) =>
                          <td>{c}</td>
                        )
                      );
                    })
                    }
                  </tr>
                </thead>
                <tbody className='tblBdy'>
                  {
                    empRimbsmet.map((data, i) => {
                      return (<><tr key={i}>
                        {Object.keys(data?.colName).map((c, i) =>
                          <td>{data?.colName[c]}</td>
                        )}
                      </tr>
                      </>);
                    }
                    )
                  }
                </tbody>
              </table>
            </div>
            :
            <p className='text-white text-center mt-5'>----No Data Found----</p>
        }
        <h5 className='text-white fw-bold mt-2 text-center'>Perk</h5>
        <hr className='myhr text-white' />
        {
          empPerk.length > 0 ?
            <div className={`${uType === ALLOWED_USERTYPE.ADMIN ? "tblSly" : "tblSlyFull"} table-responsive mt-3`}>
              <table className="table table-bordered border-secondary ">
                <thead className='tblHeader'>
                  <tr>
                    {empPerk.filter((key, i) => i === 0).map((data, i) => {
                      return (
                        Object.keys(data?.colName).map((c, i) =>
                          <td>{c}</td>
                        )
                      );
                    })
                    }
                  </tr>
                </thead>
                <tbody className='tblBdy'>
                  {
                    empRimbsmet.map((data, i) => {
                      return (<><tr key={i}>
                        {Object.keys(data?.colName).map((c, i) =>
                          <td>{data?.colName[c]}</td>
                        )}
                      </tr>
                      </>);
                    }
                    )
                  }
                </tbody>
              </table>
            </div>
            :
            <p className='text-white text-center mt-5'>----No Data Found----</p>
        }
      </div>
      <div className='row'>
        <div className='col-sm-4 col-md-4 col-4 gridBorder'>
          <h5 className='SecoHead text-center mt-2'>Original </h5>
          <hr className='myhr text-white' />
          {changesdata.length > 0 ?
            changesdata.filter((key, i) => i === 0).map((data, i) => {
              return (
                Object.keys(data?.colName).map((c, i) =>
                  <h6 className='text-white'>{c} :- {data?.colName[c]}</h6>
                )
              );
            })
            : ""
          }
        </div>
        <div className='col-sm-4 col-md-4 col-4 gridBorder'>
          <h5 className='SecoHead text-center mt-2'>Actual</h5>
          <hr className='myhr text-white' />
          {dynamic.length > 0 ?
            dynamic.filter((key, i) => i === 0).map((data, i) => {
              return (
                Object.keys(data?.colName).filter((key, i) => key.charAt(0) === "O").map((c, i) =>
                  <h6 className='text-white'>{c.slice(1)} :- {data?.colName[c]}</h6>
                )
              );
            })
            : ""
          }
        </div>
        <div className='col-sm-4 col-md-4 col-4 gridBorder'>
          <h5 className='SecoHead text-center mt-2'>Total</h5>
          <h6 className='text-white text-center'> {changeTotal}</h6>
          <hr className='myhr text-white' />
          <h5 className='SecoHead text-center mt-2'>Total Payable</h5>
          <h6 className='text-white text-center'> {spaybleTotal}</h6>
          <hr className='myhr text-white' />
          <div className='text-center'>
            {
              !proSlary ?
                <button className='btn btn-success text-center' onClick={onHandleGenerateSly}>Done</button>
                :<h6 className="text-success">---Salary Already Processed---</h6>
            }
          </div>
        </div>
      </div>
    </>
  )
}
