import React, { useEffect, useState, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
//import * as filServer from 'file-server';
import XLSX from 'sheetjs-style';
import {
  useParams, Link, useNavigate, useLocation
} from "react-router-dom";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios';
import Costsheetdefault from "../../assets/Element_Sheet.xlsx";
import complianceSheet from "../../assets/Compliance_Sheet.xlsx";
import {
  GET_DOCUMENT, GET_DOCUMENTBYID, UPDATE_CLIENT_CONTACT_SHEET, GET_PRO_DOCUMENT, TENDER_STATE, GET_INDENT_PR, GET_PRO_CLIENT_CONTACT, GET_CLIENT, GET_COMPLIANCE_EXCEL_BY_PROJECT,
  CREATE_CLIENT_CONTACT_SHEET, CREATE_DOCUMENT, GET_PROJECTBYID, ALLOWED_USERTYPE
} from '../../utiles/AllApis';
import ErrorAlertBox from '../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../common/AlertBox/SuccessAlertBox';
import ProjectInfo from './Component/ProjectInfo';
import ExcelUpload from '../../common/excelUpload/Main';
import './project.css';
import Select from 'react-select';
import { onlynumerical } from "../../GenericFunction/GenericFunction";
const ProDetails = (props) => {
  const inputRef = useRef(null);
  const tableRef = useRef(null);
  const [heading, setHeading] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const { projectName } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [isCompliance, setIsCompliance] = useState(false);
  const [isProName, setIsProName] = useState(false);
  const [documents, setdocuments] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [eligibilityDoc, setEligibilityDoc] = useState([]);
  const [complianceList, setComplianceList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [ProjectDocList, setProjectDocList] = useState([]);
  const [cltName, setCltName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [clt, setClt] = useState("");
  const [data, setdata] = useState([]);
  const [clientSeach, setClientSeach] = useState("");
  const [costSheet, setCostSheet] = useState([]);
  const [intrested, setintrested] = useState(true);
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenErr, setIsOpenErr] = useState(false);
  const [selectedExcelType, setSelectedExcelType] = useState("");
  const [file, setFile] = useState("");
  const [pdfLen, setPdfLen] = useState(0);
  const [won, setwon] = useState(false);
  const [errEmail, setErrorEmail] = useState("");
  const [enableOprt, setEnableOprt] = useState(false)
  const [rowpos, setRowpos] = useState(null);
  const [mcode, setMcode] = useState(null);
  const [projectNamee, setProjectName] = useState(null);
  const [sheet, SetSheets] = useState(false)
  const [documentimg, setdocumentimg] = useState([]);
  const [secSepShow, setsecSepShow] = useState(false); // we need to change  this state 
  const [totalPRAmt, setTotalPRAmt] = useState(0);
  const [docList, seDocList] = useState([]);
  const [ProductRiderData, setProductRiderData] = useState([]);
  // const [list1,setList1]=useState([]);
  const [eligibilityDocView, setEligibilityDocView] = useState("");
  const [openEligibilityDoc, setOpenEligibilityDoc] = useState(false);
  const [proReject, setProjectRejection] = useState(false);
  // const [excelOpen,setExcelOpen]=useState(false);
  const handalOpenErr = () => { setIsOpenErr(true); }
  const handalOpen1 = () => { setIsOpen1(true); }
  const urlVal = "";
  var optClient = [];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport className="exBtn" />
      </GridToolbarContainer>
    );
  }
  const navigate = useNavigate();
  const location = useLocation();
  const [clientData, setClientData] = useState({
    clientName: "",
    mobile: "",
    mcode: "",
    email: "",
    remarks: "",
    designation: "",
    contactName: "",
    ProId: projectName


  });
  useEffect(() => {
    getProjBtId();
    getProdRider();
    getCompiliance();
  }, [isReload]);
  useEffect(() => {

    getAllClientList();
  }, []);
  const onHandleChangeSearch = (e, name) => {
    setCltName(e.value);

  }
  //---------Get all client ------------
  const getAllClientList = () => {
    var config = {
      method: "get",
      url: GET_CLIENT,
    };
    axios(config)
      .then(function (response) {
        if (response.data.isSuccess === true) {
          //setdropdownclient(response.data.data);
          response?.data?.data?.map((data, i) => {
            optClient.push({ value: data.mcode, label: data.clientName })
          })
          setClientSeach(<Select options={optClient} onChange={(e) => onHandleChangeSearch(e, "CLIENT_MCODE")} className='w-75' defaultValue={clt} />)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const reloadFun = (type) => {
    getProjBtId();
    setexcelUopload(type);
    getCompiliance();
  }
  const getProjBtId = () => {
    var config = {
      method: 'post',
      url: GET_PROJECTBYID,
      headers: {
        'Content-Type': 'application/json'
      },
      data: { "ROWPOS": projectName }
    };
    const ProjectArray = [];
    setLoading(true);
    axios(config)
      .then(function (response) {
        setProjectRejection(response.data.data[0].reject);
        setLoading(false);
        if (response.data.isSuccess === true) {
          setCostSheet(data[0]?.costSheet);
          var oleEDate = `${new Date(response.data.data[0].eventDate).getFullYear()}/${new Date(response.data.data[0].eventDate).getMonth() + 1}/${new Date(response.data.data[0].eventDate).getDate()}`;
          if (oleEDate === "1990/1/1") {
            response.data.data[0].eventDate = "--NA--";
          }
          else {
            response.data.data[0].eventDate = `${new Date(response.data.data[0].eventDate).toLocaleString([], { weekday: 'short' })} ${new Date(response.data.data[0].eventDate).toLocaleString([], { day: '2-digit' })} ${new Date(response.data.data[0].eventDate).toLocaleString([], { month: 'short' })}`;
          }
          var oleeventToDate = `${new Date(response.data.data[0].eventToDate).getFullYear()}/${new Date(response.data.data[0].eventToDate).getMonth() + 1}/${new Date(response.data.data[0].eventToDate).getDate()}`;
          if (oleeventToDate === "1990/1/1") {
            response.data.data[0].eventToDate = "--NA--";
          }
          else {
            var a = `${new Date(response.data.data[0].eventToDate).toLocaleString([], { weekday: 'short' })}`;
            response.data.data[0].eventToDate = `${new Date(response.data.data[0].eventToDate).toLocaleString([], { weekday: 'short' })} ${new Date(response.data.data[0].eventToDate).toLocaleString([], { day: '2-digit' })} ${new Date(response.data.data[0].eventToDate).toLocaleString([], { month: 'short' })}`;
          }
          var olelDateOfPreBidQueries = `${new Date(response.data.data[0].lDateOfPreBidQueries).getFullYear()}/${new Date(response.data.data[0].lDateOfPreBidQueries).getMonth() + 1}/${new Date(response.data.data[0].lDateOfPreBidQueries).getDate()}`;
          if (olelDateOfPreBidQueries === "1990/1/1") {
            response.data.data[0].lDateOfPreBidQueries = "--NA--";
          }
          var oleLastFillingDate = `${new Date(response.data.data[0].lastFillingDate).getFullYear()}/${new Date(response.data.data[0].lastFillingDate).getMonth() + 1}/${new Date(response.data.data[0].lastFillingDate).getDate()}`;
          if (oleLastFillingDate === "1990/1/1") {
            response.data.data[0].lastFillingDate = "--NA--";
          }
          var oleldateOfSubmissionBid = `${new Date(response.data.data[0].ldateOfSubmissionBid).getFullYear()}/${new Date(response.data.data[0].ldateOfSubmissionBid).getMonth() + 1}/${new Date(response.data.data[0].ldateOfSubmissionBid).getDate()}`;
          if (oleldateOfSubmissionBid === "1990/1/1") {
            response.data.data[0].ldateOfSubmissionBid = "--NA--";
          }
          var oleopenFinancialBids = `${new Date(response.data.data[0].openFinancialBids).getFullYear()}/${new Date(response.data.data[0].openFinancialBids).getMonth() + 1}/${new Date(response.data.data[0].openFinancialBids).getDate()}`;
          if (oleopenFinancialBids === "1990/1/1") {
            response.data.data[0].openFinancialBids = "--NA--";
          }
          var olepreBidMeetingDate = `${new Date(response.data.data[0].preBidMeetingDate).getFullYear()}/${new Date(response.data.data[0].preBidMeetingDate).getMonth() + 1}/${new Date(response.data.data[0].preBidMeetingDate).getDate()}`;
          if (olepreBidMeetingDate === "1990/1/1") {
            response.data.data[0].preBidMeetingDate = "--NA--";
          }
          var oletechnicalBidOpenDate = `${new Date(response.data.data[0].technicalBidOpenDate).getFullYear()}/${new Date(response.data.data[0].technicalBidOpenDate).getMonth() + 1}/${new Date(response.data.data[0].technicalBidOpenDate).getDate()}`;
          if (oletechnicalBidOpenDate === "1990/1/1") {
            response.data.data[0].technicalBidOpenDate = "--NA--";
          }
          var olepresentationDate = `${new Date(response.data.data[0].presentationDate).getFullYear()}/${new Date(response.data.data[0].presentationDate).getMonth() + 1}/${new Date(response.data.data[0].presentationDate).getDate()}`;
          if (olepresentationDate === "1990/1/1") {
            response.data.data[0].presentationDate = "--NA--";
          }

          if (response.data.data[0].won === true) {
            setsecSepShow(true);
          }
          response?.data?.data?.map((data, i) => {

            ProjectArray.push(data);
          })
        }
        else {

          SetSheets(true)
          setdocuments(false);

        }
        var isintrested = response.data.data[0].interested;
        const pdf_doc = response.data.data[0].lst_documents.filter((data, i) => {
          if (data.type === "MERGED_FILE") {
            return data;
          }

        })
        setPdfLen(pdf_doc.length);
        const li_doc = response.data.data[0].lst_documents.filter((data, i) => {
          if (data.type === "COMPANY_DOCUMENT" || data.type === "WORKORDER_DOCUMENT" || data.type === "PRESENTATION_DOCUMENT" || data.type === "EXTRA_DOCUMENT") {
            return data;
          }

        })
        setProjectDocList([...li_doc, ...response.data.data[0].lst_CompanyDocuments, ...response?.data?.data[0]?.lst_InternalDocuments]);
        if (isintrested === true) {
          setintrested(false);
          SetSheets(true);
        }
        else {
          setintrested(true);
        }
        var won = response.data.data[0].won;
        if (won === true && isintrested === true) {
          setwon(false);
        }
        else {
          setwon(true);
        }
        setdata(response.data.data);
        setdocumentimg(response.data.data[0]?.generic_docs);
        setEligibilityDoc(response.data.data[0]?.eligibility_docs[response.data.data[0]?.eligibility_docs.length - 1]);

      })
      .catch(function (error) {
        console.log(error);
      });
  }



  const getProdRider = async () => {
    setLoading(true);
    await axios.post(GET_DOCUMENTBYID, { "Project_ID": projectName })
      .then((response) => {
        setLoading(false);
        if (response.data.isSuccess) {
          const filteredData = response.data.data.filter(data => data.path.includes('ELEMENT_SHEET'));
          setProductRiderData(filteredData);
          if (filteredData.length > 0) {
            console.log(filteredData);
          } else {
            console.log('No data includes ELEMENT_SHEET');
          }
        } else {
          setProductRiderData([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error fetching data:', error);
        setProductRiderData([]);
      });
  };

  //-------------get PRod rider by id-----------
  // const getProdRider=()=>
  // {
  //   var config = {
  //     method: 'POST',
  //     url: GET_INDENT_PR,
  //     headers: { 
  //       'Content-Type': 'application/json'
  //     },
  //     data:JSON.stringify({
  //       "Project_ID":projectName
  //     })
  //   };
  //   // setLoading(true);
  //   axios(config)
  //   .then(function (response) {
  //     // setLoading(false);
  //     if(response.data.isSuccess==true)
  //     {
  //       setProductRiderData(response.data.data);
  //       var TotalPRAmt=0;
  //       response?.data?.data?.map((data,i)=>
  //       {
  //         TotalPRAmt=TotalPRAmt+data.amt;
  //       })
  //       setTotalPRAmt(TotalPRAmt);
  //     } // NOW MAP THIS DATA INTO TABLE ABOVE QUESTION AND ANSWER SECTION
  //     else
  //     {
  //       setProductRiderData([]);
  //     }
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  // }
  //-------------get on site Compiliance by prop id-----------
  const getCompiliance = () => {
    var config = {
      method: 'POST',
      url: GET_COMPLIANCE_EXCEL_BY_PROJECT,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        "Pro_id": projectName
      })
    };
    // setLoading(true);
    axios(config)
      .then(function (response) {
        if (response.data.isSuccess) {
          setComplianceList(response.data.data);
        }
        else {
          setComplianceList([]);

        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const setexcelUopload = (type) => {
    setSelectedExcelType(type);
    if (type === "COMPLIANCE" || type === "COMPLETION_CERTIFICATE" || type === "TESTIMONIALS" || type === "ON_SITE_COMPLIANCE") {
      setIsCompliance(true);
      setIsProName(false);
    }
    else if (type === "PRO_NAME" || type === "RESULT_OF_TECH_BIDDING" || type === "RESULT_OF_FINC_BIDDING") {
      setIsProName(true);
      setIsCompliance(false);
    }
    else {
      setIsCompliance(false);
      setIsProName(false);
    }
    if (type === "PROD_RDR" || type == "RESPONSIBILITY_SHEET") {
      getProdRider();
    }
    else if (type == "CLIENT_CONTACT_SHEET") {
      getProClient();
    }
    else {
      getProDoc(type);
    }
  }
  //SEND TO PREVIOUE PAGE
  const handleGoToPrv = (e) => {
    navigate(-1);
  }
  //-----------------validate file forment----------------
  const validateFile = (file, fType) => {

    var isValidate = false;
    var fileExt = file.name.split(".")[file.name.split(".").length - 1].toUpperCase();
    if (fType === "RESEARCH") {
      if (fileExt === "DOC" || fileExt === "DOCX" || fileExt === "PPTX" || fileExt === "PDF" || fileExt === "JPG" || fileExt === "XLSX" || fileExt === "ODS" || fileExt === "CSV" || fileExt === "XLS") {
        isValidate = true;
      }
    }
    else if (fType === "DRAFT_PRESENTATION" || fType === "FINAL_PRESENTATION") {
      if (fileExt === "PPTX" || fileExt === "PDF") {
        isValidate = true;
      }
    }
    else if (fType === "PROD_RDR" || fType === "CREATIVE_SHEET" || fType === "RESPONSIBILITY_SHEET" || fType === "CLIENT_CONTACT_SHEET"
      || fType === "INTERNAL_AUDIT_REPOR" || fType === "ON_SITE_COMPLIANCE" || fType === "SYNOPSIS") {
      if (fileExt === "XLSX" || fileExt === "ODS" || fileExt === "CSV" || fileExt === "XLS") {
        isValidate = true;
      }
    }
    else if (fType === "COMPLETION_CERTIFICATE" || fType === "TESTIMONIALS") {
      if (fileExt === "PDF" || fileExt === "JPG" || fileExt === "JPEG") {
        isValidate = true;
      }
    }
    else if (fType === "INTERNAL_REVIEW") {
      if (fileExt === "DOC" || fileExt === "DOCX") {
        isValidate = true;
      }
    }
    else if (fType === "RESULT_OF_TECH_BIDDING" || fType === "RESULT_OF_FINC_BIDDING") {
      if (fileExt === "JPG" || fileExt === "JPEG" || fileExt === "XLSX" || fileExt === "ODS" || fileExt === "CSV" || fileExt === "XLS") {
        isValidate = true;
      }
    }
    return isValidate;
  }
  //----------------get file from input box----------------
  const handleGetFile = () => {
    if (file === "") {
      setHeading("Submission Failed!");
      setErrMsg("Please select file!");
      handalOpenErr();
    }
    else {

      if (!validateFile(file, selectedExcelType)) {
        setHeading("Submission Failed!");
        setErrMsg("Plase select valid file.");
        handalOpenErr();
      }
      else {
        //-----upload doc-----
        uploadDoc(selectedExcelType);
      }
      inputRef.current.value = null;
    }
  }
  //----------------upload files------------------------
  const uploadDoc = async (type) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", file);
    data.append("ID", projectName);
    data.append("SubType", `${type}`);
    data.append("name", `${projectName}_${type}`);
    data.append("Type", "PROJECT_DOCUMENT");

    var config = {
      method: "post",
      url: CREATE_DOCUMENT,

      data: data,
    };
    setLoading(true);
    await axios(config)
      .then(async function (response) {
        setLoading(false);
        setHeading1("Success");
        setsuccessMsg("Document Add Successfully");
        handalOpen1();
        setFile("");
        //-----get doc-----
        getProDoc(selectedExcelType);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //--------------get all pro document------------------
  const getProDoc = (type) => {
    var config = {
      method: "post",
      url: GET_PRO_DOCUMENT,
      data: {
        "ROWPOS": projectName,
        "Type": type
      },
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        seDocList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //---------get and set client details-----------------
  const handleClient = (e) => {
    if (e.target.id === "clientEmail") {
      let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (e.target.value.match(emailValidate)) {
        setErrorEmail("");
      } else {
        setErrorEmail("* Please enter valid Email ID");
        //e.target.value="";
      }
    }
    setClientData({ ...clientData, [e.target.name]: e.target.value })
  }

  const handleEdit = (row) => {
    // setCltName(row.value);

    setClientData({
      clientName: row.clientName,
      mobile: row.mobile,

      email: row.email,
      remarks: row.remarks,
      designation: row.designation,
      contactName: row.contactName,

    });
    setSelectedUserID(row.id);
    setRowpos(row.rowpos)

    setIsEdit(true)
  }



  const handleUpdate = () => {

    setLoading(true);

    setIsEdit(false);

    axios.post(UPDATE_CLIENT_CONTACT_SHEET, {
      ...clientData,
      mcode: cltName,
      ROWPOS: rowpos // Ensure rowpos is defined
    }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess) {
        window.location.reload();

      } else {
        // Handle error scenario
        // For example, display error message
      }
    }).catch((error) => {
      setLoading(false);
      // Handle error scenario
    });

  }

  const handleViewClick = (path) => {
    window.open(path, '_blank');
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert '0' to '12'
    return `${formattedHours}:${minutes} ${ampm}`;
  }

  const refreshdata = selectedExcelType === "PROD_RDR" ? getProdRider : null;


  const handleExcelUpload = (type) => {
    setSelectedExcelType(type);
    setEnableOprt(false);
    // Optionally fetch data or handle additional logic
  };


  //------------------create client---------------------
  const createClient = () => {
    setIsEdit(false)
    if (cltName === "") {
      setHeading("Submission Failed!");
      setErrMsg("Fields Cannot Empty");
      handalOpenErr();
      return;
    }
    if (clientData.mobile !== "") {
      if (clientData.mobile.length < 9) {
        setHeading("Submission Failed!");
        setErrMsg("Mobile No cannot less then 10 digit");
        handalOpenErr();
        return;
      }
    }

    if (errEmail !== "") {
      setHeading("Submission Failed!");
      setErrMsg("Invalid Email format");
      handalOpenErr();
      return;
    }
    setLoading(true);
    axios.post(CREATE_CLIENT_CONTACT_SHEET,
      {
        mobile: clientData.mobile,
        mcode: cltName,
        email: clientData.email,
        remarks: clientData.remarks,
        designation: clientData.designation,
        contactName: clientData.contactName,
        ProId: projectName

      }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setHeading1("Success");
          setsuccessMsg("client Add Successfully");
          handalOpen1();
          setClt("");
          setClientData({
            mobile: "",
            mcode: "",
            email: "",
            remarks: "",
            designation: "",
            contactName: "",
            ProId: projectName
          })
          getProClient();
        } else {
          setHeading("Creation Failed!");
          setErrMsg(response.data.message);
          handalOpenErr();
        }
      });
  }
  //----------GET PROJECT CLIENT-----------
  const getProClient = () => {
    setLoading(true);
    axios.post(GET_PRO_CLIENT_CONTACT, { "ProId": projectName }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setClientList(response.data.data);
      } else {
        setClientList([]);
      }
    });
  }
  //------------------export to excel----------------
  const excporttoExcel = (tablId) => {
    var data = document.getElementById(`${tablId}`);
    var fp = XLSX.utils.table_to_book(data, { sheet: 'sheet1' });
    XLSX.write(fp, {
      bookType: 'xlsx',
      type: 'base64'
    })
    XLSX.writeFile(fp, `${projectName}_${selectedExcelType}_Sheet.xlsx`);
  }
  return (
    <div className='row'>
      {
        isLoading ? <div className='loaderDiv'>
        </div> : null
      }
      <div className={`${props.tokectDetails?.logintype !== ALLOWED_USERTYPE.ADMIN
        ? 'col-md-12 col-12 col-sm-12 '
        : 'col-md-10 col-10 col-sm-10'} myview`}>
        <div className='px-4 '>
          <span className="cursor-pointer"><a className="mt-5" href="#" onClick={e => { handleGoToPrv(e) }}>Go Back</a></span>
          <ProjectInfo project={data[0]} tokectDetails={props.tokectDetails} />
          {data[0]?.state === TENDER_STATE.REJECTED ? <div className='text-center'><span className='h5 colorSec'>Reason for Reject : </span><span className=' p text-white'>{data[0]?.remarks}</span></div> : null}
          <div className='text-center mt-2'>
            {
              data[0]?.state === TENDER_STATE.FILING ?
                <>    <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "RESEARCH" ? "activeCls" : null}`} onClick={() => { setexcelUopload("RESEARCH"); setEnableOprt(false); }}>RESEARCH</button>
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "DRAFT_PRESENTATION" ? "activeCls" : null}`} onClick={() => { setexcelUopload("DRAFT_PRESENTATION"); setEnableOprt(false); }}>DRAFT PRESENTATION</button>
                  <button className={`newShdBtn px-3 mx-1  ${selectedExcelType === "FINAL_PRESENTATION" ? "activeCls" : null}`} onClick={() => { setexcelUopload("FINAL_PRESENTATION"); setEnableOprt(false); }}>FINAL PRESENTATION</button></>
                : ""
            }
            {
              data[0]?.state === TENDER_STATE.RUNNING ?
                <>
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "PROD_RDR" ? "activeCls" : null}`} onClick={() => { setexcelUopload("PROD_RDR"); setEnableOprt(false); }}>ELEMENT SHEET</button>
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "CREATIVE_SHEET" ? "activeCls" : null}`} onClick={() => { setexcelUopload("CREATIVE_SHEET"); setEnableOprt(false); }}>CREATIVE SHEET</button>
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "RESPONSIBILITY_SHEET" ? "activeCls" : null}`} onClick={() => { setexcelUopload("RESPONSIBILITY_SHEET"); setEnableOprt(false); }}>RESPONSIBILITY SHEET</button>
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "CLIENT_CONTACT_SHEET" ? "activeCls" : null}`} onClick={() => { setexcelUopload("CLIENT_CONTACT_SHEET"); setEnableOprt(false); }}>CLIENT CONTACT SHEET</button>
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "SYNOPSIS" ? "activeCls" : null}`} onClick={() => { setexcelUopload("SYNOPSIS"); setEnableOprt(false); }}>SYNOPSIS</button>
                </>
                : ""
            }
            {
              data[0]?.state === TENDER_STATE.COMPLETED ?
                <>
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "COMPLIANCE" || isCompliance ? "activeCls" : null}`} onClick={() => { setexcelUopload("COMPLIANCE") }}>COMPLIANCE</button>
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "INTERNAL_AUDIT" ? "activeCls" : null}`} onClick={() => { setexcelUopload("INTERNAL_AUDIT"); setEnableOprt(false); }}>INTERNAL AUDIT REPORT</button></>
                : ""
            }
            {
              data[0]?.state === TENDER_STATE.REJECTED ?
                <><button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "PRO_NAME" ? "activeCls" : null}`} onClick={() => { setexcelUopload("PRO_NAME"); setEnableOprt(false); }}>PROJECT NAME</button>
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "REASONS" ? "activeCls" : null}`} onClick={() => { setexcelUopload("REASONS"); setEnableOprt(false); }}>REASONS</button>
                  <button className={`newShdBtn px-3  mx-1 ${selectedExcelType === "INTERNAL_REVIEW" ? "activeCls" : null}`} onClick={() => { setexcelUopload("INTERNAL_REVIEW"); setEnableOprt(false); }}>INTERNAL REVIEW</button></>
                : ""
            }
            {
              isCompliance ?
                <>
                  <div className='mt-4' />
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "ON_SITE_COMPLIANCE" ? "activeCls" : null}`} onClick={() => { setexcelUopload("ON_SITE_COMPLIANCE") }}>ON SITE COMPLIANCE</button>
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "COMPLETION_CERTIFICATE" ? "activeCls" : null}`} onClick={() => { setexcelUopload("COMPLETION_CERTIFICATE") }}>COMPLETION CERTIFICATE</button>
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "TESTIMONIALS" ? "activeCls" : null}`} onClick={() => { setexcelUopload("TESTIMONIALS") }}>TESTIMONIALS</button>
                </>
                : ""
            }
            {
              isProName ?
                <>
                  <div className='mt-4' />
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "RESULT_OF_TECH_BIDDING" ? "activeCls" : null}`} onClick={() => { setexcelUopload("RESULT_OF_TECH_BIDDING") }}>RESULT OF TECHNICAL BIDDING</button>
                  <button className={`newShdBtn px-3 mx-1 ${selectedExcelType === "RESULT_OF_FINC_BIDDING" ? "activeCls" : null}`} onClick={() => { setexcelUopload("RESULT_OF_FINC_BIDDING") }}>RESULT OF FINANCIAL BIDDING</button>
                </>
                : ""
            }
            {
              selectedExcelType !== "" ?
                selectedExcelType === "PROD_RDR" || selectedExcelType === "RESPONSIBILITY_SHEET" || selectedExcelType === "INTERNAL_AUDIT" || selectedExcelType === "ON_SITE_COMPLIANCE" ?
                  <>
                    <ExcelUpload
                      refreshdata={refreshdata}
                      title={selectedExcelType === "PROD_RDR" ? "Upload Element Sheet" : selectedExcelType === "INTERNAL_AUDIT" ? "Upload Internal Report" : selectedExcelType === "ON_SITE_COMPLIANCE" ? "Upload on site Compliance" : "Upload Responsibility Sheet"}
                      dwnPath={selectedExcelType === "ON_SITE_COMPLIANCE" ? complianceSheet : Costsheetdefault} ProjectId={projectName} reloadFun={reloadFun} for={selectedExcelType} setLoading={setLoading} onUploadSuccess={(type) => handleExcelUpload(type)} />
                    {ProductRiderData?.length > 0 && selectedExcelType === "RESPONSIBILITY_SHEET" ?
                      <button className='newSuccBtn px-2 my-2' onClick={e => { excporttoExcel("rspsblSheet"); }}> Export excel </button>
                      : ""
                    }

                    {ProductRiderData?.length > 0 && (selectedExcelType === "PROD_RDR" || selectedExcelType === "RESPONSIBILITY_SHEET") ?
                      <div className='table-responsive mt-3 emgncy'>
                        <table className="table table-bordered border-secondary tblHeader" id="rspsblSheet" ref={tableRef}>
                          <thead className='tblHeader'>
                            <tr>
                              <th scope="col text-white">Rowpos</th>
                              <th scope="col text-white" className={selectedExcelType !== "RESPONSIBILITY_SHEET" ? "d-none" : ""}>Responsive</th>
                              <th scope="col text-white">Name</th>
                              <th scope="col text-white">Created At</th>
                              <th scope="col text-white">Create Time</th>

                              <th scope="col text-white">Created By</th>

                              <th scope="col text-white">View  </th>
                            </tr>
                          </thead>
                          <tbody className='tblBdy'>
                            {
                              ProductRiderData?.map((data, i) => {
                                return (<tr key={i}>
                                  <th scope="row">{data.rowpos}</th>
                                  <td className={selectedExcelType !== "RESPONSIBILITY_SHEET" ? "d-none" : ""}>{data.responsive}</td>
                                  <td>{data.name}</td>
                                  <td>{data.edate.split("T")[0]}</td>
                                  <td>{formatTime(data.edate.split("T")[1])}</td>
                                  <td>{data.euser}</td>
                                  <button
                                    className='btn btn-success'
                                    onClick={() => handleViewClick(data.path)}
                                  >
                                    View
                                  </button>

                                </tr>);
                              }
                              )
                            }

                          </tbody>
                        </table>
                      </div>
                      :
                      ""
                    }
                    {
                      complianceList?.length > 0 && selectedExcelType === "ON_SITE_COMPLIANCE" ?
                        <> <button className='newSuccBtn px-2 my-2' onClick={e => { excporttoExcel("complianceSheet"); }}> Export excel </button>
                          <div className='table-responsive mt-3 emgncy'>
                            <table className="table table-bordered border-secondary tblHeader" id="complianceSheet">
                              <thead className='tblHeader'>
                                <tr>
                                  <th scope="col text-white">Rowpos</th>
                                  <th scope="col text-white">Pro_Id</th>
                                  <th scope="col text-white">Contract_Term</th>
                                  <th scope="col text-white">Status</th>
                                  <th scope="col text-white">Date</th>
                                  <th scope="col text-white">Remarks</th>
                                </tr>
                              </thead>
                              <tbody className='tblBdy'>
                                {
                                  complianceList?.map((data, i) => {
                                    return (<tr key={i}>
                                      <th scope="row">{data.rowpos}</th>
                                      <th>{projectName}</th>
                                      <td>{data.contracT_TERM === "" ? "--NA--" : data.contracT_TERM}</td>
                                      <td>{data.status === "" ? "--NA--" : data.status}</td>
                                      <td>{data?.date?.split("T")[0]}</td>
                                      <td>{data.remarks === "" ? "--NA--" : data.remarks}</td>
                                    </tr>);
                                  }
                                  )
                                }

                              </tbody>
                            </table>
                          </div></>
                        : ""
                    }
                  </>
                  :
                  selectedExcelType === "CLIENT_CONTACT_SHEET" ?
                    <>
                      <div className='row mt-2 px-5 clieFrm'>
                        <div className="col-md-12 col-sm-12 col-12 my-3">
                          <h3
                            style={{
                              color: "#0f533d",
                              textTransform: "uppercase",
                              fontWeight: "900",
                              textAlign: "center",
                            }}
                            className="mb-2"
                          >
                            {" "}
                            <span className="text-white h4 text-capitalize mrglft-5"> Add Client Contact Sheet
                            </span>
                          </h3>
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 mt-3'>
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label  p"
                          >
                            Client Name<span className='required'>*</span>
                          </label>
                          {clientSeach}
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 mt-3'>
                          <label
                            htmlFor="contactName"
                            className="form-label  p"
                          >
                            Name of Person
                          </label>
                          <input
                            type="text"
                            className="form-control w-75 mt-2"
                            id="contactName"
                            name="contactName"
                            value={clientData?.contactName}
                            placeholder="Enter name of person Here..."
                            onChange={e => { handleClient(e) }}
                          />
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 mt-3'>
                          <label
                            htmlFor="mobile"
                            className="form-label  p"
                          >
                            Mobile Number
                          </label>
                          <input
                            type="text"
                            className="form-control w-75 mt-2"
                            id="mobile"
                            name="mobile"
                            maxLength="10"
                            value={clientData.mobile}
                            placeholder="Enter Employee Mobile Here..."
                            onChange={e => { handleClient(e) }}
                            onKeyPress={onlynumerical}

                          />
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 mt-3'>
                          <label
                            htmlFor="designation"
                            className="form-label  p "
                          >
                            Designation
                          </label>
                          <input
                            type="text"
                            className="form-control mt-2 w-75"
                            id="designation"
                            name="designation"
                            value={clientData.designation}
                            placeholder="Enter Employee Name Here..."
                            onChange={e => { handleClient(e) }}
                          />
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 mt-3'>
                          <label
                            htmlFor="clientEmail"
                            className="form-label  p"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control mt-2 w-75"
                            id="clientEmail"
                            name="email"
                            value={clientData.email}
                            placeholder="Enter Employee Name Here..."
                            onChange={e => { handleClient(e) }}
                          />
                          <span className="text-danger py-1 fw-bold">{errEmail}</span>
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 mt-2'>
                          <label
                            htmlFor="Remarks"
                            className="form-label p"
                          >
                            Remarks
                          </label>
                          <textarea className="form-control w-75" name="remarks" id="Remarks" placeholder="Enter Remarks Here..."
                            onChange={e => { handleClient(e) }} value={clientData.remarks}
                          ></textarea>
                        </div>
                        <div className='col-md-3 col-sm-3 col-3 '>

                          {
                            isEdit ?
                              <button className='newSuccBtn px-2 mt-5' onClick={handleUpdate} ><span>Update</span></button> :
                              <button className='newSuccBtn px-2 mt-5' onClick={createClient}  > Add </button>
                          }
                        </div>
                      </div>
                      {
                        clientList.length > 0 ?
                          <div className='table-responsive mt-3 emgncy'>
                            <table className="table table-bordered border-secondary">
                              <thead className='tblHeader'>
                                <tr>
                                  <th scope="col text-white">#</th>
                                  <th scope="col text-white">Created At</th>
                                  <th scope="col text-white">Client Name</th>
                                  <th scope="col text-white">Name Of Persom</th>
                                  <th scope="col text-white">Mobile Number</th>
                                  <th scope="col text-white">Designation</th>
                                  <th scope="col text-white">Email</th>
                                  <th scope="col text-white">Remarks</th>
                                  <th scope="col text-white">Edit</th>
                                </tr>
                              </thead>
                              <tbody className='tblBdy'>
                                {
                                  clientList?.map((data, i) => {
                                    return (<tr key={i}>
                                      <th scope="row">{i + 1}</th>
                                      <td>{data?.edate?.split('T')[0]}</td>
                                      <td>{data.clientName}</td>
                                      <td>{data.contactName}</td>
                                      <td>{data.mobile}</td>
                                      <td>{data.designation}</td>
                                      <td>{data.email}</td>
                                      <td>{data.remarks}</td>
                                      <td><button onClick={() => handleEdit(data)} className='btn btn-warning'>Edit</button></td>
                                    </tr>);
                                  }
                                  )
                                }
                              </tbody>
                            </table>
                          </div>
                          : ""
                      }
                    </>
                    :
                    selectedExcelType !== "COMPLIANCE" ?
                      <>
                        <input
                          type="file"
                          id="fileCost"
                          ref={inputRef}
                          onChange={(e) => setFile(e.target.files[0])}
                          style={{ padding: "10px" }}
                          className="text-white"
                        />
                        <button
                          className='newSuccBtn px-2'
                          onClick={handleGetFile}
                        >
                          Upload
                        </button>
                      </> : ""
                : null
            }
            {
              docList?.length > 0 && (selectedExcelType !== "CLIENT_CONTACT_SHEET" && selectedExcelType !== "RESPONSIBILITY_SHEET" && selectedExcelType !== "CREATIVE_SHEET" && selectedExcelType !== "PROD_RDR") ?
                <div className='table-responsive mt-3 emgncy'>
                  <table className="table table-bordered border-secondary">
                    <thead className='tblHeader'>
                      <tr>
                        <th scope="col text-white">#</th>
                        <th scope="col text-white">Created At</th>
                        <th scope="col text-white">Doc Id</th>
                        <th scope="col text-white">Doc Name</th>
                        <th scope="col text-white">Sub Type</th>
                        <th scope="col text-white">Path</th>
                      </tr>
                    </thead>
                    <tbody className='tblBdy'>
                      {
                        docList?.map((data, i) => {
                          return (<tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{data.edate?.split("T")[0]}</td>
                            <td>{formatTime(data.edate?.split("T")[1])}</td>
                            <td>{data.documentRowpos}</td>
                            <td>{data.name}</td>
                            <td>{data.type}</td>
                            <td><a target='_blank' href={data.path} className='lnk'>{data.path}</a></td>
                          </tr>);
                        }
                        )
                      }

                    </tbody>
                  </table>
                </div>
                : ""
              //  <p className='text-white text-center mt-5'>No Data Found!!</p>
            }

          </div>
        </div>
        <ErrorAlertBox isOpen={isOpenErr} setIsOpen={setIsOpenErr} heading={heading} errMsg={errMsg} />
        <SuccessAlertBox href={urlVal} isOpen1={isOpen1} setIsOpen1={setIsOpen1} heading1={heading1} successMsg={successMsg} />
      </div>
    </div>)


}
export default ProDetails;